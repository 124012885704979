import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel,Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import SearchIcon from '@mui/icons-material/Search';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import PopupWithSX from "./components/PopupWithSX";
import { geolocated } from "react-geolocated";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useGeolocated } from "react-geolocated";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import ZoomVideo from '@zoom/videosdk';
import { format } from 'date-fns';
import ReactPlayer from "react-player";
import { useState,useRef,useEffect } from "react";
import { MuiOtpInput } from 'mui-one-time-password-input'
import HomeIcon from '@mui/icons-material/Home';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "./axiosweb";
import useTable from "./components/useTable";
import useTableSmall from "./components/useTablesmall";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import PuffLoader from "react-spinners/PuffLoader";
import NoImage from './asset/noimage.png';
import DirectoryImage from './asset/directoryicon.png'
import DirectoryImageNeon from './asset/directoryneon.png'
import DoorImage from './asset/doorpinicon.png'
import DoorImageNeon  from './asset/doorpinneon.png'
import OfficeImage from './asset/officeicon.png'
import OfficeImageNeon  from './asset/officeneon.png'
import DeliveryImage from './asset/deliveryicon.png'
import DeliveryImageNeon  from './asset/deliveryneon.png'
import Popup from "./components/Popup";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import Webcam from "react-webcam";
import _ from "lodash";
import { useParams,useNavigate  } from "react-router-dom";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import { SxProps } from "@mui/material";
import {  ThemeProvider } from '@mui/material/styles';
//import { LocalVideoTrack } from "twilio-video";
import useToken from "./useToken";
import Theme from './components/ItemTheme';
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const {Device} = require('twilio-client');

const { connect,createLocalTracks,createLocalVideoTrack} = require('twilio-video');
const {LocalDataTrack} = require('twilio-video');
const videoConstraints = {
  width: 540,
  facingMode: "environment"
};
//const WebcamComponent = () => <Webcam />;
async function notifyUser(title,desc){
  if(!"Notification" in window){
    alert("Desktop notifications not available in your browser..");
  }
  else if (Notification.permission === "granted"){
    console.log("in notificatin");
    var options = {
      body: desc,
     // icon: 'https://mdvaccess.com/webviewer/images/icon.png',
      requireInteraction: true,
    };

    const notification = new Notification(title,options);

    notification.onclick = () => {
     
      window.focus();
      notification.close();
    }
  }
  else if(Notification.permission !== "denied"){
    Notification.requestPermission().then((permission) => {
      if(permission == "granted"){
        var options = {
          body: desc,
         // icon: 'https://mdvaccess.com/webviewer/images/icon.png',
          requireInteraction: true,
        };
    
        const notification = new Notification(title,options);
        notification.onclick = () => {
     
          window.focus();
          notification.close();
        }
      }

    })

  }
}

function QRWebViewer (props) {
   // const {token} = useToken();
   const { RefreshToken,token,switchU } = props;
  const theme = Theme;
  const device = new Device();
  let localVideo = ZoomVideo.createLocalVideoTrack();

  const videoRef = useRef(null);
  const {id} = useParams();
  let [dataTrack,setDataTrack] = useState(null);
  //const {RefreshToken} = props;
  let [dataOfWebViewer,setDataOfWebViewer] = useState([]);
  let [dataOfUser,setDataOfUser] = useState([]);
  let [maindataOfUser,setMainDataOfUser] = useState([]);
  const [number, setNumber] = useState("");
  const [conn, setConn] = useState(null);
  const [unlockingMode,setUnlockingMode] = useState('');
  let [mainView,setMainView] = useState(true);
  const [userResponded,setUserResponded] = useState(false);
  let [deviceToken,setDeviceToken] = useState('');
  let [videoToken,setVideoToken] = useState('');
  const { getAlertTitle} = GlobalAlert();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [filterText, setfilterText] = useState('');
  const [isId, setIsID] = useState(true);
  const [isInProxy, setIsInProxy] = useState(true);
  const myContainer = useRef(null);
  const [timer, setTimer] = useState(600);
  const [idletimer, setIdleTimer] = useState(300);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [wrongPin, setWrongPIN] = useState(0);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
  const zoomClient = ZoomVideo.createClient();
  
  /*let timerid = 0;
  const startTimer = () => {
    if(timer > 0){
      timerid = setInterval(countDown(), 1000);
    }
  }
  const countDown = () => {
    let seconds = timer - 1;
    setTimer(seconds);
    console.log('time is '+timer);
    if(seconds == 0){
      clearInterval(timerid);
    }
  }*/
  const sx: SxProps = {
    "& .MuiDialog-container": {
      alignItems: "flex-start"
    }
 };
  const Ref = useRef(null);
  const [timerCount, setTimerCount] = useState('');
  
  
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
      let { total, hours, minutes, seconds } 
                  = getTimeRemaining(e);
      if (total >= 0) {

          // update the timer
          // check if less than 10 then we need to 
          // add '0' at the beginning of the variable
          setTimerCount(
              
              (minutes > 9 ? minutes : '0' + minutes) + ':'
              + (seconds > 9 ? seconds : '0' + seconds)
          )
      }
      else{
        
        console.log('Time is finished');
        if (Ref.current) clearInterval(Ref.current);
       // handleEndCall();
        setTimerCount('');
        /* if(conn != null){
          console.log('Connection is not null');
          conn.disconnect();
        }
        if(devices != null){
          console.log('devices is not null');
          try{
          devices.disconnectAll();
          }catch (error){

          }
        //  devices.destroy();
        }
        setState(states.READY);
        setConn(null);
        setDevices(null);
        if(currentRoom != null){
          currentRoom.disconnect();
          setCurrentRoom(null);
          dataTrack = null;
          setDataTrack(dataTrack);
      } */
      }
  }
  const cleanTimer = (e) => {
    setTimerCount('00:00');
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
  }, 1000)
  Ref.current = id;
  }
  const getCleanTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if 
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 0);
    return deadline;
}
  const clearTimer = (e) => {
  
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next    
    setTimerCount('00:59');

    // If you try to remove this line the 
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
        startTimer(e);
    }, 1000)
    Ref.current = id;
}
  const degreesToRadians = (degrees) => {
    var radians = (degrees * Math.PI)/180;
    return radians;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if 
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 59);
    return deadline;
}

  const [distanceInkm, setdistanceInKm] = useState(0);

  const calcCoordsDistance = (startinglat, startinglong, destinationlat,destinationlong) =>  {
    const startingLat = degreesToRadians(startinglat);
    const startingLong = degreesToRadians(startinglong);
    const destinationLat = degreesToRadians(destinationlat);
    const destinationLong = degreesToRadians(destinationlong);

    // Radius of the Earth in kilometers
    const radius = 6571;

    // Haversine equation
    const distanceInKilometers = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
    Math.cos(startingLat) * Math.cos(destinationLat) *
    Math.cos(startingLong - destinationLong)) * radius;

    return (distanceInKilometers * 100.000) / 100.000;
}
const [issetlc, setissetlc] = useState(false);
const [currentRoom,setCurrentRoom] = useState(null);
  const [ispressed,setIsPressed] = useState(false);
  const getMyPostData = async () => {
    setLoading(true);

    if(id != ""){
    try {
      const res = await axios.get("/accesswebapp.php?licensekey="+id ,{
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      });
      console.log("data format"+JSON.stringify( res));
      if(res.data.mydoorview.status == "success"){
      setDataOfWebViewer(res.data.mydoorview);
      //setDeviceToken(res.data.mydoorview.voiceToken);
      //setVideoToken(res.data.mydoorview.videoToken);
      setLoading(false);
      console.log("token "+res.data.mydoorview.voiceToken);
      let loc_no = res.data.mydoorview.loc_number;
      loc_no = loc_no.substring(1);
      setCallLocation(loc_no);
      setLocationCode(res.data.mydoorview.locationcode);
      setLocationName(res.data.mydoorview.locationname);
      setUnlockingMode(res.data.mydoorview.unlocking_mode);
      getUserData(res.data.mydoorview.pid,res.data.mydoorview.locationcode);
      await zoomClient.init('en-US', 'Global', { patchJsMedia: true });
      setLocalVideoTrack(true);
      //console.log(' coords '+res.data.mydoorview.unlocking_mode);
     
      
     // devices.setup(res.data.mydoorview.voiceToken);
    // let localVideo = ZoomVideo.createLocalVideoTrack();
     //const dd = async () => {await localVideo?.start(videoRef.current)};
      // dd();
      }
      else{
        setIsID(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      /*if(error.response.message == 'UnAuthorized'){
        RefreshToken();
			}*/
    }
  }
  else{
    setIsID(false);
  }
  };

  const getMyToken = async () => {
    setLoading(true);
    console.log("get my token called");

    setIsPressed(true);
    try {
      const res = await axios.get("/update_token_resident.php?deviceToken="+ dataOfWebViewer.licence_key + number ,{
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      });
      console.log("data format"+JSON.stringify( res));
      if(res.data.mydoorview.status == "success"){
      //setDataOfWebViewer(res.data.mydoorview);
      setDeviceToken(res.data.mydoorview.token);
      
     
      console.log("token "+res.data.mydoorview.token);
        let currentvideoToken ;
        const requestBody = {
          'tpc' : locationName,
          'uid' :'qeuser',
          'rt' : 1,
      };
        const requestOptions = {
          method: 'POST',
          headers: {
              Accept:  'application/json',
              'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: JSON.stringify(requestBody)
      };

    /*  fetch("https://dev.mdvaccess.com/php/zoomtoken.php?tpc="+dataOfWebViewer.locationname+"&uid=qeuser&rt=1", requestOptions
      ).then( async  response => {
        setLoading(false);
        const data = await response;
          const token =  data.mydoorview.token;
          console.log("token gpt "+ data.mydoorview.token);
          setVideoToken(token);
          currentvideoToken = token;
      }).catch(error => {
        console.log(error.response);
      }).finally(() => {
        const res = await axios.get("/zoomtoken.php?tpc="+ dataOfWebViewer.locationname + "&uid=qeuser&rt=1" ,{
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        });*/
        const resdt = await axios.get("/zoomtoken.php?tpc="+ dataOfWebViewer.locationname + "&uid=qeuser&rt=1" ,{
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        });
        console.log("data format"+JSON.stringify( resdt));
        if(resdt.data.mydoorview.token != null){
        const device = new Device();
        const token =  resdt.data.mydoorview.token;
        console.log("token gpt "+ resdt.data.mydoorview.token);
        setVideoToken(resdt.data.mydoorview.token);
        currentvideoToken = token;
        const init = async () =>{

          
    
    
    
          try {
    
            
            const first8 = locationCode.slice(0, 8);
            await zoomClient.join(locationName, currentvideoToken, 'qeuser', first8).catch((e) => {
              console.error("locationName",locationName);
              console.error("currentvideoToken",currentvideoToken);
              console.error("password",locationCode);
              console.error("error in joining session",e);
    
             
    
            });
    
            const zoomSession = zoomClient.getMediaStream();
    
            const startVideoOptions = {
    
              hd: false,
    
              ptz: zoomSession?.isBrowserSupportPTZ(),
    
              originalRatio: true
    
            };
    
            await zoomSession?.startVideo(startVideoOptions);
    
            const canvasElement = document.getElementById('local-media') ;
    
            zoomSession?.renderVideo(
    
              canvasElement,
    
              zoomClient.getSessionInfo().userId,
    
              canvasElement.width,
    
              canvasElement.height,
    
              0,
    
              0,
    
              3
    
            );
    
            zoomClient.on('chat-on-message', (payload) => {
    
              console.log(payload)
    
              console.log(`Message: ${payload.message}, from ${payload.sender.name} to ${payload.receiver.name}`);


              let data = payload.message;



              if(data == "Unlock"){

                if(openlock == 0){

                  setOpenLock(1);

                 var node = document.getElementById('local-media');

                 var canvas = document.createElement("canvas");

               canvas.width = node.videoWidth;

               canvas.height = node.videoHeight;

               canvas.getContext('2d')

                     .drawImage(node, 0, 0, canvas.width, canvas.height);

                     console.log('data url is'+canvas.toDataURL());

                     //setOpenSnackData('data url is'+canvas.toDataURL());

                     let bitmap = canvas.toDataURL();

                  openLockDtata("","Call Unlock","Call Unlock",bitmap,"");

                

              }

              }
    
            })
    
          
    
           
    
          } catch (e) {
    
            console.error('Error joining Zoom session or starting video', e);
    
          }
    
        }
    
        init();
  device.setup(res.data.mydoorview.token);
  device.audio.speakerDevices.set('default');
  device.on('incoming', connection => {
    setState(states.INCOMING);
    setConn(connection);
    connection.on("reject", () => {
      console.log('call reject');
      setState(states.READY);
      setConn(null);
    });
   
  });

  device.on('ready', device => {
    
    setState(states.READY);
    console.log('device is ready' + putCall);
    if(putCall == true){
      setputCall(false);
      console.log('device is ready in side');
    let call =  device.connect({ To: number });
    clearTimer(getDeadTime());
    call.on('accept', call => {
      console.log('The incoming call was accepted or the outgoing call media session has finished setting up.');
    });
    uploadVideoLog();
    setDevices(device);
      setOpenLock(0);
      setCustomOpenLock(0);
      dataTrack = true;
        setDataTrack(dataTrack);
       
       
        //let videoTrack = createLocalTracks();
        
      }
  });
  device.on('accept',connection => {
    console.log('The incoming call was accepted or the outgoing call');
  });
  device.on('connect', connection => {
    setConn(connection);
    connection.on('accept', call => {
      console.log('The incoming call was accepted or the outgoing call');
    });
    if(currentIdOfvideoLog != "")
    uploadVideoLogStatus();
    
 
  
    setState(states.ON_CALL);
  });

  /*device.on('ringing', hasEarlyMedia => {
    // setConn(connection);
    // uploadVideoLogStatus();
    // setState(states.ON_CALL);
    console.log("video is ringing ");
   });

  device.on('open', connection => {
   // setConn(connection);
   // uploadVideoLogStatus();
   // setState(states.ON_CALL);
   console.log("video is connected ");
  });*/
  device.on('disconnect', connection => {
    console.log('call disconnect');
    cleanTimer(getCleanTime());
    setState(states.READY);
    setConn(null);
    setCurrentIdOfvideoLog('');
    zoomClient.leave();
   // if(currentRoom != null){
     // currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      setDataTrack(dataTrack);
 // }
  });

  device.on("cancel", () => {
    console.log('call cancel');
    cleanTimer(getCleanTime());
    setState(states.READY);
    setConn(null);
    //if(currentRoom != null){
      //currentRoom.disconnect();
      setCurrentRoom(null);
      zoomClient.leave();
      dataTrack = null;
      setDataTrack(dataTrack);
 // }
  });
  device.on("reject", () => {
    console.log('call reject');
    cleanTimer(getCleanTime());
    setState(states.READY);
    setConn(null);
   // if(currentRoom != null){
      //currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      setDataTrack(dataTrack);
      zoomClient.leave();
  //}
  });

  device.on('error', function(error) {
    console.log('call error');
    cleanTimer(getCleanTime());
    console.log(error.message);
    setState(states.READY);
    setConn(null);
    //if(currentRoom != null){
    // currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      zoomClient.leave();
      setDataTrack(dataTrack);
  //}
  });
       
      };
     
      
      }
      else{
        //console.log(error.message);
        cleanTimer(getCleanTime());
        setLoading(false);
        setIsPressed(false);
        setOpenSnackData("There was an error while placing a call, please try again later.");
   setSeverityData("error");
   setMainView(true);
   setOpenSnack(true);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setIsPressed(false);
      setOpenSnackData("There was an error while placing a call, please try again later.");
   setSeverityData("error");
   setMainView(true);
   setOpenSnack(true);
     
    }
  
 
  };

  useEffect(() => {
    //let isInproxy = false;
    if(dataOfWebViewer.dis_prox != '1' && coords){
      // if(isGeolocationAvailable && isGeolocationEnabled && coords){
       let lat = coords.latitude;
       let long = coords.longitude;

       let distance = calcCoordsDistance(lat,long,dataOfWebViewer.latitude,dataOfWebViewer.longitude);
       console.log(distance + 'ddis');
       if(distance <= 0.5){
         setIsInProxy(true);
         //isInproxy = true;
        // startTimer();
       }
       else{
         setIsInProxy(false);
       }
    /* }else{
       setIsInProxy(false);
     }*/
     setdistanceInKm(distance);
     }
     else{
       setIsInProxy(true);
       //isInproxy = true;
      // startTimer();
     }
     /*createLocalVideoTrack().then(track => {
      setLocalVideoTrack(track);
      const localMediaContainer = document.getElementById('local-media');
      // localMediaContainer.appendChild(track.attach());
      if(localMediaContainer != null){
        
        track.attach(localMediaContainer);
         
      }
    });*/
    
  }, [dataOfWebViewer,coords]);

  useEffect(() => {
    //console.log("timer data is "+timer);
    if(issetlc == false || timer == 115){
    if(localVideoTrack != null){
      const localMediaContainer = document.getElementById('local-media');
      // localMediaContainer.appendChild(track.attach());
      if(localMediaContainer != null){
        
        try {
          let localVideo = ZoomVideo.createLocalVideoTrack();
          const dd = async () => {await localVideo?.start(videoRef.current)};
            dd();
          } 
          catch (error) {
            //console.error('Error joining Zoom session or starting video', error);
      
            setOpenSnackData('Error joining Zoom session or starting video');
            setSeverityData("error");
            setOpenSnack(true);
          }
          setissetlc(true);
        }
        else{
          setissetlc(false);
        }
      }
     
     }
     if(timer > 0){ 
     if(idletimer == 0 || wrongPin >= 5) {
      console.log("in  inner timer");
      setTimer(0);
  
        handleEndCall();
      if(wrongPin >= 5) {
        postWarningData();
      }
     
     }
    
    }
    if(timer <= 0){
      handleEndCall();
      window.open("about:blank", "_self");
      window.close();
    }
    if(conn != null){
      console.log("connection status "+conn.status());
      if(currentIdOfvideoLog != ""){
      uploadVideoLogStatus();
    }
    if(timerCount == ""){
      if(conn != null){
        handleEndCall();
      }
    }
    }
  },[isInProxy,localVideoTrack,timer,idletimer,wrongPin,timerCount]);
  const getUserData = async (pid,loccode) => {
    setLoading(true);
    console.log("get user data called");
    
    try {
      const res = await axios.get("/getallusers.php?pid="+pid+ "&loc_code="+loccode ,{
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      });
      console.log("data format"+JSON.stringify( res.data.mydoorview.users));
      //setDataOfUser(res.data.mydoorview.users);
      let used = res.data.mydoorview.users;
      let newdata = [];
      for(let j= 0 ; j < used.length ; j ++){
        if(used[j].user.hide == '1'){
          
        }else{
          newdata.push (used[j]);
        }
      }
      setDataOfUser(newdata);
      setMainDataOfUser(used);
      setLoading(false);
      
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      /*if(error.response.message == 'UnAuthorized'){
        RefreshToken();
			}*/
    }
  }

  const postWarningData= async () => {
    const requestBody = {
      'loc_code' : dataOfWebViewer.locationcode,
      'pid' :dataOfWebViewer.pid,
      'type' : '0',
      'totalattempt':'5'
  };
  const requestOptions = {
      method: 'POST',
      headers: {
          Accept:  'application/json',
          'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(requestBody)
  };
  fetch("https://dev.mdvaccess.com/php/warningalert.php?pid="+dataOfWebViewer.pid+"&loc_code="+dataOfWebViewer.locationcode+"&type=0&totalattempt=5", requestOptions
      ).then( async  response => {
          const data = await response;
         /* console.log(response);
          if(!response.ok){
              throw new Error();
          }
          else{*/
         /* setOpenSnackData("Successful. You may now open the Door.");
          setSeverityData("success");
          setOpenSnack(true);
          setLoading(false);
          uploadLog(uid,utype,pintype,1,bitmap,guestnames,"");*/
           
            //getMyPostData();
          //}
              
      }).catch(error => {
        
       /* setLoading(false);
        setOpenSnackData('There was an error while opening door, please try again later');
        setSeverityData("error");
        setOpenSnack(true);
        uploadLog(uid,utype,pintype,0,bitmap,guestnames,"");*/
      }).finally(() => {
        
      });

  }
  
  const [open, setOpen] = React.useState(false);
  const [openEndCall,setOpenEndCall] = React.useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [titleforalert, setTitleforalert] = React.useState("Would you like to enable notifcications?");
  const [openlock, setOpenLock] = React.useState(0);
  const [customopenlock, setCustomOpenLock] = React.useState(0);
  /*const [uid,setuid] = useState("");
  const [utype,setutype] = useState("");
  const [pintype,setpintype] = useState("");
  const [bitmap,setbitmap] = useState("");
  const [guestnames,setguestnames] = useState("");*/
  const openLockDtata= async (uid,utype,pintype,bitmap,guestnames,floorUnlockNo) => {
    const requestBody = {
      'loc_code' : dataOfWebViewer.locationcode,
      'type' :'webp',
      'username' : ''
  };
  const requestOptions = {
      method: 'POST',
      headers: {
          Accept:  'application/json',
          'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: JSON.stringify(requestBody)
  };
  if(pintype == "Call Unlock Custom"){
    /*if(customopenlock == 0){
      setCustomOpenLock(1);

      fetch("https://mdvaccess.com/php/send_notification_vr.php?loc_code="+dataOfWebViewer.locationcode+"&type="+utype+"&username="+uid, requestOptions
      ).then( async  response => {
          const data = await response;
        
              
      }).catch(error => {
        
     
      }).finally(() => {
     //   setOpenLock(0);
      });
    }*/
    fetch("https://dev.mdvaccess.com/php/unlockbrivocustomlock.php?userid="+dataOfWebViewer.pid+ "&loc_code="+dataOfWebViewer.locationcode+"&custom_id="+floorUnlockNo, requestOptions
    ).then( async  response => {
        const data = await response;
        setOpenSnackData("Successful. You may now open the Door.");
        setSeverityData("success");
        setOpenSnack(true);
        setLoading(false);
        uploadLog(uid,utype,"Call Unlock",1,bitmap,guestnames,"");
            
    }).catch(error => {
      setLoading(false);
        setOpenSnackData('There was an error while opening door, please try again later');
        setSeverityData("error");
        setOpenSnack(true);
        uploadLog(uid,utype,"Call Unlock",0,bitmap,guestnames,"");
    }).finally(() => {
      setOpenLock(0);
     
    });
    
  }else{
  fetch("https://dev.mdvaccess.com/php/send_notification_vr.php?loc_code="+dataOfWebViewer.locationcode+"&type="+utype+"&username="+uid, requestOptions
      ).then( async  response => {
          const data = await response;
         /* console.log(response);
          if(!response.ok){
              throw new Error();
          }
          else{*/
          setOpenSnackData("Successful. You may now open the Door.");
          setSeverityData("success");
          setOpenSnack(true);
          setLoading(false);
          uploadLog(uid,utype,pintype,1,bitmap,guestnames,"");
           
            //getMyPostData();
          //}
              
      }).catch(error => {
        
        setLoading(false);
        setOpenSnackData('There was an error while opening door, please try again later');
        setSeverityData("error");
        setOpenSnack(true);
        uploadLog(uid,utype,pintype,0,bitmap,guestnames,"");
      }).finally(() => {
        setOpenLock(0);
      });
      if(floorUnlockNo != "" && unlockingMode == "1" ){
      fetch("https://dev.mdvaccess.com/php/unlockbrivolockfloor.php?userid="+dataOfWebViewer.pid+ "&loc_code="+dataOfWebViewer.locationcode+"&floor="+floorUnlockNo, requestOptions
      ).then( async  response => {
          const data = await response;
         
              
      }).catch(error => {
        
      }).finally(() => {
        
      });
    
    }
  }
  }
  const [putCall,setputCall] = useState(false);
  const handleClose = async () => {
    //setOpen(false);
   // await notifyUser('test','onetiw thre').then(()=>{
    //  setUserResponded(true);
   // });
   setUserResponded(false);
  // setOpenSnackData("Sorry, this functionality is in progress!!");
  // setSeverityData("error");
   setMainView(true);
   /*createLocalVideoTrack().then(track => {
    setLocalVideoTrack(track);
   });*/
  /* const timeout = setTimeout(() => {
    if(localVideoTrack != null){
      const localMediaContainer = document.getElementById('local-media');
      // localMediaContainer.appendChild(track.attach());
      if(localMediaContainer != null){
        
        localVideoTrack.attach(localMediaContainer);
        
      }
     
     }
  }, 500);*/
   getMyToken();
   //setOpenSnack(true);
   


  };
  const handleCloseActive =() =>{
    //setOpen(false);
    setputCall(false);
    setUserResponded(false);
    
  }

  const handleEnd=() =>{
      setOpen(false);
  }

  const handleAccept=() =>{
    setOpen(false);
    if(currentConnection != null){
      currentConnection.accept();
      setOpenPopup(true);
      setOpenEndCall(true);
    }
  }

  const handleEndCall = () =>{
    //devices.disconnectAll();
    console.log("Here in end call");
    if(conn != null){
      console.log("connection is not null");
      conn.disconnect();
    }
    if(devices != null){
      console.log("devices is not null");
      /*try{
        devices.connection.disconnect();
        devices.disconnectAll();
      }catch(error){
        console.log("error is "+error.message);
      }*/
    //  devices.destroy();
    }
    setissetlc(false);
    setState(states.READY);
    setConn(null);
    setDevices(null);
   
    setCurrentRoom(null);
    dataTrack = null;
    setDataTrack(dataTrack);
    zoomClient.leave();
   
   /* setOpenEndCall(false);
    if(currentConnection != null){
      currentConnection.reject();
      currentConnection = null;
    }*/
  }

  const handleUnlock = () =>{
    if(currentConnection != null){
      if(callLocCamBrand == "ResidentLink"){
        dataTrack.send("Unlock");
      }
      else{
      currentConnection.sendDigits(callSendDTMFCode);
      }
    }
  }
  const headCells = [
    { id: 'username', label: 'Resident Name'},
   
   
  ];
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const FilterMenu = {
    id: "1",
    label: "User Name",
    items: [
      {
        label: "User Name",
        
         
      },
    ]
  };
  const handleSearchToText = (val) => {
    //let target = e.target;
    setIdleTimer(300);
    setFilterFn({
        fn: items => {
          if (val == "")
          return items;
          else if(!isNaN(val)){
            return items;
          }
          else{
            return items.filter(x => ((x.user.fname.toString().toLowerCase()+" "+x.user.lname.toString().toLowerCase()).includes(val.toLowerCase())))

          }
                
        }
    })
    setPageToNewOne();
}
  const handleSearch = () => {
    //let target = e.target;
    setIdleTimer(300);
    setFilterFn({
        fn: items => {
          if (filterText == "")
          return items;
          else{
            return items.filter(x => ((x.user.fname.toString().toLowerCase()+" "+x.user.lname.toString().toLowerCase()).includes(filterText.toLowerCase())||(x.user.dial_code.toString() === (filterText.toLowerCase()))))

          }
                
        }
    })
    setPageToNewOne();
}
const handleReset =() =>{
  setIdleTimer(300);
  setfilterText('');
  if(document.getElementById('input-location') != null)
    document.getElementById('input-location').value ='';
  setFilterFn({
    fn: items => {
      return items;
            
    }
})
      setPageToNewOne();
}
  
  const {
    TblContainer,
    TblHead,
    TblPagination,
    BelowTblPagination,
    recordsAfterPagingAndSorting,
    setPageToNewOne
} = useTableSmall(dataOfUser, headCells, filterFn,"");
const [currentConnection,setcurrentConnection] = useState(null);
/*const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(dataOfUser, headCells, filterFn,7,350);*/
  const [callLocCamBrand, setCallLogCamBrand] = useState('');
  const [callSendDTMFCode, setCallSendDTMFCode] = useState('');
  const states = {
    CONNECTING: "Connecting",
    READY: "Ready",
    INCOMING: "Incoming",
    ON_CALL: "On call",
    OFFLINE: "Offline"
  };
  const [callCamIp, setCallCamIp] = useState('');
  const [callUserName, setCallUserName] = useState('');
  const [callPassword, setCallPassword] = useState('');
  const [callLocation, setCallLocation] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [locationName, setLocationName] = useState('');
  const [uniCaller, setUniCaller] = useState('');
  const [floorUnlock,setFloorUnlock] = useState('');
  const [uniCallerType, setUniCallerType] = useState('');
  const [currentCalleeFname, setCurrentCalleeFname] = useState('');
  const [currentCalleeLname, setCurrentCalleeLname] = useState('');
  const [currentCalleetype, setCurrentCalleetype] = useState('');
  const [currentCalleePhoneN0, setCurrentCalleePhoneN0] = useState('');
  const [currentCalleeToken, setCurrentCalleeToken] = useState('');

  const handleOfficeClick = () =>{
    if(ispressed == false){
    setIdleTimer(300);
    console.log('office clicked');
    if(dataOfWebViewer.loc_office_name != ""){
      setTitleforalert('Call '+dataOfWebViewer.loc_office_name+"?");
    }else{
    setTitleforalert('Call Office?');
  }
    let number = '';
    if(dataOfWebViewer.web_status == 1){
    number = dataOfWebViewer.loc_telephone_dial;
    number = number.replace("(","");
    number = number.replace(")","");
    number = number.replace(/-/g,"");
    number = "1"+number; 
    setCurrentCalleetype('Phone');
    setUniCallerType('Phone');
    
    setCurrentCalleePhoneN0(number);
    setCurrentCalleeToken('');
    }else{
      number = "14438443135";
      setCurrentCalleetype('web');
      setUniCallerType('web');
     
    }
    setFloorUnlock('');
    setOpenLock(0);
    setCustomOpenLock(0);
    setUniCaller('Office');
    setCurrentCalleePhoneN0(number);
    setCurrentCalleeToken('');
    setCurrentCalleeFname(dataOfWebViewer.loc_office_name != ""  ? dataOfWebViewer.loc_office_name : 'Office');
    setCurrentCalleeLname('');
    setNumber(number);
    setTypeOfpin(2);
    setputCall(true);
    setUserResponded(true);
   
  }
  }
  const CallUser  = (val) => {
    if(ispressed == false){
    setIdleTimer(300);
    let deletid;
      for(let i = 0; i < dataOfUser.length ; i++){
          if(dataOfUser[i].user.id == val ){
              deletid = i;
              break;
          }
      }
     
      if(dataOfUser[deletid].user.type == "Phone"){
        
        setNumber("1"+dataOfUser[deletid].user.phoneno)
      }else{
        setNumber(dataOfUser[deletid].user.virtualno);
      }
      setUniCaller(dataOfUser[deletid].user.fname + " "+ dataOfUser[deletid].user.lname);
      setFloorUnlock(dataOfUser[deletid].user.floor);
      setOpenLock(0);
      setCustomOpenLock(0);
      setUniCallerType(dataOfUser[deletid].user.type);
      setCurrentCalleetype(dataOfUser[deletid].user.type);
      if(dataOfUser[deletid].user.type == "Phone"){
        setCurrentCalleePhoneN0("1"+dataOfUser[deletid].user.phoneno)
      }else{
      setCurrentCalleePhoneN0(dataOfUser[deletid].user.virtualno);
      }
      setCurrentCalleeToken(dataOfUser[deletid].user.devicetoken);
      setCurrentCalleeFname(dataOfUser[deletid].user.fname);
      setCurrentCalleeLname(dataOfUser[deletid].user.lname);
      setTypeOfpin(2);
      setputCall(true);
      setTitleforalert('Call '+dataOfUser[deletid].user.fname + " "+ dataOfUser[deletid].user.lname+"?");
      setUserResponded(true);
      
    }
  }
  const handleBack = () => {
    handleReset();
    setIdleTimer(500);
    
    setMainView(true);
    /*createLocalVideoTrack().then(track => {
      setLocalVideoTrack(track);
     });*/
     /*const timeout = setTimeout(() => {
      if(localVideoTrack != null){
        const localMediaContainer = document.getElementById('local-media');
        // localMediaContainer.appendChild(track.attach());
        if(localMediaContainer != null){
          
          localVideoTrack.attach(localMediaContainer);
          
        }
       
       }
    }, 500);*/
    let localVideo = ZoomVideo.createLocalVideoTrack();
    const dd = async () => {await localVideo?.start(videoRef.current)};
      dd();
    
  }

  
 const [currentIdOfvideoLog,setCurrentIdOfvideoLog] = useState('');
  const uploadVideoLog = async () => {
    //setLoading(true);

    try {
      const params = new URLSearchParams({
        loc_code: dataOfWebViewer.locationcode,
         loc_name: dataOfWebViewer.locationname,
         userfname: currentCalleeFname,
         userlname: currentCalleeLname,
         phone_no: currentCalleePhoneN0,
         usertype: currentCalleetype,
         usertoken: currentCalleeToken,
         status:'0',
         pid: dataOfWebViewer.pid
       });
      const res = await axios.postForm("/addresidentlinkcalllog.php",params,{
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
      },
      });
      console.log("data format"+JSON.stringify( res.data));
      console.log("data format"+ res.data.currentId);
      if(res.data.Error == "None"){
        console.log('log uploaded');
        setCurrentIdOfvideoLog(res.data.currentId);
        setTimeout(() => {
        var node = document.getElementById('local-media');
    var canvas = document.createElement("canvas");
    canvas.width = node.videoWidth;
    canvas.height = node.videoHeight;
    canvas.getContext('2d')
        .drawImage(node, 0, 0, canvas.width, canvas.height);
    console.log('data url is'+canvas.toDataURL());
        //setOpenSnackData('data url is'+canvas.toDataURL());
    let bitmap = canvas.toDataURL();
    uploadCallLogImage(res.data.currentId,bitmap);}, 8000);
      }
      
      //setLoading(false);
      
    } catch (error) {
      console.log(error.message);
     // setLoading(false);
     /* setOpenSnackData("Error while checking Door PIN. Please try again");
        setSeverityData("error");
        setOpenSnack(true);*/
      
    }
  }
  const uploadVideoLogStatus = async () => {
    //setLoading(true);
 console.log('In upload videolog status' +currentIdOfvideoLog);
    try {
      const params = new URLSearchParams({
        status: '1',
        currentid: currentIdOfvideoLog,
       });
      const res = await axios.postForm("/updatecalllogvideostatus.php",params,{
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
      },
      });
      console.log("data format"+JSON.stringify( res.data));
      if(res.data.Error == "None"){
        console.log('log uploaded');
        setCurrentIdOfvideoLog('');
      }
      
      //setLoading(false);
      
    } catch (error) {
      console.log(error.message);
     // setLoading(false);
     /* setOpenSnackData("Error while checking Door PIN. Please try again");
        setSeverityData("error");
        setOpenSnack(true);*/
      
    }
  }
  const uploadCallLogImage = async (videoId,bitmap) => {
   
    
    try {
      const params = new URLSearchParams({
        currentid: videoId,
        log_image: bitmap,
       });
      const res = await axios.postForm("/addcalllogimagesforresidentlink.php",params,{
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
      },
      });
      console.log("data format"+JSON.stringify( res.data));
      if(res.data.Error != "None"){
        console.log('log uploaded');
      }
      
      //setLoading(false);
      
    } catch (error) {
      console.log(error.message);
      //setLoading(false);
     /* setOpenSnackData("Error while checking Door PIN. Please try again");
        setSeverityData("error");
        setOpenSnack(true);*/
      
    }
  }
  const uploadLog = async (user_id,usertype,pintype,status,bitmap,guestnames,rfidone) => {
    setLoading(true);

    
    try {
      const params = new URLSearchParams({
        loc_code: dataOfWebViewer.locationcode,
         loc_name: dataOfWebViewer.locationname,
         userid: user_id,
         usertype: usertype,
         pintype: pintype,
         status: status,
         guestname: guestnames,
        log_image:bitmap,
         rfidpin: rfidone
       });
      const res = await axios.postForm("/addlogforresidentlink.php",params,{
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
      },
      });
      console.log("data format"+JSON.stringify( res.data));
      if(res.data.Error != "None"){
        console.log('log uploaded');
      }
      
      setLoading(false);
      
    } catch (error) {
      console.log(error.message);
      setLoading(false);
     /* setOpenSnackData("Error while checking Door PIN. Please try again");
        setSeverityData("error");
        setOpenSnack(true);*/
      
    }
  }
  const checkDoorPINOwner = async () => {
    setLoading(true);

    
    try {
      const res = await axios.get("/checkdoorpinowner.php?pid="+dataOfWebViewer.pid+ "&loc_code="+dataOfWebViewer.locationcode + "&door_pin=" +textOfEnteredPIN,{
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      });
      console.log("data format"+JSON.stringify( res.data.mydoorview));
      if(res.data.mydoorview.status == "Success"){
          let mainindex;
          for(let i = 0; i < maindataOfUser.length ; i++){
          if(maindataOfUser[i].user.door_pin == textOfEnteredPIN){
           console.log('door pin is '+ maindataOfUser[i].user.door_pin);
           console.log('entered pin is '+textOfEnteredPIN);
           mainindex = i;
           break;
          }
        }
        let callername = maindataOfUser[mainindex].user.fname+ " " + maindataOfUser[mainindex].user.lname;
        
        let type = maindataOfUser[mainindex].user.type;
          var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
       
        openLockDtata(callername,type,"Doorpin",bitmap,"", maindataOfUser[mainindex].user.floor);
      }
      else if(res.data.mydoorview.status == "disabled"){
        setOpenSnackData("PIN entry is not authorized at this location. Please contact management for assistance.");
        setSeverityData("error");
        setOpenSnack(true);
    
        var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
      
    uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      }
      else if(res.data.mydoorview.status == "outtime"){
        setOpenSnackData("PIN entry is not authorized for this time. Please contact management for assistance.");
        setSeverityData("error");
        setOpenSnack(true);
     
        var node = document.getElementById('local-media');
        var canvas = document.createElement("canvas");
      canvas.width = node.videoWidth;
      canvas.height = node.videoHeight;
      canvas.getContext('2d')
            .drawImage(node, 0, 0, canvas.width, canvas.height);
            console.log('data url is'+canvas.toDataURL());
            //setOpenSnackData('data url is'+canvas.toDataURL());
            let bitmap = canvas.toDataURL();
    uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      }
      else{
        setWrongPIN(wrongPin+1);
        setOpenSnackData("You have entered an invalid Door PIN. Please try again.");
        setSeverityData("error");
        setOpenSnack(true);
       
        var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
        uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      }
      setLoading(false);
      
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData("Error while checking Door PIN. Please try again");
     
        var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
        setSeverityData("error");
        setOpenSnack(true);
        uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      
    }
  }

  const checkGuestUser = async () => {
    setLoading(true);

    
    try {
      const res = await axios.get("/checkdoorpin.php?pid="+dataOfWebViewer.pid+ "&loc_code="+dataOfWebViewer.locationcode + "&door_pin=" +textOfEnteredPIN,{
        headers: {
         
          'Content-Type': 'application/json',
      },
      });
      console.log("url is "+"/checkdoorpin.php?pid="+dataOfWebViewer.pid+ "&loc_code="+dataOfWebViewer.locationcode + "&door_pin=" +textOfEnteredPIN);
      console.log("data format"+JSON.stringify( res.data.mydoorview));
      if(res.data.mydoorview.status == "Success"){

        let locationusername =res.data.mydoorview.userdata[0].loc_username;
        let floorofuser = res.data.mydoorview.floor;
        let locationtype = res.data.mydoorview.userdata[0].loc_user_type;
        let guestname = res.data.mydoorview.userdata[0].guest_name;

        if(locationtype == 'ios'){
          locationtype = 'iOS';
        }
        else if(locationtype == 'android'){
          locationtype = 'Android';
        }
      var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
      openLockDtata(locationusername,locationtype,"Doorpin",bitmap,guestname,floorofuser);
        //  openLockDtata();
      }
      else if(res.data.mydoorview.status == "disabled"){
        setOpenSnackData("PIN entry is not authorized at this location. Please contact management for assistance.");
        setSeverityData("error");
        setOpenSnack(true);
       
          var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
          uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      }
      else if(res.data.mydoorview.status == "outtime"){
        setOpenSnackData("PIN entry is not authorized for this time. Please contact management for assistance.");
        setSeverityData("error");
        setOpenSnack(true);
       
          var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
          uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      }
      else{
        setOpenSnackData("You have entered an invalid Door PIN. Please try again.");
        setSeverityData("error");
        setOpenSnack(true);
        setWrongPIN(wrongPin+1);
          var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
          uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      }
      setLoading(false);
      
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setOpenSnackData("Error while checking Door PIN. Please try again");
        setSeverityData("error");
        setOpenSnack(true);
       
          var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
          uploadLog("Unknown","Unknown","Doorpin",0,bitmap,"","");
      
    }
  }
 const handleDoorPINClick = () => {
  if(ispressed == false){
  setIdleTimer(300);
  setTypeOfpin(0);
  setTextOfEnteredPIN('');
  if(document.getElementById('doorpinenter') != null)
  document.getElementById('doorpinenter').value ='';
  
  setOpenPopup(true);
  }
 }
 const [state, setState] = useState(states.CONNECTING);
 const handleDeliveryPINClick = () => {
  if(ispressed == false){
  setIdleTimer(300);
  setTypeOfpin(1);
  setTextOfEnteredPIN('');
  if(document.getElementById('doorpinenter') != null)
  document.getElementById('doorpinenter').value ='';

  setOpenPopup(true);
  }
 }
  const handleDirectory = () => {
    if(ispressed == false){
    setIdleTimer(300);
    //getUserData();
    setMainView(false);
    }
  }
  const handleChangeText = e => {
    console.log(e.target.value);
    let value = e.target.value;
    setfilterText(value);
    console.log(filterText);
    handleSearchToText(e.target.value);
}
useEffect(() => {
getMyPostData();

},[]);
/*
useEffect(() => {
  // getMyPostData();
  if(deviceToken != ""){
  const device = new Device();
   
  device.setup(deviceToken);

  device.on('incoming', connection => {
    setState(states.INCOMING);
    setConn(connection);
    connection.on("reject", () => {
      setState(states.READY);
      setConn(null);
    });
   
  });

  device.on('ready', device => {
    setDevices(device);
    setState(states.READY);
  });

  device.on('connect', connection => {
    setConn(connection);
    setState(states.ON_CALL);
  });

  device.on('disconnect', connection => {
    setState(states.READY);
    setConn(null);
    if(currentRoom != null){
      currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      setDataTrack(dataTrack);
  }
  });

  device.on("cancel", () => {
    setState(states.READY);
    setConn(null);
    if(currentRoom != null){
      currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      setDataTrack(dataTrack);
  }
  });
  device.on("reject", () => {
    setState(states.READY);
    setConn(null);
    if(currentRoom != null){
      currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      setDataTrack(dataTrack);
  }
  });

  device.on('error', function(error) {
    console.log(error.message);
    setState(states.READY);
    setConn(null);
    if(currentRoom != null){
      currentRoom.disconnect();
      setCurrentRoom(null);
      dataTrack = null;
      setDataTrack(dataTrack);
  }
  });

  return () => {
    device.destroy();
    setDevices(null);
    setState(states.OFFLINE);
  };
}
  

  
 }, [deviceToken]);*/
  
  
   useEffect(() => {
   const timercount = setInterval(() => {
      setTimer(timer - 1);
     // console.log('timer is '+timer);
      
    }, 1000);

    if (timer === 0) {
      clearInterval(timercount);
    }
    return () => clearInterval(timercount);
  }); 

  useEffect(() => {
    const timercount = setInterval(() => {
      setIdleTimer(idletimer - 1);
      // console.log('timer is '+timer);
       
     }, 1000);
 
     if (idletimer === 0) {
       clearInterval(timercount);
     }
     return () => clearInterval(timercount);
   }); 

  
  /*useEffect(() => {  let myInterval = setInterval(() => {
    if (timer > 0) {
      //let time = timer -1;
        setTimer(timer -1);
        console.log('timer is '+timer);
    }
    if (timer === 0) {
       
        clearInterval(myInterval)
        device.destroy();
    
    setState(states.OFFLINE);
    } 
}, 1000)})*/
  /*useEffect(() => {
    console.log('token changed');
    setDataOfWebViewer([]);
      setDeviceToken("");
    getMyPostData();
  }, [switchU]);*/
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  
  const [loading,setLoading] = useState(false);
  
  //const currentRoom = null;
  let [typeOfPin, setTypeOfpin] = useState(0);
  let [textOfEnteredPIN,setTextOfEnteredPIN] = useState('');
  const handleEnteredPIN = e => {
    setTextOfEnteredPIN(e.target.value);
  }
  const checkDeliveryPIN = () => {

  }
  const checkDoorPIN = () => {
   /* if(textOfEnteredPIN.length != dataOfWebViewer.pin_length){
      setOpenSnackData("PIN Length Should be" +dataOfWebViewer.pin_length +" digit Only.");
   setSeverityData("error");
   setMainView(true);
   setOpenSnack(true);
   return;
    }*/
   
    if(typeOfPin == 0){
     let ismatched = false;
      for(let i = 0; i < maindataOfUser.length ; i++){
          
             if(maindataOfUser[i].user.door_pin == textOfEnteredPIN){
              console.log('door pin is '+ maindataOfUser[i].user.door_pin);
              console.log('entered pin is '+textOfEnteredPIN);
              ismatched = true;
              break;
             }
              
          
      }
      console.log("ismatched "+ ismatched);
      if(ismatched == true){
        checkDoorPINOwner();
        
      }
      else{
        checkGuestUser();
      }
      
      setMainView(true);
     // setOpenSnack(true);
      setOpenPopup(false);
      
    }
    else if(typeOfPin == 1){
      if(textOfEnteredPIN != dataOfWebViewer.delivery_pin){
        setOpenSnackData("Sorry, The Delivery PIN you entered is invalid");
        setSeverityData("error");
        setMainView(true);
       
        setOpenPopup(false);
        setWrongPIN(wrongPin+1);
          var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
          setOpenSnack(true);
        uploadLog("","Delivery","Delivery",0,bitmap,"","");
      }else{
     
      var node = document.getElementById('local-media');
          var canvas = document.createElement("canvas");
        canvas.width = node.videoWidth;
        canvas.height = node.videoHeight;
        canvas.getContext('2d')
              .drawImage(node, 0, 0, canvas.width, canvas.height);
              console.log('data url is'+canvas.toDataURL());
              //setOpenSnackData('data url is'+canvas.toDataURL());
              let bitmap = canvas.toDataURL();
        setMainView(true);
       setOpenPopup(false);
      openLockDtata("","Delivery","Delivery",bitmap,"","");
    }
    }
  
  }
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  
 /*  const handleDevices = React.useCallback(
    
    mediaDevices =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );
  useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
     // console.log('hendle device called'+devices);
    },
    [handleDevices]
  );  */
 

  return  <ThemeProvider theme={theme} > 
  {isId == false && <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    
   <Typography sx={{
   position: 'absolute',
   width: 'auto',
   height: '1.62rem',
   left: '2.37rem',fontWeight: '700',right: '2.37rem',
   fontSize:{ xs: "1.1rem", md: "1.37rem" },
   lineHeight:{ xs: "1.2rem", md: "1.6rem" },
   
   lineHeight: '1.6rem',
   display: 'flex',
   top: '0.68rem',
   alignItems: 'center',
   color: '#FFFFFF',
   fontFamily: "Lato"
 }}>Location Not Found, Please Check Your URL</Typography>
   
    
    
   
    </Box>}
  
  {isId == true  && <>
  {isInProxy == false && <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
  
 <Typography sx={{
   position: 'absolute',
   width: 'auto',
   height: '1.62rem',
   left: '2.37rem',fontWeight: '700',right: '2.37rem',
   fontSize:{ xs: "1.1rem", md: "1.37rem" },
   lineHeight:{ xs: "1.2rem", md: "1.6rem" },
   
   lineHeight: '1.6rem',
   display: 'flex',
   top: '0.68rem',
   alignItems: 'center',
   color: '#FFFFFF',
   fontFamily: "Lato"
 }}>Either you haven't enabled your location on your browser or you are not near the location. Please enable location on your browser </Typography>
 
  
  
 
  </Box>}
  {isInProxy == true &&  timer <= 0  && <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
  
 <Typography sx={{
   position: 'absolute',
   width: 'auto',
   height: '1.62rem',
   left: '2.37rem',fontWeight: '700',right: '2.37rem',
   fontSize:{ xs: "1.1rem", md: "1.37rem" },
   lineHeight:{ xs: "1.2rem", md: "1.6rem" },
   
   lineHeight: '1.6rem',
   display: 'flex',
   top: '0.68rem',
   alignItems: 'center',
   color: '#FFFFFF',
   fontFamily: "Lato"
 }}>Your Session Has Expired, Please Scan QR Again. </Typography>
 
  
  </Box>}
  {isInProxy == true &&  timer > 0  && <>
  
 <img  src={"https://dev.mdvaccess.com/"+dataOfWebViewer.loc_photo} style={{height:"3.75rem", width:"100%",'object-fit': 'contain'}}></img>

<Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">

{mainView == true && <Box sx={{ position: 'absolute',
        width:'100%',
        
        left: '0rem',
        display:'flex',
        flexDirection:'column',
        alignContent:'space-between',
        backgroundColor:"menubarprimary.main"}} backgroundColor="menubarprimary.main">

<Grid
  container
  direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={2}
 
>
<Grid item >
<Stack
 justifyContent="center"
 alignItems="center"
  direction="row"
  spacing={2}
  sx={{ border:'3px solid #525252',}}
>

<Box  height={340} width="50%" >
 <video  
    id='local-media'
    width="100%"
    height={340} style={{'object-fit': 'cover'}} ref = {videoRef}>
      
  </video> 
  <Typography sx={{ fontFamily: 'Lato',
            fontWeight: '700',
            fontSize: '1.22rem',
            lineHeight: '1.47rem',
            textAlign:'left',
            color:'#fff',
            position: 'absolute',
            top:'1%',
            left: '1%',
            'z-index': '1'
          }}
            >{timerCount}</Typography>
</Box>

<Stack
 justifyContent="center"
 alignItems="center"
  direction="column"
  spacing={2}
  height={340} width="50%" 
>

<Typography  sx={{ fontFamily: 'Lato',
            fontWeight: '700',
            fontSize: '1.22rem',
            lineHeight: '1.47rem',
            textAlign:'center',
            color:'#fff',}}>{dataOfWebViewer.locationname}</Typography>
<Typography  sx={{ fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',textAlign:'center',}}>{dataOfWebViewer.loc_add_one} </Typography>
<Typography  sx={{ fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',textAlign:'center',}}>{dataOfWebViewer.loc_add_two} </Typography>
<Typography  sx={{ fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',textAlign:'center',}}>{dataOfWebViewer.loc_telephone_dial}</Typography>
 <img  src={dataOfWebViewer.loc_url_watermark}  style={{'object-fit': 'contain',height:'50px', width:"50%"}}></img>
</Stack>

</Stack>
</Grid>
<Grid item >
  <Box>
<Grid
  container
  direction="row"
  justifyContent="center"
  alignItems="center"
  spacing={2}
 
>
<Grid item xs={2}>
<img  src={dataOfWebViewer.wel_message_headerlogo }  style={{'object-fit': 'contain',height:'50px', width:"50px", marginLeft:'15px'}}></img>
</Grid>
<Grid item xs={1}></Grid>
<Grid item xs={9}>
<Typography  sx={{
   fontFamily: 'Lato',
   fontWeight: '700',
   fontSize: '1.1rem',
   lineHeight: '1.47rem',
  color:dataOfWebViewer.wel_message_headercolor ? dataOfWebViewer.wel_message_headercolor : '#fff'
}}>{dataOfWebViewer.wel_message_header}</Typography>
<Typography  sx={mainThemeStyle.popUpLable}>{dataOfWebViewer.welcomemessage}</Typography>
</Grid>
  </Grid>
  </Box>
</Grid>

  
  
{ state != states.ON_CALL && ispressed == false &&
<Grid item >
<Stack direction="row" spacing={0.5}>
  { dataOfWebViewer.dis_off != "1" && <Box height={120}  justifyContent="center"
 alignItems="center"
 sx={{
  '--primary-neon':'rgba(50,119,255,.9)',
  '--light-neon':'rgba(50,119,200,.7)',
  '--pulse-neon':'rgba(50,119,175,.5)',
  '--bright-neon':'rgba(50,119,220,1)',
  '--off-neon':'#333',
  
  '--neon-shadow':'0 0 7px 4px var(--light-neon), 0 0 7px 4px var(--light-neon) inset',
  '--neon-shadow-pulse':'0 0 8px 5px var(--pulse-neon), 0 0 8px 5px var(--pulse-neon) inset',
  '--neon-shadow-bright':'0 0 8px 4px var(--bright-neon), 0 0 8px 4px var(--bright-neon) inset',
  '--neon-shadow-text':'0 0 7px var(--primary-neon)',
  border:'3px solid var(--primary-neon)',
  'color':'var(--primary-neon)',
  'text-shadow':'var(--neon-shadow-text)',
  'border-radius':'5px',
  
  'align-items':'center',
  'justify-content':'center',
  'text-transform':'uppercase',
  'textAlign':'center',
  'font-size':'0.93rem',
  'font-weight':'bold',
  'box-shadow':'var(--neon-shadow)',
  backgroundColor: 'rgba(0, 0, 0,0)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
      'color':'var(--bright-neon)',
      'border':'3px solid var(--bright-neon)',
      'box-shadow':'var(--bright-neon)',
    opacity: [0.9, 0.8, 0.7],
  },
}} onClick={handleOfficeClick}
    >
      <Stack  direction="column" spacing={1}>
<img src={OfficeImageNeon} height={80}  width={80} sx={{marginTop:'10px',padding:'20px'}} ></img>
<Typography  width={80} sx={{fontSize:'14px'}}> { dataOfWebViewer.loc_office_name != ""  ? dataOfWebViewer.loc_office_name : 'Office'}  </Typography></Stack>
    </Box>}
    { dataOfWebViewer.dis_dir != "1" &&
    <Box height={120} width="100%"
     justifyContent="center"
     alignItems="center"
          sx={{
            '--primary-neon':'rgba(50,119,255,.9)',
  '--light-neon':'rgba(50,119,200,.7)',
  '--pulse-neon':'rgba(50,119,175,.5)',
  '--bright-neon':'rgba(50,119,220,1)',
            '--off-neon':'#333',
            
            '--neon-shadow':'0 0 7px 4px var(--light-neon), 0 0 7px 4px var(--light-neon) inset',
            '--neon-shadow-pulse':'0 0 8px 5px var(--pulse-neon), 0 0 8px 5px var(--pulse-neon) inset',
            '--neon-shadow-bright':'0 0 8px 4px var(--bright-neon), 0 0 8px 4px var(--bright-neon) inset',
            '--neon-shadow-text':'0 0 7px var(--primary-neon)',
            border:'3px solid var(--primary-neon)',
            'color':'var(--primary-neon)',
            'text-shadow':'var(--neon-shadow-text)',
            'border-radius':'5px',
            'textAlign':'center',
            'align-items':'center',
            'justify-content':'center',
            'text-transform':'uppercase',
            'textAlign':'center',
            'font-size':'0.93rem',
            'font-weight':'bold',
            'box-shadow':'var(--neon-shadow)',
           
            backgroundColor: 'rgba(0, 0, 0,0)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0)',
                'color':'var(--bright-neon)',
                'border':'3px solid var(--bright-neon)',
                'box-shadow':'var(--bright-neon)',
              opacity: [0.9, 0.8, 0.7],
            },
          }} onClick ={handleDirectory}
    >
 <Stack  direction="column" spacing={1} >
<img src={DirectoryImageNeon} height={80}  width={80} sx={{marginTop:'10px',padding:'20px',
}}  ></img>
<Typography width={80} sx={{fontSize:'14px'}}>Directory </Typography></Stack>
    </Box> }
    { dataOfWebViewer.dis_doorpin != "1" &&
    <Box height={120} width="100%"
     justifyContent="center"
     alignItems="center"
     
     sx={{
      '--primary-neon':'rgba(50,119,255,.9)',
      '--light-neon':'rgba(50,119,200,.7)',
      '--pulse-neon':'rgba(50,119,175,.5)',
      '--bright-neon':'rgba(50,119,220,1)',
      '--off-neon':'#333',
      
      '--neon-shadow':'0 0 7px 4px var(--light-neon), 0 0 7px 4px var(--light-neon) inset',
      '--neon-shadow-pulse':'0 0 8px 5px var(--pulse-neon), 0 0 8px 5px var(--pulse-neon) inset',
      '--neon-shadow-bright':'0 0 8px 4px var(--bright-neon), 0 0 8px 4px var(--bright-neon) inset',
      '--neon-shadow-text':'0 0 7px var(--primary-neon)',
      border:'3px solid var(--primary-neon)',
      'color':'var(--primary-neon)',
      'text-shadow':'var(--neon-shadow-text)',
      'border-radius':'5px',
      
      'align-items':'center',
      'justify-content':'center',
      'text-transform':'uppercase',
      'textAlign':'center',
      'font-size':'0.93rem',
      
      'font-weight':'bold',
      'box-shadow':'var(--neon-shadow)',
      backgroundColor: 'rgba(0, 0, 0,0)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
          'color':'var(--bright-neon)',
          'border':'3px solid var(--bright-neon)',
          'box-shadow':'var(--bright-neon)',
        opacity: [0.9, 0.8, 0.7],
      },
    }}  onClick={handleDoorPINClick}
    >
      <Stack  direction="column" spacing={1}>
<img src={DoorImageNeon} height={80}  width={80}  ></img>
<Typography width={80} sx={{fontSize:'14px'}}>DOOR PIN</Typography></Stack>

    </Box>}
    { dataOfWebViewer.dis_del != "1" &&
    <Box height={120} width="100%"
     justifyContent="center"
     alignItems="center"
     
     sx={{
      '--primary-neon':'rgba(50,119,255,.9)',
      '--light-neon':'rgba(50,119,200,.7)',
      '--pulse-neon':'rgba(50,119,175,.5)',
      '--bright-neon':'rgba(50,119,220,1)',
      '--off-neon':'#333',
      
      '--neon-shadow':'0 0 7px 4px var(--light-neon), 0 0 7px 4px var(--light-neon) inset',
      '--neon-shadow-pulse':'0 0 8px 5px var(--pulse-neon), 0 0 8px 5px var(--pulse-neon) inset',
      '--neon-shadow-bright':'0 0 8px 4px var(--bright-neon), 0 0 8px 4px var(--bright-neon) inset',
      '--neon-shadow-text':'0 0 7px var(--primary-neon)',
      border:'3px solid var(--primary-neon)',
      'color':'var(--primary-neon)',
      'text-shadow':'var(--neon-shadow-text)',
      'border-radius':'5px',
     
      'align-items':'center',
      'justify-content':'center',
      'text-transform':'uppercase',
      'textAlign':'center',
      'font-size':'0.93rem',
      'font-weight':'bold',
      'box-shadow':'var(--neon-shadow)',
      backgroundColor: 'rgba(0, 0, 0,0)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
          'color':'var(--bright-neon)',
          'border':'3px solid var(--bright-neon)',
          'box-shadow':'var(--bright-neon)',
        opacity: [0.9, 0.8, 0.7],
      },
    }} onClick={handleDeliveryPINClick}
    >
       <Stack  direction="column" spacing={1}>
<img src={DeliveryImageNeon} height={80}  width={80}  ></img>
<Typography  width={80} sx={{fontSize:'14px'}}>Delivery</Typography></Stack>
    </Box>}
</Stack>
</Grid> }
</Grid>
{ state == states.ON_CALL &&  <Button  variant="contained"
          fullWidth={true}
          type="submit" sx= {{'--primary-neon':'rgba(50,119,255,.9)',
          '--light-neon':'rgba(50,119,200,.7)',
          '--pulse-neon':'rgba(50,119,175,.5)',
          '--bright-neon':'rgba(50,119,220,1)',
  '--off-neon':'#333',
  
  '--neon-shadow':'0 0 7px 4px var(--light-neon), 0 0 7px 4px var(--light-neon) inset',
  '--neon-shadow-pulse':'0 0 8px 5px var(--pulse-neon), 0 0 8px 5px var(--pulse-neon) inset',
  '--neon-shadow-bright':'0 0 8px 4px var(--bright-neon), 0 0 8px 4px var(--bright-neon) inset',
  '--neon-shadow-text':'0 0 7px var(--primary-neon)',
  border:'3px solid var(--primary-neon)',
  'color':'var(--primary-neon)',
  'text-shadow':'var(--neon-shadow-text)',
  'border-radius':'5px',
  
  'align-items':'center',
  'justify-content':'center',
  'text-transform':'uppercase',
  'textAlign':'center',
  'font-size':'0.93rem',
  'font-weight':'bold',
  'box-shadow':'var(--neon-shadow)',
  backgroundColor: 'rgba(0, 0, 0,0)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
      'color':'var(--bright-neon)',
      'border':'3px solid var(--bright-neon)',
      'box-shadow':'var(--bright-neon)',
    opacity: [0.9, 0.8, 0.7],
  },}} onClick={handleEndCall}> END CALL </Button>}
                <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box>

               
               </Box> }
               {mainView == false && <>
{/* <Button sx={{
  width: 'auto',
  height: '2.06rem',
  left: '2.5rem',
  fontWeight: '700',
  fontSize: '0.93rem',
  lineHeight: '1.1rem',
  marginRight:'1.25rem',
 top:'-2rem',
  alignItems: 'center',
  color: '#FFFFFF',
  fontFamily: "Lato",
  borderRadius:'0.31rem',
 '&:hover': {
  backgroundColor: 'rgba(109, 195, 252, 1)',
 }
}} onClick={handleBack}>Back</Button> */}
              <Typography sx={{position: 'absolute',
            width: 'auto',
            height: '1.31rem',
            left: '3.12rem',fontWeight: '700',
            fontSize: '1.16rem',
            lineHeight: '1.37rem',
            display: 'flex',
            top: '1rem',
            alignItems: 'center',
            color: '#00FF00',
            fontFamily: "Lato"}}>Resident Directory</Typography>
              < Box sx={{position: 'absolute',
           width:'auto',
           height: '280px',
           top:'20px',
           left: '5%',
           right: '5%',
           flexGrow: 1,
          
           }}>
             <Grid container  direction="column">
             <Grid item >
           <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  
                 {/*  <NestedButtonDropdown
            itemId={FilterMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.rainbowButton}
            data={FilterMenu}
          />   */}
          <Box height="66px">
         <Stack direction="column">
<Typography sx={{
  'color':'#00AAFF',
  'marginTop': '2.06rem',
 
      'font-size':'0.9rem',
      
      'font-weight':'bold',
      'width':'auto',
      'height':'auto',
}}> RESIDENT NAME  </Typography> 
<Typography sx={{
  'color':'#00AAFF',
  
      
      'font-size':'0.75rem',
      
      'font-weight':'bold',
      'width':'auto',
      'height':'auto',
}}>or DIRECTORY CODE</Typography> 
</Stack>
</Box>

                   <TextField
                        id="input-location"
                        ref={myContainer}
                        type= 'text'
                        name="text"
                        onChange={handleChangeText}
                        sx={{ maxWidth: '8rem',
                        top:'1.3rem',
                        fontWeight: '700',
                        fontSize: '1.06rem',
                        lineHeight: '1.37rem',
                        }}
                         variant="filled"
                         hiddenLabel
                        InputLabelProps={{ shrink: true }}
                          
                        InputProps={{
                           
                            sx: {
                                
                              
                              color: 'rgba(255, 255, 255, 1)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: 'rgba(95, 98, 103, 1)',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '5px 0 5px 0',
                              textIndent: '15px !important',
                              border: `1px solid rgba(31, 39, 54, 1)`,
                              
                              '&.Mui-hover': {
                                backgroundColor: 'rgba(95, 98, 103, 1)',
                               
                              },
                              '&.Mui-focused': {
                                backgroundColor:'rgba(95, 98, 103, 1)',
                              
                              }
                                
                            },
                        }}
                       
                    /><IconButton sx={{
                      'color':'#00AAFF',top:'1.3rem',}} onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                    </Stack></Grid>
                   <Grid item>
                   <Stack direction="row" >
                   <Button sx={mainThemeStyle.rainbowButton}  onClick={handleBack}>Back</Button>
                  
                 {/*   <Typography width={20}></Typography>
                   <Button sx={mainThemeStyle.rainbowButton} onClick={handleSearch}>Go</Button>
                    */}
                   <Typography width={20}></Typography>
                   <Button sx={mainThemeStyle.rainbowButton} onClick={handleReset}>Reset Filter</Button>
                   </Stack>
                 </Grid></Grid>
                 
                 
                 </Box>
                 <Box sx={{ position: 'absolute',
        width:'100%',
        top:'11.5rem',
        left: '0rem',
        display:'flex',
        flexDirection:'column',
        alignContent:'space-between',
        backgroundColor:"menubarprimary.main"}} backgroundColor="menubarprimary.main">
               <BelowTblPagination ></BelowTblPagination>
    <TblContainer>
    <TableCell align="left" >
                                   
                                   <Typography  sx={ {  fontFamily: 'Lato',
           fontWeight: '500',
           fontSize: '0.9rem',
          
          
          

           color: '#fff'}} >Resident Name</Typography>
                                   </TableCell> 
                                  {/*  <TblHead/> */}
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                ( <TableRow
                                  key={item.id}
                                  sx={{ borderBottom: "1.5px solid black",'&:last-child td, &:last-child th': { border: 0 },
                                   }}
                                >
                                  <TableCell align="left" >
                                   
                                    <Typography  sx={ {  fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '0.85rem',
            lineHeight: '0.87rem',
           
           

            color: '#00FF19'}} onClick={() => {CallUser(item.user.id)}}> {item.user.fname+" "+item.user.lname}</Typography>
                                    </TableCell> 
                                
                                </TableRow>)
                            )
                        }
                    </TableBody>
                   
                </TblContainer>
               <Box sx={{marginTop:'10px'}}>
                <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />



</Box>
                </Box></>}
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      
       <Dialog
       open={userResponded}
       onClose={handleCloseActive}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
       sx={{
         padding: '16px',
     
        backgroundColor:'#00000000',
        
     }}
     >
       <DialogTitle id="alert-dialog-title" sx={{
       
     
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
         {titleforalert}
       </DialogTitle>
       <DialogContent sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         
       </DialogContent>
       <DialogActions sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         <Button onClick={handleClose} sx={{
          marginTop:'0rem',
            
          fontWeight: '700',
          fontSize: '0.93rem',
          
          backgroundColor:'#008000',
          alignItems: 'center',
          color: '#FFFFFF',
          fontFamily: "Lato",
          borderRadius:'0.31rem',
         '&:hover': {
          backgroundColor: '#006000',
         }
         }}>Call</Button>
         <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
           Cancel
         </Button>
       </DialogActions>
     </Dialog>

     <Dialog
       open={open}
       onClose={handleEnd}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
       sx={{
         padding: '16px',
     
        backgroundColor:'#00000000',
        
     }}
     >
       <DialogTitle id="alert-dialog-title" sx={{
       
     
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
         
       </DialogTitle>
       <DialogContent sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         
       </DialogContent>
       <DialogActions sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         <Button onClick={handleAccept} sx={mainThemeStyle.normalBigButton}>ANSWER DOOR CALLER</Button>
         <Button onClick={handleEnd} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
         REJECT DOOR CALLER
         </Button>
       </DialogActions>
     </Dialog>

     <Dialog
       open={openEndCall}
       onClose={handleEndCall}
       aria-labelledby="alert-dialog-title"
       aria-describedby="alert-dialog-description"
       sx={{
         padding: '16px',
     
        backgroundColor:'#00000000',
        
     }}
     >
       <DialogTitle id="alert-dialog-title" sx={{
       
     
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
         
       </DialogTitle>
       <DialogContent sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         
       </DialogContent>
       <DialogActions sx={{
       
     
       backgroundColor:'#2A2F3B',
       color:'white'
       
    }}>
         <Button onClick={handleUnlock} sx={mainThemeStyle.normalBigButton} disabled={callLocCamBrand == "ResidentLink" ? (dataTrack === null ? true : false) : false}>UNLOCK</Button>
         <Button onClick={handleEndCall} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
         ENDCALL
         </Button>
       </DialogActions>
     </Dialog>
      </Box>
      
      <PopupWithSX
                title=""
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                sx={sx}
            >
             
             <Box   justifyContent="center"
    alignItems="center"> 
        <Stack justifyContent="center"
    alignItems="center" direction="column">
        <Typography sx={{ fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#878787',textAlign:'center'}} height={40}  justifyContent="center"
 alignItems="center"> Enter your PIN to Unlock</Typography>
        <TextField
        id="doorpinenter"
        type="number"
        height={40}
        justifyContent="center"
        pattern="[0-9]*" 
        inputmode="numeric"
        alignItems="center"
        value={textOfEnteredPIN}
        autoFocus="true"
        onChange={handleEnteredPIN}
        InputLabelProps={{
          shrink: true,
        }}
        hiddenLabel
        variant='filled'
        InputProps={{
                           
          sx: {
           
              color: 'rgba(255, 255, 255, 1)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: 'rgba(95, 98, 103, 1)',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '5px 0 5px 0',
              textIndent: '15px !important',
              border: `1px solid rgba(31, 39, 54, 1)`,
              '&.Mui-hover': {
                backgroundColor: 'rgba(95, 98, 103, 1)',
               
              },
              '&.Mui-focused': {
                backgroundColor:'rgba(95, 98, 103, 1)',
              
              }
              
          },
      }}
      /></Stack>
      <Button sx={{
        fontWeight: '700',
        fontSize: '0.93rem',
        lineHeight: '1.1rem',
      
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: "Lato",
        borderRadius:'0.31rem',
       '&:hover': {
        backgroundColor: 'rgba(109, 195, 252, 1)',
       },
       '&.Mui-disabled': {
        color: 'light-gray',
        backgroundColor:'gray'
      },
      }} onClick={checkDoorPIN} disabled={textOfEnteredPIN.length < 4 ? true :false}>Enter</Button>
        </Box>
               
            </PopupWithSX></>}
 </> }
          
 </ThemeProvider>
          
          
          ;
};

export default QRWebViewer;