
import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import InfoIcon from '@mui/icons-material/Info';
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/system';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import Autocomplete from '@mui/material/Autocomplete';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import useToken from "./useToken";
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
import LoadingButton from "@mui/lab/LoadingButton";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function OfficeGroup ( props){
  const [EnLog,setEnLog] = useState([]);
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  let [loading, setLoading] = useState(true);
  let [iPhoneUserData, setiPhoneUserData] = useState([]);
  let [androidUserData, setandroidUserData]= useState([]);
  let [residentUserData, setresidentUserData]=useState([]);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();

  const getDisableValueAdd = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("47").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("47").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("47").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("47").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("47").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("47").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("47").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("47").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("48").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("48").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("48").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("48").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("48").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("48").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("48").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("48").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("49").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("49").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("49").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("49").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("49").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("49").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("49").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("49").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/officecallgroup/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log(res.data);
      setEnLog(res.data);
      setiPhoneUserData(res.data.Users);
     // setandroidUserData(res.data.Android);
      setresidentUserData(res.data.Office_Group);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const deleteMyGroupPostData = async () => {
    console.log(deleteData);
    setloadingbtn(true);
    try {
      const res = await axios.delete("/admin/deletewebusergroup/"+token.id+"/"+deleteData.user_type+"/"+deleteData.device_id,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email,
       'issub':token.issub,
       'subId':token.subid,
       'username':deleteData.user_name }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('OGGDS'));
      setSeverityData("success");
      setOpenSnack(true);
     // setLoading(false);
      getMyPostData();
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('OGGDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'username', label: 'User Name' },
    { id: 'phone_no', label: 'Phone #' },
    { id: 'user_email', label: 'Email' },
    { id: 'type', label: 'User Type' },
    { id: 'web_status', label: 'WebViewer Status' },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(residentUserData, headCells, filterFn,8,650);
  const Input = styled('input')(({ theme }) => ({
    width: 'auto',
            fontWeight: '700',
            fontSize: '17px',
            height:'40px',
            
            '& .MuiFilledInput-root': {
                border: '1px solid rgba(31, 39, 54, 1)',
                '&:hover': {
                    color:'rgba(255, 255, 255, 1)',
        height:'33px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '0 0 15px 0',
        textIndent: '15px !important',
                },
                '&.Mui-focused': {
                    color:'rgba(255, 255, 255, 1)',
        height:'33px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '0 0 15px 0',
        textIndent: '15px !important',
                },
              },
          
  }));

  const Listbox = styled('ul')(({ theme }) => ({
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: "#1E1E1E",
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
      backgroundColor: '#121212',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#121212',
      color: 'white',
    },
  }));
  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);
  const [openSnack, setOpenSnack] = React.useState(false);
  const groupAccessName =['Beta Tester','James Home','Everyone On The list'];
  const [iPhoneUser, setiPhoneUser] = useState('James Webb');
  const [androidUser, setAndroidUser] = useState('Derek Masa');
  const [showTable, setShowTable] = useState(0);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
 
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: iPhoneUser,
    getOptionLabel: (option) => option.label,
  });
  /*for (let i =0; i < 5; i++){
    iPhoneUserData[i] = {
      id: i,
      username:faker.name.fullName(),
      email:faker.internet.email(),
      phone:faker.phone.number('(###) ###-####'),
    }
  }
  for (let i =0; i < 5; i++){
    androidUserData[i] = {
      id: i,
      username:faker.name.fullName(),
      email:faker.internet.email(),
      phone:faker.phone.number('(###) ###-####'),
    }
  }
  for (let i =0; i < 5; i++){
    residentUserData[i] = {
      id: i,
      username:faker.name.fullName(),
      email:faker.internet.email(),
      phone:faker.phone.number('(###) ###-####'),
    }
  }*/
  const showTableOfSelcted = {
    id: "1",
    label: "By Name",
    items: [
      {
        label: "By Name",
        callback: () =>  {
        setShowTable(0);
        },
      },
      {
        label: "By Phone",
        callback: () =>  {
        setShowTable(1);
        },
      },
      
      
    ]
  };
  const iPhoneUserMenu = {
    id: "1",
    label: "James Webb",
    items: [
      {
        label: "James Webb",
        callback: () =>  {
        setiPhoneUser("James Webb");
        },
      },
      {
        label: "Carl Roxx",
        callback: () =>  {
          setiPhoneUser("Carl Roxx");
        }
      },
      {
        label: "Elon Musk",
        callback: () => {
          setiPhoneUser("Elon Musk");
        }
      },
      
    ]
  };
  const AndroidUserMenu = {
    id: "1",
    label: "Derek Masa",
    items: [
      {
        label: "Derek Masa",
        callback: () =>  {
          setAndroidUser("Derek Masa");
        },
      },
      {
        label: "Apple Fiona",
        callback: () =>  {
          setAndroidUser("Apple Fiona");
        }
      },
      {
        label: "Robert Junior",
        callback: () => {
          setAndroidUser("Robert Junior");
        }
      },
      
    ]
  };
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  let [loadingbtn,setloadingbtn] = useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [currentDeleteType, setCurrentDeleteType] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openadd, setOpenAdd] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCancel = () => {
    setOpenAdd(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    deleteMyGroupPostData();
  }
  const handleCloseAccept = () => {
    setOpenAdd(false);
    addGroupPost();
  }
  const handleEnable = (val,val2) => {
    updateGroupPost({id:val,web_status:0,issub:token.issub,subId:token.subid,username:val2});
  }
  const handleDisable = (val,val2) => {
    updateGroupPost({id:val,web_status:1,issub:token.issub,subId:token.subid,username:val2});
  }
  const [deleteData, setDeleteData] = useState();
  const handleDelete = (val,val2,val3) => {
    setTitleforalert("Are you sure you want to delete user "+ val2 + " from PC Office Group?");
    setDeleteData({
      device_id : val,
      group_id : 1,
      user_type : val3,
      user_id : token.id,
      username:val2
    });
    console.log(val2);
    setOpen(true);
  }
  
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const updateGroupPost = async (val) => {
    console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.patch("/admin/updatewebresident/"+token.id+"/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      setOpenSnackData(getAlertTitle('OGGUS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      console.log(res.data);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('OGGUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const addGroupPost = async () => {
    console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.post("/admin/addnewwebgroup/"+token.id,{device_id:currentSelectedval.devicetoken,username:currentSelectedval.username,user_id:token.id,user_type:currentSelectedval.type,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      setOpenSnackData(getAlertTitle('OGGAS'));
      setSeverityData("success");
      console.log(res.data);
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      getMyPostData();
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('OGGAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const [currentSelectedval,setCurrentSelectedval] = useState();
  const handleChangeUserName = (event,newValue) => {
    setCurrentSelectedval(newValue);
    console.log(newValue);
  }
  const handleAddMessage = () => {
    if(currentSelectedval == null){
      setOpenSnackData(getAlertTitle('OGUNS'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else{
      setTitleforalert("You are about to add "+ currentSelectedval.username + " to the PC Office calling group.");
      setOpenAdd(true);
    }
   
  }
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<Box sx={{ display: { xs: "none", md: "flex" }}}>
<Grid container>
  <Grid item xs={6}>
                <Stack direction='row' spacing={2} padding={2}>
                <Typography variant="h6" component="div" style={{ paddingTop: '16px',color:'#fff' }}>
                       Search
                   </Typography>
                <NestedButtonDropdown
            itemId={showTableOfSelcted.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={showTableOfSelcted}
          />
            <Autocomplete
            style={{marginTop: 13,minWidth: 250}}
             sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(135, 135, 135, 0.5)" },"& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },}}
            disablePortal
            disabled = {getDisableValueAdd()}
          onChange={handleChangeUserName}
          variant="filled"
          hiddenLabel
          options={iPhoneUserData}
          getOptionLabel={(option) => showTable == 0 ? option.username : option.phone_no}
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
          >
            
          </Autocomplete>
          
                   <LoadingButton loading={loadingbtn} sx={mainThemeStyle.normalButtonDown} onClick={handleAddMessage} disabled = {getDisableValueAdd()}> Add </LoadingButton>
                   <Tooltip title="MyDoorView users added to this Calling Group will receive all incoming calls intended for the Property Office. If multiple users are added to the calling group, the user who answers the call first will be connected to the entry panel.  Users may temporarily disable these calls by using the 'Do Not Disturb' setting in their app. ">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip></Stack></Grid>
    <Grid item xs={4}> <Stack direction='row' spacing={2} padding={2} paddingTop={4} justifyContent="center"
  alignItems="center" >
                <Typography variant="h6" component="div" style={{ color:'#fff' }}>
                Enable WebViewer For Office Calls
                   </Typography>
                   <Tooltip title="If enabled, all panel calls to the 'Office' button will ring in the WebViewer app as well as the MyDoorView users in the group above. If disabled, all panel calls to the 'Office' button will be routed to the telephone number configured in the panel settings. ">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
                   
                   </Stack></Grid></Grid>
 </Box>
 <Box sx={{ display: { xs: "flex", md: "none" }}}>

              
               <Stack direction='column' paddingLeft={2} paddingRight={2}>
                <Stack direction='row' spacing={2} paddingTop={2} paddingLeft={2} paddingRight={2}>
                <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Search
                   </Typography>
                <NestedButtonDropdown
            itemId={showTableOfSelcted.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={showTableOfSelcted}
          /> </Stack>
            <Stack direction='row' spacing={2} paddingBottom={2} paddingLeft={2} paddingRight={2}>
            <Autocomplete
            style={{marginTop: 13,minWidth: 120}}
             sx={{  "& .MuiAutocomplete-inputRoot": {
              color: "rgba(135, 135, 135, 0.5)" },"& .MuiSvgIcon-root": {
                color: "rgba(135, 135, 135, 0.5)",
              },}}
            disablePortal
            disabled = {getDisableValueAdd()}
          onChange={handleChangeUserName}
          variant="filled"
          hiddenLabel
          options={iPhoneUserData}
          getOptionLabel={(option) => showTable == 0 ? option.username : option.phone_no}
          PaperComponent={({ children }) => (
            <Paper style={{ backgroundColor: "#1E1E1E" ,color:"#AFAFAF", "&:hover":{backgroundColor: "#fff"}}}>{children}</Paper>
          )}
          renderInput={(params) => <TextField {...params}  variant="outlined" size="small" style={{ backgroundColor: "#121212",borderRadius: '5px' ,color:"#fff",
        
            
        }} />}
          >
            
          </Autocomplete>
          
                   <LoadingButton loading={loadingbtn} sx={mainThemeStyle.normalButtonDown} disabled = {getDisableValueAdd()} onClick={handleAddMessage}> Add </LoadingButton>
                  {/*  <Tooltip title="MyDoorView users added to this Calling Group will receive all incoming calls intended for the Property Office. If multiple users are added to the calling group, the user who answers the call first will be connected to the entry panel.  Users may temporarily disable these calls by using the 'Do Not Disturb' setting in their app. ">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip> */}</Stack> 
    
                   </Stack>
  </Box>
           <Divider sx={{backgroundColor:'white'}} />

              {/*  {(showTable == 0 && <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Iphone User
                   </Typography>)}    
                   {(showTable == 0 &&  <NestedButtonDropdown
            itemId={iPhoneUserMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={iPhoneUserMenu}
          />)} 
     {(showTable == 0 && <Button sx={mainThemeStyle.normalButtonDown} onClick={handleAddMessage}> Add </Button>)}

     {(showTable == 1 && <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Android User
                   </Typography>)}
                   {(showTable == 1 && <NestedButtonDropdown
            itemId={AndroidUserMenu.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={mainThemeStyle.filterButtonCenter}
            data={AndroidUserMenu}
          />)}
                   
                   {(showTable == 1 && <Button sx={mainThemeStyle.normalButtonDown} onClick={handleAddMessage}> Add </Button>)}
      
              
              
          
   
         
       
           {(showTable == 0 && 
        <TableContainer  component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        marginTop:'32px',
        backgroundColor:'#171E29',
       
        }}>
      <Table >
        
       

      <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={4}>Iphone User</TableCell>
            
          </TableRow>
         
        </TableHead>
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="left" sx={mainThemeStyle.cellHeader}> User Name</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader}>Email</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader}> Phone #</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader}>Action</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {iPhoneUserData.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.fname+" "+row.lname}
              </TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.user_email}</TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.phone_no}</TableCell>
              <TableCell align="left"> <Button  sx={mainThemeStyle.viewLogButton} onClick={handleAddMessage}>Delete</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)}

    
    {(showTable == 1 && 
    <TableContainer  component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        marginTop:'32px',
        backgroundColor:'#171E29',
       
        }}>
      <Table >
        
       

      <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={4}>Android User</TableCell>
            
          </TableRow>
         
        </TableHead>
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="left" sx={mainThemeStyle.cellHeader}> User Name</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader}>Email</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader}> Phone #</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader}>Action</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {androidUserData.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.fname+" "+row.lname}
              </TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.user_email}</TableCell>
              <TableCell  align="left" sx={mainThemeStyle.cellRow}> {row.phone_no}</TableCell>
              <TableCell align="left"> <Button  sx={mainThemeStyle.viewLogButton} onClick={handleAddMessage}>Delete</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)} */}
    
     
   <TblContainer>
    <TblHead/>
        <TableBody>
         {residentUserData.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
          
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.username}
              </TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.phone_no}
              </TableCell>
              <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.user_email}
              </TableCell>
              {row.type != 'ResidentVue' && row.type != 'Virtual Panel' &&  <TableCell align="left" sx={mainThemeStyle.cellRow}>
              {row.type}
              </TableCell>}
              {(row.type == 'ResidentVue'|| row.type == 'Virtual Panel') &&  <TableCell align="left" sx={mainThemeStyle.cellRowLight}>
              {row.type}
              </TableCell>}
              <TableCell  align="left" sx={(row.type == 'ResidentVue'|| row.type == 'Virtual Panel') ? (row.web_status ==  0 ?  mainThemeStyle.cellGreen :  mainThemeStyle.cellRed) :  mainThemeStyle.cellRow}> {(row.type == 'ResidentVue'|| row.type == 'Virtual Panel') ? (row.web_status ==  0 ? "Enabled" : "Disabled") : 'N/A'}</TableCell>
             {/*  <TableCell  align="left" sx={mainThemeStyle.cellRow}> 4438443135 </TableCell> */}
              <TableCell align="left"> 
              {(row.type == 'ResidentVue'|| row.type == 'Virtual Panel') && <Stack direction="row" spacing={1} >
                <Typography></Typography>
                 <LoadingButton  sx={mainThemeStyle.viewLogButton} loading={loadingbtn} disabled = {getDisableValueEdit()} onClick={() => {handleEnable(row.id,row.username) }}>Enable</LoadingButton>
                 <LoadingButton  sx={mainThemeStyle.viewLogButtonRed} loading={loadingbtn} disabled = {getDisableValueEdit()} onClick={() => {handleDisable(row.id,row.username) }}>Disable</LoadingButton>
              </Stack>}
              {row.type != 'ResidentVue'  && row.type != 'Virtual Panel' &&  <Stack direction="row" spacing={1} ><Typography></Typography><LoadingButton  loading={loadingbtn}  disabled = {getDisableValueDelete()} sx={mainThemeStyle.viewLogButtonRed} onClick={() => {handleDelete(row.devicetoken,row.username,row.type)}}>Delete</LoadingButton></Stack>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      
      </TblContainer>
    {/*   <Divider sx={{backgroundColor:'white'}} /> */}
     {/*  <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                <Typography variant="h6" component="div" style={{  paddingLeft: '16px',flexGrow: 1,color:'#fff' }}>
                Enable WebViewer For Office Calls
                   </Typography>
                   <Tooltip title="If enabled, all panel calls to the 'Office' button will ring in the WebViewer app as well as the MyDoorView users in the group above. If disabled, all panel calls to the 'Office' button will be routed to the telephone number configured in the panel settings. ">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
                   </Stack></div>
                   </div> */}
   <BelowTblPagination></BelowTblPagination>
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />

</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openadd}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseAccept} sx={mainThemeStyle.normalBigButton}>Accept</Button>
          <Button onClick={handleCloseCancel} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default OfficeGroup;