import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Select,MenuItem,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import FilledInput from '@mui/material/FilledInput';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import axios from "./axios";
import GlobalAlert from "./GlobalAlert";
import PuffLoader from "react-spinners/PuffLoader"; 
import LoadingButton from '@mui/lab/LoadingButton';
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useInnerTable from "./components/useInnerTable";
import InputAdornment from '@mui/material/InputAdornment';
import TimezoneSelect from 'react-timezone-select'
import { useSSR } from "react-i18next";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function PanelPrice ( props){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
  const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [loadingbtn ,setloadingbtn] = React.useState(false);
  const [panelSub,setPanelSub] = useState([]);
  const [subItemSelector,setSubItemSelector] = useState([]);
 // const [itemCostPerSelector,setitemCostPerSelector] = useState([]);
  const [panelAddOnSub,setPanelAddOnSub] = useState([]);
  const [panelSAASSub,setPanelSAASSub] = useState([]);
  const [vueAddOnSub,setVueAddOnSub] = useState([]);
  const [lprAddOn,setLprAddOn] = useState([]);
  const [lprAddOnSub,setLprAddOnSub] = useState([]);
  const [lprSubscriptionSub,setLprSubscriptionSub] = useState([]);
  const [lprSubscription,setLprSubscription] = useState([]);
  const [extraAddOnSub,setExtrarAddOnSub] = useState([]);
  const [extraAddOn,setExtrarAddOn] = useState([]);
  const [extraSubscriptionSub,setExtraSubscriptionSub] = useState([]);
  const [extraSubscription,setExtraSubscription] = useState([]);
  const [apiValue, setAPIValue] = useState();
  
  
  let [loading, setLoading] = useState(true);
  const [currentDeleteID,setCurrentDeleteId] = useState();
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseActiveDelete =() =>{
    setOpenDelete(false);
    deleteMyPostData();
  }
  const [deleteItemName,setDeleteItemName] = useState("");
  const deleteMyPostData = async () => {
    setloadingbtn(true);
     try {
       const res = await axios.delete("/administrator/deletesellitem/"+currentDeleteID, 
       {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
       'token' : token.token,
       'tokenid' : token.email,
       'issub':token.issub,
       'subId':token.subid,
       'name':deleteItemName }});
       console.log( res.data);
       setOpenSnackData("Sale Item Successfully Deleted");
       setSeverityData("success");
       setOpenSnack(true);
       setLoading(false);
       
       getMyPostData();
     } catch (error) {
       console.log(error.message);
       setLoading(false);
       setOpenSnackData("Sale Item Delete Failed, Please Try Again Later.");
       setSeverityData("error");
       setOpenSnack(true);
       setloadingbtn(false);
       if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}

     }
   };
  const OptionalMandatorySelect = {
    id: "1",
    label: "Optional",
    items: [
      {
        label: "Optional",
        callback: () =>  {
         
        }
      },
      {
        label: "Mandatory",
        callback: () =>  {
         
        }
      },
    ]
  }
  
  const ItemTypeSelect = {
    id: "1",
    label: "Panel Products",
    items: [
      {
        label: "Panel Products",
        callback: () =>  {
         
        }
      },
      {
        label: "Panel Add-Ons",
        callback: () =>  {
          
        }
      },
      {
        label: "Sub Item Of Panel Add-Ons",
        callback: () =>  {
          
        }
      },
      {
        label:  "Panel Hosting Service Subscriptions",
        callback: () =>  {
         
        }
      },
      {
        label:  "Sub Item Of Panel Hosting Service Subscriptions",
        callback: () =>  {
         
        }
      },
      {
        label:  "Vue QR (yes/no)",
        callback: () =>  {
         
        }
      },
      {
        label:  "Vue QR Items",
        callback: () =>  {
         
        }
      },
      {
        label:  "Vue QR Options",
        callback: () =>  {
         
        }
      },
      {
        label:  "Vue QR Images",
        callback: () =>  {
         
        }
      },
      {
        label:  "Vue QR Subscription",
        callback: () =>  {
         
        }
      },
      {
        label: "Sub Item Of Vue QR Subscriptions",
        callback: () =>  {}
      },
      {
        label: "LPR Products",
        callback: () =>  {}
      },
      {
        label: "LPR Required Hardware",
        callback: () =>  {}
      },
      {
        label: "Sub Item Of LPR Required Hardware",
        callback: () =>  {}
      },
      {
        label: "LPR Subscriptions",
        callback: () =>  {}
      },
      {
        label: "Sub Item Of LPR Subscriptions",
        callback: () =>  {}
      },
      {
        label: "Additional Items",
        callback: () =>  {}
      },
      {
        label: "Additional Item Add-Ons",
        callback: () =>  {}
      },
      {
        label: "Sub Item Of Additional Item Add-Ons",
        callback: () =>  {}
      },
      {
        label: "Additional Subscriptions",
        callback: () =>  {}
      }
      ,
      {
        label: "Sub Item Of Additional Subscriptions",
        callback: () =>  {}
      }
    ]
  }
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  
   const getDisableValueEdit = (val) => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      console.log("token sub type" + token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("18").administrator_control;
        console.log("valOfDisable" + valOfDisable);
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("18").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  
  const getDisableValue = (val) => {
    if(getDisableValueEdit("") == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("18");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("18");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/administrator/getpanelprice/",{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+JSON.stringify( res.data));
      
      setRecords( res.data);
      let pas = ["All"];
      let padons = ["All"];
      let pssa = ["All"];
      let vas = ["All"];
      let ladd = ["All"];
      let laddons = ["All"];
      let lsub = ["All"];
      let lsubsub = ["All"];
      let eadd = ["All"];
      let eaddons = ["All"];
      let esuba = ["All"];
      for(let i = 0; i < res.data.length ; i++){
        if(res.data[i].type == '1'){
          pas.push(res.data[i].item_name);
        }
        if(res.data[i].type == '2'){
          padons.push(res.data[i].item_name);
        }
        if(res.data[i].type == '4'){
          pssa.push(res.data[i].item_name);
        }
        if(res.data[i].type == '10'){
          vas.push(res.data[i].item_name);
        }
        if(res.data[i].type == '12'){
          ladd.push(res.data[i].item_name);
          lsub.push(res.data[i].item_name);
        }
        if(res.data[i].type == '13'){
          laddons.push(res.data[i].item_name);
        }
        
        if(res.data[i].type == '15'){
          lsubsub.push(res.data[i].item_name);
        }
        if(res.data[i].type == '17'){
          eadd.push(res.data[i].item_name);
        }
        if(res.data[i].type == '18'){
          eaddons.push(res.data[i].item_name);
        }
        if(res.data[i].type == '20'){
          esuba.push(res.data[i].item_name);
        }
      }
      setPanelSub(pas);
      setPanelAddOnSub(padons);
      setPanelSAASSub(pssa);
      setVueAddOnSub(vas);
      setLprAddOnSub(laddons);
      setLprAddOn(ladd);
      setLprSubscriptionSub(lsubsub);
      setLprSubscription(lsub);
      setExtrarAddOnSub(eaddons);
      setExtraSubscriptionSub(esuba);
      setExtrarAddOn(eadd);
      setExtraSubscription(eadd);
      setLoading(false);
      setloadingbtn(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  
  const [records,setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'item_name', label: 'Item Name', disableSorting: false },
    { id: 'type', label: 'Item Category', disableSorting: false },
    { id: 'item_option', label: 'Item Type', disableSorting: false },
    { id: 'sub_of', label: 'Sub Item Of', disableSorting: false },
    { id: 'item_description', label: 'Item Description', disableSorting: true },
    { id: 'item_price', label: 'Price', disableSorting: false },
    { id: 'item_image', label: 'Item Image', disableSorting: true },
    { id: 'min_quantity', label: 'Min Qunatity', disableSorting: true },
    { id: 'max_quantity', label: 'Max Quantity', disableSorting: true },
    { id: 'sandh', label: 'S & H', disableSorting: false },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer,
    TblHead,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne ,
  } = useInnerTable(records, headCells, filterFn,5,650);
  /* useEffect(() => {
    {
      TblContainer,
      TblHead,
      TblPagination ,
      BelowTblPagination,
      recordsAfterPagingAndSorting ,
      setPageToNewOne ,
    } = useInnerTable(records, headCells, filterFn,5,650);
  }, [records]); */
 
  
  const updateMyPostData = async (val) => {
    console.log(apiValue);
    setloadingbtn(true);
    try {
      const res = await axios.patch("/administrator/updatepanelprice/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData("Item Details Successfully Updated");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      
     
      getMyPostData();
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData("Item Details Update Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
 
  
  
  const handleTablePriceChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].item_price = event.target.value;
   /* let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }

  const handleTablePricePerChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].item_price_per = event.target.value;
  /*  let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }

  const handleTableItemNameChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].item_name = event.target.value;
   /* let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }

  
  const handleTableDescriptionChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].item_description = event.target.value;
   /* let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }

  

  const handleTableMinQuantityChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].min_quantity = event.target.value;
  /*  let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }

  const handleTableMaxQuantityChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].max_quantity = event.target.value;

    /*let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }

  const handleTableSAndHChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].sandh = event.target.value;
   /* let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }*/
    setRecords(records);
  }
  const getOptionValue = (val) => {
    if(val == "0"){
      return  'Optional';
    }
    if(val == "1"){
      return 'Mandatory';
    }
  } 
  const getSubItemValue  = (val) => {
    if(val > 0){
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == val ){
       
          idIn = i;
          break;
      }
    }
    return records[idIn].item_name;
    }
    else if( val == "-1"){
      return 'All';
    }
    else{
      return 'N/A';
    }
  } 
  const handleChangeTableOptionalType = (event) => {
    let od = '0';
    if(event.target.value == "Optional"){
      od = '0';
    }
    if(event.target.value == "Mandatory"){
      od = '1';
    }
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].item_option = od;
    let newrecord = [];
    for(let i = 0; i < records.length ; i++){
      newrecord.push(records[i]);
    }
    setRecords(newrecord);
  }
  
  
  const handleTableImageUrlChange= (event) => {
    let idIn;
    for(let i = 0; i < records.length ; i++){
      let item = records[i];
      if(item.id == event.target.name ){
       
          idIn = i;
          break;
      }
    }
    console.log(event.target.name);
    console.log(idIn );
    records[idIn].item_image = event.target.value;
    setRecords(records);
  }

  const handleItemModify = (event) => {
    console.log('token is '+token.issub);
   
    let idIn;
    for(let i = 0; i < records.length ; i++){
      if(records[i].id == event.target.name){
          idIn = i;
          break;
      }
    }
   
        
    let asv = { id:records[idIn].id,item_name:records[idIn].item_name,item_image:records[idIn].item_image,item_price:records[idIn].item_price,item_price_per:records[idIn].item_price_per,item_description:records[idIn].item_description,item_option:records[idIn].item_option,optional_data:records[idIn].optional_data,min_quantity:records[idIn].min_quantity,max_quantity:records[idIn].max_quantity,sandh:records[idIn].sandh,issub:token.issub,subId:token.subid};
    updateMyPostData(asv);
  }
  const handleDelete = (event) =>{
     let idofItem =  records.filter(x => x.id == event.target.name);
     setCurrentDeleteId(event.target.name);
     setDeleteItemName(idofItem[0].item_name);
     setTitleforalert("Are you sure you want to delete item "+ idofItem[0].item_name + " ?");
     setOpenDelete(true);
   }
  const [itemType,setItemType] = useState('Panel Products');
  const [itemTypeCode,setItemTypeCode] = useState('1');
  const [itemName,setItemName] = useState('');
  const [sAndH,setSAndH] = useState('');
  const [isSubSelector,setIsSubSelector] = useState(false);
  const [isOptionalOrMandatory,setIsOptionalOrMandatory] = useState(false);
  const [optionType,setOptionType] = useState('Optional');
  const [itemDescription,setItemDescription] = useState('');
  const [itemImageUrl,setItemImageUrl] = useState('');
  const [itemCost,setItemCost] = useState('');
  const [itemCostPer,setItemCostPer] = useState('1');
  const [minItemQuant,setMinItemQuant] = useState('');
  const [maxItemQuant,setMaxItemQuant] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemImageData, setItemImageData] = useState(null);
  const [subOf,setSubOf] = useState('');
  const [subValue,setSubValue] = useState('');
  const handleOptionalType = (event) => {
    setOptionType(event.target.value);
    if(event.target.value == "Optional"){
      setIsOptionalOrMandatory(false);
    }
    if(event.target.value == "Mandatory"){
      setIsOptionalOrMandatory(true);
    }
    
  }

 
  const handleSubTypeChange = (event) => {
    setSubOf(event.target.value);
    for(let i = 0; i < records.length ; i++){
      if(records[i].item_name == event.target.value){
        setSubValue(records[i].id);
      }
      else if(records[i].item_name == "All"){
        setSubValue("-1");
      }
    }
    
  }
  const handleChangeItemType = (event) => {
    setItemType(event.target.value);
    setItemName('');
    setItemDescription('');
    setItemImageData('');
    setItemImageUrl('');
    setItemCost('');
    setMinItemQuant('');
    setMaxItemQuant('');
    setSubOf('');
    setOptionType('Optional');
    setIsOptionalOrMandatory(false);
    if(event.target.value == "Panel Products"){
      setIsSubSelector(false);
      setItemTypeCode("1");
    }
    if(event.target.value == "Panel Add-Ons"){
      setIsSubSelector(true);
      setSubItemSelector(panelSub);
      setSubOf(panelSub[0]);
      setSubValue("-1");
      setItemTypeCode("2");
    }
    if(event.target.value ==  "Sub Item Of Panel Add-Ons"){
        setIsSubSelector(true);
        setSubItemSelector(panelAddOnSub);
        setSubOf(panelAddOnSub[0]);
       /* for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == panelAddOnSub[0]){
            setSubValue(records[i].id);
          }
        }*/
        setSubValue("-1");
        setItemTypeCode("3");
    }
    if(event.target.value == "Panel Hosting Service Subscriptions"){
      setIsSubSelector(false);
      setItemTypeCode("4");
    }
    if(event.target.value ==  "Sub Item Of Panel Hosting Service Subscriptions"){
      setIsSubSelector(true);
      setSubItemSelector(panelSAASSub);
        setSubOf(panelSAASSub[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == panelSAASSub[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("5");
    }


    if(event.target.value ==  "Vue QR (yes/no)" ){
      setIsSubSelector(false);
      setItemTypeCode("6");
    }
    if(event.target.value == "Vue QR Items"){
      setIsSubSelector(false);
      setItemTypeCode("7");
      
    }
    
    if(event.target.value == "Vue QR Options"){
      setIsSubSelector(false);
      setItemTypeCode("8");
      
    }
    if(event.target.value == "Vue QR Images"){
      setIsSubSelector(false);
      setItemTypeCode("9");
      
    }
    if(event.target.value ==  "Vue QR Subscription"){
      setIsSubSelector(false);
      setItemTypeCode("10");
      
    }

    if(event.target.value == "Sub Item Of Vue QR Subscriptions"){
      setIsSubSelector(true);
      setSubItemSelector(vueAddOnSub);
      setSubOf(vueAddOnSub[0]);
      setSubValue("-1");
      setItemTypeCode("11");
    }
    if(event.target.value == "LPR Products"){
      setIsSubSelector(false);
      setItemTypeCode("12");
    }
    if(event.target.value == "LPR Required Hardware"){
      setIsSubSelector(true);
      setSubItemSelector(lprAddOn);
        setSubOf(lprAddOn[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == lprAddOn[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("13");
    }
    if(event.target.value == "Sub Item Of LPR Required Hardware"){
      setIsSubSelector(true);
      setSubItemSelector(lprAddOnSub);
        setSubOf(lprAddOnSub[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == lprAddOnSub[0]){
            setSubValue(records[i].id);
          }
        }
      setItemTypeCode("14");
    }
    if(event.target.value == "LPR Subscriptions"){
      setIsSubSelector(true);
      setSubItemSelector(lprSubscription);
        setSubOf(lprSubscription[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == lprSubscription[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("15");
    }
    if(event.target.value == "Sub Item Of LPR Subscriptions"){
      setIsSubSelector(true);
      setSubItemSelector(lprSubscriptionSub);
        setSubOf(lprSubscriptionSub[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == lprSubscriptionSub[0]){
            setSubValue(records[i].id);
          }
        }
      setItemTypeCode("16");
    }

    if(event.target.value == "Additional Items"){
      setIsSubSelector(false);
        setItemTypeCode("17");
    }
    if(event.target.value == "Additional Item Add-Ons"){
      setIsSubSelector(true);
      setSubItemSelector(extraAddOn);
      setSubOf(extraAddOn[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == extraAddOn[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("18");
    }
    if(event.target.value == "Sub Item Of Additional Item Add-Ons"){
      setIsSubSelector(true);
      setSubItemSelector(extraAddOnSub);
        setSubOf(extraAddOnSub[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == extraAddOnSub[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("19");
    }
    if(event.target.value == "Additional Subscriptions"){
      setIsSubSelector(true);
      setSubItemSelector(extraSubscription);
      setSubOf(extraSubscription[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == extraSubscription[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("20");
    }
    if(event.target.value == "Sub Item Of Additional Subscriptions"){
      setIsSubSelector(true);
      setSubItemSelector(extraSubscriptionSub);
        setSubOf(extraSubscriptionSub[0]);
        for(let i = 0; i < records.length ; i++){
          if(records[i].item_name == extraSubscriptionSub[0]){
            setSubValue(records[i].id);
          }
        }
        setItemTypeCode("21");
    }
  }
  const getItemType = (val) => {
    if(val == "1"){
      return "Panel Products";
    }
    if(val == "2"){
      return "Panel Add-Ons";
    }
    if(val ==  "3"){
      return "Sub Item Of Panel Add-Ons";
    }
    if(val == "4"){
      return "Panel Hosting Service Subscriptions";
    }
    if(val ==  "5"){
      return "Sub Item Of Panel Hosting Service Subscriptions";
    }
    if(val == "6"){
      return "Vue QR (yes/no)";
    }
    if(val == "7"){
      return "Vue QR Items";
    }
    if(val == "8"){
      return "Vue QR Options"
    }
    if(val == "9"){
      return "Vue QR Images"
    }
    if(val == "10"){
      return "Vue QR Subscription"
    }
    if(val == "11"){
      return "Sub Item Of Vue QR Subscriptions"
    }
    if(val == "12"){
      return "LPR Products";
    }
    if(val == "13"){
      return "LPR Required Hardware";
    }
    if(val == "14"){
      return "Sub Item Of LPR Required Hardware";
    }
    if(val == "15"){
      return "LPR Subscriptions";
    }
    if(val == "16"){
      return "Sub Item Of LPR Subscriptions";
    }
    if(val == "17"){
      return "Additional Items";
    }
    if(val == "18"){
      return "Additional Item Add-Ons";
    }
    if(val == "19"){
      return "Sub Item Of Additional Item Add-Ons";
    }
    if(val == "20"){
      return "Additional Subscriptions";
    }
    if(val == "21"){
      return "Sub Item Of Additional Subscriptions";
    }
  }

  const handleChangeSANDH = (event) => {
    setSAndH(event.target.value);
  }
  const handleChangeItemDescription = (event) => {
    setItemDescription(event.target.value);
  }
  const handleChangeMinItemQuantity = (event) => {
    setMinItemQuant(event.target.value);
  }
  const handleChangeMaxItemQuantity = (event) => {
    setMaxItemQuant(event.target.value);
  }
  const handleChangeItemName = (event) => {
    setItemName(event.target.value);
  }
  const handleChangeItemCost = (event) => {
    setItemCost(event.target.value);
  }
  const handleChangeItemCostPer = (event) => {
    if(event.target.value < 1) {
      event.target.value = '1';
    }
    setItemCostPer(event.target.value);
  }
  const addMyPostData = async (val) => {
    setloadingbtn(true);
    try {
      const res = await axios.post("/administrator/addorderitem/",val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid  }});
      console.log( res.data);
      if(res.data.rows == '1'){
      setOpenSnackData("New Sale Item Added Successfully.");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      getMyPostData();
      setloadingbtn(false);
      }
      else{
        setOpenSnackData("Sale Item With Same Name Already Exist, Please Try Different Name");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
    
      setloadingbtn(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenSnackData("New Sale Item Added Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setItemImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  let rowOfOne = -1;
  const handleItemAdd = () => {
    let opt = isOptionalOrMandatory == false ? 0 : 1;
    let asv = {itemType:itemTypeCode, itemName:itemName,itemDescription:itemDescription,itemCost:itemCost,itemCostPer:itemCostPer,itemImageData:itemImageData,item_option:opt,min_quantity:minItemQuant,max_quantity:maxItemQuant,sub_of:subValue,sandh:sAndH,"issub":token.issub,"subId":token.subid};
    
    console.log("Post data : "+JSON.stringify(asv));
    addMyPostData(asv);
   }
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
  
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
 <Box  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px',display: { xs: "none", md: "flex" }}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Sale Item
                   </Typography>
                   <Stack direction="column" spacing={2}>
                   <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'16px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "itemType"
          value={itemType}
          label="Age"
          onChange={handleChangeItemType}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {ItemTypeSelect.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
            <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'16px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "optionType"
          value={optionType}
          label="Age"
          onChange={handleOptionalType}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {OptionalMandatorySelect.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select></Stack>
            <Stack direction="column" spacing={1}>
            {isSubSelector == true &&  <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'16px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "subOf"
          value={subOf}
          label="Age"
          onChange={handleSubTypeChange}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {subItemSelector.map((menuItem) => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
            </Select>}
       <TextField
        id="text"
        type="text"
      placeholder="Item Name"
      value={itemName}
       variant="filled"
       onChange={handleChangeItemName}
                        InputLabelProps={{ shrink: true }
                      }
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <Stack direction="row" spacing={1}>
      <TextField
        id="text"
        type="number"
      placeholder="Item Cost"
      value={itemCost}
       variant="filled"
       onChange={handleChangeItemCost}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px' ,width:'112px'}}
                         
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <Typography style={{paddingTop: '22px'}} sx={mainThemeStyle.popUpLableAnswer} height={40}>/</Typography>
      <TextField
        id="text"
        type="number"
      placeholder="1"
      value={itemCostPer}
       variant="filled"
       onChange={handleChangeItemCostPer}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px',width:'60px'}}
                         
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                               
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <Typography style={{paddingTop: '20px'}} sx={mainThemeStyle.popUpLableAnswer} height={40}>Item</Typography>
      </Stack>
      </Stack>
       <TextField 
        id="text"
       
      placeholder="Item Description"
      value={itemDescription}
       variant="filled"
      multiline = "true"
      maxRows={3}
       onChange={handleChangeItemDescription}
                      
                     
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'80px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '10px 10px 10px 10px',
                                
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />


      
       
    {isSubSelector == false && <>  <input
        accept="image/*"
        type="file"
        id="select-image"
        style={{ display: "none"}}
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              setItemImageData(reader.result);
              };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }}
      />
      <label htmlFor="select-image" style={{paddingTop: '16px'}}>
      
      <Button  sx={mainThemeStyle.normalBigButtonGray} variant="contained"  component="span">
          Select Item Image
        </Button>
      </label>
      {itemImageUrl && selectedImage && (
        
          
          <img src={itemImageUrl} alt={selectedImage.name} height="100px" />
        
      )} </>}
      <Stack direction="column" spacing={1}>
       <TextField
        id="text"
        type="number"
      placeholder="Minimum Quantity"
      value={minItemQuant}
       variant="filled"
       onChange={handleChangeMinItemQuantity}
                        InputLabelProps={{ shrink: true }
                      }
                      sx={mainThemeStyle.InputMenuItem}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
      placeholder="Maximum Quantity"
      value={maxItemQuant}
       variant="filled"
       onChange={handleChangeMaxItemQuantity}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                         
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></Stack>
       <TextField
        id="text"
        type="number"
      placeholder="S & H"
      value={sAndH}
       variant="filled"
       onChange={handleChangeSANDH}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                         
                          sx={mainThemeStyle.InputMenuItem}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <LoadingButton sx={mainThemeStyle.normalButtonDown}  onClick={handleItemAdd}  loading={loadingbtn} > Add Item</LoadingButton>
      </Stack>
               </div>
              
           </Box> 
          
           <Divider sx={{backgroundColor:'white'}} />
          
   
<TblContainer>
                    <TblHead />

        <TableBody>
         {recordsAfterPagingAndSorting().map(item => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              {() =>{rowOfOne++; console.log(rowOfOne)}}
             
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
      <TextField
        id="text"
        type="text"
        defaultValue={item.item_name}
        name = {item.id}
       onChange={handleTableItemNameChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      
                        InputProps={{
                          
                            sx: {
                              minWidth:'120px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /> </TableCell>
      <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
     {getItemType(item.type)} </TableCell>
     <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
     <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
           
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = {item.id}
          value={getOptionValue(item.item_option)}
          label="Age"
          onChange={handleChangeTableOptionalType}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {OptionalMandatorySelect.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select> </TableCell>
     <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
     {getSubItemValue(item.sub_of)} </TableCell>
     <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
     <TextField
        id="text"
        type="text"
        defaultValue={item.item_description}
        name = {item.id}
        multiline = "true"
        maxRows={2}
       onChange={handleTableDescriptionChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "standard"
                        InputLabelProps={{ shrink: true }
                      }
                      
                        InputProps={{
                          
                            sx: {
                              minWidth:'250px',
                              maxHeight: '50px',
                                color:'rgba(135, 135, 135, 0.5)',
                               
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '10px 10px 10px 10px',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /> </TableCell>
      <TableCell align="left"> 
      <Stack direction="row" spacing="3">
      <TextField
        id="text"
        type="number"
        defaultValue={item.item_price}
        name = {item.id}
       onChange={handleTablePriceChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      style={{width: '80px'}}
                        InputProps={{
                          
                            sx: {
                             
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
       <Typography style={{padding: '5px'}} sx={mainThemeStyle.popUpLableAnswer} height={40}> / </Typography>
      <TextField
        id="text"
        type="number"
        defaultValue={item.item_price_per}
        name = {item.id}
       onChange={handleTablePricePerChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      style={{width: '60px'}}
                        InputProps={{
                          
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
        <Typography style={{padding: '5px' }} sx={mainThemeStyle.popUpLableAnswer} height={40}> Item</Typography>
      </Stack>
     </TableCell>


       <TableCell align="left"  sx={mainThemeStyle.cellRowBig}>
        <Stack direction="row" spacing={2}>
      { item.item_image != "" && item.optional_data == null && <img src={"https://dev.mdvaccess.com/"+item.item_image} height="80px" />}
      { item.optional_data != null && <img  src={item.optional_data != "" ? item.optional_data : null} height={80} width={80}></img>}
      <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label" >Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
      onChange={ 
        e => {
          if (e.target.files[0].size > 2000000) {
            console.log("File too large");
            return;
          }
          
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
      
          reader.onload = () => {
            console.log(reader.result);
            let idIn;
            for(let i = 0; i < records.length ; i++){
              let items = records[i];
              if(items.id == item.id){
               
                  idIn = i;
                  break;
              }
            }
           
            records[idIn].optional_data =reader.result;
            let newrecord = [];
            for(let i = 0; i < records.length ; i++){
              newrecord.push(records[i]);
            }
            setRecords(newrecord);
            };
          reader.onerror = error => {
            console.log("Error: ", error);
          };


        
        }
      }/></Button></Stack>
       </TableCell>
       <TableCell align="left"> 
      <TextField
        id="text"
        type="number"
        defaultValue={item.min_quantity}
        name = {item.id}
       onChange={handleTableMinQuantityChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      
                        InputProps={{
                          
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
     <TableCell align="left"> 
      <TextField
        id="text"
        type="number"
        defaultValue={item.max_quantity}
        name = {item.id}
       onChange={handleTableMaxQuantityChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      
                        InputProps={{
                          
                            sx: {
                             
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>

<TableCell align="left"> 
      <TextField
        id="text"
        type="number"
        defaultValue={item.sandh}
        name = {item.id}
       onChange={handleTableSAndHChange}
       sx={mainThemeStyle.InputMenuItem}
          variant = "filled"
                        InputLabelProps={{ shrink: true }
                      }
                      
                        InputProps={{
                          
                            sx: {
                            
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell align="left">
              <Stack direction='row' spacing={2}>
                  <Typography></Typography> 
               <LoadingButton sx={mainThemeStyle.viewLogButton} name={item.id} loading={loadingbtn} onClick={handleItemModify}  disabled = {getDisableValueEdit("")}> Save Changes </LoadingButton>
               <LoadingButton loading={loadingbtn} name={item.id} sx={mainThemeStyle.viewLogButtonRed} onClick={handleDelete} >Delete</LoadingButton>
               </Stack>
                      </TableCell>
            </TableRow>
           ))}
        </TableBody>
        </TblContainer>
        <BelowTblPagination></BelowTblPagination>
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default PanelPrice;