import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Logo from './Logoheader';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MDVLogo from '../../asset/MDVLogoSingle.svg';
import RefineLogo from '../../asset/RefineLogo.png';
import useToken from "../../useToken";
import { Grid, MenuList, Stack } from "@mui/material";
import NestedMenuItem from "mui-nested-menu";
import { NestedDropdown } from "./NestedDropdown";
import { hasChildren } from "./utils";
import {ActivityData, LocationData,PropertyOwnerDealerData,DealerProfileData,SideMenuUser,SideMenuDealer, UserData,DealerDistData,DealerManagerData,PropertyOwnerDistData,DistribuorProfileData,DistribuorManagerData, SetUpData,SideMenu,SideMenuAdministrator ,DealerData,DistributorData,PropertyOwnerData,MainAdminLocationData ,UserActivityData, UserLocationData,UserUserData,UserProfileData,SideMenuDistributor} from "./header.data";
import { borderRadius } from "@mui/system";
import { ListItem,ListItemIcon,ListItemText,Collapse ,List } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link ,useNavigate} from "react-router-dom";
import { useRef,useEffect } from "react";
import { Label } from "@mui/icons-material";
const pages = ["Home", "Activity", "Location", "User", "Set Up"];
const pagesmainAdmin = ["Home", "Distributor", "Delaer", "Property Owner", "Extra"];
const pagesmainDistributor = ["Home", "Delaer", "Property Owner", "Profile", "Set Up"];
const pagesmainDealer = ["Home", "Property Owner", "Profile", "Set Up"];

const pagesUser = ["Home", "Activity","Location","User","Profile"];

const Activitypages = [
  "Entry Logs",
  "Call Logs",
  "LP Tag Logs",
  "Guest PIN Logs",
  "Guest Parking Logs"
];
const Locationpages = ["Add Location", "Manage Location"];
const Userpages = ["Add Users", "Manage Users", "Groups"];
const Grouppages = ["Calling Group", "Office Group"];
const SetupPage = ["API", "RFID Card Format", "Blacklist", "Account"];
const BlacklistPages = ["LP Blacklist", "PIN Blacklist"];
const AccountPages = ["Profile", "Property Contacts", "Password"];
const settings = ["Change Role","Change Password","Change Language","Logout"];
const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#1D2837"
    }
  },
  
  menu: {
    "& .MuiMenu-root": {
      backgroundColor: "#2F323A",fontFamily: "Lato"
    }
  }
});

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: "none"
  }
});


const ResponsiveAppBar = (props) => {
  const [anchorElement, setanchorElement] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElActUser, setAnchorElActUser] = React.useState(null);
  const [anchorElLocUser, setAnchorElLocUser] = React.useState(null);
  const [anchorElUsrUser, setAnchorElUsrUser] = React.useState(null);
  const [anchorElSetUser, setAnchorElSetUser] = React.useState(null);
  const { LogOutUser,setOpenPopup,token,setOpenPopupPassword,setOpenPopupLanguage } = props;

  

  const MenuItemInner = ({ item }) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} sx={{fontSize: '15px',fontFamily: "Lato"}}/>;
  };

  const SingleLevel = ({ item }) => {
    const {  callback} = item;
    return (
      <ListItem button sx={{ my: 0, color: "white", height: "60px",'&:hover': {
        backgroundColor: '#3C3C3C',borderRadius: 0,
        color: '#fff',borderRight: '1px solid black' ,borderLeft: '1px solid black'
    }, fontFamily: "Lato",fontSize:'18px',borderRight: '1px solid black' ,borderLeft: '1px solid black'}} onClick={() => {
      callback(navigate);
      setAnchorElNav(null);
    }}>
        <ListItemIcon sx={{color: "white"}}>{item.icon}</ListItemIcon>
        <ListItemText  primary={<Typography  style={{ color:"white",fontFamily: "Lato",fontSize:'14px' }}>{item.label}</Typography>} />
        
      </ListItem>
    );
  };
  //const {token ,setToken,clearToken} = useToken();
  useEffect(() => {
    
   }, [token]);
  const MultiLevel = ({ item }) => {
    const { items: children } = item;
    
    const [open, setOpen] = React.useState(false);
  
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
  
    return (
      <React.Fragment>
        <ListItem button onClick={handleClick} sx={{ my: 0, color: "white", height: "60px",'&:hover': {
          backgroundColor: '#3C3C3C',borderRadius: 0, fontFamily: "Lato",fontSize:'12px',
          color: '#fff',borderRight: '1px solid black' ,borderLeft: '1px solid black'
      }, fontFamily: "Lato",fontSize:'12px',borderRight: '1px solid black' ,borderLeft: '1px solid black'}}>
        <ListItemIcon sx={{color: "white", fontFamily: "Lato",fontSize:'12px'}}>{item.icon}</ListItemIcon>
          <ListItemText primary={<Typography  style={{ color:"white",fontFamily: "Lato",fontSize:'14px' }}>{item.label}</Typography>} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItemInner key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };
  const openMenu = event => {
    setanchorElement(event.currentTarget);
  };
  
  const closeMenu = () => {
    setanchorElement(null);
  };
  
  const handleMenuItemClick = (data) => () => {
    closeMenu();
  };
  const handleClick = event => {
    setanchorElement(event.currentTarget);
  };
  let currentlyHovering = false;
  const handleClose = () => {
    setanchorElement(null);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    if (anchorElUser != event.currentTarget) {
      
      setAnchorElUser(event.currentTarget);
    }
    //setAnchorElUser(event.currentTarget);
  };

  const handleHover = () => {
    currentlyHovering = true;
  };
  const handleOpenActivityMenu = (event) => {
    setAnchorElActUser(event.currentTarget);
  };
  const handleOpenLocationMenu = (event) => {
    setAnchorElLocUser(event.currentTarget);
  };
  const handleOpenUserUserMenu = (event) => {
    setAnchorElUsrUser(event.currentTarget);
  };
  const handleOpenSetUpMenu = (event) => {
    setAnchorElSetUser(event.currentTarget);
  };
  const handleCloseActivityMenu = (event) => {
    setAnchorElActUser(null);
  };
  const handleCloseLocationMenu = (event) => {
    setAnchorElLocUser(null);
  };
  const handleCloseUserUserMenu = (event) => {
    setAnchorElUsrUser(null);
  };
  const handleCloseSetUpMenu = (event) => {
    setAnchorElSetUser(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    navigate('/');
  };

  const handleCloseSideMenu = () => {
    setAnchorElNav(null);
  };

  let navigate = useNavigate();
  const handleCloseUserMenu = () => {
    console.log("Print one");
    setAnchorElUser(null);
    //navigate('/location');
    console.log("Print one");
      };
      
      const styles = useStyles();
  const handleCloseUserMenuLogOut = (e) => {
    setAnchorElUser(null);
   
    console.log(e);

    if(e == 0){
      setOpenPopup(true);
    
  }
  else if (e == 1){
    setOpenPopupPassword(true);
  }
  else if (e == 2){
    setOpenPopupLanguage(true);
  }
   else{
    localStorage.clear();
   
    navigate('/');
    LogOutUser();
    }
    //
    //clearToken();
  }
  const leaveMenu = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
      setAnchorElUser(null);
      }
    }, 50);
  };
  
const MyMap = new Map();
MyMap.set("Activity",Activitypages);
MyMap.set("Location",Locationpages);
MyMap.set("User",Userpages);
MyMap.set("Set Up",SetupPage);



const MenuMap = new Map();
MenuMap.set("Activity",anchorElActUser);
MenuMap.set("Location",anchorElLocUser);
MenuMap.set("User",anchorElUsrUser);
MenuMap.set("Set Up",anchorElSetUser);

const MenuOpenMap = new Map();
MenuOpenMap.set("Activity",handleOpenActivityMenu);
MenuOpenMap.set("Location",handleOpenLocationMenu);
MenuOpenMap.set("User",handleOpenUserUserMenu);
MenuOpenMap.set("Set Up",handleOpenSetUpMenu);


const MenuCloseMap = new Map();
MenuCloseMap.set("Activity",handleCloseActivityMenu);
MenuCloseMap.set("Location",handleCloseLocationMenu);
MenuCloseMap.set("User",handleCloseUserUserMenu);
MenuCloseMap.set("Set Up",handleCloseSetUpMenu);

const menuItemsActivity = [
  Object.assign({}, ActivityData),
  
];
const [itemsActivity] = React.useState(menuItemsActivity);
menuItemsActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuLocationActivity = [
  Object.assign({}, LocationData),
  
];
const [itemsLocation] = React.useState(menuLocationActivity);
menuLocationActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuUserActivity = [
  Object.assign({}, UserData),
  
];
const [itemsUser] = React.useState(menuUserActivity);
menuUserActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuSetupActivity = [
  Object.assign({}, SetUpData),
  
];
const [itemsSetup] = React.useState(menuSetupActivity);
menuSetupActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuDistributorActivity = [
  Object.assign({}, DistributorData),
  
];
const [itemsDistributor] = React.useState(menuDistributorActivity);
menuDistributorActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuDealerActivity = [
  Object.assign({}, DealerData),
  
];
const [itemsDealer] = React.useState(menuDealerActivity);
menuDealerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuPropertyOwnerActivity = [
  Object.assign({}, PropertyOwnerData),
  
];
const [itemsPropertyOwner] = React.useState(menuPropertyOwnerActivity);
menuPropertyOwnerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuMainAdminLocationActivity = [
  Object.assign({}, MainAdminLocationData),
  
];
const [itemsMainAdminLocation] = React.useState(menuMainAdminLocationActivity);
menuMainAdminLocationActivity.forEach((m, index) => {
  m.id = index.toString();
});

const MyMapInsiderHelp = new Map();
MyMapInsiderHelp.set("Activity",itemsActivity);
MyMapInsiderHelp.set("Location",itemsLocation);
MyMapInsiderHelp.set("User",itemsUser);
MyMapInsiderHelp.set("Set Up",itemsSetup);

const MyMapInsiderMainAmdinHelp = new Map();
MyMapInsiderMainAmdinHelp.set("Distributor",itemsDistributor);
MyMapInsiderMainAmdinHelp.set("Delaer",itemsDealer);
MyMapInsiderMainAmdinHelp.set("Property Owner",itemsPropertyOwner);
MyMapInsiderMainAmdinHelp.set("Extra",itemsMainAdminLocation);

const menuDealerDistActivity = [
  Object.assign({}, DealerDistData),
  
];
const [itemsDealerDist] = React.useState(menuDealerDistActivity);
menuDealerDistActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuPropertyOwnerDistActivity = [
  Object.assign({}, PropertyOwnerDistData),
  
];
const [itemsPropertyOwnerDist] = React.useState(menuPropertyOwnerDistActivity);
menuPropertyOwnerDistActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuDistributorProfileActivity = [
  Object.assign({}, DistribuorProfileData),
  
];
const [itemsDistributorProfile] = React.useState(menuDistributorProfileActivity);
menuDistributorProfileActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuDistributorManagerActivity = [
  Object.assign({}, DistribuorManagerData),
  
];
const [itemsDistributorManager] = React.useState(menuDistributorManagerActivity);
menuDistributorManagerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const MyMapInsiderMainDistributorHelp = new Map();
MyMapInsiderMainDistributorHelp.set("Delaer",itemsDealerDist);
MyMapInsiderMainDistributorHelp.set("Property Owner",itemsPropertyOwnerDist);
MyMapInsiderMainDistributorHelp.set("Profile",itemsDistributorProfile);
MyMapInsiderMainDistributorHelp.set("Set Up",itemsDistributorManager);

const menuPropertyOwnerealerActivity = [
  Object.assign({}, PropertyOwnerDealerData),
  
];
const [itemsPropertyDealerOwnerDist] = React.useState(menuPropertyOwnerealerActivity);
menuPropertyOwnerealerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuDealerProfileActivity = [
  Object.assign({}, DealerProfileData),
  
];
const [itemsDealerProfile] = React.useState(menuDealerProfileActivity);
menuDealerProfileActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuDealerManagerActivity = [
  Object.assign({}, DealerManagerData),
  
];
const [itemsDealerManager] = React.useState(menuDealerManagerActivity);
menuDealerManagerActivity.forEach((m, index) => {
  m.id = index.toString();
});
const MyMapInsiderMainDealerHelp = new Map();
MyMapInsiderMainDealerHelp.set("Property Owner",itemsPropertyDealerOwnerDist);
MyMapInsiderMainDealerHelp.set("Profile",itemsDealerProfile);
MyMapInsiderMainDealerHelp.set("Set Up",itemsDealerManager);

const menuUserUserActivity = [
  Object.assign({}, UserActivityData),
  
];
const [itemsUserUserActivity] = React.useState(menuUserUserActivity);
menuPropertyOwnerealerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuUserLocationActivity = [
  Object.assign({}, UserLocationData),
  
];
const [itemsUserLocationActivity] = React.useState(menuUserLocationActivity);
menuPropertyOwnerealerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuUserUserData = [
  Object.assign({}, UserUserData),
  
];
const [itemsUserUserData] = React.useState(menuUserUserData);
menuPropertyOwnerealerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const menuUserUserProfile = [
  Object.assign({}, UserProfileData),
  
];
const [itemsUserUserProfile] = React.useState(menuUserUserProfile);
menuPropertyOwnerealerActivity.forEach((m, index) => {
  m.id = index.toString();
});

const MyMapInsiderMainUserHelp = new Map();
MyMapInsiderMainUserHelp.set("Activity",itemsUserUserActivity);
MyMapInsiderMainUserHelp.set("Location",itemsUserLocationActivity);
MyMapInsiderMainUserHelp.set("User",itemsUserUserData);
MyMapInsiderMainUserHelp.set("Profile",itemsUserUserProfile);

  return (
    
    <ThemeProvider theme={darkTheme}>
      <AppBar position="fixed" sx={{height:"60px" }}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, ml: "10px" }}>
          <div
                style={{
                   
                    width: '170px',
                    height: '50px',
                    marginTop:'0',
                    backgroundImage: `url(${RefineLogo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }}
            />
        {/*  <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              ml: 1,
              marginTop:'7px',
              flexGrow: 1,
              fontFamily: "Lato",
              fontWeight: "700",
              fontSize:'25px',
              color: "inherit",
              textDecoration: "none"
            }}
          >
            MyDoorView
          </Typography> */}
          <Box sx={{display: { xs: "none", md: "flex" }, width: '1px',
      backgroundColor: 'black', 
      height: '60px', 
      mt:'-5px',
      boxShadow: '2px 0px 2px #000'
      }} ></Box>
      </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseSideMenu}
              sx={{ mt: "2px" ,borderRadius: 0,"& .MuiList-root":{
                padding:0, fontFamily: "Lato",fontSize:'12px', backgroundColor: "#1E1E1E",
              },"& .MuiPaper-root": {
                backgroundColor: "#1E1E1E",
                borderRadius: 0, fontFamily: "Lato",fontSize:'12px'
              }}}
            >
              {token.role === "admin" && SideMenu.map((item, key) => <MenuItemInner key={key} item={item} />)}
              {token.role === "superadmin" && SideMenuAdministrator.map((item, key) => <MenuItemInner key={key} item={item} />)}
              {token.role === "distributor" && SideMenuDistributor.map((item, key) => <MenuItemInner key={key} item={item} />)}
              {token.role === "dealer" && SideMenuDealer.map((item, key) => <MenuItemInner key={key} item={item} />)}
              {token.role === "user" && SideMenuUser.map((item, key) => <MenuItemInner key={key} item={item} />)}
            </Menu>
            <div
                style={{
                   
                    width: '100px',
                    height: '50px',
                    marginTop:'7px',
                    marginRight:'10px',
                    backgroundImage: `url(${RefineLogo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                }}
            />
          </Box>
          {/* <Box
                sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
            >
          
            </Box> */}
         {/*  <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Lato",
              fontWeight: "700",
              color: "inherit",
              textDecoration: "none"
            }}
          >
            MyDoorView
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex"}  }}>
            
            {token.role === "admin" && pages.map((page) => 
              page === "Home" ? (
                
                <Button
                  variant="outlined"
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ mt: '-5px', color: "white",borderRadius: 0, height: "60px",'&:hover': {
                    backgroundColor: '#3C3C3C',
                    color: '#fff',
                    
                    borderRight: '1px solid black' ,borderLeft: '1px solid black'
                },fontFamily: "Lato",fontSize:'12px',
                borderRight: '1px solid black' ,borderLeft: '1px solid black' }}
                >
                  {page}
                  
                </Button>
                
              ) : (
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex"}  }}>
{

 MyMapInsiderHelp.get(page).map((m) => (
          <NestedDropdown
            itemId={m.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ borderRadius: "50px", fontFamily: "Lato",
            fontWeight: 700, }}
            data={m}
            navigate={navigate}
          />
        ))
}
                  
                </Box>
                
              )
            )}
            {token.role === "superadmin" && pagesmainAdmin.map((page) => 
              page === "Home" ? (
                
                <Button
                  variant="outlined"
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ mt: '-5px', color: "white",borderRadius: 0, height: "60px",'&:hover': {
                    backgroundColor: '#3C3C3C',
                    color: '#fff',
                    
                    borderRight: '1px solid black' ,borderLeft: '1px solid black'
                },fontFamily: "Lato",fontSize:'12px',
                borderRight: '1px solid black' ,borderLeft: '1px solid black' }}
                >
                  {page}
                  
                </Button>
                
              ) : (
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex"}  }}>
{

 MyMapInsiderMainAmdinHelp.get(page).map((m) => (
          <NestedDropdown
            itemId={m.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ borderRadius: "50px", fontFamily: "Lato",
            fontWeight: 700, }}
            data={m}
            navigate={navigate}
          />
        ))
}
                  
                </Box>
                
              )
            )}
          {token.role === "distributor" && pagesmainDistributor.map((page) => 
              page === "Home" ? (
                
                <Button
                  variant="outlined"
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ mt: '-5px', color: "white",borderRadius: 0, height: "60px",'&:hover': {
                    backgroundColor: '#3C3C3C',
                    color: '#fff',
                    
                    borderRight: '1px solid black' ,borderLeft: '1px solid black'
                },fontFamily: "Lato",fontSize:'12px',
                borderRight: '1px solid black' ,borderLeft: '1px solid black' }}
                >
                  {page}
                  
                </Button>
                
              ) : (
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex"}  }}>
{

MyMapInsiderMainDistributorHelp.get(page).map((m) => (
          <NestedDropdown
            itemId={m.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ borderRadius: "50px", fontFamily: "Lato",
            fontWeight: 700, }}
            data={m}
            navigate={navigate}
          />
        ))
}
                  
                </Box>
                
              )
            )}
            {token.role === "dealer" && pagesmainDealer.map((page) => 
              page === "Home" ? (
                
                <Button
                  variant="outlined"
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ mt: '-5px', color: "white",borderRadius: 0, height: "60px",'&:hover': {
                    backgroundColor: '#3C3C3C',
                    color: '#fff',
                    
                    borderRight: '1px solid black' ,borderLeft: '1px solid black'
                },fontFamily: "Lato",fontSize:'12px',
                borderRight: '1px solid black' ,borderLeft: '1px solid black' }}
                >
                  {page}
                  
                </Button>
                
              ) : (
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex"}  }}>
{

MyMapInsiderMainDealerHelp.get(page).map((m) => (
          <NestedDropdown
            itemId={m.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ borderRadius: "50px", fontFamily: "Lato",
            fontWeight: 700, }}
            data={m}
            navigate={navigate}
          />
        ))
}
                  
                </Box>
                
              )
            )}
             {token.role === "user" && pagesUser.map((page) => 
              page === "Home" ? (
                
                <Button
                  variant="outlined"
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ mt: '-5px', color: "white",borderRadius: 0, height: "60px",'&:hover': {
                    backgroundColor: '#3C3C3C',
                    color: '#fff',
                    
                    borderRight: '1px solid black' ,borderLeft: '1px solid black'
                },fontFamily: "Lato",fontSize:'12px',
                borderRight: '1px solid black' ,borderLeft: '1px solid black' }}
                >
                  {page}
                  
                </Button>
                
              ) : (
                <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex"}  }}>
{

MyMapInsiderMainUserHelp.get(page).map((m) => (
          <NestedDropdown
            itemId={m.id} // NOTE: itemId is required for track items
            variant="contained"
            sx={{ borderRadius: "50px", fontFamily: "Lato",
            fontWeight: 700, }}
            data={m}
            navigate={navigate}
          />
        ))
}
                  
                </Box>
                
              )
            )}
          </Box>
          {token.role !=='superadmin' && token.issub === true && token.name === 'SUPER ADMIN' && <Box sx={{ flexGrow: 0 ,mt: "-5px",display: { xs: "none", md: "flex"}}}> <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{  color: "white","&:disabled": {color: "#75E6DA"}, height: "60px",borderRadius: 0,fontFamily: "Lato",
              fontSize:'75%'}}
                >
                   Currently Managing {(token.role == 'admin' ? 'Property' :token.role)}:  {token.fname}
                  
                </Button> </Box>}
                {token.role !=='superadmin' && token.issub === false  && <Box sx={{ flexGrow: 0 ,mt: "-5px",display: { xs: "none", md: "flex"}}}> <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{  color: "white","&:disabled": {color: "#75E6DA"}, height: "60px",borderRadius: 0,fontFamily: "Lato",
              fontSize:'75%'}}
                >
                    Managing {(token.role == 'admin' ? 'Property' :token.role)}:  {token.name}
                  
                </Button> </Box>}
                {token.role !=='superadmin' && token.issub === true && token.name !== 'SUPER ADMIN'  && <Box sx={{ flexGrow: 0 ,mt: "-5px",display: { xs: "none", md: "flex"}}}> <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{  color: "white","&:disabled": {color: "#75E6DA"}, height: "60px",borderRadius: 0,fontFamily: "Lato",
              fontSize:'75%'}}
                >
                    Managing {(token.role == 'admin' ? 'Property' :token.role)}:  {token.fname}
                  
                </Button> </Box>}
                
                {token.role ==='superadmin' && token.issub === false  && <Box sx={{ flexGrow: 0 ,mt: "-5px",display: { xs: "none", md: "flex"}}}> <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{  color: "white","&:disabled": {color: "#75E6DA"}, height: "60px",borderRadius: 0,fontFamily: "Lato",
              fontSize:'75%'}}
                >
                    Super Administrator
  
                </Button> </Box>}
                {token.role ==='superadmin' && token.issub === true  && <Box sx={{ flexGrow: 0 ,mt: "-5px",display: { xs: "none", md: "flex"}}}> <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{  color: "white","&:disabled": {color: "#75E6DA"}, height: "60px",borderRadius: 0,fontFamily: "Lato",
              fontSize:'75%'}}
                >
                    Managing Super Administrator
  
                </Button> </Box>}
          <Box sx={{ flexGrow: 0 ,mt: "-5px",}} >
            
          {/* <Stack direction="row" > */}
         {/*    {console.log("tplem "+JSON.stringify( token))} */}
          
              
              <Button
                  id="account-button"
                  variant="outlined"
                  key="user"
                  onClick={handleOpenUserMenu}
                  onMouseOver={handleOpenUserMenu}
                  onMouseLeave={leaveMenu}
                
                  startIcon={<PersonOutlineOutlinedIcon/>}
                  endIcon={<ArrowDownIcon/>}
                  sx={{color: "white", height: "60px",borderRadius: 0,'&:hover': {
                    backgroundColor: '#3C3C3C',
                    color: '#fff',
                    borderLeft: '1px solid black'
                },fontFamily: "Lato",lineHeight:'1.2em',fontSize:'0.85em',
               borderLeft: '1px solid black' }}
                >
                     {token.username}
                  
                </Button>
                
            <Menu
              sx={{ mt: "44px"  ,borderRadius: 0,"& .MuiList-root":{
                padding:0,
                width: '250px'
              },"& .MuiPaper-root": {
                backgroundColor: "#1E1E1E",
                borderRadius: 0,
                
              }}}
              MenuListProps={{
                onMouseEnter:handleHover,
                onMouseLeave: leaveMenu,
                style: { pointerEvents: "auto" }
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              getContentAnchorEl={null}
              PopoverClasses={{
                root: styles.popOverRoot
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting,index) => (
                <MenuItem name={index} key={setting} onClick={ () => {handleCloseUserMenuLogOut(index)}} sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  border: '.5px solid grey',
                  borderRadius:0
                  ,fontFamily: "Lato"
              }}>
                  {setting}
                </MenuItem>
              ))}
            </Menu>
            
           {/*  </Stack> */}
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};
export default ResponsiveAppBar;
