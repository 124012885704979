import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel,Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "./axios";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import {  ThemeProvider } from '@mui/material/styles';
import GlobalAlert from "./GlobalAlert";
import useToken from "./useToken";
import Theme from './components/ItemTheme';
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function DealerProfile ( {RefreshToken,...props}){
    const {token} = useToken();
  const theme = Theme;
  let [dataOfDistributor,setDataOfDistributor] = useState({
      del_id: '',
      company_name: '',
      contact_name: '',
      street_add: '',
      city: '',
      state: '',
      zip_code: '',
      telephone: '',
      email: '',
      password: '',
      unit_cost:'',
      total_unit:'',
      apfname:'',
      aplname:'',
      apphone:'',
      apemail:'',
      start_date:'',
      purchasefname:'',
      purchaselname:'',
      purchasephone:'',
      purchaseemail:'',
      dis_id:'',
      accept_payment:'0'
  });
  //const {RefreshToken} = props;
  const { getAlertTitle} = GlobalAlert();
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");

  const changeTimeZone  =  (date, timeZone)=> {
    const laDate = moment.utc(date).format();
    //if (typeof date === 'string') {
      const ldated =  new Date(
        new Date(laDate).toLocaleString('en-US', {
          timeZone,
        }),
      );
      const mystr = moment(ldated).format('MM/DD/YYYY');
      return mystr;
   /* }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );*/
  }

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("34").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("34").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      console.log('role is '+ token.sub_type);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("34").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("34").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("34").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("34").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }

  const getDisableValue = (val) => {
    if(getDisableValueEdit() == false){
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("34");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("34");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("34");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("34");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer'){
      if(token.sub_type == "1"){
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("34");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let idData ;
        let valOfDisableData = getRoleAccessInnerVal("34");
        for(let i = 0; i < valOfDisableData.length ; i ++){
          if(valOfDisableData[i].id == val ){
            idData = i;
            break;
          }
        }
        let valOfDisable = valOfDisableData[idData].distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
  }else{
    return true;
  }
    
  }
  
  const getMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/dealer/profile/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
      console.log("data format"+res.data);
      setDataOfDistributor({del_id: res.data[0].del_id,
        company_name: res.data[0].company_name,
        contact_name:res.data[0].contact_name,
        street_add: res.data[0].street_add,
        city: res.data[0].city,
        state: res.data[0].state,
        zip_code: res.data[0].zip_code,
        telephone: res.data[0].telephone,
        email: res.data[0].email,
        password: res.data[0].password,
        unit_cost:res.data[0].unit_cost,
        total_unit:res.data[0].total_unit,
        dis_id:res.data[0].dis_id,
        accept_payment:res.data[0].accept_payment,
        apfname:res.data[0].apfname,
        aplname:res.data[0].aplname,
        apphone:res.data[0].apphone,
        apemail:res.data[0].apemail,
        start_date:res.data[0].start_date,
        purchasefname:res.data[0].purchasefname,
        purchaselname:res.data[0].purchaselname,
        purchasephone:res.data[0].purchasephone,
        purchaseemail:res.data[0].purchaseemail
    });
      //setDistributorData();
     
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  
  const [loading,setLoading] = useState(false);
  const [isVisible,setIsVisibile] = useState(false);
  const onClickTogglePassword = () =>{
    setIsVisibile(!isVisible);
  }
  const setDistributorData = () => {
    setshowUpdateButton(true);
    setDataOfDistributor({
    del_id: dataOfDistributor.del_id,
      company_name: dataOfDistributor.company_name,
      contact_name: dataOfDistributor.contact_name,
      street_add: dataOfDistributor.street_add,
      city: dataOfDistributor.city,
      state: dataOfDistributor.state,
      zip_code: dataOfDistributor.zip_code,
      telephone: dataOfDistributor.telephone,
      email: dataOfDistributor.email,
      password: dataOfDistributor.password,
      unit_cost:dataOfDistributor.unit_cost,
      total_unit:dataOfDistributor.total_unit,
      dis_id:dataOfDistributor.dis_id,
      accept_payment:dataOfDistributor.accept_payment,
      apfname:dataOfDistributor.apfname,
      aplname:dataOfDistributor.aplname,
      apphone:dataOfDistributor.apphone,
      apemail:dataOfDistributor.apemail,
      start_date:dataOfDistributor.start_date,
      purchasefname:dataOfDistributor.purchasefname,
      purchaselname:dataOfDistributor.purchaselname,
      purchasephone:dataOfDistributor.purchasephone,
      purchaseemail:dataOfDistributor.purchaseemail,
      issub:token.issub,
      subId:token.subid
    });
  }
  const handleInputChange = (event) =>{
    let ds = event.target.name;
    dataOfDistributor[ds] = event.target.value;
    setDistributorData();
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleUpdateDistributor = () =>{
    if(dataOfDistributor.contact_name == ""){
      setOpenSnackData(getAlertTitle('DPNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDistributor.company_name == ""){
      setOpenSnackData(getAlertTitle('DPCNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDistributor.email == ""){
      setOpenSnackData(getAlertTitle('DPENE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(!isValidEmail(dataOfDistributor.email)){
      setOpenSnackData(getAlertTitle('DPENV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(dataOfDistributor.password.length  < 1 ){
      setOpenSnackData(getAlertTitle('DPPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    updateMyPostData();
  }
  const updateMyPostData = async () => {
    setLoading(true);
    try {
      const res = await axios.patch("/dealer/profile/",dataOfDistributor, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('DPUS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('DPUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };

  return  <ThemeProvider theme={theme} ><> 
 

<Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
<Box sx={mainThemeStyle.box} >

<Typography sx={mainThemeStyle.lable}>Profile</Typography>
</Box>
  <Box sx={mainThemeStyle.boxTopHeader}>
     
            
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Edit Profile
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Id </Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
    
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
   
     <TextField
       
        type="text"
        name = "contact_name"
        onChange={handleInputChange}
        value={dataOfDistributor.contact_name}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("128")}
                          variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
          <TextField
       
        type="text"
        name = "company_name"
        onChange={handleInputChange}
        value={dataOfDistributor.company_name}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("129")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
   
          <TextField
       
        type="text"
        name = "street_add"
        onChange={handleInputChange}
        value={dataOfDistributor.street_add}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("130")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
       <TextField
        
        type="text"
        name = "city"
        onChange={handleInputChange}
        value={dataOfDistributor.city}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("131")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name = "state"
                        onChange={handleInputChange}
                       value={dataOfDistributor.state}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("132")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                               
                           },
                       }}
                      
                   />
                   
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Cell Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Customer Since</Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>Password</Typography> */}
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>Unit Cost</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Total Unit</Typography> */}
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
      
        <TextField
                        
                        height={40}
                        type= 'text'
                        name = "zip_code"
                        onChange={handleInputChange}
                        value={dataOfDistributor.zip_code}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("133")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                     
                     <TextField
                        
                        height={40}
                        type= 'number'
                        name = "telephone"
                        onChange={handleInputChange}
                        value={dataOfDistributor.telephone}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("134")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{dataOfDistributor.email}</Typography>
                    <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{changeTimeZone(dataOfDistributor.start_date, 'America/New_York')}</Typography>
          
                    {/*  <TextField
                        
                        height={40}
                        type= 'text'
                        name = "email"
                        onChange={handleInputChange}
                        value={dataOfDistributor.email}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                 {/*    <TextField
                        
                        height={40}
                        type= {isVisible ? 'text' : 'password'}
                        name = "password"
                        onChange={handleInputChange}
                        value={dataOfDistributor.password}
                        margin="normal"
                        variant = "filled"
                        
                        InputLabelProps={{
                            shrink: true,
                            style: {
                            '&::placeholder': {
                                textOverflow: 'ellipsis !important',
                                color: 'blue'
                              },
                            }
                        }}
                        
                        InputProps={{
                           
                            style: {
                                
                              color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                               
                                
                            },
                            
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        style={{
                                            color:
                                            'rgba(135, 135, 135, 0.5)',
                                            padding: '18px 5px 0 0',
                                        }}
                                        aria-label={
                                            'visibility token'
                                        }
                                        onClick={onClickTogglePassword}
                                    >
                                       
                                       {isVisible ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                        
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                       
                    /> */}
                    
                    
      </Stack>
      </Grid>
     
      
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}> Id </Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Company Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Cell Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Customer Since</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
   
     <TextField
       
        type="text"
        name = "contact_name"
        onChange={handleInputChange}
        value={dataOfDistributor.contact_name}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("128")}
                          variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
          <TextField
       
        type="text"
        name = "company_name"
        onChange={handleInputChange}
        value={dataOfDistributor.company_name}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("129")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
   
          <TextField
       
        type="text"
        name = "street_add"
        onChange={handleInputChange}
        value={dataOfDistributor.street_add}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("130")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
       <TextField
        
        type="text"
        name = "city"
        onChange={handleInputChange}
        value={dataOfDistributor.city}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("131")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name = "state"
                        onChange={handleInputChange}
                       value={dataOfDistributor.state}
                       sx={mainThemeStyle.InputMenuItem}
                       InputLabelProps={{ shrink: true }
                     }
                     disabled = {getDisableValue("132")}
                     variant="filled"
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
             

                                background: 'rgba(135, 135, 135, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                        
                        height={40}
                        type= 'text'
                        name = "zip_code"
                        onChange={handleInputChange}
                        value={dataOfDistributor.zip_code}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("133")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                     
                     <TextField
                        
                        height={40}
                        type= 'number'
                        name = "telephone"
                        onChange={handleInputChange}
                        value={dataOfDistributor.telephone}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("134")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />
                    <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{dataOfDistributor.email}</Typography>
                    <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{changeTimeZone(dataOfDistributor.start_date, 'America/New_York')}</Typography>
          
                   
      </Stack>
      </Grid>
      
      
    
     
      
      </Grid>
      </Box>

      <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   AP Contact
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name</Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
     
                    <TextField
                       
                        height={40}
                        type= 'text'
                        name="apfname"
                        value={dataOfDistributor.apfname}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }
                        
                      }
                      variant="filled"
                
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(255, 255, 255, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="aplname"
                       value={dataOfDistributor.aplname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                  
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
      </Stack>
    
      
      </Grid>
      <Grid item xs={1}>
     
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Phone </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Email </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= "tel"
                       name="apphone"
                       value={dataOfDistributor.apphone}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                    
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            
                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
      
                  <TextField
                       
                       height={40}
                       type= 'text'
                       name="apemail"
                       value={dataOfDistributor.apemail}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
    
      
      </Grid>
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Phone</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Email </Typography>
      
     
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="apfname"
                       value={dataOfDistributor.apfname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
              
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name="aplname"
                       value={dataOfDistributor.aplname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                   
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                      
                      height={40}
                      type= 'tel'
                      name="apphone"
                      value={dataOfDistributor.apphone}
                      sx={mainThemeStyle.InputMenuItem}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }
                      
                    }
                    variant="filled"
               
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                              '&.Mui-disabled': {
           

                                background: 'rgba(255, 255, 255, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                          },
                      }}
                     
                  />
                  
                  <TextField
                       
                       height={40}
                       type= 'text'
                       name="apemail"
                       value={dataOfDistributor.apemail}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                    
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
    
      
      </Grid>
     
      </Grid>
      
      </Box>
      <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Purchasing Contact
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
     
                    <TextField
                       
                        height={40}
                        type= 'text'
                        name="purchasefname"
                        value={dataOfDistributor.purchasefname}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }
                        
                      }
                      variant="filled"
                    
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(255, 255, 255, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                       
                       height={40}
                       type= 'text'
                       name="purchaselname"
                       value={dataOfDistributor.purchaselname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                    
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   
                   
      </Stack>
    
      
      </Grid>
      <Grid item xs={1}>
     
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Phone</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Email </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'tel'
                       name="purchasephone"
                       value={dataOfDistributor.purchasephone}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                   
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
      
                  <TextField
                       
                       height={40}
                       type= 'text'
                       name="purchaseemail"
                       value={dataOfDistributor.purchaseemail}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                  
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
    
      
      </Grid>
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Phone</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Email </Typography>
      
     
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="purchasefname"
                       value={dataOfDistributor.purchasefname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                    
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                       
                       height={40}
                       type= 'text'
                       name="purchaselname"
                       value={dataOfDistributor.purchaselname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                    
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
                   <TextField
                      
                      height={40}
                      type= 'text'
                      name="purchasephone"
                      value={dataOfDistributor.purchasephone}
                      sx={mainThemeStyle.InputMenuItem}
                      onChange={handleInputChange}
                      InputLabelProps={{ shrink: true }
                      
                    }
                    variant="filled"
                  
                      InputProps={{
                         
                          sx: {
                              
                              color:'rgba(135, 135, 135, 0.5)',
                              height:'33px',
                              borderRadius: '5px',
                              backgroundColor: '#121212',
                              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                              padding: '0 0 15px 0',
                              textIndent: '15px !important',
                              '&.Mui-disabled': {
           

                                background: 'rgba(255, 255, 255, 0.5)',
                              },
                              
                              "& input.Mui-disabled": {
                                color: "white"
                              }
                             
                              
                          },
                      }}
                     
                  />
                  
                  <TextField
                       
                       height={40}
                       type= 'text'
                       name="purchaseemail"
                       value={dataOfDistributor.purchaseemail}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       InputLabelProps={{ shrink: true }
                       
                     }
                     variant="filled"
                   
                       InputProps={{
                          
                           sx: {
                               
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                               '&.Mui-disabled': {
            

                                background: 'rgba(255, 255, 255, 0.5)',
                               },
                               
                               "& input.Mui-disabled": {
                                 color: "white"
                               }
                              
                               
                           },
                       }}
                      
                   />
      </Stack>
    
      
      </Grid>
     
      </Grid>
      
      </Box> 
{showUpdateButton &&  <Box sx= {mainThemeStyle.boxDown}><LoadingButton
         
         sx={mainThemeStyle.normalButtonRelative}
         loading={loading}
         onClick={handleUpdateDistributor}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save Details
       </LoadingButton>
    <Typography></Typography>
      </Box>}


</Box>

<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      </Box>
 </> 
          
 </ThemeProvider>
          
          
          ;
};

export default DealerProfile;