import axios from "axios";

// we need to pass the baseURL as an object
const API = axios.create({
  baseURL: "https://dev.mdvaccess.com/webphp",
});

export default API;

//http://localhost:3000/user/manageuser

//"http://52.91.46.4/php",