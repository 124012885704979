import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, Select,InputLabel,MenuItem, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import SaveIcon from '@mui/icons-material/Save';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import {saveAs} from "file-saver";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import axios from "./axios";
import LoadingButton from '@mui/lab/LoadingButton';
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import PuffLoader from "react-spinners/PuffLoader"; 
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function DealerEditOrder ( {idOfOrder,RefreshToken,...props}){
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const {token} = useToken();
 
  const { getAlertTitle} = GlobalAlert();
  const [imageUrl, setImageUrl] = useState();
  const [imageUrlDealer, setImageUrlDealer] = useState();
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [EnLog,setEnLog] = useState([]);
  const [showUpdateButton, setshowUpdateButton] = React.useState(false);
  const [apiValue, setAPIValue] = useState({id:idOfOrder.id,
    del_id:idOfOrder.del_id,
    contact_name:idOfOrder.contact_name,
    email:idOfOrder.email,
    street_add:idOfOrder.street_add,
    city:idOfOrder.city,
    state:idOfOrder.state,
    zip_code:idOfOrder.zip_code,
    company_name:idOfOrder.company_name,
    telephone:idOfOrder.telephone,
    items:JSON.parse(idOfOrder.items),
    apfname:idOfOrder.apfname,
  apphone:idOfOrder.apphone,
  aplname:idOfOrder.aplname,
  apemail:idOfOrder.apemail,
  purchasefname:idOfOrder.purchasefname,
  purchaselname:idOfOrder.purchaselname,
  purchasephone:idOfOrder.purchasephone,
  purchaseemail:idOfOrder.purchaseemail,
  proeprty_type:idOfOrder.proeprty_type,
  user_property_name:idOfOrder.user_property_name,
  user_first_name:idOfOrder.user_first_name,
  user_last_name:idOfOrder.user_last_name,
  user_unit_quantity:idOfOrder.user_unit_quantity,
  user_phone_no:idOfOrder.user_phone_no,
  user_email:idOfOrder.user_email,
  user_address:idOfOrder.user_address,
  user_city:idOfOrder.user_city,
  user_state:idOfOrder.user_state,
  user_zipcode:idOfOrder.user_zipcode,
  orderdate:idOfOrder.orderdate,
  orderaddress:idOfOrder.orderaddress,
  ordercity:idOfOrder.ordercity,
  ordernumber:idOfOrder.ordernumber,
  orderstate:idOfOrder.orderstate,
  orderzipcode:idOfOrder.orderzipcode,
  all_order_subtotal:idOfOrder.all_order_subtotal,
all_order_subtotal_plus_payment_charge:idOfOrder.all_order_subtotal_plus_payment_charge,
promocode:idOfOrder.promocode,
promocode_discount:idOfOrder.promocode_discount,
promocode_expiration_date:idOfOrder.promocode_expiration_date,
all_order_subtotal_after_discount:idOfOrder.all_order_subtotal_after_discount,
all_order_subtotal_after_discount_plus_payment_charge:idOfOrder.all_order_subtotal_after_discount_plus_payment_charge,
payment_transaction:idOfOrder.payment_transaction,
refund_transaction:idOfOrder.payment_transaction,
    issub:token.issub,subId:token.subid});
  const [dealerDetails, setDealerDetails] = useState('');
  console.log(idOfOrder.items);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  let [loading, setLoading] = useState(true);

  const setAPIValueData = () => {
    setAPIValue({id:apiValue.id,
    del_id:apiValue.del_id,
    contact_name:apiValue.contact_name,
    email:apiValue.email,
    street_add:apiValue.street_add,
    city:apiValue.city,
    state:apiValue.state,
    zip_code:apiValue.zip_code,
    company_name:apiValue.company_name,
    telephone:apiValue.telephone,
    items:JSON.parse(apiValue.items),
    apfname:apiValue.apfname,
  apphone:apiValue.apphone,
  aplname:apiValue.aplname,
  apemail:apiValue.apemail,
  purchasefname:apiValue.purchasefname,
  purchaselname:apiValue.purchaselname,
  purchasephone:apiValue.purchasephone,
  purchaseemail:apiValue.purchaseemail,
  proeprty_type:apiValue.proeprty_type,
  user_property_name:apiValue.user_property_name,
  user_first_name:apiValue.user_first_name,
  user_last_name:apiValue.user_last_name,
  user_unit_quantity:apiValue.user_unit_quantity,
  user_phone_no:apiValue.user_phone_no,
  user_email:apiValue.user_email,
  user_address:apiValue.user_address,
  user_city:apiValue.user_city,
  user_state:apiValue.user_state,
  user_zipcode:apiValue.user_zipcode,
  orderdate:apiValue.orderdate,
  orderaddress:apiValue.orderaddress,
  ordercity:apiValue.ordercity,
  ordernumber:apiValue.ordernumber,
  orderstate:apiValue.orderstate,
  orderzipcode:apiValue.orderzipcode,
  all_order_subtotal:apiValue.all_order_subtotal,
all_order_subtotal_plus_payment_charge:apiValue.all_order_subtotal_plus_payment_charge,
promocode:apiValue.promocode,
promocode_discount:apiValue.promocode_discount,
promocode_expiration_date:apiValue.promocode_expiration_date,
all_order_subtotal_after_discount:apiValue.all_order_subtotal_after_discount,
all_order_subtotal_after_discount_plus_payment_charge:apiValue.all_order_subtotal_after_discount_plus_payment_charge,
isNewProperty:apiValue.isNewProperty,
payment_transaction:apiValue.payment_transaction,
refund_transaction:apiValue.payment_transaction,
    issub:token.issub,subId:token.subid});
  } 
  const [openPopup, setOpenPopup] = useState(false);
   
  
  const downloadPropertyLogo = () => {
   /* fetch("https://dev.mdvaccess.com/"+idOfOrder.property_logo_url, {
        method: "GET",
        headers: {}
      })
        .then(response => {
          response.arrayBuffer().then(function(buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "image.png"); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch(err => {
          console.log(err);
        });*/
        let url = "https://dev.mdvaccess.com/"+idOfOrder.property_logo_url;
    saveAs(url, "property-logo");
  }
  const downloadDealerLogo = () => {
    /* fetch("https://dev.mdvaccess.com/"+idOfOrder.property_logo_url, {
         method: "GET",
         headers: {}
       })
         .then(response => {
           response.arrayBuffer().then(function(buffer) {
             const url = window.URL.createObjectURL(new Blob([buffer]));
             const link = document.createElement("a");
             link.href = url;
             link.setAttribute("download", "image.png"); //or any other extension
             document.body.appendChild(link);
             link.click();
           });
         })
         .catch(err => {
           console.log(err);
         });*/
         let url = "https://dev.mdvaccess.com/"+idOfOrder.dealer_logo_url;
     saveAs(url, "dealer-logo");
   }
 
  
   const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const PropertyType = {
    id: "1",
    label: "Apartments",
    items: [
      {
        label: "Apartments",
        callback: () =>  {}
      },
      {
        label: "Gated Community",
        callback: () =>  {}
      },
      {
        label: "Commercial / Office",
        callback: () =>  {}
      },
      {
        label: "Estate",
        callback: () =>  {}
      },
    ]
  }
  const handleSetPropertyType = (event) => {
    if(event.target.value == 'Gated Community'){
      apiValue.proeprty_type = '1'
      setAPIValueData();
    }else  if(event.target.value == 'Commercial / Office'){
   
      apiValue.proeprty_type = '2'
      setAPIValueData();
    }else  if(event.target.value == 'Estate'){
      apiValue.proeprty_type = '3'
      setAPIValueData();
    }else{
      apiValue.proeprty_type = '0'
      setAPIValueData();
    }
  }
  const handleSetNewProperty = (event) => {
    let idIn;
    for(let i = 0; i < dataOfPropertyOwner.length ; i++){
      if(dataOfPropertyOwner[i].id == event.target.value){
          idIn = i;
          break;
      }
    }
    console.log("company Name"+dataOfPropertyOwner[idIn].copmpany);
    apiValue.user_email = dataOfPropertyOwner[idIn].email;
    apiValue.user_address = dataOfPropertyOwner[idIn].address;
    apiValue.user_city = dataOfPropertyOwner[idIn].city;
    apiValue.user_state = dataOfPropertyOwner[idIn].state;
    apiValue.user_zipcode= dataOfPropertyOwner[idIn].zip_code;
    apiValue.user_property_name= dataOfPropertyOwner[idIn].copmpany;
    apiValue.user_first_name= dataOfPropertyOwner[idIn].name;
    apiValue.proeprty_type = dataOfPropertyOwner[idIn].proeprty_type;
    apiValue.user_unit_quantity= dataOfPropertyOwner[idIn].total_unit;
    apiValue.user_phone_no= dataOfPropertyOwner[idIn].office;

    apiValue.orderaddress = dataOfPropertyOwner[idIn].address;
apiValue.ordercity = dataOfPropertyOwner[idIn].city;
apiValue.orderstate= dataOfPropertyOwner[idIn].state;
apiValue.orderzipcode= dataOfPropertyOwner[idIn].zip_code;

    setAPIValueData();
  }
  const GetPropertyType = () =>{
    if(apiValue.proeprty_type == '1'){
      return  "Gated Community";
    }else  if(apiValue.proeprty_type == '2'){
      return  "Commercial / Office";
    }else  if(apiValue.proeprty_type == '3'){
      return  "Estate";
    }else{
      return  "Apartments";
    }
  }

  const handleChangeValueOfPanelSelect = (event) => {
    let ds = event.target.name;
    let items = apiValue.items;
    let itemschange;
    let indexoffind;
    console.log("datea is "+ JSON.stringify(apiValue));
    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == "1" && items[i].item_name == ds){
        itemschange = items[i];
        indexoffind = i;
      }
    }
  
    if(event.target.checked == true){
      itemschange.total_quantity = '1';
    }else{
      itemschange.total_quantity = '0';
    }
    for(let i = 0; i < items.length ; i ++){
      if(items[i].sub_of == "1" || items[i].sub_of == "2"){
        let itemsds = items[i];
        itemsds.total_quantity = itemsds.min_quantity;
        apiValue.items[i] = itemsds;
      }
    }
    apiValue.items[indexoffind] = itemschange;
  /*  apiValue.extrenal_card_quantity = '0';
    apiValue.extrenal_card_subtotal = '0';
    apiValue.key_fobs_quantity = '0';
    apiValue.key_fob_subtotal = '0';
    apiValue.surface_pedestal_quantity = '0';
    apiValue.surface_pedestal_subtotal = '0';
    apiValue.flush_pedestal_quantity = '0';
    apiValue.rain_hood_flush_mount_quantity = '0';
      apiValue.cooling_fan_flush_mount_quantity = '0';
      apiValue.heater_flush_mount_quantity = '0';
      apiValue.flush_pedestal_add_on_subtotal = '0';
    apiValue.flush_pedestal_subtotal = '0';
    apiValue.contorlrelay = '0';
    apiValue.elevator_floor = '0';
    apiValue.elevator_cr_quantity = '0';
    apiValue.elevator_r_quantity = '0';
    apiValue.elevator_sub_total = '0';
    apiValue.all_pedestal_add_on_subtotal = '0';
    apiValue.saas_commercial = '0';
    apiValue.saas_commercial_subtotal = '0';
    apiValue.saas_multi_family_subtotal = '0';
    apiValue.saas_multifamily = '0';
    apiValue.saas_panel_host = '0';
    apiValue.saas_panel_host_subtotal = '0';
    apiValue.saas_single_family = '0';
    apiValue.saas_single_family_subtotal = '0';
    apiValue.total_household = '0';
    apiValue.totalpanel = parseInt(apiValue.panel_two_quantity) + parseInt(apiValue.panel_three_quantity) + parseInt(apiValue.panel_one_quantity);
    apiValue.panelshippingtotal = ( parseFloat(apiValue.panel_two_quantity) + parseFloat(apiValue.panel_three_quantity) + parseFloat(apiValue.panel_one_quantity)) * 50;
    apiValue.totalpanelcharge = ((parseFloat(apiValue.panel_one_quantity) * parseFloat(apiValue.vue_surface_cost)) + (parseFloat(apiValue.panel_two_quantity) * parseFloat(apiValue.vue_flush_cost)) + (parseFloat(apiValue.panel_three_quantity)* parseFloat(apiValue.vue_hood_cost)) + parseFloat(apiValue.panelshippingtotal));
    
 
    apiValue.all_order_subtotal = parseFloat(apiValue.totalpanelcharge) + parseFloat(apiValue.all_pedestal_add_on_subtotal)+ parseFloat(apiValue.all_panel_saas_subtotal) + parseFloat(apiValue.all_qr_subtotal) + parseFloat(apiValue.all_lpr_subtotal);
    apiValue.all_order_subtotal_plus_payment_charge = parseFloat(apiValue.all_order_subtotal) + ((parseFloat(apiValue.all_order_subtotal)*4)/100)
   */ setAPIValueData();
  }

  
  const handleChangeValueOfSaas = (event) => {
    let ds = event.target.name;
    setshowUpdateButton(true);
   
    let items = apiValue.items;
    let itemschange;
    let indexoffind;
    //console.log("datea is "+ JSON.stringify(apiValue));
    for(let i = 0; i < items.length ; i ++){
      if(items[i].id == ds){
        itemschange = items[i];
        indexoffind = i;
        break;
      }
    }
    itemschange.total_quantity = event.target.checked == true ? "1" : "0";

    
      for(let i = 0; i < items.length ; i ++){
        if(items[i].sub_of == ds){
          let itemsds = items[i];
          itemsds.total_quantity = itemsds.min_quantity;
          apiValue.items[i] = itemsds;
        }
      }
    

    apiValue.items[indexoffind] = itemschange;
   /* apiValue[ds] = event.target.checked == true ? "1" : "0";
    if(ds == 'saas_multifamily' && event.target.checked == true){
      apiValue.total_household = '20';
      apiValue.saas_multi_family_subtotal = parseFloat(apiValue.total_household)*2;
    }
    if(ds == 'saas_multifamily' && event.target.checked == false){
      apiValue.total_household = '0';
      apiValue.saas_multi_family_subtotal = '0';
    }
    if(ds == 'saas_commercial' && event.target.checked == true){
      apiValue.saas_commercial_subtotal = '50';
    }
    if(ds == 'saas_commercial' && event.target.checked == false){
      apiValue.saas_commercial_subtotal = '0';
    }
    if(ds == 'saas_panel_host' && event.target.checked == true){
      apiValue.saas_panel_host_subtotal = '12';
    }
    if(ds == 'saas_panel_host' && event.target.checked == false){
      apiValue.saas_panel_host_subtotal = '0';
    }
    if(ds == 'saas_single_family' && event.target.checked == true){
      apiValue.saas_single_family_subtotal = '45';
    }
    if(ds == 'saas_single_family' && event.target.checked == false){
      apiValue.saas_single_family_subtotal = '0';
    }
    apiValue.all_panel_saas_subtotal = parseFloat(apiValue.saas_multi_family_subtotal) + parseFloat(apiValue.saas_commercial_subtotal) + parseFloat(apiValue.saas_panel_host_subtotal) + parseFloat(apiValue.saas_single_family_subtotal);
    apiValue.all_order_subtotal = parseFloat(apiValue.totalpanelcharge) + parseFloat(apiValue.all_pedestal_add_on_subtotal)+ parseFloat(apiValue.all_panel_saas_subtotal) + parseFloat(apiValue.all_qr_subtotal) + parseFloat(apiValue.all_lpr_subtotal);
    apiValue.all_order_subtotal_plus_payment_charge = parseFloat(apiValue.all_order_subtotal) + ((parseFloat(apiValue.all_order_subtotal)*4)/100)
    */
    
    setAPIValueData();
  }
  const getTotalPaymentCharge = () => {
    let payment = parseFloat(getProductAddOnCost("20","21")) +
    parseFloat(getProductAddOnCost("18","19")) +
    parseFloat(getProductCost("17")) +
    parseFloat(getProductAddOnCost("15","16")* getProductTotalCount("12")) +
    parseFloat(getProductAddOnCost("13","14")) +
    parseFloat(getProductCost("12")) +
    parseFloat(getSubTotalOfVueSubscription()) +
    parseFloat(getSubTotalOfPanelSubscription()) +
    parseFloat(getVuePanelAddOnsCost()) +
    parseFloat(getVuePanelCost()) ;

    payment = parseFloat(payment) + ((parseFloat(payment)*4)/100);
    return payment;

  }
  const getValueOfVue = () => {
    let items = apiValue.items;
    
    let val;

    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == "6"){
        val = items[i].total_quantity;
        
        break;
      }
    }
    return val;
  }
  const handleChangeValueOfVue = (event) => {
    let ds = event.target.name;
    setshowUpdateButton(true);
   
    let items = apiValue.items;
    let itemschange;
    let indexoffind;

    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == "6"){
        itemschange = items[i];
        indexoffind = i;
        break;
      }
    }
    if(event.target.checked == true){
    itemschange.total_quantity =  "1" ;

    
      for(let i = 0; i < items.length ; i ++){
        if(items[i].type == "7" || items[i].type == "8" || items[i].type == "9" || items[i].type == "10" || items[i].type == "11"){
          let itemsds = items[i];
          itemsds.total_quantity = itemsds.min_quantity;
          itemsds.optional_data = "";
          apiValue.items[i] = itemsds;
        }
      }
    

    apiValue.items[indexoffind] = itemschange;
    setAPIValueData();
  }
  }
  const handleChangeValueOfVueReverse = (event) => {
    let ds = event.target.name;
    setshowUpdateButton(true);
   
    let items = apiValue.items;
    let itemschange;
    let indexoffind;

    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == "6"){
        itemschange = items[i];
        indexoffind = i;
        break;
      }
    }
    if(event.target.checked == true){
    itemschange.total_quantity =  "0" ;

    
      for(let i = 0; i < items.length ; i ++){
        if(items[i].type == "7" || items[i].type == "8" || items[i].type == "9" || items[i].type == "10" || items[i].type == "11"){
          let itemsds = items[i];
          itemsds.total_quantity = itemsds.min_quantity;
          itemsds.optional_data = "";
          apiValue.items[i] = itemsds;
        }
      }
    

    apiValue.items[indexoffind] = itemschange;
    setAPIValueData();
    }
  }
  const getDisableValue = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("4").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("4").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("4").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("4").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("4").administrator_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
      else{
        let valOfDisable = getRoleAccessVal("4").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const currentDisableValue = getDisableValue();
  const [dataOfDealerFromWeb , setDataOfDealerFromWeb] = useState("");
  const [dataOfPropertyOwner,setDataOfPropertyOwner] = useState([]);

  const [selectedImage, setSelectedImage] = useState(idOfOrder.property_logo_url != null ? "https://dev.mdvaccess.com/"+idOfOrder.property_logo_url : NoImage);
  const [selectedImageDealer, setSelectedImageDealer] = useState(idOfOrder.dealer_logo_url != null ? "https://dev.mdvaccess.com/"+idOfOrder.dealer_logo_url : NoImage);
  /* useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImageDealer) {
      setImageUrlDealer(URL.createObjectURL(selectedImageDealer));
      console.log("sle"+selectedImageDealer.name);
    }
  }, [selectedImageDealer]); */
  const updateMyPostData = async () => {
    setLoading(true);
    try {
   
      const res = await axios.post("/dealer/addneworder/"+token.id,apiValue, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      console.log(apiValue);
      setOpenSnackData("Order Succesfully Placed");
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
      handleOrderCancel();
    } catch (error) {
      console.log(error.response);
      console.log(apiValue);
      setLoading(false);
      setOpenSnackData("New Order Create Failed, Please Try Again Later");
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  const handleInputChange=(event) =>{
    console.log(apiValue);
    setshowUpdateButton(true);
    let ds = event.target.name;
    apiValue[ds] = event.target.value;
    setAPIValueData();
  }
  const handlePanelQuantatiyChange=(event) =>{
    console.log(apiValue);
    setshowUpdateButton(true);
    let ds = event.target.name;
    if(event.target.value < 1){
      event.target.value = 1;
      
    }
    let items = apiValue.items;
    let itemschange;
    let indexoffind;
    //console.log("datea is "+ JSON.stringify(apiValue));
    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == "1" && items[i].item_name == ds){
        itemschange = items[i];
        indexoffind = i;
        break;
      }
    }
    itemschange.total_quantity = event.target.value;

    apiValue.items[indexoffind] = itemschange;
  /*  apiValue[ds] = event.target.value;
    apiValue.totalpanel = parseInt(apiValue.panel_two_quantity) + parseInt(apiValue.panel_three_quantity) + parseInt(apiValue.panel_one_quantity);
    apiValue.panelshippingtotal = ( parseFloat(apiValue.panel_two_quantity) + parseFloat(apiValue.panel_three_quantity) + parseFloat(apiValue.panel_one_quantity)) * 50;
    apiValue.totalpanelcharge = ((parseFloat(apiValue.panel_one_quantity) * parseFloat(apiValue.vue_surface_cost)) + (parseFloat(apiValue.panel_two_quantity) * parseFloat(apiValue.vue_flush_cost)) + (parseFloat(apiValue.panel_three_quantity)* parseFloat(apiValue.vue_hood_cost)) + parseFloat(apiValue.panelshippingtotal));
    apiValue.all_order_subtotal = parseFloat(apiValue.totalpanelcharge) + parseFloat(apiValue.all_pedestal_add_on_subtotal)+ parseFloat(apiValue.all_panel_saas_subtotal) + parseFloat(apiValue.all_qr_subtotal) + parseFloat(apiValue.all_lpr_subtotal);
    apiValue.all_order_subtotal_plus_payment_charge = parseFloat(apiValue.all_order_subtotal) + ((parseFloat(apiValue.all_order_subtotal)*4)/100)
    */
    setAPIValueData();
  }


  
  const [formDisable,setFormDisable] = useState(true);
  const handleChangeNewPropertySelect = (event) => {
    apiValue.isNewProperty = event.target.checked == true ? "1" : "0";
    apiValue.user_email = "";
    apiValue.user_address = "";
    apiValue.user_city = "";
    apiValue.user_state = "";
    apiValue.user_zipcode= "";
    apiValue.user_property_name= "";
    apiValue.user_first_name= "";
    apiValue.proeprty_type = "0";
    apiValue.user_unit_quantity= "";
    apiValue.user_phone_no= "";
    if(event.target.checked == false){
      setFormDisable(true);
    }else{
      setFormDisable(false);
    }
    setAPIValueData();
  }

  

  const [currentTab, setCurrentTab] = useState(0);
 
    
  const handleTabClick =(val) =>{
    setCurrentTab(val);
  }
  const handleAddOrder = () => {
    setLoading(true);
    if(apiValue.company_name == ""){
    
      setOpenSnackData("Dealer Company Name Can't Be Empty, Please Enter Dealer Company Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.street_add == ""){
    
      setOpenSnackData("Dealer Company Address Can't Be Empty, Please Enter Dealer Company Address");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.city == ""){
    
      setOpenSnackData("Dealer Company City Can't Be Empty, Please Enter Dealer Company City");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.state == ""){
    
      setOpenSnackData("Dealer Company State Can't Be Empty, Please Enter Dealer Company State");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.zip_code == ""){
    
      setOpenSnackData("Dealer Company Zip Code Can't Be Empty, Please Enter Dealer Company Zip Code");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.apfname == ""){
    
      setOpenSnackData("AP First Name Can't Be Empty, Please Enter AP First Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.aplname == ""){
    
      setOpenSnackData("AP Last Name Can't Be Empty, Please Enter AP Last Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.apphone == ""){
    
      setOpenSnackData("AP Phone Can't Be Empty, Please Enter AP Phone");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.apemail == ""){
    
      setOpenSnackData("AP Email Can't Be Empty, Please Enter AP Email");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.purchasefname == ""){
    
      setOpenSnackData("Purchasing Contact First Name Can't Be Empty, Please Enter Purchasing Contact First Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.purchaselname == ""){
    
      setOpenSnackData("Purchasing Contact Last Name Can't Be Empty, Please Enter Purchasing Contact Last Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.purchasephone == ""){
    
      setOpenSnackData("Purchasing Contact Phone Number Can't Be Empty, Please Enter Purchasing Contact Phone Number");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.purchaseemail == ""){
    
      setOpenSnackData("Purchasing Contact Email Can't Be Empty, Please Enter Purchasing Contact Email");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    if(apiValue.user_property_name == ""){
    
      setOpenSnackData("End User Business Name Can't Be Empty, Please Enter End User Business Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    if(apiValue.user_first_name == ""){
    
      setOpenSnackData("End User First Name Can't Be Empty, Please Enter End User First Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_last_name == ""){
    
      setOpenSnackData("End User Last Name Can't Be Empty, Please Enter End User Last Name");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_phone_no == ""){
    
      setOpenSnackData("End User Phone Number Can't Be Empty, Please Enter End User Phone Number");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_email == ""){
    
      setOpenSnackData("End User Email Can't Be Empty, Please Enter End User Email");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_address == ""){
    
      setOpenSnackData("End User Property Address Can't Be Empty, Please Enter End User Property Address");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_city == ""){
    
      setOpenSnackData("End User City Can't Be Empty, Please Enter End User City");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_state == ""){
    
      setOpenSnackData("End User State Can't Be Empty, Please Enter End User State");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.user_zipcode == ""){
    
      setOpenSnackData("End User Zip Code Can't Be Empty, Please Enter End User Zip Code");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.orderdate == ""){
    
      setOpenSnackData("Order Date Can't Be Empty, Please Enter Order Date");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.orderaddress == ""){
    
      setOpenSnackData("Order Address Can't Be Empty, Please Enter Order Address");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.ordercity == ""){
    
      setOpenSnackData("Order City Can't Be Empty, Please Enter Order City");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.ordernumber == ""){
    
      setOpenSnackData("Purchase Order Number Can't Be Empty, Please Enter Purchase Order Number");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.orderstate == ""){
    
      setOpenSnackData("Order State Can't Be Empty, Please Enter Order State");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(apiValue.orderzipcode == ""){
    
      setOpenSnackData("Order Zip Code Can't Be Empty, Please Enter Order Zip Code");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
   // let toatlval = parseFloat(apiValue.totalpanelcharge) + parseFloat(apiValue.all_pedestal_add_on_subtotal) + parseFloat(apiValue.all_panel_saas_subtotal) + parseFloat(apiValue.all_qr_subtotal) + parseFloat(apiValue.all_lpr_subtotal);
    if(apiValue.all_order_subtotal <= 0) {
      setOpenSnackData("There Is No Items Selected For Order, Please Select Any Item");
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    setLoading(false);
    setOpenPopup(true);
  }
  const handleCancelPayment = () => {
    setOpenPopup(false);
    setLoading(false);
  }
  const handleSubmitOrder = () => {
    setOpenPopup(false);
    setLoading(true);
    updateMyPostData();
  }
  const handleOrderCancel= () => {
   
 }
 const getVUEPanelname = () => {
  let items = apiValue.items;
  console.log("item value is "+items);
  let panelminvalue = [];
  let minvalue = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "1"){
      panelminvalue.push(items[i].item_price);
    }
  }
  if(panelminvalue.length > 0){
  let numAscending = [...panelminvalue].sort((a, b) => a - b);
  minvalue = numAscending[0];
  }
  if(minvalue > 0){
    return 'VUE-7 Panel (Starting From $'+minvalue+")" ;
  }
  else{
    return 'VUE-7 Panel';
  }

}

const getVueName = () => {
  let items = apiValue.items;
  let panelminvalue = [];
  let minvalue = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "6"){
      panelminvalue.push(items[i].item_price);
    }
  }
  if(panelminvalue.length > 0){
  let numAscending = [...panelminvalue].sort((a, b) => a - b);
  minvalue = numAscending[0];
  }
  if(minvalue > 0){
    return 'VUE-QR (Starting From $'+minvalue+")" ;
  }
  else{
    return 'VUE-QR $0.00 (Free Setup)';
  }

}
const getVueDescription = () => {
  let items = apiValue.items;
 
  let desc = '';
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "6"){
      desc = items[i].item_description;
      break;
    }
  }
  
    return desc;
  
}
const handleChangeAddedSubscriptionQuantity = (event) => {
  setshowUpdateButton(true);
  let ds = event.target.name;
  
  let items = apiValue.items;
  let itemschange;
  let indexoffind;
  //console.log("datea is "+ JSON.stringify(apiValue));
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == ds){
      itemschange = items[i];
      indexoffind = i;
      break;
    }
  }
  itemschange.optional_data = event.target.value;

  apiValue.items[indexoffind] = itemschange;
  setAPIValueData();

}
const handleChangeSubscriptionQuantity = (event) => {
  console.log(event.target.value);
  setshowUpdateButton(true);
  let ds = event.target.name;
  
  let items = apiValue.items;
  let itemschange;
  let indexoffind;
  //console.log("datea is "+ JSON.stringify(apiValue));
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == ds){
      itemschange = items[i];
      indexoffind = i;
      break;
    }
  }
  if(event.target.value < itemschange.min_quantity){
    event.target.value = itemschange.min_quantity;
    
  }
  if(itemschange.max_quantity > 0){
  if(event.target.value > itemschange.max_quantity){
    event.target.value = itemschange.max_quantity;
    
  }
  }
  itemschange.total_quantity = event.target.value;

  apiValue.items[indexoffind] = itemschange;
  setAPIValueData();
}
const handlePanelAddOnQuantatiyChange=(event) =>{
  console.log(apiValue);
  setshowUpdateButton(true);
  let ds = event.target.name;
  
  let items = apiValue.items;
  let itemschange;
  let indexoffind;
  //console.log("datea is "+ JSON.stringify(apiValue));
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == ds){
      itemschange = items[i];
      indexoffind = i;
      break;
    }
  }
  if(event.target.value < itemschange.min_quantity){
    event.target.value = itemschange.min_quantity;
    
  }
  if(itemschange.max_quantity > 0){
    if(event.target.value > itemschange.max_quantity){
      event.target.value = itemschange.max_quantity;
      
    }
  }
  itemschange.total_quantity = event.target.value;

  for(let i = 0; i < items.length ; i ++){
    if(items[i].sub_of == ds){
      let itemsds = items[i];
      itemsds.total_quantity = itemsds.min_quantity;
      apiValue.items[i] = itemsds;
    }
  }

  apiValue.items[indexoffind] = itemschange;
/*  apiValue[ds] = event.target.value;
  apiValue.totalpanel = parseInt(apiValue.panel_two_quantity) + parseInt(apiValue.panel_three_quantity) + parseInt(apiValue.panel_one_quantity);
  apiValue.panelshippingtotal = ( parseFloat(apiValue.panel_two_quantity) + parseFloat(apiValue.panel_three_quantity) + parseFloat(apiValue.panel_one_quantity)) * 50;
  apiValue.totalpanelcharge = ((parseFloat(apiValue.panel_one_quantity) * parseFloat(apiValue.vue_surface_cost)) + (parseFloat(apiValue.panel_two_quantity) * parseFloat(apiValue.vue_flush_cost)) + (parseFloat(apiValue.panel_three_quantity)* parseFloat(apiValue.vue_hood_cost)) + parseFloat(apiValue.panelshippingtotal));
  apiValue.all_order_subtotal = parseFloat(apiValue.totalpanelcharge) + parseFloat(apiValue.all_pedestal_add_on_subtotal)+ parseFloat(apiValue.all_panel_saas_subtotal) + parseFloat(apiValue.all_qr_subtotal) + parseFloat(apiValue.all_lpr_subtotal);
  apiValue.all_order_subtotal_plus_payment_charge = parseFloat(apiValue.all_order_subtotal) + ((parseFloat(apiValue.all_order_subtotal)*4)/100)
  */
  setAPIValueData();
}


const getVuePanelCount = () =>{
  let items = apiValue.items;
  let totalPanel = 0;
  if(items != null){
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "1"){
      totalPanel++;
    }
  }
  }
  console.log("toita panel "+ items[0]);
  console.log("toita panel "+ totalPanel);
  return totalPanel;
}



const getVuePanelShippingCost = () =>{
  let items = apiValue.items;
  let totalCost = 0;
  let mainquantity = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "1"){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      mainquantity = mainquantity + quantity;
      totalCost = totalCost + (parseFloat(items[i].sandh) * parseFloat(quantity));
    }
  }
  if(mainquantity > 1)
  totalCost = totalCost - ((mainquantity- 1)*40);
  return totalCost;
}
const getAnyShippingCost = (val) => {
    
  let items = apiValue.items;
  let totalCost = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      totalCost = totalCost + (parseFloat(items[i].sandh) * parseFloat(quantity));
    }
  }
  return totalCost;
}

const getAnyProductCost = (val) => {
  let items = apiValue.items;
  let totalCost = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      totalCost = totalCost + (parseFloat(items[i].item_price) * parseFloat(quantity));
    }
  }
  return totalCost;
}

const getVuePanelCost = () =>{
  let items = apiValue.items;
  let totalCost = 0;
  let mainquantity = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "1"){
      
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      mainquantity = mainquantity + quantity;
      //let itemtotalCost = parseFloat(quantity) * parseFloat(items[i].item_price);
      totalCost = totalCost + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) *parseFloat(items[i].sandh));
    }
  }
  if(mainquantity > 1)
  totalCost = totalCost - ((mainquantity- 1)*40);
  return totalCost;
}

const getProductCost = (val) =>{
  let items = apiValue.items;
  let totalCost = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      //let itemtotalCost = parseFloat(quantity) * parseFloat(items[i].item_price);
      totalCost = totalCost + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) *parseFloat(items[i].sandh));
    }
  }
  return totalCost;
}

const getProductAddOnCost = (val,val2) =>{
  let items = apiValue.items;
  let totalCost = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      let itemtotalCost = parseFloat(quantity) * parseFloat(items[i].item_price);
      totalCost = totalCost + parseFloat(itemtotalCost) + parseFloat(items[i].sandh);
    }
    if(items[i].type == val2){
      let qun = 0;
      for(let j = 0; j < items.length ; j ++){
        if(items[j].id == items[i].sub_of){
          qun = items[j].total_quantity;
        }
      }
      if(qun > 0){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      let itemtotalCost = parseFloat(quantity) * parseFloat(items[i].item_price);
      totalCost = totalCost + parseFloat(itemtotalCost) + parseFloat(items[i].sandh);
      }
    }
  }
  return totalCost;
}

const getVuePanelAddOnsCost = () =>{
  let items = apiValue.items;
  let totalCost = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "2"){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      let itemtotalCost = parseFloat(quantity) * parseFloat(items[i].item_price);
      totalCost = totalCost + parseFloat(itemtotalCost) + parseFloat(items[i].sandh);
    }
    if(items[i].type == "3"){
      let qun = 0;
      for(let j = 0; j < items.length ; j ++){
        if(items[j].id == items[i].sub_of){
          qun = items[j].total_quantity;
        }
      }
      if(qun > 0){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      let itemtotalCost = parseFloat(quantity) * parseFloat(items[i].item_price);
      totalCost = totalCost + parseFloat(itemtotalCost) + parseFloat(items[i].sandh);
      }
    }
  }
  return totalCost;
}
const getVuePanelTotalCount = () =>{
  let items = apiValue.items;
  let totalPanel = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "1"){
      totalPanel = totalPanel + parseFloat(items[i].total_quantity);
    }
  }
  return totalPanel;
}

const getProductWithTotalCount = (val,val2) =>{
  let items = apiValue.items;
  let totalPanel = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      totalPanel = totalPanel + parseFloat(items[i].total_quantity);
    }
  }
  if(totalPanel > 0){
    totalPanel = 0;
    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == val2){
        //totalPanel = totalPanel + parseFloat(items[i].total_quantity);
        let qun = items[i].total_quantity;
        if(qun > 0){
          for(let j = 0; j < items.length ; j ++){
            if(items[j].type == val){
              let ad = parseFloat(items[i].total_quantity);
              if(ad > 0){
                totalPanel = totalPanel + parseFloat(items[i].total_quantity);
              }
            }
          }
        }
      }
    }

  }
  return totalPanel;
}
const getVuePanelList = () => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "1"){
      panellist.push(items[i]);
    }
  }
  return panellist;
}
const handleProductOptionChange =(event) =>{
  setshowUpdateButton(true);
  let ds = event.target.name;
  
  let items = apiValue.items;
  let itemschange;
  let indexoffind;
  //console.log("datea is "+ JSON.stringify(apiValue));
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == ds){
      itemschange = items[i];
      indexoffind = i;
      break;
    }
  }

  itemschange.optional_data  = event.target.value;
  apiValue.items[indexoffind] = itemschange;

  setAPIValueData();
}
const handleProductAddOnQuantatiyChange=(event) =>{
  console.log(apiValue);
  setshowUpdateButton(true);
  let ds = event.target.name;
  
  let items = apiValue.items;
  let itemschange;
  let indexoffind;
  //console.log("datea is "+ JSON.stringify(apiValue));
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == ds){
      itemschange = items[i];
      indexoffind = i;
      break;
    }
  }
  if(event.target.value < itemschange.min_quantity){
    event.target.value = itemschange.min_quantity;
    
  }
  if(itemschange.max_quantity > 0){
    if(event.target.value > itemschange.max_quantity){
      event.target.value = itemschange.max_quantity;
      
    }
  }
  itemschange.total_quantity = event.target.value;

  for(let i = 0; i < items.length ; i ++){
    if(items[i].sub_of == ds){
      let itemsds = items[i];
      itemsds.total_quantity = itemsds.min_quantity;
      apiValue.items[i] = itemsds;
    }
  }

  apiValue.items[indexoffind] = itemschange;

  setAPIValueData();
}
const getProductTotalCount = (val) =>{
  let items = apiValue.items;
  let totalPanel = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      totalPanel = totalPanel + parseFloat(items[i].total_quantity);
    }
  }
  return totalPanel;
}
const getProductList = (val) => {
  let items = apiValue.items;
  let panellist = [];
  if(items != null){
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      panellist.push(items[i]);
    }
  }
  }
  return panellist;
}

const getVuePanelAddOnList = () => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "2"){
      panellist.push(items[i]);
    }
  }
  return panellist;
}

const getVuePanelSubOfAddOnList = () => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "3"){
      panellist.push(items[i]);
    }
  }
  return panellist;
}

const getProductSubOfAddOnList = (val) => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      panellist.push(items[i]);
    }
  }
  return panellist;
}

const getVuePanelSubscriptionList = () => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "4"){
      panellist.push(items[i]);
    }
  }
  return panellist;
}

const getProductSubscriptionList = (val) => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      panellist.push(items[i]);
    }
  }
  return panellist;
}
const getVuePanelSubscriptionAddOnList = () => {
  let items = apiValue.items;
  let panellist = [];
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "5"){
      panellist.push(items[i]);
    }
  }
  return panellist;
}

const checkifPanelAddOnActiveOrNot = (val) => {
  let items = apiValue.items;
  let totalquantity = 0;

  for(let i = 0; i < items.length ; i ++){
    if(val != "-1"){
    if(items[i].id == val){
      totalquantity = items[i].total_quantity;
      break;
    }
    }else{
      totalquantity = totalquantity + parseFloat(items[i].total_quantity);
    }
  }
  if(totalquantity > 0){
    return true;
  }else{
    return false;
  }
}

const checkifProductIsActiveOrNot = (val,val2) => {
  let items = apiValue.items;
  let totalquantity = 0;

  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == val){
      totalquantity = items[i].total_quantity;
      break;
    }
  }
  if(totalquantity > 0){
    if(getProductList(val2).length > 0)
    return true;
  else 
  return false;
  }else{
    return false;
  }
}
const getChecklistOfPanel = (val) => {
  let items = apiValue.items;
  let totalquantity = 0;
  for(let i = 0; i < items.length ; i ++){
    if( items[i].id == val){
      totalquantity = items[i].total_quantity;
    }
  }
  return totalquantity;
}


const getTotalAddOnQuantity = (val) => {
  let items = apiValue.items;
  let totalquantity = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == val){
      totalquantity = items[i].total_quantity;
    }
  }
  return totalquantity;
}
const checkifItHasAddOnorNot = (val) => {
  let items = apiValue.items;
  let totalquantity = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].sub_of == val){
      totalquantity++;
    }
  }
  if(totalquantity > 0){
    for(let i = 0; i < items.length ; i ++){
      if(items[i].id == val){
        totalquantity = items[i].total_quantity;
      }
    }
    if(totalquantity > 0){
      return true;
    }else{
      return false;
    }
  } 
   else 
   return false;
}

const checkifInnerIsOn = (val) => {
  let items = apiValue.items;
  let totalquantity = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].sub_of == val){
      totalquantity =  totalquantity + parseFloat(items[i].total_quantity);
    }
  }
   if(totalquantity > 0){
    return true;
   } 
   else 
   return false;
}
const getSubTotalOf = (val) => {
  let items = apiValue.items;
  let total = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
  }
  return total;
}

const getSubTotalOfWithInner = (val) => {
  let items = apiValue.items;
  let total = 0;
  let qun = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == val){
      qun = items[i].total_quantity;
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
   
  }
  if(qun > 0){ 
  for(let i = 0; i < items.length ; i ++){
    if(items[i].sub_of == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
  }
  }
  return total;
}

 
const getSubTotalOfPanelSubscription = () => {
  let items = apiValue.items;
  let total = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "4"){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
    if(items[i].type == "5"){
      let qun = 0;
      for(let j = 0; j < items.length ; j ++){
        if(items[j].id == items[i].sub_of){
          qun = items[j].total_quantity;
        }
      }
      if(qun > 0){
        let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
      }
    }
    
  }
  
  return total;
}

const getSubTotalOfLPRSubscription = () => {
  let items = apiValue.items;
  let total = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "15"){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
    if(items[i].type == "16"){
      let qun = 0;
      for(let j = 0; j < items.length ; j ++){
        if(items[j].id == items[i].sub_of){
          qun = items[j].total_quantity;
        }
      }
      if(qun > 0){
        let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
      }
    }
    
  }
  let qity = getProductTotalCount("12") ;
    return total*qity;
}

const getSubTotalOfVueSubscription = () => {
  let items = apiValue.items;
  let total = 0;
  let qun = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].type == "6"){
      qun = items[i].total_quantity;
      break;
    }
  }
  if(qun > 0){
    for(let i = 0; i < items.length ; i ++){
      if(items[i].type == "6" || items[i].type == "7" || items[i].type == "10"){
        let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
        total = total + (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
      }
  }
  }
  return total;
}
const getSubAddOnSubTotal = (val) => {
  let items = apiValue.items;
  let total = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].sub_of == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total =  total+ (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
  }
  return total;
}

const getTransactionId = () => {
  try{
    let ad = JSON.parse(apiValue.payment_transaction);
    if(ad.id != null){
      return ad.id;
    }
    else{
      return "N/A";
    }
    }
    catch {
      return "N/A";
    }
}

const getProductSubTotal = (val) => {
  let items = apiValue.items;
  let total = 0;
  for(let i = 0; i < items.length ; i ++){
    if(items[i].id == val){
      let quantity = parseInt((items[i].total_quantity) /items[i].item_price_per);
      total =  total+ (parseFloat(quantity) * parseFloat(items[i].item_price)) + (parseFloat(quantity) * parseFloat(items[i].sandh));
    }
  }
  return total;
}
  const ShowLog = () =>{
    
    return(<><Box sx={{
      display:  "flex" ,
      }}>
        
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Panels </Typography>
     </Box>
     
      { (apiValue.panel_one_quantity > 0 || apiValue.panel_two_quantity > 0 || apiValue.panel_three_quantity > 0) &&  <Box sx={{
      display:  "flex" ,
      width : "500px",
      }}>
      <Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {apiValue.panel_one_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> VUE-7 Surface Panel </Typography>}
      {apiValue.panel_two_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> VUE-7 Flush Panel </Typography>}
      {apiValue.panel_three_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> VUE-7 Hood Panel </Typography>}
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {apiValue.panel_one_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.panel_one_quantity} </Typography>}
      {apiValue.panel_two_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.panel_two_quantity} </Typography>}
      {apiValue.panel_three_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.panel_three_quantity} </Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {apiValue.panel_one_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$50.00 </Typography>}
      {apiValue.panel_two_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$50.00 </Typography>}
      {apiValue.panel_three_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $50.00</Typography>}
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {apiValue.panel_one_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {(parseFloat(apiValue.panel_one_quantity)*parseFloat(apiValue.vue_surface_cost)) + (parseFloat(apiValue.panel_one_quantity)*50.00) } </Typography>}
      {apiValue.panel_two_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {(parseFloat(apiValue.panel_two_quantity)*parseFloat(apiValue.vue_flush_cost)) + (parseFloat(apiValue.panel_two_quantity)*50.00) }</Typography>}
      {apiValue.panel_three_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $ {(parseFloat(apiValue.panel_three_quantity)*parseFloat(apiValue.vue_hood_cost)) + (parseFloat(apiValue.panel_three_quantity)*50.00) }</Typography>}
      </Stack>
      </Grid>



      </Grid> </Box>}
      <Box sx={{
      display:  "flex" ,
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL PANELS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.totalpanelcharge} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box>

      { (apiValue.all_pedestal_add_on_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Panel Add-Ons </Typography>
      </Box>)}
      
      { (apiValue.all_pedestal_add_on_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {apiValue.extrenal_card_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>26-Bit Card Reader</Typography>}
      {apiValue.key_fobs_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>100 Charcoal Key Fobs</Typography>}
      {apiValue.surface_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> 42" Gooseneck Surface Pedestal </Typography>}
      {apiValue.flush_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> 54" Flush Pedestal </Typography>}
      {apiValue.rain_hood_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Rain Hood for 54" Pedestal </Typography>}
      {apiValue.cooling_fan_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Cooling Fan for 54" Pedestal</Typography>}
      {apiValue.heater_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Heater for 54" Pedestal </Typography>}
      {apiValue.elevator_cr_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Elevate-CR </Typography>}
      {apiValue.elevator_r_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Elevate-R+ </Typography>}
      

      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {apiValue.extrenal_card_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.extrenal_card_quantity}</Typography>}
      {apiValue.key_fobs_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.key_fobs_quantity}</Typography>}
      {apiValue.surface_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.surface_pedestal_quantity} </Typography>}
      {apiValue.flush_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.flush_pedestal_quantity} </Typography>}
      {apiValue.rain_hood_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.rain_hood_flush_mount_quantity}</Typography>}
      {apiValue.cooling_fan_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.cooling_fan_flush_mount_quantity}</Typography>}
      {apiValue.heater_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.heater_flush_mount_quantity} </Typography>}
      {apiValue.elevator_cr_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.elevator_cr_quantity}</Typography>}
      {apiValue.elevator_r_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.elevator_r_quantity}</Typography>}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {apiValue.extrenal_card_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$2.00</Typography>}
      {apiValue.key_fobs_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$2.00</Typography>}
      {apiValue.surface_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$25.00</Typography>}
      {apiValue.flush_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$25.00</Typography>}
      {apiValue.rain_hood_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$5.00</Typography>}
      {apiValue.cooling_fan_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$5.00</Typography>}
      {apiValue.heater_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$5.00</Typography>}
      {apiValue.elevator_cr_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$5.00</Typography>}
      {apiValue.elevator_r_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$5.00</Typography>}
      
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {apiValue.extrenal_card_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.extrenal_card_subtotal)}</Typography>}
      {apiValue.key_fobs_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.key_fob_subtotal)}</Typography>}
      {apiValue.surface_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.surface_pedestal_subtotal)}</Typography>}
      {apiValue.flush_pedestal_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.flush_pedestal_subtotal)}</Typography>}
      {apiValue.rain_hood_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.rain_hood_flush_mount_quantity)*144}</Typography>}
      {apiValue.cooling_fan_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.cooling_fan_flush_mount_quantity)*186}</Typography>}
      {apiValue.heater_flush_mount_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.heater_flush_mount_quantity)*343}</Typography>}
      {apiValue.elevator_cr_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.elevator_cr_quantity)*650}</Typography>}
      {apiValue.elevator_r_quantity > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.elevator_r_quantity)*300}</Typography>}
      
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (apiValue.all_pedestal_add_on_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> PANEL ADD-ONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_pedestal_add_on_subtotal} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}



      { (apiValue.all_panel_saas_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Panel SaaS Subscriptions </Typography>
      </Box>)}
      
      { (apiValue.all_panel_saas_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {apiValue.saas_multi_family_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>SaaS Multi-Family </Typography>}
      {apiValue.saas_commercial_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> SaaS Commercial</Typography>}
      {apiValue.saas_single_family_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> SaaS Single Family </Typography>}
      {apiValue.saas_panel_host_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> SaaS Panel Host + </Typography>}
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {apiValue.saas_multi_family_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.total_household} </Typography>}
      {apiValue.saas_commercial_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> 1 </Typography>}
      {apiValue.saas_single_family_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>1 </Typography>}
      {apiValue.saas_panel_host_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>1</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {apiValue.saas_multi_family_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$0.00 </Typography>}
      {apiValue.saas_commercial_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$0.00 </Typography>}
      {apiValue.saas_single_family_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $0.00</Typography>}
      {apiValue.saas_panel_host_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $0.00</Typography>}
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {apiValue.saas_multi_family_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.saas_multi_family_subtotal) } </Typography>}
      {apiValue.saas_commercial_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.saas_commercial_subtotal) } </Typography>}
      {apiValue.saas_single_family_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.saas_single_family_subtotal) } </Typography>}
      {apiValue.saas_panel_host_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.saas_panel_host_subtotal) } </Typography>}
      
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (apiValue.all_panel_saas_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> SaaS SUBSCRIPTIONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_panel_saas_subtotal} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}



      { (apiValue.all_qr_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Virtual QR Tele-Entry </Typography>
      </Box>)}
      
      { (apiValue.all_qr_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {apiValue.qr_saas_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>VUE-QR + QR SaaS</Typography>}
      {apiValue.qr_add_placecard > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>QR Placards</Typography>}
      {apiValue.brivo_integration == '0' && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>VUE-QR Cellular Network Relay </Typography>}
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {apiValue.qr_saas_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.vueqr_total_household} </Typography>}
      {apiValue.qr_add_placecard > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.qr_add_placecard} </Typography>}
      {apiValue.brivo_integration == '0' &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>1 </Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {apiValue.qr_saas_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$0.00 </Typography>}
      {apiValue.qr_add_placecard > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$15.00 </Typography>}
      {apiValue.brivo_integration == '0' && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $5.00</Typography>}
   
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {apiValue.qr_saas_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.qr_saas_subtotal) } </Typography>}
      {apiValue.qr_add_placecard > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.qr_placecard_total) } </Typography>}
      {apiValue.brivo_integration == '0' && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ 699 </Typography>}
       
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (apiValue.all_qr_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> QR + QR SUBSCRIPTIONS SUBTOTAL w Cell Relay </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_qr_subtotal} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}


      { (apiValue.all_lpr_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Additional Items </Typography>
      </Box>)}
      
      { (apiValue.all_lpr_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {apiValue.lpr_camera_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>LPR Camera</Typography>}
      {apiValue.eagleeye_bridge_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>EagleEye Bridge</Typography>}
      {apiValue.poe_switch_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>Managed POE Switch</Typography>}
      {apiValue.ethernet_remote_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>LPR-R Ethernet Remote</Typography>}
      {apiValue.lpr_saas_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>LPR SaaS Subscriptions</Typography>}
     
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {apiValue.lpr_camera_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.lpr_camera_quantity} </Typography>}
      {apiValue.eagleeye_bridge_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.eagleeye_bridge_quantity} </Typography>}
      {apiValue.poe_switch_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.poe_switch_quantity} </Typography>}
      {apiValue.ethernet_remote_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.ethernet_remote_quantity}</Typography>}
      {apiValue.lpr_saas_subtotal > 0 &&<Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{apiValue.lpr_total_saas_subscription}</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {apiValue.lpr_camera_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$10.00 </Typography>}
      {apiValue.eagleeye_bridge_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$25.00 </Typography>}
      {apiValue.poe_switch_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $5.00</Typography>}
      {apiValue.ethernet_remote_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $5.00</Typography>}
      {apiValue.lpr_saas_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> $0.00</Typography>}
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {apiValue.lpr_camera_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.lpr_camera_subtotal) } </Typography>}
      {apiValue.eagleeye_bridge_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.eagleeye_bridge_subtotal) } </Typography>}
      {apiValue.poe_switch_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.poe_switch_subtotal) } </Typography>}
      {apiValue.ethernet_remote_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.ethernet_remote_subtotal) } </Typography>}
      {apiValue.lpr_saas_subtotal > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {parseFloat(apiValue.lpr_saas_subtotal) } </Typography>}
      
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (apiValue.all_lpr_subtotal > 0 && <Box sx={{
      display:  "flex" ,
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ADDITIONAL ITEMS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_lpr_subtotal} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}

      <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
     
       
     <Stack direction="row"  spacing={20}>
     <Stack direction="column"  >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> ORDER TOTAL </Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_order_subtotal}</Typography>
       </Stack>

       <Stack direction="column"  >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} > COUPON </Typography> 
         <Typography sx={mainThemeStyle.popUpLable} height={40}> {apiValue.promocode}</Typography>
         </Stack>

       <Stack direction="column"  >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} > COUPON DISCOUNT </Typography> 
         <Typography sx={mainThemeStyle.popUpLable} height={40}>- $ {apiValue.promocode_discount}</Typography>
         </Stack>
         </Stack>

       
         
      
      </Box>
      <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
         <Stack direction="row"  spacing={20}>
       <Stack direction="column"  >
         <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} >** ORDER SUB TOTAL **</Typography> 
         {/* <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> +4% for Payment Processing</Typography> */}
         <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_order_subtotal_after_discount_plus_payment_charge}</Typography>
         </Stack>
         
         <Stack direction="column"  >
         <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} > PAYMENT TRANSACTION ID</Typography> 
          <Typography sx={mainThemeStyle.popUpLable} height={40}>  {getTransactionId()}</Typography>
         </Stack>
         </Stack>

      </Box>

      <Box sx={{
      display:  "flex" ,
      
      }}>
         
       
       <Stack direction="row" spacing={2} >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
       
       <LoadingButton
         
         sx={mainThemeStyle.normalButtonNoRelative}
         loading={loading}
         onClick={handleSubmitOrder}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Submit New Order
       </LoadingButton>
       <LoadingButton
         
         sx={mainThemeStyle.normalButtonNoRelative}
         loading={loading}
         onClick={handleCancelPayment}
         variant="outlined"
       >
         Cancel
       </LoadingButton>
       </Stack>
       
      </Box>
      </>);

  }
  return <> 
  

 {currentDisableValue == false &&  <Box sx={mainThemeStyle.boxTopHeader}>
 {(currentTab == 0  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}
  {(currentTab == 1  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}
  {(currentTab == 2  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}
  {(currentTab == 3  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}
            
  {(currentTab == 4  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}

  {(currentTab == 5  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}

  {(currentTab == 6  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}

  {(currentTab == 7  && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>User Info</Button>
    
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Panels</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>LPR</Button>
    {getProductList("17").length > 0 && <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Additional Items</Button>}
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(7)}}>Order Summary</Button>
  </Stack>)}


     </Box>}

     {currentDisableValue == false &&   <Box sx={{position: 'absolute',
           width:'auto',
          
           top:'100px',
           left: '5px',
           display: { xs: "flex", md: "none" },
           right: '5px',
           flexGrow: 1,
          
           }}>
           {(currentTab == 0  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
    
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   </Grid>)}
   {(currentTab == 1  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
     <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
   
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}
   {(currentTab == 2  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
   
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}
   {(currentTab == 3  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
     <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
   
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}
   {(currentTab == 4  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
      <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
  
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}
   {(currentTab == 5  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
     <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
   
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}

   {(currentTab == 6  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
     <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
   
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}

   {(currentTab == 7  && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>User Info</Button></Grid>
     <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Panels</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>VUE-QR</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>LPR</Button></Grid>
    {getProductList("17").length > 0 && <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Additional Items</Button></Grid>}
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Shipping Info</Button></Grid>
   
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(7)}}>Order Summary</Button></Grid>
   
   </Grid>)}
           </Box> }
     {currentDisableValue == false && <Box sx={mainThemeStyle.boxHeaderBelow}>
     {(currentTab == 0 && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                      Dealer Information
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'>

           <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Company</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State</Typography>
      </Stack>
      </Grid>

      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.company_name}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.street_add}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.city}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.state}</Typography>    
        </Stack>
      </Grid>

      <Grid item xs={2}>
     
     </Grid>

     <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Telephone </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      </Stack>
      </Grid>

      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.zip_code}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.telephone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.email} </Typography>
      </Stack>
      </Grid>

      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '32px'>

           <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Company</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Address </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Telephone </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      </Stack>
      </Grid>

      <Grid item xs={7}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.company_name}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.street_add}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.city}</Typography>     
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.state}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.zip_code}</Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.telephone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.email} </Typography>
      
      </Stack>
      </Grid>

     

   

     

      </Grid>
      </Box>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   AP Contact
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name</Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.apfname} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.aplname} </Typography> 
      </Stack>
    
      
      </Grid>
      <Grid item xs={2}>
     
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Phone </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Email </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.apphone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.apemail} </Typography>
      </Stack>
    
      
      </Grid>
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Phone</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> AP Email </Typography>
      
     
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.apfname} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.aplname} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.apphone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.apemail} </Typography>
      </Stack>
    
      
      </Grid>
     
      </Grid>
      
      </Box>
      <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Purchasing Contact
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      
      
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchasefname} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchaselname} </Typography>
                  
      </Stack>
    
      
      </Grid>
      <Grid item xs={2}>
     
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Phone</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Email </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchasephone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchaseemail} </Typography>
        
      </Stack>
    
      
      </Grid>
      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name</Typography>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Phone</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchasing Email </Typography>
      
     
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchasefname} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchaselname} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchasephone} </Typography>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>  {apiValue.purchaseemail} </Typography>
      
      </Stack>
    
      
      </Grid>
     
      </Grid>
      
      </Box> </>)}

      {(currentTab == 1 && <>
        <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       End-User Information
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'>

           <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Type</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property / Business Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Contact Person Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Quantity</Typography> 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> User Contact Phone #</Typography>
      
      </Stack>
      </Grid>

      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {GetPropertyType()}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_property_name}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_first_name}</Typography>
                   
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_unit_quantity}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_phone_no}</Typography>
       
       </Stack>
      </Grid>

      <Grid item xs={2}>
     
     </Grid>

     <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
           <Typography sx={mainThemeStyle.popUpLable} height={40}> User Contact Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Address</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip Code </Typography>
      </Stack>
      </Grid>

      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_email}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_address}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_city}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_state}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_zipcode}</Typography>
       
      </Stack>
      </Grid>

      </Grid>
      </Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Type</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property / Business Name</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Contact Person Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Quantity</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> User Contact Phone #</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> User Contact Email</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Address</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip Code </Typography>
     
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {GetPropertyType()}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_property_name}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_first_name}</Typography>
                   
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_unit_quantity}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_phone_no}</Typography>
       
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_email}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_address}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_city}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_state}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.user_zipcode}</Typography>
       
              
      </Stack>
    
      
      </Grid>
     
      </Grid>
      
      </Box>
     
      </>)}

      {(currentTab == 2 && <>
       
       <div  sx={{
        right: '54px' ,left: '54px',borderRadius:'0px'}}>
                <div style={{ display: 'flex' }}>
               
                    <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                    Shipping Information
                    </Typography>
                    
                </div>
               
            </div>
            <Divider sx={{backgroundColor:'white'}} />
            <Box sx={{display: { xs: "none", md: "flex" }}}>
            <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={2.5}>
         <Stack direction="column"  spacing={2}>
      
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Date</Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Shipping Address</Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
       
       </Stack>
       </Grid>
       
       <Grid item xs={2.5}>
       <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderdate}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderaddress}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.ordercity}</Typography>            
       </Stack>
     
       
       </Grid>
       <Grid item xs={2}>
      
       </Grid>
       <Grid item xs={2.5}>
         <Stack direction="column"  spacing={2}>
         <Typography sx={mainThemeStyle.popUpLable} height={40}> Purchase Order # </Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip Code </Typography>
       </Stack>
       </Grid>
       
       <Grid item xs={2.5}>
       <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.ordernumber}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderstate}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderzipcode}</Typography>
       </Stack>
     
       
       </Grid>
       </Grid>
       </Box>
       <Box sx={{display: { xs: "flex", md: "none" }}}>
       <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={5}>
         <Stack direction="column"  spacing={2}>
         <Typography sx={mainThemeStyle.popUpLable} height={40}> Date</Typography>
         <Typography sx={mainThemeStyle.popUpLable} height={200}> Purchase Order # </Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Shipping Address</Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> City </Typography>
       
       <Typography sx={mainThemeStyle.popUpLable} height={40}> State </Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Zip Code </Typography>
      
       </Stack>
       </Grid>
       
       <Grid item xs={7}>
       <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderdate}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.ordernumber}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderaddress}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.ordercity}</Typography>      
       
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderstate}</Typography>
       <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {apiValue.orderzipcode}</Typography>    
       
       </Stack>
     
       
       </Grid>
      
       </Grid>
       
       </Box>
       </>)}
     
       {(currentTab == 3 && <>
        <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Panels
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}> 
           <Grid container spacing={2} padding= '32px'> 
            <Grid item xs={5}>
            <Stack direction="column"  spacing={1}>
            <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>{getVUEPanelname()}</Typography>
            <Typography   sx={mainThemeStyle.popUpLableAnswer}>{"Video Tele-entry Panel, Internal and external 26 bit Wiegand, built-in VoIP, Two on-board relays for operating two doors or vehicle gates in stand-alone mode, LPR credentialing, fully functional IOS and Android Apps."}</Typography>
            
            </Stack>
            </Grid>
         { getVuePanelCount() > 0 &&  
         <Grid item xs={5}>
      <Stack direction="row"  spacing={4}>
      
      {getVuePanelList().map((item) => (
       <><Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled name={item.item_name}  checked={getChecklistOfPanel(item.id) > 0 ? true : false}/>
      
         <img  src={"https://dev.mdvaccess.com/"+item.item_image} height={120} width={100} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =NoImage)
          )
        }></img></> 
        ))}
       
  
      </Stack>
      </Grid>}
           </Grid>

           <Grid container spacing={2} padding= '32px'> 
      {getVuePanelList().map((item) => (
      getChecklistOfPanel(item.id) > 0 && <Grid item xs={3}>
         <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name}</Typography>
         <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getChecklistOfPanel(item.id)}</Typography>
         
      </Grid> )
      )}
       </Grid>

       <Grid container spacing={2} padding= '32px' direction="row"> 
      <Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Total Number of Panels </Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getVuePanelTotalCount()}</Typography>
      </Grid><Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Panels Shipping & Handling (+$50 / Panel)</Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getVuePanelShippingCost()}</Typography>
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> Ground shipping is standard, expedited shipping: please email info@mydoorview.com</Typography> 
    
      </Grid><Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>ALL PANELS SUBTOTAL</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getVuePanelCost()}</Typography>
      </Grid>
       </Grid>
       </Box>

       {getVuePanelTotalCount() > 0 &&  <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '0px',flexGrow: 1,color:'#fff' }}>
                   Panel Add-Ons
                   </Typography>
                   
               </div>
              
           </div>}

           {getVuePanelTotalCount() > 0  && <Divider sx={{backgroundColor:'white'}} />}
           {getVuePanelTotalCount() > 0  &&<Box sx={{display: { xs: "none", md: "block" }}}>
           
           {getVuePanelAddOnList().map((item) => (
       <>
      { checkifPanelAddOnActiveOrNot(item.sub_of)  == true && <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={2}>
       <img  src={"https://dev.mdvaccess.com/"+item.item_image} height={120} width={100} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =NoImage)
          )
        }></img>
       
       </Grid>
       <Grid item xs={3}><Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} ${item.item_price} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswerfixedWidth} height={40}>{item.item_description}</Typography>
       </Stack></Grid>
       <Grid item xs={3}>
       <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Number of {item.item_name} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(item.id)} </Typography>
       </Stack></Grid>
                    <Grid item xs={3}>
                    <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} Subtotal </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOf(item.id)} </Typography>
        </Stack>
       </Grid>
        </Grid> }
        {checkifItHasAddOnorNot(item.id) && <Grid container spacing={2} padding= '32px'>
        {getVuePanelSubOfAddOnList().map((itemInner) => (
          checkifPanelAddOnActiveOrNot(itemInner.sub_of)  == true && item.id == itemInner.sub_of && 
        <Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={60}>{itemInner.item_name} ${itemInner.item_price}</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(itemInner.id)} </Typography>
         </Grid> 
                   
                   
                   ))}

                   {checkifInnerIsOn(item.id)  == true && 
                   <Grid item xs={3}>
                   <Typography  sx={mainThemeStyle.popUpLable} height={60}>{item.item_name} Add-Ons Subtotal</Typography>
                   <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+getSubAddOnSubTotal(item.id)}</Typography>
      
                  
                                </Grid>}
       </Grid>}
       </>
        ))}
       <Grid container spacing={2} padding= '32px'> 
       <Grid item>
            <Stack direction="column"  spacing={2}>
            <Typography  sx={mainThemeStyle.popUpLable} height={40}>ALL PANELS Add-Ons SUBTOTAL</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getVuePanelAddOnsCost()}</Typography>
      </Stack>
      </Grid>
      </Grid>
           </Box>}
      
           {getVuePanelTotalCount() > 0 &&  <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Panel SaaS Subscriptions
                   </Typography>
                   
               </div>
              
           </div>}

           {getVuePanelTotalCount() > 0  && <Divider sx={{backgroundColor:'white'}} />}
           {getVuePanelTotalCount() > 0  && 
           <Grid container spacing={2} padding= '32px'>  
           
           <Grid item xs={5}>
            <Stack direction="column"  spacing={2}>
            {getVuePanelSubscriptionList().map((item) => (
            <Stack direction="row"  spacing={2}>
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name} - {item.item_description}</Typography>
              <Checkbox sx={mainThemeStyle.formCheckBoxLow} disabled  name={item.id}  checked={item.total_quantity > 0 ? true : false}/>
     
            </Stack> 
            ))}
            </Stack>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getVuePanelSubscriptionAddOnList().map((item) => (
            checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}</Typography>
             
            ))}
            {getVuePanelSubscriptionList().map((item) => (
            <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}  Subtotal</Typography>
            ))}
            <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL PANEL SaaS SUBTOTAL</Typography>
            </Stack>
            </Grid>

            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getVuePanelSubscriptionAddOnList().map((item) => (
           checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
           <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.total_quantity}</Typography>
           
         
            ))}
            {getVuePanelSubscriptionList().map((item) => (
              <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {"$ "+ getSubTotalOfWithInner(item.id)}</Typography>
           
          
            ))}
             <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOfPanelSubscription()}</Typography>
           
             
            </Stack>
            </Grid>
            
           </Grid>
           }
           
       </>)}

       {(currentTab == 4 && <>
        <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Virtual QR Tele-Entry
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={5}>
      <Stack direction="column" spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{getVueName()}</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getVueDescription()}</Typography>
      </Stack></Grid> <Grid item xs={3}>
      <Stack direction="row"  spacing={2}>
      
      <Stack direction="row"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name={"vueqryes"} disabled checked={getValueOfVue() == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Yes</Typography>
      </Stack>

      <Stack direction="row"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="vueqrno" disabled checked={getValueOfVue() == "0" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>No</Typography>
      </Stack>
      </Stack>
      </Grid>
    
       </Grid>

       
       {getProductTotalCount("6") > 0 && <>
       <Grid container spacing={2} padding= '32px'> 
       {getProductList("7").map((item) => (
       <>
        <Grid item xs={3}>
           <Typography  sx={mainThemeStyle.popUpLable} height={40}>Number Of {item.item_name} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={60}>{item.item_description}</Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={60}>{item.total_quantity}</Typography>
       
        </Grid>
        <Grid item xs={3}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} Subtotal</Typography>
        <Typography  sx={mainThemeStyle.popUpLableAnswer} height={60}>{"$ "+getProductSubTotal(item.id)}</Typography>
        
        </Grid>
        </>
       ))}</Grid>
       <Grid container spacing={2} padding= '32px'> 
     {getProductList("8").map((item) => (
      <> 
      <Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} </Typography>
  <Typography  sx={mainThemeStyle.popUpLableAnswer} height={60}>{item.item_description}</Typography>
  <Typography  sx={mainThemeStyle.popUpLableAnswer} height={60}>{item.optional_data}</Typography>
        
  
   </Grid>
   </>
     ))}</Grid>
     
 <Grid container spacing={2} padding= '32px'> 
{getProductList("9").map((item) => (
  <>
  <Grid item xs={2.5}>
  <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name}</Typography>
  </Grid>
  <Grid item xs={2.5}>
    
    <Stack direction="row" spacing={2} >
      <img  src={item.item_image != null ? "https://dev.mdvaccess.com/"+item.item_image : NoImage} height={150} width={150}></img>
      <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label" onClick={()=>{
        if(item.item_image != null){
         let url = "https://dev.mdvaccess.com/"+item.item_image;
         saveAs(url, item.item_name);
        }
      }}>Download </Button>
       
      </Stack>
      
      </Grid>
      <Grid item xs={2}> </Grid>
  </>
 ))}</Grid>
 
 <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Vue QR Subscription
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
<Grid container spacing={2} padding= '32px'>  
           
           <Grid item xs={5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("10").map((item) => (
            <Stack direction="row"  spacing={2}>
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name} - {item.item_description}</Typography>
              <Checkbox sx={mainThemeStyle.formCheckBoxLow}  disabled name={item.id} checked={item.total_quantity > 0 ? true : false}/>
     
            </Stack> 
            ))}
            </Stack>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("11").map((item) => (
            checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}</Typography>
             
            ))}
            {getProductSubscriptionList("10").map((item) => (
            <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}  Subtotal</Typography>
            ))}
            
            </Stack>
            </Grid>

            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("11").map((item) => (
           checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
           <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.optional_data}</Typography>
           
         
            ))}
            {getProductSubscriptionList("10").map((item) => (
              <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {"$ "+ getSubTotalOfWithInner(item.id)}</Typography>
            
            ))}
             
            </Stack>
            </Grid>
            
           </Grid>
           <Grid container spacing={2} padding= '32px'>  
           <Grid item xs={3}>
            <Stack direction="column"  spacing={2}>
           <Typography sx={mainThemeStyle.popUpLable} height={40}> All Vue QR Subtotal</Typography>
           <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {"$ "+ getSubTotalOfVueSubscription()}</Typography>
         </Stack></Grid>
                </Grid>
</>
       
       }

      

          </>)}

          {(currentTab == 5 && <>
        <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   LPR
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           {getProductList("12").map((item) => (
       <>
       <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={2}>
       <img  src={"https://dev.mdvaccess.com/"+item.item_image} height={120} width={100} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =NoImage)
          )
        }></img>
       
       </Grid>
       <Grid item xs={3}><Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} ${item.item_price} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswerfixedWidth} height={40}>{item.item_description}</Typography>
       </Stack></Grid>
       <Grid item xs={3}>
       <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Number of {item.item_name} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(item.id)}</Typography>
       </Stack></Grid>
                    <Grid item xs={3}>
                    <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} Subtotal </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOf(item.id)}</Typography>
        </Stack>
       </Grid>
        </Grid> 
        
       </>
        ))}

<Grid container spacing={2} padding= '32px'> 
       <Grid item>
            <Stack direction="column"  spacing={2}>
            <Typography  sx={mainThemeStyle.popUpLable} height={40}>ALL LPR SUBTOTAL</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getProductCost("12")}</Typography>
      </Stack>
      </Grid>
      </Grid>
      { checkifProductIsActiveOrNot("12","13")  && <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   LPR Required Hardware
                   </Typography>
                   
               </div> 
              
           </div>}
           { checkifProductIsActiveOrNot("12","13")  &&  <Divider sx={{backgroundColor:'white'}} />}
      {getProductList("13").map((item) => (
       <>
      { checkifPanelAddOnActiveOrNot(item.sub_of)  == true && <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={2}>
       <img  src={"https://dev.mdvaccess.com/"+item.item_image} height={120} width={100} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =NoImage)
          )
        }></img>
       
       </Grid>
       <Grid item xs={3}><Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} ${item.item_price} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswerfixedWidth} height={40}>{item.item_description}</Typography>
       </Stack></Grid>
       <Grid item xs={3}>
       <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Number of {item.item_name} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(item.id)}</Typography>
      </Stack></Grid>
                    <Grid item xs={3}>
                    <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} Subtotal </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOf(item.id)}</Typography>
        </Stack>
       </Grid>
        </Grid> }
        {checkifItHasAddOnorNot(item.id) && <Grid container spacing={2} padding= '32px'>
        {getProductSubOfAddOnList("14").map((itemInner) => (
          checkifPanelAddOnActiveOrNot(itemInner.sub_of)  == true && item.id == itemInner.sub_of && 
        <Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={60}>{itemInner.item_name} ${itemInner.item_price}</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(itemInner.id)}</Typography>
      
        </Grid> 
                   
                   
                   ))}

                   {checkifInnerIsOn(item.id)  == true && 
                   <Grid item xs={3}>
                   <Typography  sx={mainThemeStyle.popUpLable} height={60}>{item.item_name} Add-Ons Subtotal</Typography>
                   <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+getSubAddOnSubTotal(item.id)}</Typography>
      
                   
                                </Grid>}
       </Grid>}
       </>
        ))}
{ checkifProductIsActiveOrNot("12","13")  &&
<Grid container spacing={2} padding= '32px'> 
       <Grid item>
            <Stack direction="column"  spacing={2}>
            <Typography  sx={mainThemeStyle.popUpLable} height={40}>ALL LPR Required Hardware SUBTOTAL</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getProductAddOnCost("13","14")}</Typography>
      </Stack>
      </Grid>
      </Grid>}
         
      { checkifProductIsActiveOrNot("12","15")  && <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   LPR Subscription
                   </Typography>
                   
               </div> 
              
           </div>}
           { checkifProductIsActiveOrNot("12","15")  &&  <Divider sx={{backgroundColor:'white'}} />}
           { checkifProductIsActiveOrNot("12","15")  && <Grid container spacing={2} padding= '32px'>  
           
           <Grid item xs={5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("15").map((item) => (
            <Stack direction="row"  spacing={2}>
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name} - {item.item_description}</Typography>
              <Checkbox sx={mainThemeStyle.formCheckBoxLow}  disabled name={item.id} checked={item.total_quantity > 0 ? true : false}/>
     
            </Stack> 
            ))}
            </Stack>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("16").map((item) => (
            checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}</Typography>
             
            ))}
            {getProductSubscriptionList("15").map((item) => (
            <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}  Subtotal</Typography>
            ))}
            <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL LPR Subscription SUBTOTAL</Typography>
            </Stack>
            </Grid>

            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("16").map((item) => (
           checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&

           <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity}</Typography>
           
            ))}
            {getProductSubscriptionList("15").map((item) => (
              <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOfWithInner(item.id)}</Typography>
            
            ))}

          <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOfLPRSubscription()}</Typography>
            
             
            </Stack>
            </Grid>
            
           </Grid> }
     

          </>)}
         

          {(currentTab == 6 && <>
        <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Additional Items
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />

           {getProductList("17").map((item) => (
       <>
       <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={2}>
       <img  src={"https://dev.mdvaccess.com/"+item.item_image} height={120} width={100} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =NoImage)
          )
        }></img>
       
       </Grid>
       <Grid item xs={3}><Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} ${item.item_price} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswerfixedWidth} height={40}>{item.item_description}</Typography>
       </Stack></Grid>
       <Grid item xs={3}>
       <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Number of {item.item_name} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(item.id)}</Typography>
       </Stack></Grid>
                    <Grid item xs={3}>
                    <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} Subtotal </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOf(item.id)}</Typography>
     </Stack>
       </Grid>
        </Grid> 
        
       </>
        ))}

<Grid container spacing={2} padding= '32px'> 
       <Grid item>
            <Stack direction="column"  spacing={2}>
            <Typography  sx={mainThemeStyle.popUpLable} height={40}>ALL LPR SUBTOTAL</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getProductCost("17")}</Typography>
      </Stack>
      </Grid>
      </Grid>
      { checkifProductIsActiveOrNot("17","18")  && <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Additional Items Add-Ons
                   </Typography>
                   
               </div> 
              
           </div>}
           { checkifProductIsActiveOrNot("17","18")  &&  <Divider sx={{backgroundColor:'white'}} />}
      {getProductList("18").map((item) => (
       <>
      { checkifPanelAddOnActiveOrNot(item.sub_of)  == true && <Grid container spacing={2} padding= '32px'> 
       <Grid item xs={2}>
       <img  src={"https://dev.mdvaccess.com/"+item.item_image} height={120} width={100} onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =NoImage)
          )
        }></img>
       
       </Grid>
       <Grid item xs={3}><Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} ${item.item_price} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswerfixedWidth} height={40}>{item.item_description}</Typography>
       </Stack></Grid>
       <Grid item xs={3}>
       <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Number of {item.item_name} </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(item.id)}</Typography>
     
       </Stack></Grid>
                    <Grid item xs={3}>
                    <Stack direction="column" spacing={2}>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>{item.item_name} Subtotal </Typography>
       <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOf(item.id)}</Typography>
     </Stack>
       </Grid>
        </Grid> }
        {checkifItHasAddOnorNot(item.id) && <Grid container spacing={2} padding= '32px'>
        {getProductSubOfAddOnList("19").map((itemInner) => (
          checkifPanelAddOnActiveOrNot(itemInner.sub_of)  == true && item.id == itemInner.sub_of && 
        <Grid item xs={3}>
      <Typography  sx={mainThemeStyle.popUpLable} height={60}>{itemInner.item_name} ${itemInner.item_price}</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{getTotalAddOnQuantity(itemInner.id)}</Typography>
      </Grid> 
                   
                   
                   ))}

                   {checkifInnerIsOn(item.id)  == true && 
                   <Grid item xs={3}>
                   <Typography  sx={mainThemeStyle.popUpLable} height={60}>{item.item_name} Add-Ons Subtotal</Typography>
                   <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+getSubAddOnSubTotal(item.id)}</Typography>
                  
                                </Grid>}
       </Grid>}
       </>
        ))}
{ checkifProductIsActiveOrNot("17","18")  &&
<Grid container spacing={2} padding= '32px'> 
       <Grid item>
            <Stack direction="column"  spacing={2}>
            <Typography  sx={mainThemeStyle.popUpLable} height={40}>ALL Additional Items Add-Ons SUBTOTAL</Typography>
      <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getProductAddOnCost("18","19")}</Typography>
      </Stack>
      </Grid>
      </Grid>}
         
      { checkifProductIsActiveOrNot("17","20")  && <div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
              
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Additional Items Subscription
                   </Typography>
                   
               </div> 
              
           </div>}
           { checkifProductIsActiveOrNot("17","20")  &&  <Divider sx={{backgroundColor:'white'}} />}
           { checkifProductIsActiveOrNot("17","20")  && <Grid container spacing={2} padding= '32px'>  
           
           <Grid item xs={5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("20").map((item) => (
            <Stack direction="row"  spacing={2}>
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name} - {item.item_description}</Typography>
              <Checkbox sx={mainThemeStyle.formCheckBoxLow}  disabled name={item.id} checked={item.total_quantity > 0 ? true : false}/>
     
            </Stack> 
            ))}
            </Stack>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("21").map((item) => (
            checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
               <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}</Typography>
             
            ))}
            {getProductSubscriptionList("20").map((item) => (
            <Typography sx={mainThemeStyle.popUpLable} height={40}> {item.item_name}  Subtotal</Typography>
            ))}
            <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL Additional Items Subscription SUBTOTAL</Typography>
            </Stack>
            </Grid>

            <Grid item xs={2.5}>
            <Stack direction="column"  spacing={2}>
            {getProductSubscriptionList("21").map((item) => (
           checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
           <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity}</Typography>
                 
        
         
            ))}
            {getProductSubscriptionList("20").map((item) => (
               <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getSubTotalOfWithInner(item.id)}</Typography>
                 
        
            ))}
            <Typography  sx={mainThemeStyle.popUpLableAnswer} height={40}>{"$ "+ getProductAddOnCost("20","21")} </Typography>
               
             
            </Stack>
            </Grid>
            
           </Grid> }
     

          </>)}

          {(currentTab == 7 &&
      <>
          <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
        
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Panels </Typography>
     </Box>
     <Divider sx={{backgroundColor:'white'}} />
      {  getVuePanelCount() > 0 &&  <Box sx={{
      display:  "flex" ,
      
      padding:'32px'
      }}>
      <Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getVuePanelList().map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.item_name} </Typography>
      ))}</Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getVuePanelList().map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.total_quantity} </Typography>
      ))}</Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getVuePanelList().map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}</Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {getVuePanelList().map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)} </Typography>
      ))} </Stack>
      </Grid>



      </Grid> </Box>}
      {  getVuePanelCount() > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL PANELS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getVuePanelCost()} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box>}
      
      { (getProductWithTotalCount("1","2") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Panel Add-Ons </Typography>
      </Box>)}
      { getProductWithTotalCount("1","2") > 0 && <Divider sx={{backgroundColor:'white'}} /> }
      { (getProductWithTotalCount("1","2") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getVuePanelAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      {getVuePanelSubOfAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getVuePanelAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      {getVuePanelSubOfAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getVuePanelAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}
      {getVuePanelSubOfAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}</Typography>
      ))}
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {getVuePanelAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)} </Typography>
      ))}
      {getVuePanelSubOfAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductWithTotalCount("1","2") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> PANEL ADD-ONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getVuePanelAddOnsCost()} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}



      { (getProductWithTotalCount("1","4") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Panel SaaS Subscriptions </Typography>
      </Box>)}
      { getProductWithTotalCount("1","4") > 0 && <Divider sx={{backgroundColor:'white'}} /> }
      { (getProductWithTotalCount("1","4") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getVuePanelSubscriptionList().map((item) => ( 
       
        getChecklistOfPanel(item.id) > 0 && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      {getVuePanelSubscriptionAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name}</Typography>
      ))}
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getVuePanelSubscriptionList().map((item) => ( 
        
        getChecklistOfPanel(item.id) > 0 && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      {getVuePanelSubscriptionAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity}</Typography>
      ))}
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getVuePanelSubscriptionList().map((item) => ( 
        
        getChecklistOfPanel(item.id) > 0 && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}</Typography>
      ))}
      {getVuePanelSubscriptionAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}</Typography>
      ))} </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
       {getVuePanelSubscriptionList().map((item) => ( 
       
        getChecklistOfPanel(item.id) > 0 && 
        <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}
      {getVuePanelSubscriptionAddOnList().map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true && 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductWithTotalCount("1","4")> 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> SaaS SUBSCRIPTIONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getSubTotalOfPanelSubscription()} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}



      { (getProductTotalCount("6") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Virtual QR Tele-Entry </Typography>
      </Box>)}
      
      { getProductTotalCount("6") > 0 && <Divider sx={{backgroundColor:'white'}} /> }

      { (getProductTotalCount("6") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("7").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      {getProductList("10").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
       </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("7").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      {getProductList("10").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
        </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getProductList("7").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}
     {getProductList("10").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
       {getProductList("7").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)} </Typography>
      ))}
      {getProductList("10").map((item) => (
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)} </Typography>
      ))}
       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductTotalCount("6") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}>  Vue QR Subtotal </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getSubTotalOfVueSubscription()} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}


      { (getProductTotalCount("12")  > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> LPR </Typography>
      </Box>)}
      
      { getProductTotalCount("12")  > 0 && <Divider sx={{backgroundColor:'white'}} /> }

      {  getProductTotalCount("12") > 0 &&  <Box sx={{
      display:  "flex" ,
      
      padding:'32px'
      }}>
      <Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("12").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.item_name} </Typography>
      ))}</Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("12").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.total_quantity} </Typography>
      ))}</Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getProductList("12").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}</Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {getProductList("12").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)} </Typography>
      ))} </Stack>
      </Grid>



      </Grid> </Box>}
      {  getProductTotalCount("12") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL LPR SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getProductCost("12")} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box>}
      
      { (getProductWithTotalCount("12","13") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> LPR Required Hardware </Typography>
      </Box>)}
      { getProductWithTotalCount("12","13") > 0 && <Divider sx={{backgroundColor:'white'}} /> }
      { (getProductWithTotalCount("12","13") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("13").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      {getProductList("14").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("13").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      {getProductList("14").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>

      {getProductList("13").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}  </Typography>
      ))}
      {getProductList("14").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}

      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>

      {getProductList("13").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}
      {getProductList("14").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}

       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductWithTotalCount("12","13") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> LPR Required Hardware SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getProductAddOnCost("13","14")} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}



      { (getProductWithTotalCount("12","15") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> LPR Subscriptions </Typography>
      </Box>)}
      { getProductWithTotalCount("12","15") > 0 && <Divider sx={{backgroundColor:'white'}} /> }
      { (getProductWithTotalCount("12","15") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("15").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name}</Typography>
      ))}
      {getProductList("16").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name}</Typography>
      ))}

      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("15").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity* getProductTotalCount("12")}</Typography>
      ))}
      {getProductList("16").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity}</Typography>
      ))}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getProductList("15").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)* getProductTotalCount("12")}</Typography>
      ))}
      {getProductList("16").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}</Typography>
      ))}
      
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
       {getProductList("15").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)* getProductTotalCount("12")}</Typography>
      ))}
      {getProductList("16").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}

       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductWithTotalCount("12","15")> 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> LPR SUBSCRIPTIONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getProductAddOnCost("15","16")* getProductTotalCount("12")} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}


      { (getProductTotalCount("17")  > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Additional Items </Typography>
      </Box>)}
      
      { getProductTotalCount("17")  > 0 && <Divider sx={{backgroundColor:'white'}} /> }

      {  getProductTotalCount("17") > 0 &&  <Box sx={{
      display:  "flex" ,
      
      padding:'32px'
      }}>
      <Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("17").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.item_name} </Typography>
      ))}</Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("17").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {item.total_quantity} </Typography>
      ))}</Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getProductList("17").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}</Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
      {getProductList("17").map((item) => ( 
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)} </Typography>
      ))} </Stack>
      </Grid>



      </Grid> </Box>}
      {  getProductTotalCount("17") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> ALL Additional Items SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getProductCost("17")} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box>}
      
      { (getProductWithTotalCount("17","18") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Additional Items Add-Ons </Typography>
      </Box>)}
      { getProductWithTotalCount("17","18") > 0 && <Divider sx={{backgroundColor:'white'}} /> }
      { (getProductWithTotalCount("17","18") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("18").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      {getProductList("19").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name} </Typography>
      ))}
      
      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("18").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      {getProductList("19").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity} </Typography>
      ))}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>

      {getProductList("18").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}  </Typography>
      ))}
      {getProductList("19").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)} </Typography>
      ))}

      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>

      {getProductList("18").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}
      {getProductList("19").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}

       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductWithTotalCount("17","18") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Additional Items ADD-ONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getProductAddOnCost("18","19")} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}



      { (getProductWithTotalCount("17","20") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Typography variant="h6" height={40} style={{ color:'#fff' }}> Additional Items Subscriptions </Typography>
      </Box>)}
      { getProductWithTotalCount("17","20") > 0 && <Divider sx={{backgroundColor:'white'}} /> }
      { (getProductWithTotalCount("17","20") > 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
<Grid container spacing={2} > 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Item  </Typography>
      {getProductList("20").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name}</Typography>
      ))}
      {getProductList("21").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.item_name}</Typography>
      ))}

      </Stack>
      </Grid>

      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Qty </Typography>
      {getProductList("20").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity}</Typography>
      ))}
      {getProductList("21").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>{item.total_quantity}</Typography>
      ))}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> S&H </Typography>
      {getProductList("20").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}</Typography>
      ))}
      {getProductList("21").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyShippingCost(item.id)}</Typography>
      ))}
      
      </Stack>
      </Grid>

      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> Item Total </Typography>
       {getProductList("20").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}
      {getProductList("21").map((item) => ( 
        checkifPanelAddOnActiveOrNot(item.sub_of)  == true &&
        getChecklistOfPanel(item.id) > 0 && <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}>$ {getAnyProductCost(item.id)}</Typography>
      ))}

       </Stack>
      </Grid>
      </Grid>

      </Box>)}


      { (getProductWithTotalCount("17","20")> 0 && <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
      <Grid container spacing={2}> 
      <Grid item >
       
      <Stack direction="column"  >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Additional Items SUBSCRIPTIONS SUBTOTAL </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {getProductAddOnCost("20","21")} </Typography>
      </Stack>
      </Grid>
      
      </Grid>
      </Box> )}

      

      <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
     
       
     <Stack direction="row"  spacing={20}>
     <Stack direction="column"  >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> ORDER TOTAL </Typography>
       <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_order_subtotal}</Typography>
       </Stack>

       <Stack direction="column"  >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} > COUPON </Typography> 
         <Typography sx={mainThemeStyle.popUpLable} height={40}> {apiValue.promocode}</Typography>
         </Stack>

       <Stack direction="column"  >
       <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} > COUPON DISCOUNT </Typography> 
         <Typography sx={mainThemeStyle.popUpLable} height={40}>- $ {apiValue.promocode_discount}</Typography>
         </Stack>
         </Stack>

       
         
      
      </Box>
      <Box sx={{
      display:  "flex" ,
      padding:'32px'
      }}>
        <Stack direction="row"  spacing={20}>
       <Stack direction="column"  >
         <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} >** ORDER SUB TOTAL **</Typography> 
         {/* <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> +4% for Payment Processing</Typography> */}
         <Typography sx={mainThemeStyle.popUpLable} height={40}> $ {apiValue.all_order_subtotal_after_discount_plus_payment_charge}</Typography>
         </Stack>
         <Stack direction="column"  >
         <Typography sx={mainThemeStyle.popUpLable} height={40}></Typography>
         <Typography   sx={mainThemeStyle.popUpLable} height={40} > PAYMENT TRANSACTION ID</Typography> 
          <Typography sx={mainThemeStyle.popUpLable} height={40}>  {getTransactionId()}</Typography>
         </Stack>
         </Stack>

      </Box>

      
      </>
      
      
      
      
      )}
        
          <Popup
                title="ORDER SUMMARY"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
             
      <ShowLog/>
               
            </Popup>
          
</Box> }



{currentDisableValue == true && 
 <Box sx={mainThemeStyle.box} >
 <Typography sx={mainThemeStyle.lable}>Access For This Page Is Blocked</Typography>
 
  
  
  </Box>
    
    
    }
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

export default DealerEditOrder;