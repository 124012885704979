import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import ManageUsers from "./ManageUsers";
import { useParams,useNavigate,useLocation  } from "react-router-dom";
import ManageMainAdminLocation from "./ManageMainAdminLocation";
import EditLocationAdmin from "./EditLocationAdmin";
import MasterUser from "./MasterUser";
import { useEffect } from "react";
import EditUser from "./EditUser";
import AddUser from "./AddUser";
import CallingGroup from "./CallingGroup";
import OfficeGroup from "./OfficeGroup";
import ManageProperty from "./ManageProperty";
import AddNewProperty from "./AddNewProperty";
import EditProperty from "./EditProperty";
import PuffLoader from "react-spinners/PuffLoader";
import EditAdminUser from "./EditAdminUser";
import DealerAddOrder from "./DealerAddOrder";
import RoleAccess from "./RoleAccess";
import Feature from "./Feature";
import SystemLog from "./SystemLog";
import SysLog from "./SysLog";
import axios from "./axios";
import TwillioNumber from "./TwillioNumber";
import GlobalAlertView from "./GlobalAlertView";
import ManageSuperAdmin from "./ManageSuperAdmin";
import ManagePromoCode from "./ManagePromoCode";
import useToken from "./useToken";
import PanelPrice from "./PanelPrice";
import EditOrder from "./EditOrder";
import ManageOrder from "./ManageOrder";
import CodeOverride from "./CodeOverride";
const Misc = (props) => {
  console.log("IN location Page");
  const {token} = useToken();
  const navigate = useNavigate();
  const {RefreshToken,switchToSubUser} = props;
  const {id} = useParams();
  console.log('id is '+id);
  const theme = Theme;
  const location = useLocation();
  const [dataOfDealer,setDataOfDealer] = useState('');

  const[dataOfLocation,setDataOfLocation]  = useState('');
  const[addDataOfLocation,setaddDataOfLocation] = useState('');
  const getMyPostData = async () => {
    try {
        console.log("/admin/location/"+location.state.editlocation.userid+"/"+location.state.editlocation.id);
      const res = await axios.get("/admin/location/"+location.state.editlocation.userid+"/"+location.state.editlocation.id,{
        headers: { 'Content-Type':  'application/json' ,
        'token' : token.token,
        'tokenid' : token.email},
      });
      if(res.data[0].elevator_access == ""){
        res.data[0].elevator_access = {elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      }else{
      res.data[0].elevator_access = JSON.parse(res.data[0].elevator_access);
      }
      if(res.data[0].hold_open == ""){
        res.data[0].hold_open = {holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]};
      }else{
      res.data[0].hold_open = JSON.parse(res.data[0].hold_open);
     
        if(res.data[0].hold_open.table == ""){
          res.data[0].hold_open.table = [{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}];
        }
        
      }
      if(res.data[0].brivo_access == ""){
        res.data[0].brivo_access = {brivoprimeid:"",brivoprimename:"",brivofloors:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]};
      }else{
      res.data[0].brivo_access = JSON.parse(res.data[0].brivo_access);
      }
      if(res.data[0].brivo_array == ""){
        res.data[0].brivo_array =new Array();
      }
      if(res.data[0].pin_length == ""){
        res.data[0].pin_length = 4;
      }
      setDataOfLocation(res.data[0]);
  
      console.log("dataof loca"+res.data[0]);
    } catch (error) {
      console.log("response"+JSON.stringify( error.response.data));
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  
  useEffect(()=>{
    if(id == 'editlocation'){
    setDataOfLocation('');
      getMyPostData();
     }
     
  },[id]);
  
  
 const handleSearch = () =>{
  navigate('../extra/user', { replace: true });
 }
 const handleLocation = () =>{
    navigate('../extra/location', { replace: true });
   }
   const handleFeatures = () =>{
    navigate('../extra/features', { replace: true });
   }
   const handleLogs = () =>{
    navigate('../extra/systemlogs', { replace: true });
   }
   const handleNumber = () =>{
    navigate('../extra/twilio', { replace: true });
   }

  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    {(id === 'location' &&  <Typography sx={mainThemeStyle.lable}>Locations</Typography>)}
    {(id === 'editlocation' &&  <Typography sx={mainThemeStyle.lable}>Location : {location.state.editlocation.loc_name}</Typography>)}
    {(id === 'user' &&  <Typography sx={mainThemeStyle.lable}>Users</Typography>)}
    {(id === 'twilio' &&  <Typography sx={mainThemeStyle.lable}>Twilio Numbers</Typography>)}
    {(id === 'features' &&  <Typography sx={mainThemeStyle.lable}>Features</Typography>)}
    {(id === 'systemlogs' &&  <Typography sx={mainThemeStyle.lable}>System Logs</Typography>)}
    {(id === 'edituser' && <Typography sx={mainThemeStyle.lable}>User : {location.state.edituser.fname} {location.state.edituser.lname}</Typography>)}
    {(id === 'globalalert' &&  <Typography sx={mainThemeStyle.lable}>Global Alerts</Typography>)}
    {(id === 'manager' &&  <Typography sx={mainThemeStyle.lable}>Super Admin Manager</Typography>)}
    {(id === 'roleaccess' &&  <Typography sx={mainThemeStyle.lable}>Role-Based Access Control</Typography>)}
    
    {(id === 'otpoverride' &&  <Typography sx={mainThemeStyle.lable}>One-Time Code Override</Typography>)}
    {(id === 'sellitems' &&  <Typography sx={mainThemeStyle.lable}>Manage Sale Items</Typography>)}
    {(id === 'managepromo' &&  <Typography sx={mainThemeStyle.lable}>Manage Promo Codes</Typography>)}
    {(id === 'manageorder' &&  <Typography sx={mainThemeStyle.lable}>Order History</Typography>)}
    {(id === 'edit' &&  <Typography sx={mainThemeStyle.lable}> Order : {location.state.editorder.ordernumber}</Typography>)}
    </Box>
    {/* {(id === 'location' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleSearch} >Users</Button>)}
    {(id === 'editlocation' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleLocation} >Location</Button>)}
    {(id === 'systemlogs' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleLocation} >Locations</Button>)}
    {(id === 'user' &&   <Button sx={mainThemeStyle.activityButton} onClick={handleNumber} >Twilio Numbers</Button>)}
    {(id === 'edituser' &&   <Button sx={mainThemeStyle.activityButton} onClick={handleSearch} >Users</Button>)}
    {(id === 'features' &&   <Button sx={mainThemeStyle.activityButton} onClick={handleLogs} >System Logs</Button>)}
    {(id === 'twilio' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleFeatures} >Features</Button>)}
    {(id === 'globalalert' &&   <Button sx={mainThemeStyle.activityButton} onClick={handleLogs} >System Logs</Button>)}
     */}
    {(id === 'location' &&  <ManageMainAdminLocation RefreshToken={RefreshToken}/>)}
    {(id === 'user' && <MasterUser RefreshToken={RefreshToken}/>)}
    {(id === 'edituser' && <EditAdminUser idOfuser={location.state.edituser} RefreshToken={RefreshToken}/>)}
    {(id === 'systemlogs' &&  <SysLog RefreshToken={RefreshToken}/>)}
    {(id === 'twilio' &&  <TwillioNumber RefreshToken={RefreshToken}/>)}
    {(id === 'features' &&  <Feature RefreshToken={RefreshToken}/>)}
    {(id === 'manager' &&  <ManageSuperAdmin RefreshToken={RefreshToken}/>)}
    {(id === 'roleaccess' &&  <RoleAccess RefreshToken={RefreshToken}/>)}
    {(id === 'otpoverride' &&  <CodeOverride RefreshToken={RefreshToken}/>)}
    {(id === 'globalalert' &&  <GlobalAlertView RefreshToken={RefreshToken}/>)}
    {(id === 'manageorder' && <ManageOrder RefreshToken={RefreshToken}/>)}
    {(id === 'managepromo' &&  <ManagePromoCode RefreshToken={RefreshToken}/>)}
    {(id === 'edit' &&<EditOrder idOfOrder={location.state.editorder} RefreshToken={RefreshToken} />)}
    {(id === 'sellitems' &&  <PanelPrice RefreshToken={RefreshToken}/>)}
    {(id === 'editlocation' && dataOfLocation != '' && <EditLocationAdmin idOflocation={dataOfLocation} RefreshToken={RefreshToken} switchToSubUser={switchToSubUser}/>)}
   {(id === 'editlocation'  && dataOfLocation == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
   {/*  {(id === 'manageproperty' &&
    <Button startIcon={<AddIcon />} sx={mainThemeStyle.activityButton} onClick={handleAdd} >Add Property</Button>)}
   {(id === 'addproperty' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Property</Button>)}
   {(id === 'editproperty' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >View Property</Button>)}
    {(id === 'manageproperty' && <ManageProperty/>)}
    {(id === 'addproperty' && dataOfDealer!= '' &&<AddNewProperty dataOfDealer={dataOfDealer}/>)}
   {(id === 'addproperty'  && dataOfDealer == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)}
    {(id === 'editproperty' && dataOfDealer!= '' &&<EditProperty idOfProperty={location.state.editproperty} dataOfDealer={dataOfDealer}/>)}
   {(id === 'editproperty'  && dataOfDealer == '' &&<PuffLoader color="#878787" loading cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"250px"}} />)} */}
    </Box>
   


   
  
</ThemeProvider>);
};

export default Misc;