import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses, Input } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import GlobalAlert from "./GlobalAlert"; 
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import TimezoneSelect from 'react-timezone-select'
import Papa from "papaparse";
import { CSVLink, CSVDownload } from "react-csv";
import { useParams,useNavigate  } from "react-router-dom";
import { ro } from "date-fns/locale";
import { ExportToCsv } from 'export-to-csv';
import useToken from "./useToken";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from "./axios";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function AddUser ( {idOfuser,RefreshToken, ...props}){
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const csvLink = useRef(null);
  const navigate = useNavigate();
  //State to store the values
  const [values, setValues] = useState([]);
  const [valuesSimple, setValuesSimple] = useState([]);
  const myContainer = useRef(null);
  const [showCSVUpload, setShowCSVUpload] = useState(true);
  const [showCSVData, setShowCSVData] = useState(false);
  const tableLengthAccess =['0','1','2','3','4','5','6','7','8','9'];
  const [loading,setLoading] = useState(false);
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");



  const getDisableValue = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("41").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("41").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("41").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("41").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("41").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("41").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("41").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("41").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const currentDisableValue = getDisableValue();

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });
 

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        //setValues(valuesArray.slice(0,valuesArray.length));

        console.log("values array is "+valuesArray);

        const RowAdder = [];

        for(let valL = 0 ; valL < valuesArray.length ; valL++){
    
        let sd = [];
        sd['fname'] = valuesArray[valL][0];
        sd['lname'] = valuesArray[valL][1];
        sd['unit'] = valuesArray[valL][2];
        sd['phone'] = valuesArray[valL][3];
        sd['email'] = valuesArray[valL][4];
        sd['expiry_date'] = valuesArray[valL][5];
        sd['rfid_card'] = valuesArray[valL][6];
        sd['id'] =  valL;
        let newval = 7;
        for(let i = 0; i < idOfuser.custom_field.length ; i ++){
          newval = newval + i;
          sd[idOfuser.custom_field[i].name] = valuesArray[valL][newval];
        }
        console.log("newval" + newval);
        let newvalyeo = 7 + idOfuser.custom_field.length;
        for(let ist = 0 ; ist < (idOfuser.loc_data).length ; ist ++){
          let nameCode = idOfuser.loc_data[ist].loc_code;
          console.log(nameCode);
         // newval = newval + ist;
          if( valuesArray[valL][newvalyeo+ist] == 'X'){
            sd[nameCode] = 1;
          }else{
            sd[nameCode] = 0;
          }
          
        }
        console.log (sd);
        RowAdder.push(sd);
      }
      console.log(RowAdder);
      RowAdder.push(CreateOneRow(valuesArray.length));
      setRecords(RowAdder);
      setTotalEntry(RowAdder);
      },
    });
    setShowCSVUpload(false);
    setShowCSVData(true);
  }
  
  const handleClearData = () => {
    setShowCSVUpload(true);
    setShowCSVData(false);
    setParsedData([]);
     // Filtered Column Names
    setTableRows([]);
    // Filtered Values
    setValues([]);
    setTotalEntry(CreateOneRow(0));
    setRecords(CreateOneRow(0));
    setPageToNewOne();
       
  }

  const getFirstColumn = () => {
    let one =  ["First Name","Last Name","Unit No","Phone Number","Email Address","Exp. Date","RFID Card #"];
    for(let i = 0; i < idOfuser.custom_field.length ; i ++){
      one.push(idOfuser.custom_field[i].name);
    }
    for(let i = 0; i < idOfuser.loc_data.length ; i ++){
      one.push(idOfuser.loc_data[i].loc_name);
    }
    //console.log(one);
    return one;
  }
  const getRandomColumn = () => {
    let one =  [faker.name.firstName(), faker.name.lastName(),faker.datatype.number(100),faker.phone.phoneNumber('##########'),faker.internet.email(),"2100-01-01", faker.random.numeric(8)];
    for(let i = 0; i < idOfuser.custom_field.length ; i ++){
      one.push('');
    }
    for(let i = 0; i < idOfuser.loc_data.length ; i ++){
      if(Math.floor(Math.random() *2) == 1){
        one.push('X');
      }else{
        one.push('');
      }
      
    }
    //console.log(one);
    return one;
  }
  const csvData = [
    getFirstColumn(),
    getRandomColumn(),
    getRandomColumn(),
    getRandomColumn(),
    getRandomColumn(),
    getRandomColumn(),
    getRandomColumn(),
    
    
  ];
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleSaveData = () => {
    //getFirstColumn();
    // sd['fname'] = '';
    // sd['lname'] = '';
    // sd['unit'] = '';
    // sd['phone'] = '';
    // sd['email'] = '';
    // sd['expiry_date'] = '';
    let fault = 0;
    for(let i =0 ; i < totalEntry.length-1 ; i ++){
      if((totalEntry[i].fname).length == 0 ){
        fault = 1;
        break;
      }
      if((totalEntry[i].lname).length == 0 ){
        fault = 1;
        break;
      }
      if((totalEntry[i].unit).length == 0 ){
        fault = 1;
        break;
      }
      if((totalEntry[i].phone).length != 10 ){
        console.log("entry number is "+ i + " amd phone number is "+totalEntry[i].phone);
        fault = 2;
        break;
      }
      if(!isValidEmail(totalEntry[i].email)){
        fault = 3;
        break;
      }
     
    }

    if(fault == 1){
      setOpenSnackData(getAlertTitle('AUUDI') );
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    else if(fault == 2){
      setOpenSnackData(getAlertTitle('AUUPI')  );
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    else if(fault == 3){
      setOpenSnackData(getAlertTitle('AUUEI') );
      setSeverityData("error");
      setOpenSnack(true);
      return;
    }
    //setLoading(true);
    updateMyPostData();
  }

  const updateMyPostData = async () => {
    console.log("In update post data");
    let locode = [];
    let pin = [];
    let locname = [];
    let cam = [];
    let rformat = idOfuser.rfid_format;
    let cname = [];
    let ctype = [];
    let cid = [];
    console.log(totalEntry);
    let entryts = [];
    for(let ls = 0; ls < totalEntry.length-1 ; ls++){
      
      let ld = totalEntry[ls];
      let ds =  {};
      Object.entries(ld).map(([key,value])=>{
        ds[key] =value;
        console.log("key "+key +" valu is "+value);
      });
      
      entryts.push(ds);
      console.log("entry is "+ds);
    }
    for(let ist = 0 ; ist < (idOfuser.loc_data).length ; ist ++){
      locode.push(idOfuser.loc_data[ist].loc_code);
      pin.push(idOfuser.loc_data[ist].pin_length);
      locname.push(idOfuser.loc_data[ist].loc_name);
      cam.push(idOfuser.loc_data[ist].cam_brand);
      
     }
     for(let ist = 0 ; ist < (idOfuser.custom_field).length ; ist ++){
      cname.push(idOfuser.custom_field[ist].name);
      ctype.push(idOfuser.custom_field[ist].type);
      cid.push(idOfuser.custom_field[ist].id);
     }
    const postObj = {loc_code:locode,pin_length:pin,loc_name:locname,cam_brand:cam,format:rformat,cust_name:cname,cust_type:ctype,cust_id:cid,entry:entryts,issub:token.issub,subId:token.subid};
    console.log(postObj);
    setLoading(true);
     try {
      const res = await axios.post("/admin/adduser/"+token.id,postObj, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      console.log( res.data);
      setOpenSnackData(res.data.rows);
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
     
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('AUUAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    } 
  };
  const getAllRecords = (valRecord) => {
    let newRecord = [];
    for(let isN = 0 ; isN < valRecord.length ; isN ++){
      let ist = valRecord[isN];
      newRecord.push(ist);
    }
    return newRecord;
  }
  
  
  const dataEntered = (event) =>{
    
    let ds = (event.target.name).split('-')[1];
    let dsname = (event.target.name).split('-')[0];
    console.log("record s " +records);
    console.log("event.target.name "+event.target.name);
    console.log("ds is "+ds);
    console.log("ds name is "+ dsname);
    totalEntry[parseInt(ds)][dsname] = event.target.value;

    if((totalEntry[parseInt(ds)].fname).length > 0 && (totalEntry[parseInt(ds)].lname).length > 0
    && (totalEntry[parseInt(ds)].unit).length > 0 && (totalEntry[parseInt(ds)].phone).length > 0
    && (totalEntry[parseInt(ds)].email).length > 0 && isValidEmail(totalEntry[parseInt(ds)].email) ) {
      console.log('entry is avaialble');
      if(totalEntry[parseInt(ds) + 1] == null){
        //console.log('entry inner is avaialble');
        setShowCSVUpload(false);
        let st = CreateNewRow(parseInt(ds)+1);
        totalEntry.push(st);
        setRecords(getAllRecords(totalEntry));
      }
      else{
        console.log('entry inner is not avaialble');
      }
    }

    //let rec = getAllRecords(totalEntry);
    setTotalEntry(totalEntry);
    console.log(totalEntry);
   

  }

  const dataChecked = (event) =>{
    
    let ds = (event.target.name).split('-')[1];
    let dsname = (event.target.name).split('-')[0];
    totalEntry[parseInt(ds)][dsname] = event.target.checked ? 1 : 0;
    setTotalEntry(totalEntry);
    console.log(totalEntry);
   

  }
  
  const options = { 
    fieldSeparator: ',',
    filename: 'Sample',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: false,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };

const csvExporter = new ExportToCsv(options);


  
const downloadClick = () => {
    csvExporter.generateCsv(csvData);
  }

  
  
  
  const CreateheadCells = () => {
       let headCell = [];
       headCell.push({ id: 'First Name', label: 'First Name' });
       headCell.push( { id: 'Last Name', label: 'Last Name' });
       headCell.push({ id: 'Unit No.', label: 'Unit No.' });
       headCell.push({ id: 'Phone Number', label: 'Phone Number' });
       headCell.push({ id: 'Email Address', label: 'Email Address' });
       headCell.push({ id: 'Exp. Date', label: 'Exp. Date' });
       headCell.push({ id: 'RFID Card #', label: 'RFID Card #' });

       for(let ist = 0 ; ist < (idOfuser.custom_field).length ; ist ++){
        headCell.push({ id: idOfuser.custom_field[ist].name, label: idOfuser.loc_data[ist].name});
       }

       for(let ist = 0 ; ist < (idOfuser.loc_data).length ; ist ++){
        headCell.push({ id: idOfuser.loc_data[ist].loc_name, label: idOfuser.loc_data[ist].loc_name});
       }
       
       return headCell;
  }

  const headCells = CreateheadCells();
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const CreateOneRow = (val) => {
      const RowAdder = [];
     // for(let i = 0; i < 10 ; i ++){
       let sd = [];
       sd['fname'] = '';
       sd['lname'] = '';
       sd['unit'] = '';
       sd['phone'] = '';
       sd['email'] = '';
       sd['expiry_date'] = '';
       sd['rfid_card'] = '';
       sd['id'] =  val;

       for(let ist = 0 ; ist < (idOfuser.custom_field).length ; ist ++){
        //let nameCode = idOfuser.custom_field[ist].name;
        sd[idOfuser.custom_field[ist].name] = '';
       }
      

       for(let ist = 0 ; ist < (idOfuser.loc_data).length ; ist ++){
        let nameCode = idOfuser.loc_data[ist].loc_code;
        sd[nameCode] = 0;
       }
      
       RowAdder.push(sd);
       //console.log (Object.assign({}, RowAdder));
     // }
       return RowAdder;

  }
  const CreateNewRow = (val) => {
   // const RowAdder = [];
   // for(let i = 0; i < 10 ; i ++){
     let sd = [];
     sd['fname'] = '';
     sd['lname'] = '';
     sd['unit'] = '';
     sd['phone'] = '';
     sd['email'] = '';
     sd['expiry_date'] = '';
     sd['rfid_card'] = '';
     sd['id'] =  val;
     for(let ist = 0 ; ist < (idOfuser.custom_field).length ; ist ++){
      //let nameCode = idOfuser.custom_field[ist].name;
      sd[idOfuser.custom_field[ist].name] = '';
     }
     for(let ist = 0 ; ist < (idOfuser.loc_data).length ; ist ++){
      let nameCode = idOfuser.loc_data[ist].loc_code;
      sd[nameCode] = 0;
     }
     //console.log (sd);
     //RowAdder.push(sd);
   // }
     return sd;

}
  
  
  const [currentInput,setCurrentInput] = useState();
  const [totalEntry,setTotalEntry] = useState(CreateOneRow(0));
  const [records,setRecords] = useState(CreateOneRow(0));
  const {
    TblContainer,
    TblHead,
    TblPagination,
    BelowTblPagination,
    recordsAfterPagingAndSorting,
    setPageToNewOne
} = useInnerTable(records, headCells, filterFn,10,650);
 /*useEffect(() => {
    console.log("readr image calling");
    if(currentInput != null){
      currentInput.target.focus();
    }
    
  }, [records]); */
  return  <>
  {currentDisableValue == false && <Box ><Typography sx={mainThemeStyle.lablefilter}>Add Users</Typography>
  
  <Box sx={{position: 'absolute',
           width:'680px',
           height: '280px',
           top:'160px',
           left: '38px',
           display: { xs: "none", md: "flex" },
           right: '38px',
           flexGrow: 1,
          
           }}>
                <Stack direction="row" spacing={2} >
<Typography></Typography>
           {(showCSVUpload == true && <Button  sx={mainThemeStyle.filterResetButton} variant="contained" component="label">Upload CSV File<input hidden accept=".csv"
        onChange={changeHandler} type="file" /></Button>)}     
         {(showCSVUpload == true && 
         <Button  sx={mainThemeStyle.filterResetButton} variant="contained" onClick={downloadClick}>Download Sample CSV File</Button>)}
        
       
           {(showCSVUpload == false &&  <LoadingButton sx={mainThemeStyle.filterResetButton} loading={loading} onClick={handleClearData}>Clear Data</LoadingButton>)}        
           {(showCSVUpload == false &&  <LoadingButton sx={mainThemeStyle.filterResetButton} loading={loading} onClick={handleSaveData}>Submit Data</LoadingButton>)}   
                  
                  
                 </Stack>
         
                  </Box>
                  <Box sx={mainThemeStyle.mainTableBelowbox} >
                  
                  <TblContainer >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >First Name</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >Last Name</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >Unit No.</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >Phone Number</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >Email Address</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >Exp. Date</TableCell>
            <TableCell align="left" sx={mainThemeStyle.cellHeader} >RFID Card #</TableCell>
            {idOfuser.custom_field.map(item => (
    
    <TableCell sx={mainThemeStyle.cellHeader}>
    {item.name}
    </TableCell>
   
))}
            {idOfuser.loc_data.map(item => (
    
              <TableCell sx={mainThemeStyle.cellHeader}>
              {item.loc_name}
              </TableCell>
             
          ))}
          </TableRow>
         
        </TableHead>
       
        <TableBody>
           
         {recordsAfterPagingAndSorting().map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
                <TableCell align="right"> <TextField
                id="text"
                type="text"
                name={"fname"+"-"+row.id}
                onChange={dataEntered}
                defaultValue={row.fname}
                variant="filled"
                sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'120px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        name={"lname"+"-"+row.id}
        onChange={dataEntered}
        defaultValue={row.lname}
        variant="filled"
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'120px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        name={"unit"+"-"+row.id}
        onChange={dataEntered}
        variant="filled"
        defaultValue={row.unit}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'50px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="number"
        name={"phone"+"-"+row.id}
        onChange={dataEntered}
        variant="filled"
        defaultValue={row.phone}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'150px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        name={"email"+"-"+row.id}
        onChange={dataEntered}
        sx={mainThemeStyle.InputMenuItem}
        defaultValue={row.email}
        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'200px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> 
        
        <TextField
        id="text"
        type="date"
        sx={mainThemeStyle.InputMenuItem}
        name={"expiry_date"+"-"+row.id}
        onChange={dataEntered}
        defaultValue={row.expiry_date}
        variant="filled"
        
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'150px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /> </TableCell>
      <TableCell align="right"> <TextField
        id="text"
        type="number"
        name={"rfid_card"+"-"+row.id}
        onChange={dataEntered}
        variant="filled"
        defaultValue={row.rfid_card}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'150px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
       {idOfuser.custom_field.map(item => (
    
    <TableCell sx={mainThemeStyle.cellHeader}>
   <TextField
        id="text"
        type={item.type}
        name={item.name+"-"+row.id}
        onChange={dataEntered}
        variant="filled"
        sx={mainThemeStyle.InputMenuItem}
        defaultValue={row[item.name]}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.9)',
                                height:'33px',
                                width:'150px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
    </TableCell>
   
))}
               {idOfuser.loc_data.map(item => (
    
    <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBox} name={item.loc_code+"-"+row.id} onChange={dataChecked} defaultChecked={
      row[item.loc_code]}/></TableCell>
   
   
))}
            </TableRow>
          ))}
        </TableBody> 

     {/*    {(showCSVData == true &&
        <TableBody>
           
         {values.map((value, index) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
                <TableCell align="right"> <TextField
        id="text"
        type="text"
       onChange={dataEntered}
       defaultValue={value[0]}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                              width:'120px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        onChange={dataEntered}
        defaultValue={value[1]}
        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                width:'120px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        onChange={dataEntered}
        defaultValue={value[2]}
        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                              width:'50px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        onChange={dataEntered}
        defaultValue={value[3]}
        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                              width:'150px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        onChange={dataEntered}
        defaultValue={value[4]}
        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                              width:'200px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
        <TableCell align="right"> <TextField
        id="text"
        type="text"
        onChange={dataEntered}
        defaultValue={ value[5]}
        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                              width:'150px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      {idOfuser.loc_data.map(item => (
    
    <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBox} onChange={dataEntered} defaultChecked={
      value[6+parseInt(item.count)] ? (value[6+parseInt(item.count)] != null ? (value[6+parseInt(item.count)] == "X" ? true : false) : false):false}/></TableCell>
   
   
))}
             
            </TableRow>
          ))}
        </TableBody> )} */}
     
    </TblContainer>
    <BelowTblPagination ></BelowTblPagination></Box>
                  </Box>}
                  {currentDisableValue == true && 
 <Box sx={mainThemeStyle.box} >
 <Typography sx={mainThemeStyle.lable}>Access For This Page Is Blocked</Typography>
 
  
  
  </Box>
    
    
    }
                  <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
          </> 
          
          
          
          
          
          
          ;
};

export default AddUser;