import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import ManageUsers from "./ManageUsers";
import AddDistributor from "./AddDistributor";
import ManageDistributor from "./ManageDistributor";
import EditDistributor from "./EditDistributor";
import DistributorManager from "./DistributorManager";
import DealerPassword from "./DealerPassword";
import DealerManager from "./DealerManager";
import DealerLogs from "./DealerLogs";
import DealerAddOrder from "./DealerAddOrder";
import { useParams,useNavigate,useLocation  } from "react-router-dom";
import { useEffect } from "react";
import DealerEditOrder from "./DealerEditOrder";
import ManageDealerOrder from "./ManageDealerOrder";

const DealerSetUp = (props) => {
  console.log("IN location Page");
  const navigate = useNavigate();
  const {id} = useParams();
  const {RefreshToken} = props;
  console.log('id is '+id);
  const theme = Theme;
  const location = useLocation();
  useEffect(()=>{
   
  },[id]);
 
  
 const handleAdd = () =>{
  navigate('../setup/manager');
 }
 const handleEdit = () =>{
  navigate('../setup/password');
}


  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    {(id === 'manager' &&  <Typography sx={mainThemeStyle.lable}>Dealer Manager</Typography>)}
    {(id === 'syslogs' &&  <Typography sx={mainThemeStyle.lable}>System Logs</Typography>)}
    {(id === 'neworder' &&  <Typography sx={mainThemeStyle.lable}>New Order</Typography>)}
    {(id === 'manageorder' &&  <Typography sx={mainThemeStyle.lable}>Order History</Typography>)}
    {(id === 'edit' &&  <Typography sx={mainThemeStyle.lable}> Order : {location.state.editorder.ordernumber}</Typography>)}
    
   {/*  {(id === 'password' &&  <Typography sx={mainThemeStyle.lable}>Password</Typography>)} */}
    
    </Box>
    
   {/*   {(id === 'manager' && <Button sx={mainThemeStyle.activityButton} onClick={handleEdit} >Password</Button>)}
   {(id === 'password' &&  <Button sx={mainThemeStyle.activityButton} onClick={handleAdd} >Dealer Manager</Button>)}
   */}  
    {(id === 'manager' && <DealerManager RefreshToken={RefreshToken}/>)}
    {(id === 'syslogs' && <DealerLogs RefreshToken={RefreshToken}/>)}
    {(id === 'neworder' && <DealerAddOrder RefreshToken={RefreshToken}/>)}
    {(id === 'manageorder' && <ManageDealerOrder RefreshToken={RefreshToken}/>)}
    {(id === 'edit' &&<DealerEditOrder idOfOrder={location.state.editorder} RefreshToken={RefreshToken} />)}
   {/*  {(id === 'password' && <DealerPassword RefreshToken={RefreshToken}/>)} */}
    </Box>
   


   
  
</ThemeProvider>);
};

export default DealerSetUp;