import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel,MenuItem,Select, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useInnerTable from "./components/useInnerTable";
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useToken from "./useToken";
import useTable from "./components/useTable";
import SvgIcon from "@mui/material/SvgIcon";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import NoImage from './asset/noimage.png';
import { MuiTelInput } from 'mui-tel-input'
import Popup from "./components/Popup";
import _ from "lodash";
import GlobalAlert from "./GlobalAlert"; 
import moment from "moment-timezone";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import axios from  "./axios";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default function AddLocation ( {idOflocation,RefreshToken,...props}){
  const {token ,setToken,clearToken} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const TimeZoneList = moment.tz.zonesForCountry('US');
  console.log("id of Location"+ idOflocation.loc_name);
  const[dataOfLocation,setDataOfLocation]  = useState(idOflocation);
  //console.log("id of location is "+idOflocation.cam_brand);
  const [uploadLocationData, setUploadLocationData] = useState('');
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentBridge, setCurrentBridge] = useState( "");	
  const [currentCamera, setCurrentCamera] = useState("");	
  const [currentBridgeId, setCurrentBridgeId] = useState( "");	
  const [currentCameraId, setCurrentCameraId] = useState("");	
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();
  const [currentBridgeType, setCurrentBridgeType] = useState("Dedicated");	
  const [currentCameraType, setCurrentCameraType] = useState("Entry");
  /*const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/addocation/28/",{
        headers: { 'Content-Type':  'application/json' },
      });
      console.log( "add location "+res.data);
      dataOfLocation.pdk_enable = res.data.pdk_enable;
      dataOfLocation.brivo_enable = res.data.brivo_enable;
      dataOfLocation.elevator_accesss_control = res.data.elevator_accesss_control;
      dataOfLocation.lpr_accesss = res.data.lpr_accesss;
      dataOfLocation.brivo_array = res.data.brivo_array;
      setDataOfLocation();

      
    } catch (error) {
      
    }
  };*/

  const getDisableValue = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("38").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("38").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("38").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("38").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("38").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("38").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("38").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("38").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const currentDisableValue = getDisableValue();

  const getBridgeData = () => {	
    let bridgeArray = [];	
    	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].location_id == "new"){	
        bridgeArray.push((dataOfLocation.bridge)[i]);	
          	
      }	
    }	
    return bridgeArray;	
  }	
  const getBridgeAddArray = () => {	
    let bridgeArray = [];	
    	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].location_id == "" || (dataOfLocation.bridge)[i].type == "1"){	
        bridgeArray.push((dataOfLocation.bridge)[i]);	
          	
      }	
    }	
    const strAscending = [...bridgeArray].sort((a, b) =>	
    a.name > b.name ? 1 : -1,	
  );	
    return strAscending;	
  }	
  const getCameraData = () => {	
    let cameraArray = [];	
   	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].location_id == "new"){	
        cameraArray.push((dataOfLocation.camera)[i]);	
          	
      }	
    }	
    return cameraArray;	
  }	
  const getCameraAddArray = () => {	
    let cameraArray = [];	
   	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].location_id == "" ){	
        cameraArray.push((dataOfLocation.camera)[i]);	
          	
      }	
    }	
    //console.log("camera add array"+JSON.stringify(dataOfLocation));	
    return cameraArray;	
  }	
  const [recordsBridge, setRecordsBridge] = useState(getBridgeData());	
  const [recordsCamera, setRecordsCamera] = useState(getCameraData());	
  const [addBridge,setAddBridge] = useState(getBridgeAddArray());	
  const [addCamera,setAddCamera] = useState(getCameraAddArray());	
  const handleBridgeDelete = (val) =>{	
   	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].id == val){	
        (dataOfLocation.bridge)[i].location_id = "";	
        (dataOfLocation.bridge)[i].type = "0";	
        console.log("dataOfLocation.type "+(dataOfLocation.bridge)[i].type);	
      }	
    }	
    setLocationForData();	
  }	
  const handleCameraDelete = (val,val2) =>{	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].id == val){	
        (dataOfLocation.camera)[i].location_id = "";	
        (dataOfLocation.camera)[i].type = "0";	
        console.log("dataOfLocation.type "+(dataOfLocation.camera)[i].type);	
      }	
    }	
    setLocationForData();	
  }
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  const [loading, setLoading] = React.useState(false);

  const [currentTab, setCurrentTab] = useState(0);
  const rowsHoleOpen =['1','2','3','4','5','6','7'];
  const rowsElevatorControl =[0,1,2,3,4,5,6,7];
  const [imageUrl, setImageUrl] = useState(NoImage);
  const handleTabClick =(val) =>{
    setCurrentTab(val);
  }
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  const [holdopenvar, setholdopenvar] = useState({holdopen:"0",indefinite:"1",definite:"0",finite:"0",expiry:"",time_on:"12:00 AM",time_off:"12:00 AM",relay1:"0",relay2:"0",table:[{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"},{"st":"12:00 AM","en":"12:00 AM","s":"0","m":"0","t":"0","w":"0","th":"0","f":"0","sa":"0","r1":"0","r2":"0"}]});
  const [elevatorvar, setelevatorvar] = useState({elevatorcontrol:"0",ipaddress:"",totalrelays:"",elduration:"",flduration:"",diselevator:"",disrelease:"",diselapp:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]});
  const [brivovar, setbrivovar] = useState({brivoprimeid:"",brivoprimename:"",brivofloors:"",program:[null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]});
  const [selectedTimezone, setSelectedTimezone] =useState(
    dataOfLocation.time_zone
  );
  const setLocationForData = () => {
    setshowUpdateButton(true);
    let loc_num_one = (dataOfLocation.loc_no).replaceAll(" ","");
    loc_num_one = loc_num_one.replace("+1","");
    setDataOfLocation({
      loc_name: dataOfLocation.loc_name,
      loc_no: dataOfLocation.loc_no,
      loc_photo: dataOfLocation.loc_photo,
      loc_code: dataOfLocation.loc_code,
      loc_search_code: dataOfLocation.loc_search_code,
      cam_brand: dataOfLocation.cam_brand,
      cam_ip: dataOfLocation.cam_ip,
      cam_rtsp: dataOfLocation.cam_rtsp,
      cam_username: dataOfLocation.cam_username,
      cam_password: dataOfLocation.cam_password,
      userid: dataOfLocation.userid,
      licence_key: dataOfLocation.licence_key,
      expirydate:dataOfLocation.expirydate,
      added_by:dataOfLocation.added_by,
      status:dataOfLocation.status,
      date_purchased: dataOfLocation.date_purchased,
      subscription: dataOfLocation.subscription,
      sub_id: dataOfLocation.sub_id,
      pin_length: dataOfLocation.pin_length,
      delivery_pin: dataOfLocation.delivery_pin,
      warning_phone: dataOfLocation.warning_phone,
      loc_property_name: dataOfLocation.loc_property_name,
      unlocking_mode: dataOfLocation.unlocking_mode,
      vegax_duration: dataOfLocation.vegax_duration,
      vegax_second_duration: dataOfLocation.vegax_second_duration,
      relay_one_fname: dataOfLocation.relay_one_fname,
      relay_two_fname: dataOfLocation.relay_two_fname,
      tablet_name: dataOfLocation.tablet_name,
      loc_office_name: dataOfLocation.loc_office_name,
      loc_add_one: dataOfLocation.loc_add_one,
      loc_add_two: dataOfLocation.loc_add_two,
      loc_telephone_dial: dataOfLocation.loc_telephone_dial,
      wel_message: dataOfLocation.wel_message,
      wel_message_header: dataOfLocation.wel_message_header,
      wel_message_headerlogo: dataOfLocation.wel_message_headerlogo,
      wel_message_headercolor : dataOfLocation.wel_message_headercolor,
      loc_url_watermark: dataOfLocation.loc_url_watermark,
      loc_refresh_rate: dataOfLocation.loc_refresh_rate,
      loc_screen_layout: dataOfLocation.loc_screen_layout,
      setting_reftesh_rate: dataOfLocation.setting_reftesh_rate,
      web_status: dataOfLocation.web_status,
      loc_zip_code: dataOfLocation.loc_zip_code,
      r_pin_off: dataOfLocation.r_pin_off,
      g_pin_off: dataOfLocation.g_pin_off,
      lkey_reset: dataOfLocation.lkey_reset,
      pin_blaacklist: dataOfLocation.pin_blaacklist,
      time_zone: dataOfLocation.time_zone,
      pin_start_time: dataOfLocation.pin_start_time,
      pin_end_time:dataOfLocation.pin_end_time,
      card_start_time: dataOfLocation.card_start_time,
      card_end_time:dataOfLocation.card_end_time,
      device_id: dataOfLocation.device_id,
      rebootday: dataOfLocation.rebootday,
      reboottime: dataOfLocation.reboottime,
      rfid_enable: dataOfLocation.rfid_enable,
      app_unlock_mode: dataOfLocation.app_unlock_mode,
      lpr_enable:dataOfLocation.lpr_enable,
      lpr_cam1: dataOfLocation.lpr_cam1,
      lpr_cam2: dataOfLocation.lpr_cam2,
      hold_open: dataOfLocation.hold_open,
      elevator_access: dataOfLocation.elevator_access,
      brivo_access: dataOfLocation.brivo_access,
      dis_off: dataOfLocation.dis_off,
      dis_dir: dataOfLocation.dis_dir,
      dis_doorpin: dataOfLocation.dis_doorpin,
      dis_del: dataOfLocation.dis_del,
      enable_exe_weigand: dataOfLocation.enable_exe_weigand,
      default_vega_state: dataOfLocation.default_vega_state,
      default_vega_state_2: dataOfLocation.default_vega_state_2,
      pdk_enable: dataOfLocation.pdk_enable,
      brivo_enable: dataOfLocation.brivo_enable,
      lpr_accesss: dataOfLocation.lpr_accesss,
      pdk_node: dataOfLocation.pdk_node,
      pdk_group: dataOfLocation.pdk_group,
      brivo_group_name: dataOfLocation.brivo_group_name,
      elevator_accesss_control: dataOfLocation.elevator_accesss_control,
      brivo_array:dataOfLocation.brivo_array,
      image_upload_data:dataOfLocation.image_upload_data,
      area_code:dataOfLocation.area_code,
      bridge:dataOfLocation.bridge,	
      camera:dataOfLocation.camera,
      dis_prox:dataOfLocation.dis_prox,
      latitude:dataOfLocation.latitude,
      longitude:dataOfLocation.longitude,
      realy_hostname:dataOfLocation.realy_hostname,
      issub:token.issub,
      subId:token.subid
      });
      setUploadLocationData({
        loc_name: dataOfLocation.loc_name,
        loc_no: loc_num_one,
        loc_photo: dataOfLocation.loc_photo,
        loc_code: dataOfLocation.loc_code,
        loc_search_code: dataOfLocation.loc_search_code,
        cam_brand: dataOfLocation.cam_brand,
        cam_ip: dataOfLocation.cam_ip,
        cam_rtsp: dataOfLocation.cam_rtsp,
        cam_username: dataOfLocation.cam_username,
        cam_password: dataOfLocation.cam_password,
        userid: dataOfLocation.userid,
        licence_key: dataOfLocation.licence_key,
        expirydate:dataOfLocation.expirydate,
        added_by:dataOfLocation.added_by,
        status:dataOfLocation.status,
        date_purchased: dataOfLocation.date_purchased,
        subscription: dataOfLocation.subscription,
        sub_id: dataOfLocation.sub_id,
        pin_length: dataOfLocation.pin_length,
        delivery_pin: dataOfLocation.delivery_pin,
        warning_phone: dataOfLocation.warning_phone,
        loc_property_name: dataOfLocation.loc_property_name,
        unlocking_mode: dataOfLocation.unlocking_mode,
        vegax_duration: dataOfLocation.vegax_duration,
        vegax_second_duration: dataOfLocation.vegax_second_duration,
        relay_one_fname: dataOfLocation.relay_one_fname,
        relay_two_fname: dataOfLocation.relay_two_fname,
        tablet_name: dataOfLocation.tablet_name,
        loc_office_name: dataOfLocation.loc_office_name,
        loc_add_one: dataOfLocation.loc_add_one,
        loc_add_two: dataOfLocation.loc_add_two,
        loc_telephone_dial: dataOfLocation.loc_telephone_dial,
        wel_message: dataOfLocation.wel_message,
        wel_message_header: dataOfLocation.wel_message_header,
        wel_message_headerlogo: dataOfLocation.wel_message_headerlogo,
        wel_message_headercolor : dataOfLocation.wel_message_headercolor,
        loc_url_watermark: dataOfLocation.loc_url_watermark,
        loc_refresh_rate: dataOfLocation.loc_refresh_rate,
        loc_screen_layout: dataOfLocation.loc_screen_layout,
        setting_reftesh_rate: dataOfLocation.setting_reftesh_rate,
        web_status: dataOfLocation.web_status,
        loc_zip_code: dataOfLocation.loc_zip_code,
        r_pin_off: dataOfLocation.r_pin_off,
        g_pin_off: dataOfLocation.g_pin_off,
        lkey_reset: dataOfLocation.lkey_reset,
        pin_blaacklist: dataOfLocation.pin_blaacklist,
        time_zone: dataOfLocation.time_zone,
        pin_start_time: dataOfLocation.pin_start_time,
        pin_end_time:dataOfLocation.pin_end_time,
        card_start_time: dataOfLocation.card_start_time,
      card_end_time:dataOfLocation.card_end_time,
        device_id: dataOfLocation.device_id,
        rebootday: dataOfLocation.rebootday,
        reboottime: dataOfLocation.reboottime,
        rfid_enable: dataOfLocation.rfid_enable,
        app_unlock_mode: dataOfLocation.app_unlock_mode,
        lpr_enable:dataOfLocation.lpr_enable,
        lpr_cam1: dataOfLocation.lpr_cam1,
        lpr_cam2: dataOfLocation.lpr_cam2,
        hold_open: JSON.stringify(dataOfLocation.hold_open),
        elevator_access: JSON.stringify(dataOfLocation.elevator_access),
        brivo_access: JSON.stringify(dataOfLocation.brivo_access),
        dis_off: dataOfLocation.dis_off,
        dis_dir: dataOfLocation.dis_dir,
        dis_doorpin: dataOfLocation.dis_doorpin,
        dis_del: dataOfLocation.dis_del,
        enable_exe_weigand: dataOfLocation.enable_exe_weigand,
        default_vega_state: dataOfLocation.default_vega_state,
        default_vega_state_2: dataOfLocation.default_vega_state_2,
        pdk_enable: dataOfLocation.pdk_enable,
        brivo_enable: dataOfLocation.brivo_enable,
        lpr_accesss: dataOfLocation.lpr_accesss,
        pdk_node: dataOfLocation.pdk_node,
        pdk_group: dataOfLocation.pdk_group,
        brivo_group_name: dataOfLocation.brivo_group_name,
        elevator_accesss_control: dataOfLocation.elevator_accesss_control,
        image_upload_data:dataOfLocation.image_upload_data,
        area_code:dataOfLocation.area_code,
        bridge:dataOfLocation.bridge,	
        camera:dataOfLocation.camera,
        dis_prox:dataOfLocation.dis_prox,
        latitude:dataOfLocation.latitude,
        longitude:dataOfLocation.longitude,
        realy_hostname:dataOfLocation.realy_hostname,
        issub:token.issub,
        subId:token.subid
        });
        setRecordsBridge(getBridgeData());	
        setRecordsCamera(getCameraData());	
        setAddBridge(getBridgeAddArray());	
        setAddCamera(getCameraAddArray());	
        setCurrentBridge("");	
        setCurrentCamera("");
  }
  const [value, setValue] = useState("");
  const [info, setInfo] = useState(null);
  const DisplayedICon = {
    id: "1",
    label: 'Message Alert',
    items: [
      {
        label: 'Message Alert',
        callback: () =>  {}
      },
      {
        label: 'Bell Alert',
        callback: () =>  {}
      },
      {
        label: 'Info Alert',
        callback: () =>  {}
      },
      {
        label: 'Alert Lights',
        callback: () =>  {}
      },
      {
        label: 'Stop',
        callback: () =>  {}
      },
      {
        label: 'Announcement',
        callback: () =>  {}
      },
      {
        label: 'Hello',
        callback: () =>  {}
      },
      {
        label: 'Festive',
        callback: () =>  {}
      }
      ,
      {
        label: 'Maintenance',
        callback: () =>  {}
      }
    ]
  }

  const handleSetDisplayLogo = (event) => {
    
    if(event.target.value == 'Message Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/1028/1028741.png';
      setLocationForData();
    }else  if(event.target.value == 'Bell Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2058/2058148.png';
      setLocationForData();
    }else  if(event.target.value == 'Info Alert'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2538/2538026.png';
      setLocationForData();
    }else  if(event.target.value == 'Alert Lights'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/9069/9069950.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Stop'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/2698/2698545.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Announcement'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/8303/8303231.png';
      setLocationForData();
    }
    else  if(event.target.value == 'Hello'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/5129/5129597.png';
      setLocationForData();
    }
    else if(event.target.value == 'Festive'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/864/864763.png';
      setLocationForData();
    }
    else if(event.target.value == 'Maintenance'){
      dataOfLocation.wel_message_headerlogo = 'https://cdn-icons-png.flaticon.com/512/8956/8956124.png';
      setLocationForData();
    }
  }
  const getDisplayedLogo = () => {
    if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/1028/1028741.png'){
      return 'Message Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2058/2058148.png'){
      return 'Bell Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2538/2538026.png'){
      return 'Info Alert';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/9069/9069950.png'){
      return 'Alert Lights';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/2698/2698545.png'){
      return 'Stop';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/8303/8303231.png'){
      return 'Announcement';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/5129/5129597.png'){
      return 'Hello';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/864/864763.png'){
      return 'Festive';
    }
    else if(dataOfLocation.wel_message_headerlogo == 'https://cdn-icons-png.flaticon.com/512/8956/8956124.png'){
      return 'Maintenance';
    }
    else{
      return 'Message Alert';
    }
  }
  const handleChange = (newValue, info) => {
    dataOfLocation.loc_no = newValue;
    //setValue(newValue);
    //setInfo(info);
    setLocationForData();
  };
  const CameraBrand = {
    id: "1",
    label: "ResidentLink",
    items: [
      {
        label: "ResidentVue",
        callback: () =>  {
          dataOfLocation.cam_brand = "ResidentLink";
          setLocationForData();
        }
      },
      {
        label:"Virtual Panel",
        callback: () =>  {
          dataOfLocation.cam_brand = "Virtual Panel";
          setLocationForData();
        }
      },
     /* {
        label: "AXIS",
        callback: () =>  {
          dataOfLocation.cam_brand = "AXIS";
          setLocationForData();
        }
      },*/
      {
        label: "ACTi",
        callback: () =>  {
          dataOfLocation.cam_brand = "ACTi";
          setLocationForData();
        }
      },
      {
        label: "Hikvision",
        callback: () =>  {
          dataOfLocation.cam_brand = "Hikvision";
          setLocationForData();
        }
      },
      /*{
        label: "Nortek",
        callback: () =>  {
          dataOfLocation.cam_brand = "Nortek";
          setLocationForData();
        }
      },*/
      {
        label: "Sunell",
        callback: () =>  {
          dataOfLocation.cam_brand = "Sunell";
          setLocationForData();
        }
      },
      
    ]
  }
  const CamType = {	
    id: "1",	
    label: "Entry",	
    items: [	
      {	
        label: "Entry",	
        callback: () =>  {}	
      },	
      {	
        label: "Exit",	
        callback: () =>  {}	
      },	
    ]	
  }	
  const BriType = {	
    id: "1",	
    label: "Dedicated",	
    items: [	
      {	
        label: "Dedicated",	
        callback: () =>  {}	
      },	
      {	
        label: "Shared",	
        callback: () =>  {}	
      },	
    ]	
  }
  const GetValueForCameraBrand = () => {
    if(dataOfLocation.cam_brand == "ResidentLink"){
      return "ResidentVue";
    }
    else if(dataOfLocation.cam_brand == "Virtual Panel"){
      return "Virtual Panel";
    }
    else if(dataOfLocation.cam_brand == "Sunell"){
      return "Sunell";
    }
    else if(dataOfLocation.cam_brand == "AXIS"){
      return "AXIS";
    }
    else if(dataOfLocation.cam_brand == "Nortek"){
      return "Nortek";
    }
    else if(dataOfLocation.cam_brand == "Hikvision"){
      return "Hikvision";
    }else{
      return dataOfLocation.cam_brand;
    }
  }
  const handleChangeCameraBrand = (event) => {
    if(event.target.value == "ResidentVue") {
      dataOfLocation.cam_brand = "ResidentLink";
      setLocationForData();
    }else{
      dataOfLocation.cam_brand = event.target.value;
      setLocationForData();
    }
  }
  const handleInputChange=(event) =>{
    console.log(dataOfLocation);
   // setshowUpdateButton(true);
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.value;
    setLocationForData();
  }
  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "#1E1E1E",
          borderRadius: 0,
          maxHeight:'250px',
          borderRadius: 10,
          padding:0
      },
    },
  };
  const BootDay = {
    id: "1",
    label: "Monday",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "Monday",
        callback: () =>  {}
      },
      {
        label: "Tuesday",
        callback: () =>  {}
      },
      {
        label: "Wednesday",
        callback: () =>  {}
      },
      {
        label: "Thursday",
        callback: () =>  {}
      },
      {
        label: "Friday",
        callback: () =>  {}
      },
      {
        label: "Saturday",
        callback: () =>  {}
      },
      {
        label: "Sunday",
        callback: () =>  {}
      }
    ]
  }
  const BootTime = {
    id: "1",
    label: "00:00",
    items: [
      {
        label: "None",
        callback: () =>  {}
      },
      {
        label: "12:00 AM",
        callback: () =>  {}
      },
      {
        label: "12:30 AM",
        callback: () =>  {}
      },
      {
        label:  "01:00 AM",
        callback: () =>  {}
      },
      {
        label:  "01:30 AM",
        callback: () =>  {}
      },
      {
        label: "02:00 AM",
        callback: () =>  {}
      },
      {
        label: "02:30 AM",
        callback: () =>  {}
      },
      {
        label: "03:00 AM",
        callback: () =>  {}
      },
      {
        label: "03:30 AM",
        callback: () =>  {}
      },
      {
        label: "04:00 AM",
        callback: () =>  {}
      },
      {
        label: "04:30 AM",
        callback: () =>  {}
      },
      {
        label: "05:00 AM",
        callback: () =>  {}
      },
      {
        label: "05:30 AM",
        callback: () =>  {}
      },
      {
        label:  "06:00 AM",
        callback: () =>  {}
      },
      {
        label: "06:30 AM",
        callback: () =>  {}
      },
      {
        label: "07:00 AM",
        callback: () =>  {}
      },
      {
        label: "07:30 AM",
        callback: () =>  {}
      },
      {
        label: "08:00 AM",
        callback: () =>  {}
      },
      {
        label: "08:30 AM",
        callback: () =>  {}
      },
      {
        label: "09:00 AM",
        callback: () =>  {}
      },
      {
        label: "09:30 AM",
        callback: () =>  {}
      },
      {
        label: "10:00 AM",
        callback: () =>  {}
      },
      {
        label: "10:30 AM",
        callback: () =>  {}
      },
      {
        label: "11:00 AM",
        callback: () =>  {}
      },
      {
        label: "11:30 AM",
        callback: () =>  {}
      },
      {
        label: "12:00 PM",
        callback: () =>  {}
      },
      {
        label: "12:30 PM",
        callback: () =>  {}
      },
      {
        label: "01:00 PM",
        callback: () =>  {}
      },
      {
        label: "01:30 PM",
        callback: () =>  {}
      },
      {
        label: "02:00 PM",
        callback: () =>  {}
      },
      {
        label: "02:30 PM",
        callback: () =>  {}
      },
      {
        label: "03:00 PM",
        callback: () =>  {}
      },
      {
        label: "03:30 PM",
        callback: () =>  {}
      },
      {
        label: "04:00 PM",
        callback: () =>  {}
      },
      {
        label: "04:30 PM",
        callback: () =>  {}
      },
      {
        label: "05:00 PM",
        callback: () =>  {}
      },
      {
        label: "05:30 PM",
        callback: () =>  {}
      },
      {
        label: "06:00 PM",
        callback: () =>  {}
      },
      {
        label: "06:30 PM",
        callback: () =>  {}
      },
      {
        label: "07:00 PM",
        callback: () =>  {}
      },
      {
        label: "07:30 PM",
        callback: () =>  {}
      },
      {
        label: "08:00 PM",
        callback: () =>  {}
      },
      {
        label: "08:30 PM",
        callback: () =>  {}
      },
      {
        label: "09:00 PM",
        callback: () =>  {}
      },
      {
        label: "09:30 PM",
        callback: () =>  {}
      },
      {
        label: "10:00 PM",
        callback: () =>  {}
      },
      {
        label: "10:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:00 PM",
        callback: () =>  {}
      },
      {
        label: "11:30 PM",
        callback: () =>  {}
      },
      {
        label: "11:59 PM",
        callback: () =>  {}
      }
    ]
  }
  const headCellsBrige = [	
    { id: 'name', label: 'Friendly Bridge Name' },	
    { id: 'bridge_id', label: 'Bridge Type'},	
    { id: 'action', label: 'Action', disableSorting: true },	
  ];	
  const headCellsCamera = [	
    { id: 'name', label: 'Friendly Camera Name' },	
    { id: 'camera_id', label: 'Camera Type'},	
    { id: 'action', label: 'Action', disableSorting: true },	
  ];	
  const [filterFnBridge, setFilterFnBridge] = useState({ fn: items => { return items; } });	
  const [filterFnCamera, setFilterFnCamera] = useState({ fn: items => { return items; } });	
  const {	
    TblContainer : TblContainerBridge,	
    TblHead :  TblHeadBridge,	
    TblPagination : TblPaginationBridge,	
    BelowTblPagination : BelowTblPaginationBridge,	
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingBridge,	
    setPageToNewOne : setPageToNewOneBridge	
  } = useInnerTable(recordsBridge, headCellsBrige, filterFnBridge,5,350);	
  const {	
    TblContainer : TblContainerCamera,	
    TblHead :  TblHeadCamera,	
    TblPagination : TblPaginationCamera,	
    BelowTblPagination : BelowTblPaginationCamera,	
    recordsAfterPagingAndSorting : recordsAfterPagingAndSortingCamera,	
    setPageToNewOne : setPageToNewOneCamera	
  } = useInnerTable(recordsCamera, headCellsCamera, filterFnCamera,5,350);
  const handleChangeBoot = (event) => {
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.value;
    setLocationForData();
  }
  const ScreenLayout = {
    id: "1",
    label: "Portrait",
    items: [
      {
        label: "Portrait",
        callback: () =>  {}
      },
      {
        label: "Landscape",
        callback: () =>  {}
      },
    ]
  }
  
  const GetValueOfLocationScreenLayout = (val) => {
    if(val == "1"){
      return "Portrait";
    }else{
      return  "Landscape";
    }
  }
  const ChangeScreenLayout = (event) => {
    if(event.target.value == "Portrait") {
      dataOfLocation.loc_screen_layout = "1";
      setLocationForData();
    }else{
      dataOfLocation.loc_screen_layout = '0';
      setLocationForData();
    }
  }
  const handlecheckboxCheck=(event) =>{
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.checked == true ? "1" : "0";
   // setshowUpdateButton(true);
   setLocationForData();
  }
  const handlecheckboxCheckReverse=(event) =>{
    let ds = event.target.name;
    dataOfLocation[ds] = event.target.checked == true ? "0" : "1";
   // setshowUpdateButton(true);
   setLocationForData();
  }
  const UnlcokingMode = {
    id: "1",
    label: "BRIVO",
    items: [
      {
        label: "BRIVO",
        callback: () =>  {}
      },
      {
        label: "USB",
        callback: () =>  {}
      },
      {
        label: "GPIO",
        callback: () =>  {}
      },
      {
        label: "ResidentVue",
        callback: () =>  {}
      },
    ]
  }
  const UnlockingModeQR = {
    id: "1",
    label: "Web Relay",
    items: [
      {
        label: "Web Relay",
        callback: () =>  {}
      },
      {
        label: "BRIVO",
        callback: () =>  {}
      },]
  }
  const GetUnlockingModeQR = () =>{
    if(dataOfLocation.unlocking_mode == '1'){
      return  "BRIVO";
    }else{
      return  "Web Relay";
    }
  }
  const handleSetUnlockingModeQR = (event) => {
    if(event.target.value == 'BRIVO'){
      dataOfLocation.unlocking_mode = '1'
      setLocationForData();
    }else{
      dataOfLocation.unlocking_mode = '0'
      setLocationForData();
    }
  }
 
  const GetUnlockingMode = () =>{
    if(dataOfLocation.unlocking_mode == '1'){
      return  "GPIO";
    }else  if(dataOfLocation.unlocking_mode == '2'){
      return  "ResidentVue";
    }else  if(dataOfLocation.unlocking_mode == '3'){
      return  "BRIVO";
    }else{
      return  "USB";
    }
  }
  const handleSetUnlockingMode = (event) => {
    if(event.target.value == 'GPIO'){
      dataOfLocation.unlocking_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'ResidentVue'){
      dataOfLocation.unlocking_mode = '2'
      setLocationForData();
    }else  if(event.target.value == 'BRIVO'){
      dataOfLocation.unlocking_mode = '3'
      setLocationForData();
    }else{
      dataOfLocation.unlocking_mode = '0'
      setLocationForData();
    }
  }
  const PinLengthHolder = {
    id: "1",
    label: "4",
    items: [
      {
        label: "4",
        callback: () =>  {}
      },
      {
        label: "6",
        callback: () =>  {}
      }
    ]
  }
  const AppUserUnlockMode = {
    id: "1",
    label: "ResidentVue Push Notification",
    items: [
      {
        label: "ResidentVue Push Notification",
        callback: () =>  {}
      },
      {
        label: "Unattended Unlock",
        callback: () =>  {}
      },
      {
        label: "Disable User Swipe Unlock",
        callback: () =>  {}
      }
    ]
  }
  const AppUserUnlockModeCBW = {
    id: "1",
    label: "Unattended Unlock",
    items: [
      {
        label: "Unattended Unlock",
        callback: () =>  {}
      },
      {
        label: "Disable User Swipe Unlock",
        callback: () =>  {}
      }
    ]
  }
  const GetAppUnlockModeCBW = () => {
    if(dataOfLocation.app_unlock_mode == '2'){
      return  "Disable User Swipe Unlock";
    }else{
      return  "Unattended Unlock";
    }
  }
  const GetAppUnlockMode = () => {
    if(dataOfLocation.app_unlock_mode == '1'){
      return  "Unattended Unlock";
    }else  if(dataOfLocation.app_unlock_mode == '2'){
      return  "Disable User Swipe Unlock";
    }else{
      return  "ResidentVue Push Notification";
    }
  }
  const handleSetAppUnlockModeCBW = (event) => {
    console.log(dataOfLocation);
    console.log(selectedTimezone.value);
    if(event.target.value == 'Unattended Unlock'){
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'Disable User Swipe Unlock'){
      dataOfLocation.app_unlock_mode = '2'
      setLocationForData();
    }else{
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }
  }
  const handleSetAppUnlockMode = (event) => {
    console.log(dataOfLocation);
    console.log(selectedTimezone.value);
    if(event.target.value == 'Unattended Unlock'){
      dataOfLocation.app_unlock_mode = '1'
      setLocationForData();
    }else  if(event.target.value == 'Disable User Swipe Unlock'){
      dataOfLocation.app_unlock_mode = '2'
      setLocationForData();
    }else{
      dataOfLocation.app_unlock_mode = '0'
      setLocationForData();
    }
  }
  const handleChangeEventOfHoldOpen = (event) => {
    //setEnableHoldOpen(event.target.checked);
    if(dataOfLocation.hold_open["holdopen"] === undefined){
    console.log("In not available");
    holdopenvar.holdopen = event.target.checked ? "1" : "0";
    setholdopenvar(holdopenvar);
    dataOfLocation.hold_open = holdopenvar;
    }else{
      console.log("In  available");
      console.log("In  available and event target"+ event.target.checked);
      dataOfLocation.hold_open.holdopen = event.target.checked ? "1" : "0";
    }
    //console.log(dataOfLocation);
    setLocationForData();
  };

  const handleIndefiniteRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open[ds] = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleOneTimeValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open[ds] = event.target.value ;
    setLocationForData();
  }
  const handleChangeOpenStart=(event)=>{
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].st = event.target.value;
    setLocationForData();
  }
  const handleChangEndStart=(event)=>{
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].en = event.target.value;
    setLocationForData();
  }
  const handleSundayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].s = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleMondayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].m = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleTuesdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].t = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleWednesdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].w = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleThursdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].th = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleFridayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].f = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleSaturdayRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].sa = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleHoldRelayOne = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].r1 = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleHoldRelayTwo = (event) => {
    let ds = event.target.name;
    dataOfLocation.hold_open.table[parseInt(ds)].r2 = event.target.checked ? "1" : "0" ;
    setLocationForData();
  }
  const handleChangeValueOfHoldOpen = (event) => {
    let ds = event.target.name;
    if( ds == "indefinite"){
        dataOfLocation.hold_open.indefinite ="1" ;
        dataOfLocation.hold_open.definite = "0" ;
        dataOfLocation.hold_open.finite =  "0";
      }
    else if(ds == "definite"){
        dataOfLocation.hold_open.indefinite ="0" ;
        dataOfLocation.hold_open.definite = "1" ;
        dataOfLocation.hold_open.finite =  "0";
      }
    else if (ds == "finite"){
        dataOfLocation.hold_open.indefinite ="0" ;
        dataOfLocation.hold_open.definite = "0" ;
        dataOfLocation.hold_open.finite =  "1";
    }
    console.log(dataOfLocation);
    setLocationForData();
  };

  const handleChangeBrivoDoorValue = (event) => {
    if(dataOfLocation.brivo_access["brivoprimeid"] === undefined){
      let brivoarr = dataOfLocation.brivo_array;
      brivovar.brivofloors = brivoarr.length;
      brivoarr.forEach(element => {
        if(element.name == event.target.value){
          brivovar.brivoprimeid = element.id;
        }
      });
      setbrivovar(brivovar);
      dataOfLocation.brivo_access = brivovar;
      setLocationForData();
    }else{
      let brivoarr = dataOfLocation.brivo_array;
      brivoarr.forEach(element => {
        if(element.name == event.target.value){
          dataOfLocation.brivo_access.brivoprimeid = element.id;
        }
      });
      setLocationForData();
    }
  }
  const handleChangeBrivoRelay = (event) => {
    let ds = event.target.name;
    let brivoarr = dataOfLocation.brivo_array;
      brivoarr.forEach(element => {
        if(element.name == event.target.value){
          dataOfLocation.brivo_access.program[parseInt(ds)] = element.id;
        }
      });
    setLocationForData();
  }
  const getBrivoValueMain = () => {
    let nameOfunit;
    let brivoarr = dataOfLocation.brivo_array;
    
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.brivoprimeid){
        nameOfunit = element.name;
      }
    });
    return nameOfunit;
  }
  const getBrivoValue = (idOfunit) => {
    let nameOfunit;
    let brivoarr = dataOfLocation.brivo_array;
   // let ads = JSON.stringify(dataOfLocation.brivo_access);
    console.log(dataOfLocation.brivo_access.program);
    brivoarr.forEach(element => {
      if(element.id == dataOfLocation.brivo_access.program[idOfunit]){
        nameOfunit = element.name;
      }
    });
    return nameOfunit;
  }
  const handleChangeBridge = (event) => {	
    setCurrentBridge( event.target.value);	
    let idOdunit ;	
    dataOfLocation.bridge.forEach(element => {	
      if(element.name == event.target.value){	
        idOdunit = element.id;	
      }	
    });	
    setCurrentBridgeId(idOdunit);	
  }	
  const handleChangeCameraType = (event) => {	
    setCurrentCameraType( event.target.value);	
  }	
  const handleChangeBridgeType = (event) => {	
    setCurrentBridgeType( event.target.value);	
  }	
  const handleAddBridge = () => {	
    if(currentBridge == ""){	
      setOpenSnackData("Please Select Bridge And Try Again.");	
      setSeverityData("error");	
      setOpenSnack(true);	
    }	
    else if(currentBridgeType == ""){	
      setOpenSnackData("Please Select Bridge Type And Try Again.");	
     setSeverityData("error");	
     setOpenSnack(true);	
    }	
    console.log("add before called" +currentBridge);	
    for(let i = 0; i < (dataOfLocation.bridge).length ; i++){	
      if((dataOfLocation.bridge)[i].id == currentBridgeId){	
        (dataOfLocation.bridge)[i].location_id = "new";	
        console.log("dataOfLocation.bridge "+(dataOfLocation.bridge)[i].location_id);	
        if((dataOfLocation.bridge)[i].type  != "1")	
        (dataOfLocation.bridge)[i].type = currentBridgeType == "Dedicated" ? "0" : "1";	
        console.log("dataOfLocation.type "+(dataOfLocation.bridge)[i].type);	
      }	
    }	
    console.log("add called");	
    setLocationForData();	
  }	
  const handleAddCamera = () => {	
    if(currentCamera == ""){	
      setOpenSnackData("Please Select Camera And Try Again.");	
      setSeverityData("error");	
      setOpenSnack(true);	
    }	
    else if(currentCameraType == ""){	
      setOpenSnackData("Please Select Camera Type And Try Again.");	
     setSeverityData("error");	
     setOpenSnack(true);	
    }	
    console.log("add before called" +currentBridge);	
    for(let i = 0; i < (dataOfLocation.camera).length ; i++){	
      if((dataOfLocation.camera)[i].id == currentCameraId){	
        (dataOfLocation.camera)[i].location_id = "new";	
        console.log("dataOfLocation.bridge "+(dataOfLocation.camera)[i].location_id);	
        (dataOfLocation.camera)[i].type = currentCameraType == "Entry" ? "0" : "1";	
        console.log("dataOfLocation.type "+(dataOfLocation.camera)[i].type);	
      }	
    }	
    console.log("add called");	
    setLocationForData();	
  }	
  const handleChangeCamera = (event) => {	
    setCurrentCamera( event.target.value);	
    let idOdunit ;	
    dataOfLocation.camera.forEach(element => {	
      if(element.name == event.target.value){	
        idOdunit = element.id;	
      }	
    });	
    setCurrentCameraId(idOdunit);	
  }
  const handleChangeEleInnerRelay = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access.program[parseInt(ds)] = event.target.value ;
    setLocationForData();
  }
  const handleChangeEleValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access[ds] = event.target.value;
    setLocationForData();
  };
  const handleChangeEleCheckValue = (event) => {
    let ds = event.target.name;
    dataOfLocation.elevator_access[ds] = event.target.checked ? "1" : "0";
    setLocationForData();
  };
  const handleChangeEventOfElevatorControl = (event) => {
    //setEnableElevatorControl(event.target.checked);
    if(dataOfLocation.elevator_access["elevatorcontrol"] === undefined){
    console.log("In not available");
    elevatorvar.elevatorcontrol = event.target.checked ? "1" : "0";
    setelevatorvar(elevatorvar);
    dataOfLocation.elevator_access = elevatorvar;
    }else{
      console.log("In  available");
      console.log("In  available and event target"+ event.target.checked);
      dataOfLocation.elevator_access.elevatorcontrol = event.target.checked ? "1" : "0";
    }
    //console.log(dataOfLocation);
    setLocationForData();
  };
  const updateMyPostData = async () => {
    console.log("In update post data");
    try {
      const res = await axios.post("/admin/addlocation/"+token.id,uploadLocationData, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('ALLAS'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
       setDataOfLocation(idOflocation); 
        setCurrentTab(0);
        setImageUrl(NoImage);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('ALLAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }

    }
  };
  const handleUpdateCancel = () => {
    setDataOfLocation(idOflocation);
      setCurrentTab(0);
      setImageUrl(NoImage);
  }
  const handleUpdateLocation = () => {
    console.log(uploadLocationData);
    setLoading(true);
    let locationname = dataOfLocation.loc_name;
    locationname = locationname.replaceAll(" ","");
    if(locationname == ""){
      console.log("location name is null");
      setOpenSnackData(getAlertTitle('ALLNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    
    
    if(dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel"){
      let locationcode = dataOfLocation.loc_code;
      locationcode = locationcode.replaceAll(" ","");
    if(locationcode == ""){
      setOpenSnackData(getAlertTitle('ALLDNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    
    let camip = dataOfLocation.cam_ip;
    camip = camip.replaceAll(" ","");
    if(camip == ""){
      setOpenSnackData(getAlertTitle('ALLCINE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    
    /* if(dataOfLocation.cam_brand == "Virtual Panel"){
      let delivery_pin = dataOfLocation.delivery_pin;
    delivery_pin = delivery_pin.replaceAll(" ","");
    if(delivery_pin == ""){
      console.log("delivery pin can't be null");
      setOpenSnackData(getAlertTitle('ALLDPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(delivery_pin.length != parseInt(dataOfLocation.pin_length)){
      if(parseInt(dataOfLocation.pin_length) == 6){
        setOpenSnackData(getAlertTitle('ALLDP6NV'));
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }else{
      setOpenSnackData(getAlertTitle('ALLDP4NV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    }
  } */
    let loc_no = dataOfLocation.loc_no;
    loc_no = loc_no.replaceAll(" ","");
    if(loc_no == ""){
      console.log("location Telephone can't be empty");
      setOpenSnackData(getAlertTitle('ALLTNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    else if (loc_no.length < 10){
      setOpenSnackData(getAlertTitle('ALLTNV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
  }
  else{
    let delivery_pin = dataOfLocation.delivery_pin;
    delivery_pin = delivery_pin.replaceAll(" ","");
    if(delivery_pin == ""){
      console.log("delivery pin can't be null");
      setOpenSnackData(getAlertTitle('ALLDPNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(delivery_pin.length != parseInt(dataOfLocation.pin_length)){
      if(parseInt(dataOfLocation.pin_length) == 6){
        setOpenSnackData(getAlertTitle('ALLDP6NV'));
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }else{
      setOpenSnackData(getAlertTitle('ALLDP4NV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    }
    let area_code = dataOfLocation.area_code;
    area_code= area_code.replaceAll(" ","");
    if(area_code == ""){
     
      setOpenSnackData('Please Enter Location Area Code');
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    if(area_code.length != 3){
    
      setOpenSnackData('Location Area Code Should Be 3 Digit Only.');
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    
    }
    if(dataOfLocation.cam_brand == "Virtual Panel" && dataOfLocation.unlocking_mode != '1'){
      let urls = dataOfLocation.realy_hostname;
      if(!urls.match("api.controlbyweb.cloud/DAT/")){
        setOpenSnackData('Relay Host Url Should Be Like This api.controlbyweb.cloud/DAT/api-key, Other Url Is Not Valid.');
        setSeverityData("error");
        setOpenSnack(true);
        setLoading(false);
        return;
      }
    }
  }

  let image_upload_data = dataOfLocation.image_upload_data;
  image_upload_data = image_upload_data.replaceAll(" ","");
  if(image_upload_data == ""){
    setOpenSnackData(getAlertTitle('ALLTPNG'));
    setSeverityData("error");
    setOpenSnack(true);
    setLoading(false);
    return;
  }

    updateMyPostData();
  }
  //const [selectionItem, setSelectionItem] = useState([]);
  const SelectionMenu = {
    id: "1",
    label: "General",
    items: [
      {
        label: "General",
        callback: () =>  {handleTabClick(0)}
       
       
      },
      {
        label: "Panel Settings",
        callback: () =>  {handleTabClick(1)}
      },
      {
        label: "Relay Settings",
        callback: () => {handleTabClick(2)}
      },
      {
        label: "LPR",
        callback: () => {handleTabClick(3)}
      },
      {
        label: "Hold Open",
        callback: () => {handleTabClick(4)}
      },
      {
        label: "Elevator Control",
        callback: () => {handleTabClick(5)}
      },
      {
        label: "Brivo Control",
        callback: () => {handleTabClick(6)}
      },
    ]
  };
  return <> 
 {currentDisableValue == false &&  <Box sx={mainThemeStyle.boxTopHeader} >
 {(currentTab == 0 && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(0)}} active>General</Button>
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    }
    {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    }
     {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    }
     {dataOfLocation.cam_brand == "Virtual Panel" &&  dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>	
    }
    {/* {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button>
    } */}
    {dataOfLocation.cam_brand == "ResidentLink" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>
    }
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>
    }
  </Stack>)}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
   {/*  <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button> */}
   
  </Stack>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
    {/* <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button> */}
   
  </Stack>)}
  {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
    {/* <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button> */}
   
  </Stack>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">	
    <Typography></Typography>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>	
    { dataOfLocation.lpr_accesss == '1' &&	
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>LPR</Button>}
    	
  </Stack>)}
 {/*  {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>QR Code</Button>
   
  </Stack>)} */}
 
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    { dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Stack>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} >General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Stack>)}
  {(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Stack>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Stack>)}
  {(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Stack>)}
  {(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}} active>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Stack>)}
            
 </Box> }
 {currentDisableValue == false &&  <Box sx={{position: 'absolute',
           width:'auto',
          
           top:'100px',
           left: '10px',
           display: { xs: "flex", md: "none" },
           right: '10px',
           flexGrow: 1,
          
           }}>
  {(currentTab == 0 && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
    <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(0)}} active>General</Button></Grid>
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "Virtual Panel" && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button>	
    </Grid>} 
   {/*  {dataOfLocation.cam_brand == "Virtual Panel" && 
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button>
    } */}
    {dataOfLocation.cam_brand == "ResidentLink" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>
    </Grid>}
    {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>
    </Grid>}
  </Grid>)}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
    { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button> </Grid>
    */}
   </Grid>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
    { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button></Grid>
    */}
     </Grid>)}
     {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item >
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>
    { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button></Grid>
    */}
     </Grid>)}
     {(currentTab == 4 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">	
  <Grid item >	
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>	
    <Grid item > <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button></Grid>	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>	
    <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>Hold Open</Button></Grid>	
    { dataOfLocation.lpr_accesss == '1' && <Grid item>	
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>LPR</Button></Grid>}	
    {/* <Grid item ><Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(3)}}>QR Code</Button></Grid>	
    */}	
     </Grid>)}
  {/* {(currentTab == 3 &&  dataOfLocation.cam_brand == "Virtual Panel" && <Stack spacing={2} direction="row">
    <Typography></Typography>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(0)}}>General</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(1)}}>Virtual Panel</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactive} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    <Button sx={mainThemeStyle.buttonHeaderGroup} onClick={()=> {handleTabClick(3)}}>QR Code</Button>
   
  </Stack>)} */}
  {(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}}>General</Button></Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button></Grid>
    <Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button></Grid>
    { dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button></Grid>}
     {dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button></Grid>}
  </Grid>)}
  {(currentTab == 2 &&  dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} >General</Button></Grid>
   <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
  </Grid>  {dataOfLocation.lpr_accesss == '1' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button></Grid>}
     {dataOfLocation.unlocking_mode == '2' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button> </Grid>}
     {dataOfLocation.unlocking_mode == '3' && <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button> </Grid>}
  </Grid>)}
  {(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">
    
  <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
  </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
  </Grid><Grid item>{dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
   </Grid><Grid item> {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
   </Grid><Grid item> {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid></Grid>)}
  {(currentTab == 4 &&  dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid  container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item> <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid> <Grid item> {dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item> {dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid></Grid>)}
  {(currentTab == 5 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '2' && <Grid  container spacing={0.5} rowSpacing={0.5} justifyContent="center">
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid>
    <Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid>
    <Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}
  </Grid></Grid>)}
  {(currentTab == 6 && dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.unlocking_mode == '3' && <Grid container spacing={0.5} rowSpacing={0.5} justifyContent="center">  
  <Grid item>
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(0)}} active>General</Button>
    </Grid><Grid item>  <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(1)}}>Panel Settings</Button>
    </Grid><Grid item><Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(2)}}>Relay Settings</Button>
    </Grid><Grid item> {dataOfLocation.lpr_accesss == '1' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(3)}}>LPR</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(4)}}>Hold Open</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '2' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupInactiveMobile} onClick={()=> {handleTabClick(5)}}>Elevator Control</Button>}
    </Grid><Grid item>{dataOfLocation.unlocking_mode == '3' &&
    <Button sx={mainThemeStyle.buttonHeaderGroupMobile} onClick={()=> {handleTabClick(6)}}>Brivo Control</Button>}</Grid>
  </Grid>)}
        </Box> }
        {currentDisableValue == false && <Box sx={mainThemeStyle.boxHeaderBelow} backgroundColor="menubarprimary.main">
 {(currentTab == 0 && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Location Details
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
     
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Name </Typography>
      {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel"&& <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Telephone Number</Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Area Code# </Typography>}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera Type </Typography>
     {dataOfLocation.cam_brand == "ResidentLink" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Hexnode Device Id </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Day </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Time </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location DTMF Code </Typography>}
     
        {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera IP Address Or Hostname </Typography>}
       {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Latitude </Typography>}
     {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Longitude </Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
     
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_name"
                        variant="filled"
                        value={dataOfLocation.loc_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
       
                       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && <MuiTelInput disableDropdown defaultCountry="US" value={dataOfLocation.loc_no} onChange={handleChange} variant="filled" InputProps={{
                           
                           sx: {
                            inputProps: { 
                              max: 10, min: 0 
                          },
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}/>}
                      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="area_code"
                        variant="filled"
                        placeholder="3 Digit Area Code"
                        value={dataOfLocation.area_code}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                    }}
                        InputProps={{
                           
                            sx: {
                              inputProps: { 
                                max: 3, min: 0 
                            },
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'4px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "cam_brand"
            value={GetValueForCameraBrand()}
          label="Age"
          onChange={handleChangeCameraBrand}
          variant="filled"
          
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {CameraBrand.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      {dataOfLocation.cam_brand == "ResidentLink" && <TextField
        id="hexnode-id"
        type="text"
        name="device_id"
        value={dataOfLocation.device_id}
        onChange={handleInputChange}
        height={40}
        sx={mainThemeStyle.InputMenuItem}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
            top:'10px',
              color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
          
             
              
          },
      }}
      />}
      
      {dataOfLocation.cam_brand == "ResidentLink" && <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'10px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "rebootday"
            value={dataOfLocation.rebootday}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootDay.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
         
          }
        
            
            {dataOfLocation.cam_brand == "ResidentLink" && <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'15px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "reboottime"
          value={dataOfLocation.reboottime}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
          }
          {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="loc_code"
          value={dataOfLocation.loc_code}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      
       {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="cam_ip"
          value={dataOfLocation.cam_ip}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}

{ dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="latitude"
          height={40}
          type= 'text'
          name="latitude"
          value={dataOfLocation.latitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
               
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="longitude"
          height={40}
          type= 'text'
          name="longitude"
          value={dataOfLocation.longitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> Thumbnail Photo </Typography>
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel")&& 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Pin Length </Typography>}
       {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Delivery PIN </Typography>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.brivo_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Group Name </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK CloudNode Id </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK Group </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Camera UserName </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Camera Password </Typography>}
       {dataOfLocation.cam_brand == "ResidentLink" &&
<Typography  sx={mainThemeStyle.popUpLable} height={40}>Select Time Zone</Typography>}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
        <img  src={imageUrl} height={200} width={200} ></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfLocation.image_upload_data = reader.result;
              setLocationForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
        <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        name = "pin_length"
        value={dataOfLocation.pin_length}
      label="Age"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>
       /*  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfLocation.pin_length}</Typography> */
        }
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&
      <TextField
                        id="delivery-pin"
                        height={40}
                        type= 'number'
                        name="delivery_pin"
                        value={dataOfLocation.delivery_pin}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                        
                            sx: {
                               
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                  display: "none",
                                },
                               
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.brivo_enable == '1' &&
                      <TextField
                        id="brivo-group-name"
                        height={40}
                        type= 'text'
                        name="brivo_group_name"
                        value={dataOfLocation.brivo_group_name}
                        disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.pdk_enable == '1' &&
                      <TextField
                        id="pdk_node"
                        height={40}
                        type= 'text'
                        name="pdk_node"
                        value={dataOfLocation.pdk_node}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                       {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.pdk_enable == '1' &&
                      <TextField
                        id="pdk_group"
                        height={40}
                        type= 'text'
                        name="pdk_group"
                        value={dataOfLocation.pdk_group}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("239")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                     {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && 
      <TextField
                        id="cam-username"
                        height={40}
                        type= 'text'
                        name="cam_username"
                        value={dataOfLocation.cam_username}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
                      <TextField
                        id="cam-password"
                        height={40}
                        type= 'text'
                        name="cam_password"
                        value={dataOfLocation.cam_password}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand == "ResidentLink" &&
<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            }}
            name = "time_zone"
            
            value={dataOfLocation.time_zone}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {TimeZoneList.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container  spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
     
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Name </Typography>
      {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Phone Number</Typography>}
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Area Code# </Typography>}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera Type </Typography>
     {dataOfLocation.cam_brand == "ResidentLink" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Hexnode Device Id </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Day </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Weekly Boot Time </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location DTMF Code </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" &&  dataOfLocation.cam_brand != "Virtual Panel" &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Camera IP Address Or Hostname </Typography>}
       {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Latitude </Typography>}
     {dataOfLocation.cam_brand == "Virtual Panel" && 
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>Location Longitude </Typography>}
      <Typography sx={mainThemeStyle.popUpLable} height={145}> Thumbnail Photo </Typography>
      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel")&& 
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Pin Length </Typography>}
       {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Delivery PIN </Typography>}
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && dataOfLocation.brivo_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Group Name </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK CloudNode Id </Typography>}
      {dataOfLocation.cam_brand == "ResidentLink" && dataOfLocation.pdk_enable == '1' &&
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>PDK Group </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Camera UserName </Typography>}
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
       <Typography sx={mainThemeStyle.popUpLable} height={40}>Camera Password </Typography>}
        {dataOfLocation.cam_brand == "ResidentLink" &&
<Typography  sx={mainThemeStyle.popUpLable} height={40}>Select Time Zone</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
     
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_name"
                        variant="filled"
                        value={dataOfLocation.loc_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
        
                       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" && <MuiTelInput disableDropdown defaultCountry="US" value={dataOfLocation.loc_no} onChange={handleChange} variant="filled" 
                       
                       onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    }}
                       InputProps={{
                           
                           sx: {
                            inputProps: { 
                              max: 10, min: 0 
                          },
                          
                               color:'rgba(135, 135, 135, 0.5)',
                               height:'33px',
                               borderRadius: '5px',
                               backgroundColor: '#121212',
                               boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                               padding: '0 0 15px 0',
                               textIndent: '15px !important',
                             
                              
                               
                           },
                       }}/>}
                      {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="area_code"
                        variant="filled"
                        placeholder="3 Digit Area Code"
                        value={dataOfLocation.area_code}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                    }}
                        InputProps={{
                           
                            sx: {
                              inputProps: { 
                                max: 3, min: 0 
                            },
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'4px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "cam_brand"
            value={GetValueForCameraBrand()}
          label="Age"
          onChange={handleChangeCameraBrand}
          variant="filled"
          
          hiddenLabel
         
          MenuProps={MenuProps}>
            
            {CameraBrand.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      {dataOfLocation.cam_brand == "ResidentLink" && <TextField
        id="hexnode-id"
        type="text"
        name="device_id"
        value={dataOfLocation.device_id}
        onChange={handleInputChange}
        height={40}
        sx={mainThemeStyle.InputMenuItem}
        InputLabelProps={{
          shrink: true,
        }}
        variant="filled"
        InputProps={{
                           
          sx: {
            top:'10px',
              color: 'rgba(135, 135, 135, 0.5)',
              height:'33px',
              borderRadius: '5px',
              backgroundColor: '#121212',
              boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
              padding: '0 0 15px 0',
              textIndent: '15px !important',
          
             
              
          },
      }}
      />}
      
      {dataOfLocation.cam_brand == "ResidentLink" && <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'10px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "rebootday"
            value={dataOfLocation.rebootday}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootDay.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
         
          }
        
            
            {dataOfLocation.cam_brand == "ResidentLink" && <Select
            
            
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            top:'15px',
            bottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "reboottime"
          value={dataOfLocation.reboottime}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}
         
            >
              {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
            </Select>
          }
          {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="loc_code"
          value={dataOfLocation.loc_code}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      
       {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
          <TextField
          id="input-dtmf-code"
          height={40}
          type= 'text'
          name="cam_ip"
          value={dataOfLocation.cam_ip}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="latitude"
          height={40}
          type= 'text'
          name="latitude"
          value={dataOfLocation.latitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      { dataOfLocation.cam_brand == "Virtual Panel" &&
          <TextField
          id="longitude"
          height={40}
          type= 'text'
          name="longitude"
          value={dataOfLocation.longitude}
          onChange={handleInputChange}
          sx={mainThemeStyle.InputMenuItem}
          InputLabelProps={{ shrink: true }
        }
        variant="filled"
          InputProps={{
             
              sx: {
                top:'10px',
                  color:'rgba(135, 135, 135, 0.5)',
                  height:'33px',
                  borderRadius: '5px',
                  backgroundColor: '#121212',
                  boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                  padding: '0 0 15px 0',
                  textIndent: '15px !important',
                
                 
                  
              },
          }}
         
      />}
      <Typography></Typography>
       <Stack direction="column" spacing={2} >
        <img  src={imageUrl} height={100} width={100} ></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfLocation.image_upload_data = reader.result;
              setLocationForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") && 
        <Select
        sx={{ color: 'rgba(135, 135, 135, 0.5)',
        height:'33px',
        top:'4px',
        bottom:'3px',
        borderRadius: '5px',
        backgroundColor: '#121212',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: '5px 0 5px 0',
        "& .MuiSvgIcon-root": {
          color: "rgba(135, 135, 135, 0.5)",
        },
        
        '&.Mui-disabled': {
         

          background: 'rgba(135, 135, 135, 0.5)',
        },
        "& input.Mui-disabled": {
          color: "white"
        }
        }}
        name = "pin_length"
        value={dataOfLocation.pin_length}
      label="Age"
      onChange={handleInputChange}
      variant="filled"
      
      hiddenLabel
     
      MenuProps={MenuProps}>
        
        {PinLengthHolder.items.map(menuItem => {
              
              return (
                <MenuItem  sx={
                  {
                  
                  color:"#fff",
                  backgroundColor: "#2F323A",
                  borderBottom: '.5px solid grey',
                  fontFamily: "Lato",fontSize:'12px',
              }}
               value={menuItem.label}>
                  {menuItem.label}
                </MenuItem>
              );
            })}
      </Select>
       /*  <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfLocation.pin_length}</Typography> */
        }
        {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&
      <TextField
                        id="delivery-pin"
                        height={40}
                        type= 'number'
                        name="delivery_pin"
                        value={dataOfLocation.delivery_pin}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                        
                            sx: {
                               
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                  display: "none",
                                },
                               
                                
                            },
                        }}
                       
                    />}
                    {(dataOfLocation.cam_brand == "ResidentLink" || dataOfLocation.cam_brand == "Virtual Panel") &&  dataOfLocation.brivo_enable == '1' &&
                      <TextField
                        id="brivo-group-name"
                        height={40}
                        type= 'text'
                        name="brivo_group_name"
                        value={dataOfLocation.brivo_group_name}
                        onChange={handleInputChange}
                        disabled ={ (token.issub === true && token.name === 'SUPER ADMIN') ? false :true}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.pdk_enable == '1' &&
                      <TextField
                        id="pdk_node"
                        height={40}
                        type= 'text'
                        name="pdk_node"
                        value={dataOfLocation.pdk_node}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                       {dataOfLocation.cam_brand == "ResidentLink" &&  dataOfLocation.pdk_enable == '1' &&
                      <TextField
                        id="pdk_group"
                        height={40}
                        type= 'text'
                        name="pdk_group"
                        value={dataOfLocation.pdk_group}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValue("239")}
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
                    />}
                     {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
      <TextField
                        id="cam-username"
                        height={40}
                        type= 'text'
                        name="cam_username"
                        value={dataOfLocation.cam_username}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand != "ResidentLink" && dataOfLocation.cam_brand != "Virtual Panel" &&
                      <TextField
                        id="cam-password"
                        height={40}
                        type= 'text'
                        name="cam_password"
                        value={dataOfLocation.cam_password}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.cam_brand == "ResidentLink" &&
<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            }}
            name = "time_zone"
            
            value={dataOfLocation.time_zone}
          label="Age"
          onChange={handleChangeBoot}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {TimeZoneList.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem}>
                      {menuItem}
                    </MenuItem>
                  );
                })}
          </Select>}
      </Stack>
      </Grid>
      
      
      
     
      
      </Grid></Box>
      </>
     
)}

{(currentTab == 1 && dataOfLocation.cam_brand == "Virtual Panel" &&<>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                   Virtual Panel
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Heading </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Heading Custom Color </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Icon </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Message </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Property Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Property Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>City, State, ZIP </Typography>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Displayed Phone # </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={41}>App User Unlock Mode</Typography> */}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="wel_message_header"
                        height={40}
                        type= 'text'
                        name="wel_message_header"
                        value={dataOfLocation.wel_message_header}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'color'
                        name="wel_message_headercolor"
                        value={dataOfLocation.wel_message_headercolor}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(255, 255, 255, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "wel_message_headerlogo"
            value={getDisplayedLogo(dataOfLocation.wel_message_headerlogo)}
          label="Age"
          onChange={handleSetDisplayLogo}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {DisplayedICon.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                   
                    {/* <TextField
                        id="input-tablet-name"
                        height={40}
                        type= 'text'
                        name="tablet_name"
                        value={dataOfLocation.tablet_name}
                        onChange={handleInputChange}
                        variant="filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                       
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
     
         {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockModeCBW()}
          label="Age"
          onChange={handleSetAppUnlockModeCBW}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockModeCBW.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={41}>Text Alert Phone # </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Watermark Logo </Typography>
        
       
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate(In minutes) </Typography> */}
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography> */}
     
      <Typography sx={mainThemeStyle.popUpLable} height={41}>Enable Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Enable Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={41}>Enable Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={42}>Enable Delivery PIN Button </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}>Require GPS Proximty to Launch</Typography>
      
      </Stack>
      
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
        {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={30} style={{width:"100%",'object-fit': 'contain'}}></img>}
      
      
                   {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
          {/* <TextField
                        id="input-location"
                        height={30}
                        type= 'text'
                        name="wel_message_headerlogo"
                        value={dataOfLocation.wel_message_headerlogo}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_prox" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_prox == "0" ? true : false } />}
        label=""
      />
                  {/*   <Checkbox  sx={mainThemeStyle.formCheckBox} name="dis_off" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_off == "1" ? true : false} />
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_dir" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_dir  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_doorpin" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_doorpin  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_del" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_del  == "1" ? true : false}/> */}
                   
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
        <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Heading </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Heading Color </Typography>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Icon</Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={41}> Displayed Message </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Property Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography> */}
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography> */}
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography>
      
      
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Dilevery PIN Button </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Require GPS Proximty to Launch</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="wel_message_header"
                        height={40}
                        type= 'text'
                        name="wel_message_header"
                        value={dataOfLocation.wel_message_header}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'color'
                        name="wel_message_headercolor"
                        value={dataOfLocation.wel_message_headercolor}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                              marginTop:'15px',
                                color:'rgba(255, 255, 255, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            }
            }}
            name = "wel_message_headerlogo"
            value={getDisplayedLogo(dataOfLocation.wel_message_headerlogo)}
          label="Age"
          onChange={handleSetDisplayLogo}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {DisplayedICon.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                  
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                     
          {/* <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockModeCBW()}
          label="Age"
          onChange={handleSetAppUnlockModeCBW}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockModeCBW.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
          {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={30} style={{width:"100%",'object-fit': 'contain'}}></img>}
      
                    
                   {/*  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> */}
          {/* <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="wel_message_headerlogo"
                        value={dataOfLocation.wel_message_headerlogo}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                marginTop:'15px',
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_prox" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_prox == "0" ? true : false } />}
        label=""
      />
      </Stack>
      </Grid>
     
      
     
      
      </Grid></Box>

</>)}

{(currentTab == 1 &&  dataOfLocation.cam_brand == "ResidentLink" &&<>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Panel Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Scrolling Marquee </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}> Serial Number </Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Property Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                   
                    {/* <TextField
                        id="input-tablet-name"
                        height={40}
                        type= 'text'
                        name="tablet_name"
                        value={dataOfLocation.tablet_name}
                        onChange={handleInputChange}
                        variant="filled"
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
       <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
     
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>URL for Watermark Logo </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate(In minutes) </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Enable Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable Delivery PIN Button </Typography>
      
      
      </Stack>
      
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        
        {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={30} style={{width:"100%",'object-fit': 'contain'}}></img>}
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="loc_refresh_rate"
                        value={dataOfLocation.loc_refresh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="setting_reftesh_rate"
                        value={dataOfLocation.setting_reftesh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
                  {/*   <Checkbox  sx={mainThemeStyle.formCheckBox} name="dis_off" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_off == "1" ? true : false} />
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_dir" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_dir  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_doorpin" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_doorpin  == "1" ? true : false}/>
                    <Checkbox sx={mainThemeStyle.formCheckBox} name="dis_del" onChange={handlecheckboxCheck} checked={dataOfLocation.dis_del  == "1" ? true : false}/> */}
                   
      </Stack>
      </Grid>
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Scrolling Marquee </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Property Name </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Button Descriptor </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Property Address </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>City, State, ZIP </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Text Alert Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Displayed Phone # </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>App User Unlock Mode</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Watermark URL </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Refresh Rate </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Setting Refresh Rate</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Screen Layout </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Office Button</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Directory Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Door PIN Button </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}> Dilevery PIN Button </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
                        id="input-welcome"
                        height={40}
                        type= 'text'
                        name="wel_message"
                        value={dataOfLocation.wel_message}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                  
                    <TextField
                        id="input-property-name"
                        height={40}
                        type= 'text'
                        name="loc_property_name"
                        value={dataOfLocation.loc_property_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-office-name"
                        height={40}
                        type= 'text'
                        name="loc_office_name"
                        value={dataOfLocation.loc_office_name}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-one"
                        height={40}
                        type= 'text'
                        name="loc_add_one"
                        value={dataOfLocation.loc_add_one}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-address-two"
                        height={40}
                        type= 'text'
                        name="loc_add_two"
                        value={dataOfLocation.loc_add_two}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                       <TextField
                        id="input-sms-alert"
                        height={40}
                        type= 'text'
                        name="warning_phone"
                        value={dataOfLocation.warning_phone}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                     <TextField
                        id="input-panel-telephone"
                        height={40}
                        type= 'text'
                        name="loc_telephone_dial"
                        value={dataOfLocation.loc_telephone_dial}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            
            value={GetUnlockingMode()}
          label="Age"
          onChange={handleSetUnlockingMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlcokingMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "app_unlock_mode"
            
            value={GetAppUnlockMode()}
          label="Age"
          onChange={handleSetAppUnlockMode}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {AppUserUnlockMode.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {(token.issub === true && token.name === 'SUPER ADMIN') ?
      
      <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="loc_url_watermark"
                        value={dataOfLocation.loc_url_watermark}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                       
                      }
                      
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                              
                               
                                
                            },
                        }}
                       
                    /> : <img  src={dataOfLocation.loc_url_watermark} height={30} style={{width:"100%",'object-fit': 'contain'}}></img>}
      
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="loc_refresh_rate"
                        value={dataOfLocation.loc_refresh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'number'
                        name="setting_reftesh_rate"
                        value={dataOfLocation.setting_reftesh_rate}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "loc_screen_layout"
            
            value={GetValueOfLocationScreenLayout(dataOfLocation.loc_screen_layout)}
          label="Age"
          onChange={ChangeScreenLayout}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {ScreenLayout.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_off" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_off == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_dir" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_dir == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_doorpin" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_doorpin == "0" ? true : false } />}
        label=""
      />
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="dis_del" onChange={handlecheckboxCheckReverse} checked={dataOfLocation.dis_del == "0" ? true : false } />}
        label=""
      />
     
      </Stack>
      </Grid>
     
      
     
      
      </Grid></Box>

</>)}
{(currentTab == 2  &&  dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Relay Settings
                   </Typography>
                   
               </div>
              
           </div>  <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
          
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
     {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Schedule Time</Typography>
     {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable External Wiegand Input</Typography>}
        {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>RFID/Wiegand Schedule Time</Typography>} */}
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>Unlocking Mode </Typography>
      {/*  {dataOfLocation.unlocking_mode != '1' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography> }
       */}   

      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay Hostname</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Duration</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Relay Duration</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography> }
       {dataOfLocation.unlocking_mode != '1' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>}
   
     {dataOfLocation.unlocking_mode == '1' &&<Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>}
       {dataOfLocation.unlocking_mode == '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
           
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            
            value={GetUnlockingModeQR()}
          label="Age"
          onChange={handleSetUnlockingModeQR}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlockingModeQR.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {dataOfLocation.unlocking_mode != '1' && <TextField
                        id="input-relay-host"
                        height={80}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
           {dataOfLocation.unlocking_mode != '1' && <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />}
      {dataOfLocation.unlocking_mode != '1' && <TextField
        id="input-vegax-duration"
        type= 'number'
        name="vegax_duration"
        value={dataOfLocation.vegax_duration}
        onChange={handleInputChange}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />}
      {dataOfLocation.unlocking_mode != '1' && <TextField
        id="input-vegax2-duration"
        height={40}
        type= 'number'
        name="vegax_second_duration"
        value={dataOfLocation.vegax_second_duration}
        onChange={handleInputChange}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"       
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />}
        
      {dataOfLocation.unlocking_mode != '1' &&    <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }
     {dataOfLocation.unlocking_mode != '1' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }

    {dataOfLocation.unlocking_mode == '1' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>}
     {dataOfLocation.unlocking_mode == '1' &&
     <Typography
        height={40}
        
        sx={mainThemeStyle.popUpLableAnswer}
                       
        >{dataOfLocation.brivo_access.brivofloors}</Typography>}
      
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
        {/* {dataOfLocation.unlocking_mode != '1' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Friendly Name</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>
      <Typography></Typography>
      <Divider sx={{backgroundColor:'white'}} />
      <Typography></Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Friendly Name</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography></>}
     */} {/*  {dataOfLocation.unlocking_mode == '1' && <>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Door ID 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Door ID 2</Typography>
      </>} */}
         {dataOfLocation.unlocking_mode == '1' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography> }
       {dataOfLocation.unlocking_mode == '1' && <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>}
       {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Primary Relay</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay for App Unlock</Typography>}
      {dataOfLocation.unlocking_mode != '1' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Friendly Name</Typography>}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          
        {dataOfLocation.unlocking_mode != '1' && <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />}
       {dataOfLocation.unlocking_mode != '1' && <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />}
        {dataOfLocation.unlocking_mode != '1' && <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />}
       {dataOfLocation.unlocking_mode != '1' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
       {/*  {dataOfLocation.unlocking_mode != '1' && <>
            <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
         <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <Typography></Typography>
                    <Divider sx={{backgroundColor:'white'}} />
                    <Typography></Typography>
                 <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                     
                 
                    <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /></>} */}
                     {/* {dataOfLocation.unlocking_mode == '1' && <>
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
         
                   
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "0"
            value={getBrivoValue(0)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                     
                 
                  </>} */}
                  
                   {dataOfLocation.unlocking_mode == '1' &&   <FormControlLabel   sx={{ marginLeft: '0px',  height:'2.18rem',
            color:'#fff',}}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off"  onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }
     {dataOfLocation.unlocking_mode == '1' && <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      /> }
      </Stack>
      </Grid>
     
     
      
      </Grid>
      {dataOfLocation.unlocking_mode == '1' &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}



    
      {dataOfLocation.unlocking_mode != 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Web Relay Programming  <Tooltip title="Add a letter 'P' to designate a Primary Relay, and add a numeral 1-64 to designate a Floor Relay">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip></TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      </Box>
      <Box sx={{display: { xs: "block", md: "none" }}}>
           <Divider sx={{backgroundColor:'white'}} />
           <Grid container  spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
    <Typography sx={mainThemeStyle.popUpLable} height={35}> Guest PIN </Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={35}>Unlocking Mode </Typography>
    {/* {dataOfLocation.unlocking_mode != '1' && <>
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Relay Hostname </Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Name</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration" : "Relay 1 Unlock Duration"}</Typography>
    <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Name</Typography>
   <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration" : "Relay 2 Unlock Duration"}</Typography>
   </>} */}
   {dataOfLocation.unlocking_mode != '1' && <> 
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay Hostname</Typography>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Primary Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Relay for App Unlock</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Primary Relay Friendly Name</Typography></>}
     
     
   {dataOfLocation.unlocking_mode == '1' && <>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Door ID 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={35}>Brivo Door ID 2</Typography> */}
       <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
       <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
    
      </>}
    </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
        
      

          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
       <Select 
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "unlocking_mode"
            
            value={GetUnlockingModeQR()}
          label="Age"
          onChange={handleSetUnlockingModeQR}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {UnlockingModeQR.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          {dataOfLocation.unlocking_mode != '1' && <> 

           <TextField
                        id="input-relay-host"
                        height={80}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
    <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
     <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /> <FormControlLabel   sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      /> <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      /> <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      /> <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
          </>}
          {/* {dataOfLocation.unlocking_mode != '1' && <>  <TextField
                        id="input-relay-host"
                        height={40}
                        type= 'text'
                        name="realy_hostname"
                        value={dataOfLocation.realy_hostname}
                        onChange={handleInputChange}
                        placeholder='api.controlbyweb.cloud/DAT/api-key'
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
         
           <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    
                     
                      <TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /></> } */}
           {/*   {dataOfLocation.unlocking_mode == '1' && <>
                     <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
         
                   
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "0"
            value={getBrivoValue(0)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                     
                 
                  </>} */}
       {dataOfLocation.unlocking_mode == '1' &&<Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>}
     {dataOfLocation.unlocking_mode == '1' &&
     <Typography
        height={40}
        
        sx={mainThemeStyle.popUpLableAnswer}
                       
        >{dataOfLocation.brivo_access.brivofloors}</Typography>}
      </Stack>
      </Grid>
      
     
      
     
      
      </Grid>
      
      {dataOfLocation.unlocking_mode == '1' &&
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    {dataOfLocation.unlocking_mode != 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Web Relay Programming  <Tooltip title="Add a letter 'P' to designate a Primary Relay, and add a numeral 1-64 to designate a Floor Relay">
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip></TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
      
      </Box>

</>)}
{(currentTab == 2  &&  dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Relay Settings
                   </Typography>
                   
               </div>
              
           </div>  <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
          
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
     <Typography  sx={mainThemeStyle.popUpLable} height={40}>PIN Schedule Time</Typography>
     {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Enable External Wiegand Input</Typography>}
        {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>RFID/Wiegand Schedule Time</Typography>}
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_start_time"
 
 value={dataOfLocation.pin_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_end_time"
 
 value={dataOfLocation.pin_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
{dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="enable_exe_weigand" onChange={handlecheckboxCheck} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false } />}
        label={dataOfLocation.enable_exe_weigand == "1" ? "On" : "Off"}
      />} {dataOfLocation.unlocking_mode == '2' && dataOfLocation.enable_exe_weigand == "1" &&
<Stack direction="row" spacing={2} height={40}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_start_time"
 
 value={dataOfLocation.card_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_end_time"
 
 value={dataOfLocation.card_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>}
          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      
    
      
      
     
           {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>ResidentVue Relay 1 Friendly Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_one_fname:' Relay 1 '} State</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={43}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 1 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>}
      <Divider sx={{backgroundColor:'white'}} />
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>ResidentVue Relay 2 Friendly Name</Typography>}
      {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_two_fname:' Relay 2 '} State</Typography>}
     
        {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration (Enter Between 0 to 99 Seconds)" : "Relay 2 Unlock Duration (Enter Between 0 to 99 Seconds)"}</Typography>}
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
         
      
       {/* <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
          sx={{ color: 'rgba(135, 135, 135, 0.5)',
          height:'34px',
          borderRadius: '5px',
          marginTop:'3px',
          marginBottom:'3px',
          backgroundColor: '#121212',
          boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
          padding: '5px 0 5px 0',
          textIndent: '15px !important',}}
        />  */} 
                
               
         
          {dataOfLocation.unlocking_mode == '2' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.unlocking_mode == '2' &&      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state" onChange={handlecheckboxCheck}  checked={dataOfLocation.default_vega_state == "1" ? true : false} />}
        label={dataOfLocation.default_vega_state == "1" ? "Normally Open" : "Normally Closed"}
      />}
       {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    <Divider sx={{backgroundColor:'white'}} />
                  {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                     {dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state_2" onChange={handlecheckboxCheck} checked={dataOfLocation.default_vega_state_2 == "1" ? true : false } />}
        label={dataOfLocation.default_vega_state_2 == "1" ? "Normally Open" : "Normally Closed"}
      />}
                 
                     {dataOfLocation.unlocking_mode == '2' &&<TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
      </Stack>
      </Grid>
     
     
      
      </Grid></Box>
      <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Divider sx={{backgroundColor:'white'}} />
           <Grid container  spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={120}>PIN  Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={120}>RFID/Wiegand  Time</Typography>
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Resident PIN </Typography>
    <Typography sx={mainThemeStyle.popUpLable} height={40}> Guest PIN </Typography>
  
    {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>External Wiegand Input</Typography>}
    {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 Name</Typography>}
    {dataOfLocation.unlocking_mode == '2' &&  <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_one_fname:' Relay 1 '} State</Typography>}
   
    {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 Name</Typography>}
    {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>Default {dataOfLocation.relay_one_fname != ""?dataOfLocation.relay_two_fname:' Relay 2 '} State</Typography>}
   
    {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_one_fname).length > 0 ? dataOfLocation.relay_one_fname + " Unlock Duration" : "Relay 1 Unlock Duration"}</Typography>}
    {dataOfLocation.unlocking_mode == '2' && <Typography  sx={mainThemeStyle.popUpLable} height={40}>{ (dataOfLocation.relay_two_fname).length > 0 ? dataOfLocation.relay_two_fname + " Unlock Duration" : "Relay 2 Unlock Duration"}</Typography>}
    
    
    </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
        
      
      
<Stack  direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_start_time"
 
 value={dataOfLocation.pin_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-10px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "pin_end_time"
 
 value={dataOfLocation.pin_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
<Stack  direction="column" spacing={2} height={120}>

<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'4px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_start_time"
 
 value={dataOfLocation.card_start_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select>
<Typography sx={mainThemeStyle.popUpLableAnswer} paddingTop="7px"> To </Typography>
<Box>
<Select
 sx={{ color: 'rgba(135, 135, 135, 0.5)',
 height:'33px',
 marginTop:'-10px',
 marginBottom:'3px',
 borderRadius: '5px',
 backgroundColor: '#121212',
 boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
 padding: '5px 0 5px 0',
 "& .MuiSvgIcon-root": {
   color: "rgba(135, 135, 135, 0.5)",
 },
 
 '&.Mui-disabled': {
  

   background: 'rgba(135, 135, 135, 0.5)',
 },
 "& input.Mui-disabled": {
   color: "white"
 }
 }}
 name = "card_end_time"
 
 value={dataOfLocation.card_end_time}
label="Age"
onChange={handleChangeBoot}
variant="filled"
hiddenLabel
MenuProps={MenuProps}>
 {BootTime.items.map(menuItem => {
       
       return (
         <MenuItem  sx={
           {
           
           color:"#fff",
           backgroundColor: "#2F323A",
           borderBottom: '.5px solid grey',
           fontFamily: "Lato",fontSize:'12px',
       }}
        value={menuItem.label}>
           {menuItem.label}
         </MenuItem>
       );
     })}
</Select></Box>
</Stack>
          <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="r_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.r_pin_off == "0" ? true : false} />}
        label={dataOfLocation.r_pin_off == "0" ? "Enabled" : "Disabled"}
      />
      <FormControlLabel   sx={mainThemeStyle.popUpLableNewone}
        control={<IOSSwitch sx={{ m: 1 }} name="g_pin_off" onChange={handlecheckboxCheckReverse}  checked={dataOfLocation.g_pin_off == "0" ? true : false} />}
        label={dataOfLocation.g_pin_off == "0" ? "Enabled" : "Disabled"}
      />
            {dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="enable_exe_weigand" onChange={handlecheckboxCheck} checked={dataOfLocation.enable_exe_weigand == "1" ? true : false } />}
        label={dataOfLocation.enable_exe_weigand == "1" ? "On" : "Off"}
      />}
         
          {dataOfLocation.unlocking_mode == '2' && <TextField
                        id="input-vegax-relay1"
                        height={40}
                        type= 'text'
                        name="relay_one_fname"
                        value={dataOfLocation.relay_one_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                    {dataOfLocation.unlocking_mode == '2' &&      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state" onChange={handlecheckboxCheck}  checked={dataOfLocation.default_vega_state == "1" ? true : false} />}
        label={dataOfLocation.default_vega_state == "1" ? "Normally Open" : "Normally Closed"}
      />}
                  {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-relay2"
                        height={40}
                        type= 'text'
                        name="relay_two_fname"
                        value={dataOfLocation.relay_two_fname}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                     {dataOfLocation.unlocking_mode == '2' &&     <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="default_vega_state_2" onChange={handlecheckboxCheck} checked={dataOfLocation.default_vega_state_2 == "1" ? true : false } />}
        label={dataOfLocation.default_vega_state_2 == "1" ? "Normally Open" : "Normally Closed"}
      />}
                  {dataOfLocation.unlocking_mode == '2' &&   <TextField
                        id="input-vegax-duration"
                        height={40}
                        type= 'number'
                        name="vegax_duration"
                        value={dataOfLocation.vegax_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
                     {dataOfLocation.unlocking_mode == '2' &&<TextField
                        id="input-vegax2-duration"
                        height={40}
                        type= 'number'
                        name="vegax_second_duration"
                        value={dataOfLocation.vegax_second_duration}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 99, min: 0 
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />}
          
      
      </Stack>
      </Grid>
      
     
      
     
      
      </Grid></Box>

</>)}
{(currentTab == 3  &&  dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Hold Open Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={10}>
      <Stack direction="row"  spacing={4}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',
            marginTop:'8px',
            }} height={40} >Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      

    
      </Box>

      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '27px' paddingBottom='32px' paddingTop='32px'> 
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      </Stack>
      
      </Stack>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      </Box>
</>)}
{(currentTab == 3 && dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       LPR Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2}>
      
      <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>LPR Location </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      
      
      </Box>
      {dataOfLocation.lpr_enable == "1" && <Box sx={{display: { xs: "none", md: "block" }}}>
        <Grid container spacing={2} padding= '32px'> 
        <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <Tooltip title= {'A "Dedicated" bridge can only be assigned to a single LPR location.  If the property account has multiple LPR locations utilizing this same bridge, please select "Shared".'}>
      <IconButton  size="small" color="primary" >
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge} disabled = { (recordsBridge.length > 0 )? true : false}>Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      
      
      </Grid>
     
      <Grid container  padding= '32px'>
       
       <Grid item xs={5}>
       <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
     
     </Grid>
     <Grid item xs={2}>
     
     </Grid>
     <Grid item xs={5}>
     <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
     </Grid>
       </Grid>
       
     
       <Grid container spacing={3}>
       <Grid item xs={5}>
       {/* <BelowTblPaginationBridge></BelowTblPaginationBridge> */}</Grid>
       <Grid item xs={1}>
       </Grid>
       <Grid item xs={6}>
      {/*  <BelowTblPaginationCamera></BelowTblPaginationCamera> */}</Grid></Grid>
      
      
      
      
      </Box>}
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Location </Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      {dataOfLocation.lpr_enable == "1" &&<Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge} disabled = { (recordsBridge.length > 0 )? true : false}>Add</LoadingButton>
     </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
    
    
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
   {/*  <BelowTblPaginationCamera></BelowTblPaginationCamera> */}
      </Box>}
      </Box>

</>)}
{(currentTab == 4  &&  dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Hold Open Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={10}>
      <Stack direction="row"  spacing={4}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Recurring Schedule Mode </Typography>
     
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="definite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.definite == "1" ? true : false}/>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>One-Time Schedule Mode </Typography>
      
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="finite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.finite == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={{fontFamily: 'Lato',
            fontWeight: '500',
            fontSize: '1rem',
            lineHeight: '1.37rem',
            color:'#fff',
            marginTop:'8px',
            }} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={1}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.definite == "1" &&
      
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={3}>Relay Hold Open Times</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={7}>Weekdays</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={2}>Relay Number</TableCell>
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}></TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Begin Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>End Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>M</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>W</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>F</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>1</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>2</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsHoleOpen.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {row}
              </TableCell>
              <TableCell align="right">
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1].st}
          onChange={handleChangeOpenStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          </TableCell>
           <TableCell align="right">  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1].en}
          onChange={handleChangEndStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleSundayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].s == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleMondayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].m == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleTuesdayRelay}  checked={dataOfLocation.hold_open.table[parseInt(row)-1].t == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleWednesdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].w == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleThursdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].th == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleFridayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].f == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleSaturdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].sa == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleHoldRelayOne} checked={dataOfLocation.hold_open.table[parseInt(row)-1].r1 == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleHoldRelayTwo} checked={dataOfLocation.hold_open.table[parseInt(row)-1].r2 == "1" ? true : false}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.finite == "1" &&
    <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Start Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>End Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="date"
        type="date"
        name="expiry"
        value={dataOfLocation.hold_open.expiry}
        onChange={handleOneTimeValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_on"
            value={dataOfLocation.hold_open.time_on}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_off"
            value={dataOfLocation.hold_open.time_off}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>} 
      </Box>

      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Enable Hold Open </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }}   onChange={handleChangeEventOfHoldOpen}  checked={dataOfLocation.hold_open.holdopen == "1" ? true : false }/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
       {dataOfLocation.hold_open.holdopen == "1" && <Grid container spacing={2} padding= '27px' paddingBottom='32px' paddingTop='32px'> 
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Indefinite Mode </Typography>
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="indefinite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.indefinite == "1" ? true : false}/>
      </Stack>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Recurring Schedule Mode </Typography>
     
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="definite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.definite == "1" ? true : false}/>
      </Stack>
      <Stack direction="row"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>One-Time Schedule Mode </Typography>
      
      
      <Checkbox sx={mainThemeStyle.formCheckBoxLow} name="finite" onChange={handleChangeValueOfHoldOpen} checked={dataOfLocation.hold_open.finite == "1" ? true : false}/>
      </Stack>
      </Stack>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.indefinite == "1" &&
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1 </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2 </Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false }/>
      </Stack>
      </Grid>
      </Grid>}
      
      {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.definite == "1" &&
      
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={3}>Relay Hold Open Times</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={7}>Weekdays</TableCell>
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={2}>Relay Number</TableCell>
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}></TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Begin Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>End Time</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>M</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>W</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>T</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>F</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>S</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>1</TableCell>
            <TableCell align="right"sx={mainThemeStyle.cellHeader}>2</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsHoleOpen.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {row}
              </TableCell>
              <TableCell align="right">
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1].st}
          onChange={handleChangeOpenStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          </TableCell>
           <TableCell align="right">  <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'25px',
            marginTop:'0px',
            marginBottom:'0px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row)-1}
            value={dataOfLocation.hold_open.table[parseInt(row)-1].en}
          onChange={handleChangEndStart}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleSundayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].s == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleMondayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].m == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleTuesdayRelay}  checked={dataOfLocation.hold_open.table[parseInt(row)-1].t == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleWednesdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].w == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleThursdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].th == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleFridayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].f == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleSaturdayRelay} checked={dataOfLocation.hold_open.table[parseInt(row)-1].sa == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleHoldRelayOne} checked={dataOfLocation.hold_open.table[parseInt(row)-1].r1 == "1" ? true : false}/></TableCell>
              <TableCell align="right"><Checkbox sx={mainThemeStyle.formCheckBoxLow} name = {parseInt(row)-1} onChange={handleHoldRelayTwo} checked={dataOfLocation.hold_open.table[parseInt(row)-1].r2 == "1" ? true : false}/></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}

    {dataOfLocation.hold_open.holdopen == "1" && dataOfLocation.hold_open.finite == "1" &&
    <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='10px'> 
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Start Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>End Time</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 1</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Relay 2</Typography>
      </Stack>
      </Grid>
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="date"
        type="date"
        name="expiry"
        value={dataOfLocation.hold_open.expiry}
        onChange={handleOneTimeValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_on"
            value={dataOfLocation.hold_open.time_on}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
          <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = "time_off"
            value={dataOfLocation.hold_open.time_off}
          onChange={handleOneTimeValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {BootTime.items.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.label}>
                      {menuItem.label}
                    </MenuItem>
                  );
                })}
          </Select>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay1" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay1 == "1" ? true : false }/>
      <Checkbox sx={mainThemeStyle.formCheckBox} name="relay2" onChange={handleIndefiniteRelay} checked={dataOfLocation.hold_open.relay2 == "1" ? true : false}/>
      </Stack>
      </Grid>
      </Grid>} 
      </Box>
</>)}
{(currentTab == 4 && dataOfLocation.cam_brand == "Virtual Panel" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       LPR Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2}>
        <Stack direction="column"  spacing={2}>
      
      <Typography variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>LPR Location </Typography>
     {/*  <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      
      
      </Box>
      {dataOfLocation.lpr_enable == "1" && <Box sx={{display: { xs: "none", md: "block" }}}>
        <Grid container spacing={2} padding= '32px'> 
        <Grid item xs={0.3}></Grid>
      <Grid item xs={5.2}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Bridge </Typography>
      
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge} disabled = { (recordsBridge.length > 0 )? true : false}>Add</LoadingButton>
      </Stack>
      </Grid>
      {/* </Stack>
      </Grid> */}
      <Grid item xs={1.7}>
    
      </Grid>
      <Grid item xs={4.8}>
        <Stack direction="row"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40} > Add Camera </Typography>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          
      </Stack>
      </Grid>
      
      
      </Grid>
     
      <Grid container  padding= '32px'>
       
       <Grid item xs={5}>
       <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
     
     </Grid>
     <Grid item xs={2}>
     
     </Grid>
     <Grid item xs={5}>
     <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
     </Grid>
       </Grid>
       
     
       <Grid container spacing={3}>
       <Grid item xs={5}>
       {/* <BelowTblPaginationBridge></BelowTblPaginationBridge> */}</Grid>
       <Grid item xs={1}>
       </Grid>
       <Grid item xs={6}>
     {/*   <BelowTblPaginationCamera></BelowTblPaginationCamera> */}</Grid></Grid>
      
      
      
      
      </Box>}
      
      <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>LPR Location </Typography>
      {/* <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 1 Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>LPR Camera 2 Name </Typography> */}
      
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      
      
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false } />}
        label=""
      />
            {/* <Checkbox sx={mainThemeStyle.formCheckBox} name="lpr_enable" onChange={handlecheckboxCheck} checked={dataOfLocation.lpr_enable == "1" ? true : false}/> */}
           {/*  <TextField
                        id="input-lpr-camera1"
                        height={40}
                        type= 'text'
                        name="lpr_cam1"
                        value={dataOfLocation.lpr_cam1}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    />
                    <TextField
                        id="input-location"
                        height={40}
                        type= 'text'
                        name="lpr_cam2"
                        value={dataOfLocation.lpr_cam2}
                        onChange={handleInputChange}
                        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
                    /> */}
     
          
      </Stack>
      </Grid>
     
      
      
      </Grid>
      {dataOfLocation.lpr_enable == "1" &&<Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2} >
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Add Bridge </Typography>
      
      </Stack>
      </Grid>
      
      <Grid item xs={12}>
      <Stack direction="column"  spacing={2}>
      <Stack direction="row"  spacing={2}>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridge}
      label="Age"
      onChange={handleChangeBridge}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addBridge?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentBridgeType}
      label="Age"
      onChange={handleChangeBridgeType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {BriType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
      <LoadingButton sx={mainThemeStyle.normalButton}  loading={loading} onClick={handleAddBridge} disabled = { (recordsBridge.length > 0 )? true : false}>Add</LoadingButton>
     </Stack>
      </Stack>
      </Grid>

      
     
      
      
      </Grid>
      
      
      
      <TblContainerBridge>
                     <TblHeadBridge />
         <TableBody>
          {recordsAfterPagingAndSortingBridge().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Dedicated" :"Shared"} </TableCell>
              
               <TableCell align="left">
              
                 <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleBridgeDelete(row.id,row.name)}>Delete</LoadingButton>
                 
                 </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerBridge>
    
    
    
    <Grid container spacing={2} padding= '40px' paddingBottom='32px' paddingTop='100px' > 
    <Grid item xs={12} >
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable}> Add Camera </Typography>
      </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
          <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCamera}
      label="Age"
      onChange={handleChangeCamera}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {addCamera?.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.name}>
                {menuItem.name}
              </MenuItem>
            );
          })}
      </Select>
      <Select
      sx={{ color: 'rgba(135, 135, 135, 0.5)',
      height:'33px',
      marginTop:'4px',
      marginBottom:'3px',
      borderRadius: '5px',
      backgroundColor: '#121212',
      boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
      padding: '5px 0 5px 0',
      "& .MuiSvgIcon-root": {
        color: "rgba(135, 135, 135, 0.5)",
      },
      
      '&.Mui-disabled': {
        

        background: 'rgba(135, 135, 135, 0.5)',
      },
      "& input.Mui-disabled": {
        color: "white"
      }
      }}
      name = "bridge_select"
      
      value={currentCameraType}
      label="Age"
      onChange={handleChangeCameraType}
      variant="filled"
      hiddenLabel
      MenuProps={MenuProps}>
      {CamType.items.map(menuItem => {
            
            return (
              <MenuItem  sx={
                {
                
                color:"#fff",
                backgroundColor: "#2F323A",
                borderBottom: '.5px solid grey',
                fontFamily: "Lato",fontSize:'12px',
            }}
              value={menuItem.label}>
                {menuItem.label}
              </MenuItem>
            );
          })}
      </Select>
             <LoadingButton sx={mainThemeStyle.normalButton} onClick={handleAddCamera} loading={loading}>Add</LoadingButton>
          </Stack>
      </Stack>
      </Grid>
      
     
      
      
      </Grid>
      
      <TblContainerCamera>
                     <TblHeadCamera />
         <TableBody>
          {recordsAfterPagingAndSortingCamera().map((row) => (
             <TableRow sx={{ borderBottom: "1.5px solid black",
           }}>
               
               <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.name}</TableCell>
              <TableCell align="left"  sx={mainThemeStyle.cellRow}> {row.type == "0" ? "Entry" : "Exit"} </TableCell>
              
             <TableCell align="left">
                <LoadingButton loading={loading}  sx={mainThemeStyle.viewLogButtonRed} onClick={ () => handleCameraDelete(row.id,row.name)}>Delete</LoadingButton>
                    </TableCell>
             </TableRow>
           ))}
         </TableBody>
       
     </TblContainerCamera>
    
  {/*   <BelowTblPaginationCamera></BelowTblPaginationCamera> */}
      </Box>}
      </Box>

</>)}
{(currentTab == 5  &&  dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Elevator Control Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "block" }}}>
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={3}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Elevator Control Location </Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} onChange={handleChangeEventOfElevatorControl} checked={dataOfLocation.elevator_access.elevatorcontrol == "1" ? true : false}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
        
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Elevator Controller IP Address</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Call Elevator Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Release Relay Duration</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="elevtaor-ip-address"
        type="text"
        value={dataOfLocation.elevator_access.ipaddress}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="ipaddress"
                      onChange={handleChangeEleValue}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
     <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={3}>
      <Stack direction="column"  spacing={2}>
      <Typography></Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Call Elevator</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Release</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Elevator Control For App Unlock</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2}>
      <Stack direction="column"  spacing={2}>
      <Typography></Typography>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
    
    {/*   <Checkbox  sx={mainThemeStyle.formCheckBox} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false}/>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false}/>
      <Checkbox  sx={mainThemeStyle.formCheckBox} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false}/>
       */}
     
        
      </Stack>
      </Grid>
      </Grid>}
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Elevator Relay Programming</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </Box>
    <Box sx={{display: { xs: "block", md: "none" }}}>
      <Grid container spacing={2} padding= '10px' paddingBottom='32px' paddingTop='32px'> 
      <Grid item xs={8}>
      <Stack direction="column"  spacing={1}>
      <Typography  variant="h6" component="div" style={{ flexGrow: 1,color:'#fff' }}>Elevator Control Location </Typography>
      </Stack>
      </Grid>
      <Grid item xs={4}>
      <Stack direction="column"  spacing={2}>
      <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} onChange={handleChangeEventOfElevatorControl} checked={dataOfLocation.elevator_access.elevatorcontrol == "1" ? true : false}/>}
        
      />
      </Stack>
      </Grid>
      </Grid>
      
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <Grid container spacing={2} padding= '10px' paddingBottom='16px' paddingTop='16px'> 
      <Grid item xs={6}>
      <Stack direction="column"  spacing={2}>
        
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Elevator Controller IP Address</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of System Relays</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Call Elevator Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Floor Release Relay Duration</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Call Elevator</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Floor Release</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Disable Elevator Control For App Unlock</Typography>
      
      </Stack>
      </Grid>
      <Grid item xs={6}>
      <Stack direction="column"  spacing={2}>
      <TextField
        id="elevtaor-ip-address"
        type="text"
        value={dataOfLocation.elevator_access.ipaddress}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="ipaddress"
                      onChange={handleChangeEleValue}
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
     <TextField
        id="total-relays"
        type="number"
        value={dataOfLocation.elevator_access.totalrelays}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      name="totalrelays"
                      onChange={handleChangeEleValue}
                      variant="filled"
                        InputProps={{
                          inputProps: { 
                            max: 66, min: 0 
                        },
                            sx: {
                              
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="call-elevator-duration"
        type="number"
        value={dataOfLocation.elevator_access.elduration}
        name="elduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled" 
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
      <TextField
        id="text"
        type="number"
        value={dataOfLocation.elevator_access.flduration}
        name="flduration"
        onChange={handleChangeEleValue}
        sx={mainThemeStyle.InputMenuItem}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"       
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      />
        <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselevator" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselevator == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="disrelease" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.disrelease == "1" ? true : false} />}
        label=""
      />
       <FormControlLabel  sx={mainThemeStyle.popUpLable}
        control={<IOSSwitch sx={{ m: 1 }} name="diselapp" onChange={handleChangeEleCheckValue} checked={dataOfLocation.elevator_access.diselapp == "1" ? true : false} />}
        label=""
      />
      </Stack>
      </Grid>
    
   
      
      </Grid>}
      {dataOfLocation.elevator_access.elevatorcontrol == 1 && 
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Elevator Relay Programming</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Relay</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Floor</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[parseInt(row*8)]}
        name={parseInt(row*8)}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+1) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                      variant="filled"   
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+1]}
        name={(row*8)+1}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+2) ? true : false}
        variant="filled"   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+2]}
        name={(row*8)+2}
        onChange={handleChangeEleInnerRelay}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+3) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+3]}
        name={(row*8)+3}
        onChange={handleChangeEleInnerRelay}                
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+4) ? true : false}
        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled"   
        value={dataOfLocation.elevator_access.program[(row*8)+4]}
        name={(row*8)+4}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+5) ? true : false}
        onChange={handleChangeEleInnerRelay}   
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        value={dataOfLocation.elevator_access.program[(row*8)+5]}
        name={(row*8)+5}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+6) ? true : false}
        onChange={handleChangeEleInnerRelay}   
        variant="filled" 
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={ dataOfLocation.elevator_access.program[(row*8)+6]}
        name={(row*8)+6}
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+7) ? true : false}
        onChange={handleChangeEleInnerRelay}  
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> <TextField
        id="text"
        type="text"
        variant="filled" 
        value={dataOfLocation.elevator_access.program[(row*8)+7]}
        name={(row*8)+7}
        onChange={handleChangeEleInnerRelay}  
        disabled={dataOfLocation.elevator_access.totalrelays < (parseInt(row*8)+8) ? true : false}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                          inputProps: { 
                            maxLength:2,
                        },
                         
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'25px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 15px 0',
                                textIndent: '15px !important',
                              
                               
                                
                            },
                        }}
                       
      /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
    </Box>
    
</>)}

{(currentTab == 6  &&  dataOfLocation.cam_brand == "ResidentLink" && <>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Brivo Control Settings
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
      
           <Box sx={{display: { xs: "none", md: "block" }}}>
      
      <Grid container spacing={2} padding= '16px'> 
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
     <Typography
        height={40}
        
        sx={mainThemeStyle.popUpLableAnswer}
                       
        >{dataOfLocation.brivo_access.brivofloors}</Typography>
      
      
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
     
      </Grid>
    
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '38px',
        marginRight:'38px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    <Box sx={{display: { xs: "block", md: "none" }}}>
      
      <Grid container spacing={2} padding= '10px' paddingBottom='5px' paddingTop='32px'> 
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Brivo Primary Door Id</Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}># of Brivo Floors</Typography>
      </Stack>
      </Grid>
      <Grid item xs={5}>
      <Stack direction="column"  spacing={2}>
      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = 'brivoprimeid'
            value={getBrivoValueMain()}
          onChange={handleChangeBrivoDoorValue}
          variant="filled"
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
     <Typography
        height={40}
        
        sx={mainThemeStyle.popUpLableAnswer}
                       
        >{dataOfLocation.brivo_access.brivofloors}</Typography>
      
      
        
      </Stack>
      </Grid>
      <Grid item xs={2}></Grid>
     
      </Grid>
    
      <TableContainer component={Paper} sx={{
        width:'auto',
        height: 'auto',
        marginLeft: '10px',
        marginRight:'10px',
        marginBottom:'38px',
        backgroundColor:'#171E29',
       
        }}>
      <Table sx={{ minWidth: 650 }} >
        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
            
            <TableCell align="center" sx={mainThemeStyle.popUpLable} colSpan={16}>Brivo Relays</TableCell>
            
          </TableRow>
         
        </TableHead>

        
        <TableHead sx={{ borderBottom: "1.5px solid black",
        }}>
          <TableRow>
          <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>	Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}> Floor</TableCell>
            <TableCell align="right" sx={mainThemeStyle.cellHeader}>Door Id</TableCell>
          </TableRow></TableHead>
        <TableBody>
         {rowsElevatorControl.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+1}
              </TableCell>
              <TableCell align="right">
                
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {parseInt(row*8)}
            value={getBrivoValue(parseInt(row*8))}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+1) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                </TableCell>
              <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+2}
              </TableCell>
              <TableCell align="right">
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+1}
            value={getBrivoValue(parseInt(row*8)+1)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+2) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
                
                
               </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {parseInt(row*8)+3}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+2}
            value={getBrivoValue((row*8)+2)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+3) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+4}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+3}
            value={getBrivoValue((row*8)+3)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+4) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select>
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+5}
              </TableCell>
              <TableCell align="right">
                      <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+4}
            value={getBrivoValue((row*8)+4)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+5) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
                </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+6}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+5}
            value={getBrivoValue((row*8)+5)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+6) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+7}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+6}
            value={getBrivoValue((row*8)+6)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+7) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> 
              </TableCell>
      <TableCell sx={mainThemeStyle.cellRow}>
              {(row*8)+8}
              </TableCell>
              <TableCell align="right"> 
              <Select
            sx={{ color: 'rgba(135, 135, 135, 0.5)',
            height:'33px',
            marginTop:'4px',
            marginBottom:'3px',
            borderRadius: '5px',
            backgroundColor: '#121212',
            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
            padding: '5px 0 5px 0',
            "& .MuiSvgIcon-root": {
              color: "rgba(135, 135, 135, 0.5)",
            },
            
            '&.Mui-disabled': {
             

              background: 'rgba(135, 135, 135, 0.5)',
            },
            "& input.Mui-disabled": {
              color: "white"
            }
            }}
            name = {(row*8)+7}
            value={getBrivoValue((row*8)+7)}
          onChange={handleChangeBrivoRelay}
          variant="filled"
          disabled={dataOfLocation.brivo_access.brivofloors < (parseInt(row*8)+8) ? true : false}
          hiddenLabel
          MenuProps={MenuProps}>
            {dataOfLocation.brivo_array.map(menuItem => {
                  
                  return (
                    <MenuItem  sx={
                      {
                      
                      color:"#fff",
                      backgroundColor: "#2F323A",
                      borderBottom: '.5px solid grey',
                      fontFamily: "Lato",fontSize:'12px',
                  }}
                   value={menuItem.name}>
                      {menuItem.name}
                    </MenuItem>
                  );
                })}
          </Select> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
   
    
</>)}
 
 {showUpdateButton &&  <Box sx= {mainThemeStyle.boxDown} backgroundColor="menubarprimary.main">
 <Stack spacing={2} direction="column">
 <Typography></Typography>
  <LoadingButton
         
         sx={mainThemeStyle.normalButtonRelative}
         loading={loading}
         onClick={handleUpdateLocation}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Add New Location
       </LoadingButton>

       <LoadingButton
         
         sx={mainThemeStyle.normalButtonRelativeRelative}
         loading={loading}
         onClick={handleUpdateCancel}
         variant="outlined"
       >
         Cancel
       </LoadingButton>
       </Stack>
    
      </Box>}
</Box> }
{currentDisableValue == true && 
 <Box sx={mainThemeStyle.box} >
 <Typography sx={mainThemeStyle.lable}>Access For This Page Is Blocked</Typography>
 
  
  
  </Box>
    
    
    }
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
 </> 
          
          
          
          
          ;
};

 