import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider,Select, MenuItem,FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from "./axios";
import useToken from "./useToken";
import GlobalAlert from "./GlobalAlert";
import TimezoneSelect from 'react-timezone-select'
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function EditAdminUser ( {idOfuser,RefreshToken,...props}){

  const {token} = useToken();
  let [dataOfUser,setDataOfUser] = useState(idOfuser);
  const [currentTab, setCurrentTab] = useState(0);
  const { getAlertTitle} = GlobalAlert();
  const [showUpdateButton,setshowUpdateButton] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [imageUrl, setImageUrl] = useState( dataOfUser.user_image != null ? "https://dev.mdvaccess.com/"+dataOfUser.user_image : NoImage);
  useEffect(() => {
    console.log("readr image calling");
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      console.log("sle"+selectedImage.name);
    }
  }, [selectedImage]);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    
    
  }
 

 
  
  const [customField,setCustomField ] =  useState(dataOfUser.custom_field);
 
  const [loading, setLoading] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
 


  const setUserForData = () => {
    setshowUpdateButton(true);
    setDataOfUser({
      id: dataOfUser.id,
      type:dataOfUser.type,
      fname: dataOfUser.fname,
      lname: dataOfUser.lname,
      phone_no: dataOfUser.phone_no,
      unitno: dataOfUser.unitno,
      user_email: dataOfUser.user_email,
      dateadded: dataOfUser.dateadded,
      user_image: dataOfUser.user_image,
      image_upload_data: dataOfUser.image_upload_data? dataOfUser.image_upload_data : ''
      });
    

  }
  
  const handleInputChange=(event) =>{
    console.log(dataOfUser);
    let ds = event.target.name;
    dataOfUser[ds] = event.target.value;
    setUserForData();
  }
 
 
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const updateMyPostData = async () => {
    try {
      const res = await axios.patch("/administrator/updateuser/"+dataOfUser.id+"/" +dataOfUser.type,{"id":dataOfUser.id,
      "type":dataOfUser.type,
      "fname":dataOfUser.fname,
      "lname": dataOfUser.lname,
      "phone_no": dataOfUser.phone_no,
      "unitno": dataOfUser.unitno,
      "user_email": dataOfUser.user_email,
      "user_image": dataOfUser.user_image,
      "image_upload_data": dataOfUser.image_upload_data,
      "issub":token.issub,"subId":token.subid
    
    }, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
    'token' : token.token,
    'tokenid' : token.email }});
      console.log( res.data);
      setOpenSnackData(getAlertTitle('EUES'));
      setSeverityData("success");
      setOpenSnack(true);
      setLoading(false);
      setshowUpdateButton(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
      setOpenSnackData(getAlertTitle('EUEF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  
  const handleUpdateUser = () => {
    console.log(dataOfUser);
    setLoading(true);
    let userfname = dataOfUser.fname;
    userfname = userfname.replaceAll(" ","");
    if(userfname == ""){
      setOpenSnackData(getAlertTitle('EUUFNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }
    let userlname = dataOfUser.lname;
    userlname = userlname.replaceAll(" ","");
    if(userlname == ""){
      setOpenSnackData(getAlertTitle('EUULNNE'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    }

    if (!isValidEmail(dataOfUser.user_email)) {
      setOpenSnackData(getAlertTitle('EUUENV'));
      setSeverityData("error");
      setOpenSnack(true);
      setLoading(false);
      return;
    } 

    updateMyPostData();
  }
  return <> <Box sx={mainThemeStyle.boxTopHeader}>
    
  
            
</Box>
<Box sx={mainThemeStyle.boxHeaderBelow}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       User Identity
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Number </Typography>
      <Typography  sx={mainThemeStyle.popUpLable} height={40}>Date Added</Typography>

      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      {showUpdateButton && <LoadingButton
         
         sx={mainThemeStyle.normalButton}
         loading={loading}
         onClick={handleUpdateUser}
         variant="outlined"
         startIcon={<SaveIcon />}
         loadingPosition="start"
       >
         Save User Details
       </LoadingButton>}
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
      <TextField
                       
                        height={40}
                        type= 'text'
                        name="fname"
                        value={dataOfUser.fname}
                        sx={mainThemeStyle.InputMenuItem}
                        onChange={handleInputChange}
                        variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                          
                      InputProps={{
                           
                        sx: {
                            
                            color:'rgba(135, 135, 135, 0.5)',
                            height:'33px',
                            borderRadius: '5px',
                            backgroundColor: '#121212',
                            boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                            padding: '0 0 15px 0',
                            textIndent: '15px !important',
                          
                           
                            
                        },
                    }}
                    />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="lname"
                       value={dataOfUser.lname}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                         
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                   />
      <TextField
                       
                       height={40}
                       type= 'text'
                       name="unitno"
                       value={dataOfUser.unitno}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                         
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                   />
     <Typography sx={mainThemeStyle.popUpLableAnswer} height={40}> {dataOfUser.dateadded} </Typography>
     <TextField
                       
                       height={40}
                       type= 'text'
                       name="user_email"
                       value={dataOfUser.user_email}
                       sx={mainThemeStyle.InputMenuItem}
                       onChange={handleInputChange}
                       variant="filled"
                       InputLabelProps={{ shrink: true }
                     }
                         
                     InputProps={{
                          
                       sx: {
                           
                           color:'rgba(135, 135, 135, 0.5)',
                           height:'33px',
                           borderRadius: '5px',
                           backgroundColor: '#121212',
                           boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                           padding: '0 0 15px 0',
                           textIndent: '15px !important',
                         
                          
                           
                       },
                   }}
                   />
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> User Image </Typography>
      
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Phone </Typography>
     
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
        <img  src={imageUrl} height={200} width={200}></img>
        <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfUser.image_upload_data = reader.result;
              setUserForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button>
        </Stack>
      
      
                      
                   <Typography  sx={mainThemeStyle.popUpLable} height={40}>{dataOfUser.phone_no}</Typography>
                   
      </Stack>
      </Grid>
     
      
      </Grid>
      
</Box>


<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      
 </> 
          
          
          
          
          ;
};

export default EditAdminUser;