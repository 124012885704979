import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider,Select, MenuItem,FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText } from "@mui/material";
import NoImage from './asset/noimage.png';
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import  NestedButtonDropDownHeader  from "./components/header/NestedButtonDropDownHeader";
import ManageLocation from "./ManageLocation";
import EditLocation from "./EditLocation";
import AddLocation from "./AddLocation";
import CallLog from "./CallLog";
import AddIcon from '@mui/icons-material/Add';
import LpTags from "./LpTags";
import { useState } from "react";
import GuestPIN from "./GuestPIN";
import Stack from '@mui/material/Stack';
import GuestParking from "./GuestParking";
import { useParams,useNavigate ,useLocation } from "react-router-dom";
import { useEffect } from "react";
import PuffLoader from "react-spinners/PuffLoader"; 
import useToken from "./useToken";
import axios from "./axios";
const UserProfile = (props) => {
  console.log("IN location Page");
  const navigate = useNavigate();
  const location = useLocation();
  const {RefreshToken} = props;
  const {id} = useParams();
  console.log('id is '+id);
  const {token} = useToken();
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [recordsDirectory,setRecordsDirectory] = useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [titleforalert, setTitleforalert] = React.useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/enduser/profile/"+token.phone+"/"+token.type,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.phone },
      });
      setRecordsDirectory(res.data);
    } catch (error) {
      console.log(error.message);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
			}
    }
  };
  useEffect(() => {
    getMyPostData();
  }, []);
  const theme = Theme;
  const[dataOfLocation,setDataOfLocation]  = useState('');
  const[addDataOfLocation,setaddDataOfLocation] = useState('');
  
  const [currentId,setCurrentId] = React.useState("");
  const [openDelete, setOpenDelete] = React.useState(false);
 
 
  
  
  return (
<ThemeProvider theme={theme}>

    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
     <Typography sx={mainThemeStyle.lable}>User Profile</Typography>
    </Box>
    
   
    <Box sx={mainThemeStyle.boxTopHeader}>
    
  
            
</Box>



 <Box sx={mainThemeStyle.boxHeaderBelow}>
 
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       User Profile
                   </Typography>
                   
               </div>
              
           </div>
           <Divider sx={{backgroundColor:'white'}} />
           <Box sx={{display: { xs: "none", md: "flex" }}}>
           <Grid container spacing={2} padding= '32px'> 
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Number </Typography>
     {/*  <Typography  sx={mainThemeStyle.popUpLable} height={40}>User Floor</Typography> */}
     <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
     <Typography sx={mainThemeStyle.popUpLable} height={40}>Phone </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={2.5}>
      <Stack direction="column"  spacing={2}>
                <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.fname}</Typography>

                    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.lname}</Typography>
    
   
                    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.unitno}</Typography>
    
                {/*    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.floor}</Typography> */}
                <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.user_email}</Typography>
       
                <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.phone_no}</Typography>
          
      </Stack>
      </Grid>
      <Grid item xs={1}>
    
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={200}> User Image </Typography>
    
      
      </Stack>
      </Grid>
      <Grid item xs={2.5}>
        <Stack direction="column"  spacing={2}>
          <Stack direction="row" spacing={2}>
        <img  src={recordsDirectory.user_image != null ? "https://dev.mdvaccess.com/"+recordsDirectory.user_image : NoImage} height={200} width={200}></img>
        {/* <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfUser.image_upload_data = reader.result;
              setUserForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button> */}
        </Stack>
               
                
                 
      </Stack>
      </Grid>
     
      
      </Grid>
        </Box>
        <Box sx={{display: { xs: "flex", md: "none" }}}>
           <Grid container spacing={2} padding= '10px' marginTop='10px'> 
      <Grid item xs={5}>
        <Stack direction="column"  spacing={2}>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> First Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Last Name </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Unit Number </Typography>
      {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>User Floor</Typography> */}
      <Typography sx={mainThemeStyle.popUpLable} height={100}> User Image </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}> Email </Typography>
      <Typography sx={mainThemeStyle.popUpLable} height={40}>Phone </Typography>
      </Stack>
      </Grid>
      
      <Grid item xs={7}>
      <Stack direction="column"  spacing={2}>
                <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.fname}</Typography>

                    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.lname}</Typography>
    
   
                    <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.unitno}</Typography>
    
                   {/* <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.floor}</Typography> */}
                   <Stack direction="row" spacing={2}>
        <img  src={recordsDirectory.user_image != null ? "https://dev.mdvaccess.com/"+recordsDirectory.user_image : NoImage} height={100} width={100}></img>
        {/* <Button  sx={mainThemeStyle.normalButton} variant="contained" component="label">Upload <input hidden accept="image/png, image/jpeg" multiple type="file" 
        onChange={ 
          e => {
            if (e.target.files[0].size > 2000000) {
              console.log("File too large");
              return;
            }
            
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
        
            reader.onload = () => {
              console.log(reader.result);
              setSelectedImage(e.target.files[0]);
              dataOfUser.image_upload_data = reader.result;
              setUserForData();
            };
            reader.onerror = error => {
              console.log("Error: ", error);
            };


          
          }
        }/></Button> */}
        </Stack>
        <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.user_email}</Typography>
       
                      
                   <Typography  sx={mainThemeStyle.popUpLable} height={40}>{recordsDirectory.phone_no}</Typography>
          
      </Stack>
      </Grid>
      
     
     
     
      
      </Grid>
        </Box>

</Box>

<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
{/* <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleCloseDelete} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActiveDelete} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog> */}

    
    
    </Box>
   
</ThemeProvider>);
};

export default UserProfile;