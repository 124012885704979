import React from "react";
import {  ThemeProvider } from '@mui/material/styles';
import Theme from './components/ItemTheme'
import { Box } from "@mui/system";
import { Button, Grid, List, Typography } from "@mui/material";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ReactComponent as LogoManageDis } from './asset/MDsvg.svg';
import { ReactComponent as LogoManageDealer } from './asset/ManageDealer.svg';
import { ReactComponent as LogoManageUser } from './asset/ManageUser.svg';
import { ReactComponent as LogoSystemLog } from './asset/SystemLog.svg';
import SvgIcon from "@mui/material/SvgIcon";
import useToken from "./useToken";
import {mainThemeStyle} from './components/MainTheme';
import { useParams,useNavigate  } from "react-router-dom";
const ManageIcon=()=>(
  <SvgIcon>
    <LogoManageDis/>
  </SvgIcon>
);

const ManageDealerIcon=()=>(
  <SvgIcon>
    <LogoManageDealer/>
  </SvgIcon>
);

const ManageUserIcon=()=>(
  <SvgIcon>
    <LogoManageUser/>
  </SvgIcon>
);


const SystemLogIcon=()=>(
  <SvgIcon>
    <LogoSystemLog/>
  </SvgIcon>
);


const Home = props => {
  const theme = Theme;
  const {token} = useToken();
  console.log("IN home Page");
  const navigate = useNavigate();
  const handleManageProperty = () =>{
    navigate('../location/managelocation');
  }

  const handleAddProperty = () => {
    navigate('../location/addlocation');
  }

  const handleManageDistributor = () =>{
    navigate('../activity/entrylogs');
  }

  const handleAddDistributor = () => {
    navigate('../activity/calllogs');
  }

  const handleManageDealer = () =>{
    navigate('../user/manageuser');
  }

  const handleAddDealer = () => {
    navigate('../user/adduser');
  }

  const handleManageUser = () => {
    navigate('../setup/account/profile');
  }

  const handleSystemLog = () => {
   // navigate('../webviewer');
   window.open("https://dev.mdvaccess.com/webviewer", "_blank", "noreferrer");
  }
  return <ThemeProvider theme={theme}>
    <Box sx={mainThemeStyle.mainbox} backgroundColor="menubarprimary.main">
    <Box sx={mainThemeStyle.box}>
    <Typography sx={mainThemeStyle.lable}>Home</Typography>
    
            
           
    {/* {token.name == 'SUPER ADMIN' && <Button
                  variant="text"
                  key="user"
                  disabled
                 
                  sx={{ mt: "-6px", right:"0px",position: "absolute", color: "white","&:disabled": {color: "#68BBE3" ,backgroundColor:'#ffffff00'}, height: "60px",borderRadius: 0,fontFamily: "Lato",
               fontSize:'100%',width:'400px'}}
                >
                   Currently Managing :  {token.fname}
                  
                </Button>} */}
    </Box>
    
    {/* <Typography sx={mainThemeStyle.lableshortcut}>Shortcuts</Typography> */}
  <Box sx={{position: 'absolute',
width:'auto',
height: '280px',
top:'220px',
left: '38px',
display: 'flex',
right: '38px',
flexGrow: 1,
display: { xs: "none", md: "flex"}
}}>
  
<Grid container rowSpacing={0} columnSpacing={{ xs: 2, sm: 3, md: 4 }} sx={{ flexGrow: 1,  }}>
        <Grid item xs={3}>
          <Button fullWidth={true} size="large" startIcon={<MapsHomeWorkOutlinedIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageProperty}>
          Manage Location</Button>
        </Grid>
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<AddOutlinedIcon />} sx={mainThemeStyle.boxHome} onClick={handleAddProperty}>Add Location</Button>
        </Grid>
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<ManageIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageDistributor}>Manage Entry Logs</Button>
        </Grid>
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<ManageIcon />} sx={mainThemeStyle.boxHome} onClick={handleAddDistributor}>Manage Call Logs</Button>
        </Grid>
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<ManageDealerIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageDealer}> Manage User</Button>
        </Grid>
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<AddOutlinedIcon />} sx={mainThemeStyle.boxHome} onClick={handleAddDealer}>Add User</Button>
        </Grid>
        <Grid item xs={3}>
        <Button fullWidth={true} size="large" startIcon={<SystemLogIcon />} sx={mainThemeStyle.boxHome} onClick={handleSystemLog}>Webviewer</Button>
        </Grid>
        <Grid item xs={3}>
        <Button  item xs={3} fullWidth={true} size="large" startIcon={<ManageUserIcon />} sx={mainThemeStyle.boxHome} onClick={handleManageUser}>Manage Profile</Button>
        </Grid>
      </Grid>
      </Box>
      <Box sx={{position: 'absolute',
width:'auto',
height: '280px',
top:'80px',
left: '38px',
display: 'flex',
right: '38px',
flexGrow: 1,
display: { xs: "flex", md: "none"}
}}>
      <Grid container rowSpacing={0} columnSpacing={{ xs: 4, sm: 5, md: 6 }} sx={{ flexGrow: 1,   }}>
        <Grid item xs={6}>
          <Button fullWidth={true} size="large" startIcon={<MapsHomeWorkOutlinedIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleManageProperty}>
          Manage Location</Button>
        </Grid>
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<AddOutlinedIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleAddProperty}>Add Location</Button>
        </Grid>
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<ManageIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleManageDistributor}>Manage Entry Logs</Button>
        </Grid>
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<ManageIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleAddDistributor}>Manage Call Logs</Button>
        </Grid>
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<ManageDealerIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleManageDealer}>Manage User</Button>
        </Grid>
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<AddOutlinedIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleAddDealer}>Add User</Button>
        </Grid>
        <Grid item xs={6}>
        <Button fullWidth={true} size="large" startIcon={<SystemLogIcon />} sx={mainThemeStyle.boxHomeMobile}  onClick={handleSystemLog}>Webviewer</Button>
        </Grid>
        <Grid item xs={6}>
        <Button   fullWidth={true} size="large" startIcon={<ManageUserIcon />} sx={mainThemeStyle.boxHomeMobile} onClick={handleManageUser}>Manage Profile</Button>
        </Grid>
      </Grid>
</Box>
    </Box></ThemeProvider>;
};

export default Home;