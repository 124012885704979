import * as React from "react";
import { Box } from "@mui/system";
import { Button,Checkbox, Switch,Grid, InputLabel, List,Divider, FormControlLabel, Typography,ButtonGroup,Snackbar ,Alert ,IconButton, Icon,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText, cardMediaClasses } from "@mui/material";
import {mainThemeStyle} from './components/MainTheme';
import { NestedButtonDropdown } from "./components/header/NestedButtonDropdown";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faker } from '@faker-js/faker';
import { format } from 'date-fns';
import { useState,useRef,useEffect } from "react";
import useTable from "./components/useTable";
import useInnerTable from "./components/useInnerTable";
import SvgIcon from "@mui/material/SvgIcon";
import NoImage from './asset/noimage.png';
import Popup from "./components/Popup";
import _ from "lodash";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import useToken from "./useToken";
import { useNavigate } from "react-router-dom";
import TimezoneSelect from 'react-timezone-select';
import axios from "./axios";
import PuffLoader from "react-spinners/PuffLoader"; 
import GlobalAlert from "./GlobalAlert";
import GlobalRoleAccess from "./GlobalRoleAccess";
import GlobalRoleAccessInner from "./GlobalRoleAccessInner";
import LoadingButton from '@mui/lab/LoadingButton';
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


function CallingGroup ( props){
  const {token} = useToken();
  const { getAlertTitle} = GlobalAlert();
  const {RefreshToken} = props;
  const [EnLog,setEnLog] = useState([]);
  let [loading, setLoading] = useState(true);
  let [loadingbtn,setloadingbtn] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackData, setOpenSnackData] = React.useState("This is Success Data");
  const [severityData, setSeverityData] = React.useState("success");
  const [arrayOfChange, setArrayOfChange] = useState(EnLog);
  const navigate = useNavigate();
  const {getRoleAccessVal} = GlobalRoleAccess();
  const {getRoleAccessInnerVal} = GlobalRoleAccessInner();

  const getDisableValueAdd = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
       console.log('role name' +  token.name);
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("44").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("44").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("44").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("44").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("44").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("44").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("44").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("44").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }

  const getDisableValueEdit = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("45").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("45").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("45").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("45").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("45").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("45").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("45").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("45").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }
  const getDisableValueDelete = () => {
    if(token.role == 'superadmin' || token.name === 'SUPER ADMIN'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("46").administrator_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("46").administrator_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'distributor' || token.name === 'SUPER DISTRIBUTOR'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("46").distributor_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("46").distributor_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'dealer' || token.name === 'SUPER DEALER'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("46").dealer_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("46").dealer_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else if(token.role == 'admin'){
      if(token.sub_type == "1"){
        let valOfDisable = getRoleAccessVal("46").admin_control;
          if(valOfDisable == "1"){
            return false;
          }
          else{
            return true;
          }
      }
      else{
        let valOfDisable = getRoleAccessVal("46").admin_sub_control;
        if(valOfDisable == "1"){
          return false;
        }
        else{
          return true;
        }
      }
    }
    else{
      return true;
    }
    
    
  }

  const handleCloseSnack= (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  const getMyPostData = async () => {
    try {
      const res = await axios.get("/admin/callgroup/"+token.id,{
        headers: { 'Content-Type':  'application/json',
        'token' : token.token,
        'tokenid' : token.email },
      });
     // console.log(res.data);
      setEnLog(res.data);
      setArrayOfChange(res.data);
      setLoading(false);
      setloadingbtn(false);

    } catch (error) {
    //  console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const headCells = [
    { id: 'group_name', label: 'Group Name' },
    { id: 'access', label: 'Action', disableSorting: true },
  ];
  const {
    TblContainer ,
    TblHead ,
    TblPagination ,
    BelowTblPagination,
    recordsAfterPagingAndSorting ,
    setPageToNewOne 
  } = useInnerTable(EnLog, headCells, filterFn,8,650);

  // NOTE:  calling the function
  useEffect(() => {
    getMyPostData();
  }, []);

  const [currentGroupName,setCurrentGroupName] = useState('');
  const [titleforalert, setTitleforalert] = React.useState("");
  const [currentDeleteID, setCurrentDeleteID] = useState('');
  const addGroupPost = async () => {
   // console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.post("/admin/addnewgroup/"+token.id,{group_name:currentGroupName,user_id:token.id,issub:token.issub,subId:token.subid}, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email }});
      setOpenSnackData(getAlertTitle('CGGAS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
      getMyPostData();
     
    } catch (error) {
    //  console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('CGGAF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  const handleAddGroup = () => {
    if(currentGroupName.length <= 0){
      setOpenSnackData(getAlertTitle('CGGNNE'));
      setSeverityData("error");
      setOpenSnack(true);
    }
    else{
      addGroupPost();
    }

  }
  const handleGroupNameChange =(event) =>{
    setCurrentGroupName(event.target.value);
  }


  const tableGroupNameChange = (event) =>{
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < arrayOfChange.length ; i++){
      if(arrayOfChange[i].group_id == ds ){
          idIn = i;
          break;
      }
    }
    arrayOfChange[idIn].group_name = event.target.value;
    setArrayOfChange(arrayOfChange);
  }
  const updateGroupPost = async (val) => {
  //  console.log("In update post data");
    setloadingbtn(true);
    try {
      const res = await axios.patch("/admin/updategroup/"+token.id,val, {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8' ,
      'token' : token.token,
      'tokenid' : token.email}});
      setOpenSnackData(getAlertTitle('CGGUS'));
      setSeverityData("success");
      setOpenSnack(true);
      //setLoading(false);
      //setshowUpdateButton(false);
    //  console.log(res.data);
      getMyPostData();
     
    } catch (error) {
      //console.log(error.response.data);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('CGGUF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };
  const handleTableNameEditClick = (event) => {
  let ds = event.target.name;
    let idIn;
    for(let i = 0; i < arrayOfChange.length ; i++){
      if(arrayOfChange[i].group_id == ds ){
          idIn = i;
          break;
      }
    }
    navigate('../user/editgroup', {state:{editgroup : arrayOfChange[idIn]}});
  }
  const handleTableNameSave = (event) => {
    let ds = event.target.name;
    let idIn;
    for(let i = 0; i < arrayOfChange.length ; i++){
      if(arrayOfChange[i].group_id == ds ){
          idIn = i;
          break;
      }
    }
    //console.log(EnLog);
   // console.log(arrayOfChange);
   /* if( arrayOfChange[idIn].group_name == EnLog[idIn].group_name){
      setOpenSnackData("Group Updated Successfully.");
      setSeverityData("success");
      setOpenSnack(true);
    }
    else{*/
        let val = arrayOfChange[idIn].group_name;
        if(val.length <=  0){
          setOpenSnackData(getAlertTitle('CGGNNE'));
          setSeverityData("error");
          setOpenSnack(true);
        }
        else{
          let jsd = { "group_name":arrayOfChange[idIn].group_name,
          "group_id":arrayOfChange[idIn].group_id,
            "issub":token.issub,
            "subId":token.subid};
          updateGroupPost(jsd);
        }
    //}
   
  }
  const [open, setOpen] = React.useState(false);
  const [currentDelteGroupName, setCurrentDelteGroupName] = React.useState('');
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseActive =() =>{
    setOpen(false);
    deleteMyGroupPostData();
  }
  const handleGroupDelete = (val ,val2) =>{
    setTitleforalert("Are you sure you want to delete this Group named "+ val + " ?");
    setCurrentDelteGroupName(val);
    setCurrentDeleteID(val2);
    //console.log(val2);
    setOpen(true);
  }
  const deleteMyGroupPostData = async () => {
    setloadingbtn(true);
    try {
      const res = await axios.delete("/admin/deletegroup/"+token.id+"/"+currentDeleteID,
      {headers: { 'Content-Type':  'application/x-www-form-urlencoded; charset=UTF-8',
      'token' : token.token,
      'tokenid' : token.email,
      'issub':token.issub,
      'subId':token.subid,
      'groupname':currentDelteGroupName }});
    //  console.log( res.data);
      setOpenSnackData(getAlertTitle('CGGDS'));
      setSeverityData("success");
      setOpenSnack(true);
     // setLoading(false);
      getMyPostData();
    } catch (error) {
     // console.log(error.message);
      setLoading(false);
      setloadingbtn(false);
      setOpenSnackData(getAlertTitle('CGGDF'));
      setSeverityData("error");
      setOpenSnack(true);
      if(error.response.data.message == 'UnAuthorized'){
        RefreshToken();
      }
    }
  };

  
  return <> <Box sx={mainThemeStyle.boxTopHeader}>

</Box>

<Box sx={mainThemeStyle.boxHeaderBelow}>
<Box sx={{ display: { xs: "none", md: "flex" }}}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   <Typography variant="h6" component="div" style={{  paddingLeft: '16px',paddingTop: '16px',flexGrow: 1,color:'#fff' }}>
                       Add Group
                   </Typography>
                   <TextField
        id="text"
        type="text"
      placeholder="Group Name"
      value={currentGroupName}
      onChange={handleGroupNameChange}
       variant="filled"
       disabled = {getDisableValueAdd()}
                        InputLabelProps={{ shrink: true }
                      }
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                              
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                                
                            },
                        }}
                       
      />
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddGroup} disabled = {getDisableValueAdd()}> Add </LoadingButton>
      </Stack>
               </div>
              
           </div></Box>
           <Box sx={{ display: { xs: "flex", md: "none" }}}>
<div  sx={{
       right: '54px' ,left: '54px',borderRadius:'0px'}}>
               <div style={{ display: 'flex' }}>
                <Stack direction='row' spacing={2} padding={2}>
                   
                   <TextField
        id="text"
        type="text"
      placeholder="Group Name"
      value={currentGroupName}
      onChange={handleGroupNameChange}
       variant="filled"
                        InputLabelProps={{ shrink: true }
                      }
                      disabled = {getDisableValueAdd()}
                          style={{paddingTop: '16px'}}
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '0 0 18px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
      <LoadingButton sx={mainThemeStyle.normalButtonDown} loading={loadingbtn} onClick={handleAddGroup} disabled = {getDisableValueAdd()}> Add </LoadingButton>
      </Stack>
               </div>
              
           </div></Box>
           <Divider sx={{backgroundColor:'white'}} />
   
           <TblContainer>
           <TblHead />
        <TableBody>
         {EnLog.map((row) => (
            <TableRow sx={{ borderBottom: "1.5px solid black",
          }}>
              <TableCell align="left" sx={mainThemeStyle.InputFilter}>
              <TextField
        id="text"
        type="text"
        hiddenLabel
        name={row.group_id}
        defaultValue={row.group_name}
       variant="filled"
       onChange={tableGroupNameChange}
       disabled = {getDisableValueEdit()}
                        InputLabelProps={{ shrink: true }
                      }
                          
                        InputProps={{
                           
                            sx: {
                                
                                color:'rgba(135, 135, 135, 0.5)',
                                height:'33px',
                                borderRadius: '5px',
                                backgroundColor: '#121212',
                                boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
                                padding: '5px 0 5px 0',
                                textIndent: '15px !important',
                                '&.Mui-disabled': {
             

                                  background: 'rgba(135, 135, 135, 0.5)',
                                },
                                
                                "& input.Mui-disabled": {
                                  color: "white"
                                }
                               
                                
                            },
                        }}
                       
      />
              </TableCell>
              <TableCell align="left">  <Stack direction="row" spacing={2}>
                                    <Typography></Typography>
                                    
                                    <LoadingButton  sx={mainThemeStyle.viewLogButton}  loading={loadingbtn} name={row.group_id} onClick={handleTableNameSave} disabled = {getDisableValueEdit()}>Save Changes</LoadingButton>
                                    <LoadingButton  sx={mainThemeStyle.viewLogButton}  loading={loadingbtn} name={row.group_id} onClick={handleTableNameEditClick} disabled = {getDisableValueEdit()}>Edit</LoadingButton>
              <LoadingButton  sx={mainThemeStyle.viewLogButtonRed} loading={loadingbtn} onClick={() =>{handleGroupDelete(row.group_name,row.group_id)}} disabled = {getDisableValueDelete()}>Delete</LoadingButton></Stack>
              
              </TableCell>
   
              
            </TableRow>
          ))}
        </TableBody>
     
    </TblContainer>
<BelowTblPagination></BelowTblPagination>
    <PuffLoader color="#878787" loading={loading} cssOverride={{display: "block",
    margin: "0 auto",
    marginTop:"50px",
    marginBottom:"50px",}} />
</Box>
<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severityData} sx={{ width: '100%' }}>
          {openSnackData}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          padding: '16px',
      
         backgroundColor:'#00000000',
         
      }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
        
      
         backgroundColor:'#2A2F3B',
         color:'white'
         
      }}>
          {titleforalert}
        </DialogTitle>
        <DialogContent sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          
        </DialogContent>
        <DialogActions sx={{
        
      
        backgroundColor:'#2A2F3B',
        color:'white'
        
     }}>
          <Button onClick={handleClose} sx={mainThemeStyle.normalBigButton}>Cancel</Button>
          <Button onClick={handleCloseActive} autoFocus sx={mainThemeStyle.normalBigButtonRed}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
 </> 
          
          
          
          
          ;
};

export default CallingGroup;